// SHould really share/refactor this into separate repo(s)

const textNodesUnder = (node) => {
    const a = [];
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null, false);
    while (walker.nextNode()) a.push(walker.currentNode);
    return a;
};

export const extractText = (strHtml) => {
    if (!strHtml) return null;
    let burner = document.createElement('div');
    burner.innerHTML = strHtml;
    let tnodes = textNodesUnder(burner);
    if (!tnodes.length) return null;
    return tnodes.map((tn) => tn.textContent).join(' ');
};

export const appendStyle = (css) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) style.styleSheet.cssText = css;
    else style.appendChild(document.createTextNode(css));
    head.appendChild(style);
};

export const highlight = (strHtml, regex, highlightClass = 'highlight') => {
    let match;
    let indices = [];
    let searchStrs = [];

    while ((match = regex.exec(strHtml)) != null) {
        searchStrs.push(match[0]);
        indices.push(match.index);
    }

    if (indices.length > 0) {
        const reversedIndices = indices.reverse();
        const reversedStr = searchStrs.reverse();
        const plainText = strHtml.indexOf('>') < 0;
        for (let i of reversedIndices.keys()) {
            const index = reversedIndices[i];
            const searchStr = reversedStr[i];
            const inTag =
                plainText ||
                (strHtml.indexOf('<', index) > index && strHtml.indexOf('<', index) < strHtml.indexOf('"', index));
            if (inTag)
                strHtml =
                    strHtml.substring(0, index) +
                    '<span class="' +
                    highlightClass +
                    '">' +
                    strHtml.substring(index, index + searchStr.length) +
                    '</span>' +
                    strHtml.substring(index + searchStr.length);
        }
    }
    return strHtml;
};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const me = this,
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(me, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || 250);
        if (callNow) func.apply(me, args);
    };
};

export const addClass = (elt, className) => (elt.className += ' ' + className);

export const removeClass = (elt, className) =>
    (elt.className = elt.className.replace(new RegExp('(?:^|\\s)' + className + '(?:\\s|$)'), ' '));

export const hasClass = (elt, className) =>
    (' ' + elt.className + ' ').replace(/[\n\t]/g, ' ').indexOf(' ' + className + ' ') > -1;

export const on = (elt, types, listener) => {
    const arrTypes = types.split(' ');
    for (let type of arrTypes) {
        elt.addEventListener(type.trim(), listener);
    }
};

export const off = (elt, types, listener) => {
    const arrTypes = types.split(' ');
    for (let type of arrTypes) {
        elt.removeEventListener(type.trim(), listener);
    }
};

export const whichTransitionEvent = () => {
    const elt = document.createElement('fakeelement');
    const transitions = {
        transition: 'transitionend',
        OTransition: 'oTransitionEnd',
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd'
    };
    for (let t in transitions) {
        if (elt.style[t] !== undefined) return transitions[t];
    }
};

export const findAppVersion = (fallback = '2.0') => {
    const appNameTag = document.querySelector('head > meta[name="application-name"]');
    if (appNameTag !== undefined && appNameTag !== null) {
        const v = appNameTag.getAttribute('content');
        if (v !== undefined && v !== null && v.indexOf(';') > 0) {
            const b = v.split(';');
            if (b.length > 1) {
                const n = b[1].split('=')[1];
                if (n !== undefined && n !== null && n !== '') return n;
            }
        }
    }
    return fallback;
};
