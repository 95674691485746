import { ArcGISPortal } from 'data-catalog-js-api';

export const getBestToken = async (url, defaultToken, tokenManager, askIfMissing = true) => {
    const hasTokenManager =
            tokenManager !== undefined &&
            tokenManager !== null &&
            tokenManager.findCredential !== undefined &&
            tokenManager.getCredential !== undefined,
        existingCredentials =
            hasTokenManager && tokenManager.findCredential(url) !== null ? tokenManager.findCredential(url) : null;
    if (askIfMissing && hasTokenManager && (existingCredentials === undefined || existingCredentials === null)) {
        try {
            const tc = await tokenManager.getCredential(url);
            if (tc !== undefined && tc !== null) {
                tokenManager.registerToken({
                    server: getTokenUrl(url),
                    token: tc.token
                });
                return tc.token;
            }
        } catch (err) {
            console.error(err);
        }
    }
    return existingCredentials !== undefined && existingCredentials !== null ? existingCredentials.token : defaultToken;
};

export const getInfo = (url, defaultToken, tokenManager = null) => {
    return createAuthenticatedPromise(url, {}, ArcGISPortal.getInfo, defaultToken, tokenManager);
};

export const queryFeatures = (url, query, maxItems, params, includeFields, defaultToken, tokenManager = null) => {
    return createAuthenticatedPromise(url, params, false, defaultToken, tokenManager).then((updated) => {
        return ArcGISPortal.queryFeatures(url, query, maxItems, updated, includeFields);
    });
};

const createAuthenticatedPromise = (url, params, arcFunc, defaultToken, tokenManager = null) => {
    const hasTokenManager =
            tokenManager !== undefined &&
            tokenManager !== null &&
            tokenManager.findCredential !== undefined &&
            tokenManager.getCredential !== undefined,
        existingCredentials =
            hasTokenManager && tokenManager.findCredential(url) !== null ? tokenManager.findCredential(url) : null,
        prePromise = hasTokenManager
            ? existingCredentials
                ? Promise.resolve(existingCredentials)
                : tokenManager
                      .getCredential(url)
                      .then((tc) => {
                          tokenManager.registerToken({
                              server: getTokenUrl(url),
                              token: tc.token
                          });
                          return tc;
                      })
                      .catch((err) => {
                          console.error(err);
                          return null;
                      })
            : Promise.resolve(null),
        prom =
            url !== undefined && url !== null
                ? prePromise.then((urlSpecificCredentials) => {
                      const updated = {
                          ...params,
                          f: 'json',
                          token: urlSpecificCredentials !== null ? urlSpecificCredentials.token : defaultToken
                      };
                      if (arcFunc !== undefined && typeof arcFunc === 'function') {
                          return arcFunc(url, updated);
                      } else return updated;
                  })
                : Promise.resolve(null);
    return prom;
};

export const getTokenUrl = (url) => {
    const serviceOrPortalUrl =
        url.toLowerCase().indexOf('/rest/services') > 0
            ? url.substring(0, url.toLowerCase().indexOf('/rest/services') + '/rest/services'.length)
            : url.toLowerCase().indexOf('/sharing/rest') > 0
            ? url.substring(0, url.toLowerCase().indexOf('/sharing/rest') + '/sharing/rest'.length)
            : url;
    return serviceOrPortalUrl;
};
