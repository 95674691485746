import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import { isNullOrUndefined } from 'utils/object';

const ChooseItemsFromSetDialog = ({
    multiple = false,
    allowEmptySelection = false,
    onChoose,
    title,
    icon,
    message,
    large,
    show,
    onClose,
    params,
    mode = 'dropdown',
    itemType = 'Item'
}) => {
    const items = params.items,
        [selected, setSelected] = useState(
            items.length < 1 ? [] : multiple ? items.map((i) => i.id.toString()) : [items[0].id.toString()]
        ),
        [loading] = useState(false);

    const onSelectionChange = (evt) => {
        const itemId = evt.currentTarget.value,
            opts = evt.currentTarget.selectedOptions;
        if (!isNullOrUndefined(opts)) {
            const optIds = Array.from(opts).map((o) => o.value);
            setSelected(optIds);
        } else {
            const copies = [...selected];
            if (copies.indexOf(itemId) < 0) copies.push(itemId);
            setSelected(copies);
        }
    };

    const onBoxChange = (evt) => {
        const itemId = evt.currentTarget.value,
            chk = evt.currentTarget.checked,
            copies = [...selected];
        if (chk && copies.indexOf(itemId) < 0) copies.push(itemId);
        else if (copies.indexOf(itemId) >= 0) copies.splice(copies.indexOf(itemId), 1);
        setSelected(!multiple && copies.length > 1 ? copies.slice(copies.length - 1) : copies);
    };

    const onChosenClick = () => {
        const useBtns = !isNullOrUndefined(mode) && mode === 'buttons',
            items = params.items,
            customArgs = params.customArgs,
            activeSelection = selected !== null && selected.length > 0,
            selectedIds =
                multiple && activeSelection
                    ? selected.join(',')
                    : activeSelection
                    ? selected[0]
                    : !multiple && !useBtns && !activeSelection
                    ? items[0].id
                    : null,
            selectedItems = activeSelection ? items.filter((i) => selected.indexOf(i.id) >= 0) : []; // customArgs - allows pass through of data from somewhere else, easing re-use of a single dialog...
        if (onChoose !== undefined) onChoose(selectedIds, multiple ? selectedItems : selectedItems[0], customArgs);
    };

    const useBtns = !isNullOrUndefined(mode) && mode === 'buttons',
        listItems = useBtns ? buildBoxesList(items, multiple, selected, onBoxChange) : buildOptionsList(items),
        activeSelection = selected !== null ? selected.length > 0 : items !== undefined && items.length > 0,
        listControl = useBtns ? (
            <ul style={{ listStyle: 'none' }}>{listItems}</ul>
        ) : (
            <p>
                <select
                    className="form-control"
                    defaultValue={selected.length > 0 ? selected[0] : null}
                    onChange={onSelectionChange}
                >
                    {listItems}
                </select>
            </p>
        );

    return !show ? null : (
        <ModalDialog
            title={
                title !== undefined ? (
                    title
                ) : (
                    <FormattedMessage
                        id="chooseItemDialog.title"
                        defaultMessage="Choose {itemTypeName}..."
                        values={{
                            itemTypeName: itemType
                        }}
                    />
                )
            }
            buttons={[
                <button
                    type="button"
                    className="btn btn-default btn-primary"
                    onClick={onChosenClick}
                    disabled={activeSelection !== true && allowEmptySelection !== true}
                >
                    <FormattedMessage
                        id="chooseItemDialog.button.choose"
                        defaultMessage="{icon} Choose"
                        values={{
                            icon: <i className="fas fa-check"></i>
                        }}
                    />
                </button>,
                <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                    <FormattedMessage
                        id="chooseItemDialog.button.cancel"
                        defaultMessage="{icon} Cancel"
                        values={{
                            icon: <i className="fas fa-times"></i>
                        }}
                    />
                </button>
            ]}
            show={show}
            large={large}
            scroll={true}
            onClose={onClose}
        >
            <div>
                {loading ? (
                    <div
                        className="text-center"
                        style={{
                            padding: '30px',
                            fontSize: '64px',
                            minHeight: 'calc(100vh - 210px)'
                        }}
                    >
                        <ProgressMessage message={''} />
                    </div>
                ) : icon !== undefined ? (
                    <div>
                        <div style={{ float: 'left', marginRight: '10px', marginBottom: 10 + 'px', fontSize: '48px' }}>
                            {icon}
                        </div>
                        <div style={{ marginLeft: '68px' }}>
                            {message !== undefined ? <p>{message}</p> : null}
                            {listControl}
                        </div>
                    </div>
                ) : (
                    <div class="form-horizontal">
                        {message !== undefined ? <p>{message}</p> : null}
                        {listControl}
                    </div>
                )}
            </div>
        </ModalDialog>
    );
};

const buildOptionsList = (items) => {
    return items !== undefined
        ? items.map((mi, index) => {
              return (
                  <option id={`itemOption${mi.id}`} key={index} value={mi.id.toString()}>
                      {mi.title}
                  </option>
              );
          })
        : null;
};

const buildBoxesList = (items, multiple, preSelectedIds, changeHandler) => {
    return items !== undefined
        ? items.map((mi, index) => {
              const isHtml =
                  mi.title.substring(0, 1) === '<' &&
                  mi.title.substring(mi.title.length - 1) === '>' &&
                  mi.title.indexOf('</') > 0;
              return (
                  <li key={index}>
                      <input
                          id={`itemOptionBox${mi.id}`}
                          type={multiple ? 'checkbox' : 'radio'}
                          onChange={changeHandler}
                          value={mi.id.toString()}
                          name={`itemOptionBox${multiple ? mi.id : ''}`}
                          className="form-control"
                          checked={preSelectedIds.indexOf(mi.id.toString()) >= 0}
                      />
                      {isHtml ? (
                          <label
                              htmlFor={`itemOptionBox${mi.id}`}
                              className="control-label"
                              dangerouslySetInnerHTML={{ __html: mi.title }}
                          ></label>
                      ) : (
                          <label htmlFor={`itemOptionBox${mi.id}`} className="control-label">
                              {mi.title}
                          </label>
                      )}
                  </li>
              );
          })
        : null;
};

export default injectIntl(ChooseItemsFromSetDialog, { withRef: true });
