import React, { PureComponent, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';

class ChooseIndicatorsFromServiceDialog extends PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {
            indicators: [],
            featureLayer: null, 
            geo: {
                id: null,
                name: null,
                url: null
            },
            loading: false, 
            fullLabels: false, 
            sortByName: false
        };
    }

    componentDidMount()
    {
        this.setState({
            service: {
                ...this.props.service
            }
        });
    }
    
    handleLabelClick = () =>
    {
        const { fullLabels } = this.state;
        this.setState({
            fullLabels: !fullLabels
        });
    }

    handleSortClick = () =>
    {
        const { sortByName } = this.state;
        this.setState({
            sortByName: !sortByName
        });
    }

    handleIndicatorClick = (indicatorId) =>
    {
        const { service } = this.state,
            { indicators } = service;
        let indicator = indicators.find(i => i.id === indicatorId);
        if (indicator !== undefined)
        {
            indicator.checked = !indicator.checked;
            if (indicator.dates.length === 1) indicator.dates[0].checked = indicator.checked;
            this.setState({
                service: { ...service }
            });
        }
    }

    handleIndicatorDateClick = (indicatorId, dateId) =>
    {
        const { service } = this.state,
            { indicators } = service,
            indicator = indicators.find(i => i.id === indicatorId),
            instance = indicator.dates.find(d => d.field === dateId);
        instance.checked = !instance.checked;
        indicator.checked = false;
        for (var d of indicator.dates)
        {
            indicator.checked = indicator.checked || d.checked;
        }
        this.setState({
            service: { ...service }
        });
    }

    onChosenClick()
    {
        const { onChoose, service } = this.props,
            indicators = service.indicators,
            featureLayer = service.layer,
            target = service.target,
            geo = service.geo;
        if (onChoose !== undefined) onChoose(geo, featureLayer, indicators, target);
    }

    render()
    {
        const { loading, fullLabels, sortByName } = this.state,
            { show, onClose, service } = this.props;
        if (!show) return null;
        const indicators = [...service.indicators], 
            featureLayer = service.layer, 
            target = service.target,
            geo = service.geo,
            labelSuperClass = (fullLabels ? 'show-fields' : ''),
            sorter = (sortByName ? sortByNameFnc : sortByIndexFnc),
            rows = indicators.map((i, index) =>
            {
                return <IndicatorSelectionRow key={index} indicator={i} onIndicatorClick={this.handleIndicatorClick} onDateClick={this.handleIndicatorDateClick} />
            });
        rows.sort(sorter);
        return (!show ? null :
            <ModalDialog
            title={
                ((target !== undefined) && (target !== null) && (target.id !== undefined) ?
                    <FormattedMessage id="manager.chooseIndicatorsDialog.targeted.title" defaultMessage="Choose Dates | {geo} | {indicator}"
                        values={{
                            indicator: target.name,
                            geo: geo.name
                        }} />
                    :
                    <FormattedMessage id="manager.chooseIndicatorsDialog.title" defaultMessage="Choose Indicators & Dates | {geo}"
                        values={{
                            geo: geo.name
                        }} />)
            }
            buttons={
                [
                    <div className="pull-left" style={{ marginLeft: '20px' }}>
                        <input id="fullFieldLabelsBox" type="checkbox" defaultChecked={false} className="form-control" onChange={this.handleLabelClick} />
                        <label className="control-label slider" htmlFor="fullFieldLabelsBox">
                            <FormattedMessage id="manager.chooseIndicatorsDialog.button.showFields" defaultMessage="Show field names" values={{
                                icon: <i className="fas fa-plug fa-rotate-90"></i>
                            }} />
                        </label>
                    </div>,
                    <button type="button" className="btn btn-default btn-primary" onClick={(e) => this.onChosenClick(e)} disabled={indicators.length < 1}>
                        <FormattedMessage id="manager.chooseIndicatorsDialog.button.save" defaultMessage="{icon} Save" values={{
                            icon: <i className="fas fa-save"></i>
                        }} />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage id="manager.chooseIndicatorsDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                            icon: <i className="fas fa-times"></i>
                        }} />
                    </button>
                ]
            }
            show={show}
            large={true}
            scroll={true}
            onClose={onClose}>
            <div>
                <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}><i className="fas fa-database" style={{ fontSize: '48px' }}></i></div>
                {(indicators !== undefined) && (indicators.length > 0) ?
                   <div style={{ marginLeft: 68 + 'px' }}>
                         <div className="i-after">
                            <p><FormattedMessage id="manager.chooseIndicatorsDialog.messageFormat" defaultMessage="The following indicators & dates are available in feature service {link}." values={{
                                link: <a href={featureLayer.url} target="iaoArcWindow"><strong>{featureLayer.title} <i className="fas fa-external-link-alt"></i></strong></a>
                            }} /></p>
                            {(target !== undefined) && (target !== null) && (target.id !== undefined) ?
                                <p>
                                    <span className="small">
                                        <FormattedMessage id="manager.chooseIndicatorsDialog.disabledByMatchMessageFormat" defaultMessage="Only indicators whose ID or name match your selected indicator {target} are selected by default. You can override this, but you should be careful!" values={{
                                            target: <strong>{target.name}</strong>
                                        }} />
                                    </span>
                                </p>
                            :
                                <p>
                                    <span className="small">
                                        <FormattedMessage id="manager.chooseIndicatorsDialog.disabledByExistingMessageFormat" defaultMessage="If the controls below are disabled, remember that only indicators whose ID or name are not already in the catalog (for the currently selected core layer, {geo}) are available. If you are trying to match an existing indicator make sure you have chosen the correct core layer in the box at the top, then try again. If you need to override this, you need to create an empty indicator, then use 'Add Indicator Connection' for that indicator." values={{
                                                link: <a href={featureLayer.url} target="iaoArcWindow"><strong>{featureLayer.title} <i className="fas fa-external-link-alt"></i></strong></a>,
                                                geo: <strong>{geo.name}</strong>
                                        }} />
                                    </span>
                                </p>}
                        </div>
                            <div>
                            {loading ?
                                <div className="text-center">
                                    <i className="fas fa-cog fa-spin" style={{ fontSize: '64px' }}></i>
                                </div>
                                :
                                <div className={`dates-table form-inline indicator-selector ${labelSuperClass}`.trim()}>
                                    <p>
                                    </p>
                                    <table className="table table-striped">
                                        <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="manager.chooseIndicatorsDialog.table.header.indicator" defaultMessage="Indicator" />
                                                        <span className="btn-group btn-group-sm" style={{ marginLeft: '10px' }}>
                                                            <button className={`btn btn-default btn-sm ${(sortByName ? 'active' : '')}`} onClick={this.handleSortClick} disabled={sortByName}>
                                                                <i className="fas fa-fw fa-sort-alpha-down"></i>
                                                            </button>
                                                            <button className={`btn btn-default btn-sm ${(!sortByName ? 'active' : '')}`} onClick={this.handleSortClick} disabled={!sortByName}>
                                                                <i className="fas fa-fw fa-sort-amount-down"></i>
                                                            </button>
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="manager.chooseIndicatorsDialog.table.header.dates" defaultMessage="Dates" />
                                                    </th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                    </div>
                                }
                            </div>
                    </div>
                    :
                    <div style={{ marginLeft: 68 + 'px' }}>
                        <div className="i-after">
                            <p></p>
                        </div>
                    </div>
                        }
            </div>
        </ModalDialog>)
    }
}

class IndicatorSelectionRow extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            indicator: {
                ...props.indicator
            }
        };
    }

    render()
    {
        const { indicator, onIndicatorClick, onDateClick } = this.props, 
            dates = indicator.dates.map((d, di) =>
            {
                return (
                    <span key={di} data-instance-id={`${indicator.id}D${d.name.replace(/[^0-9a-zA-Z]/g, '')}`} className="date-field-label" data-field-name={d.field}>
                        <input onChange={(e) => onDateClick(indicator.id, d.field)} type="checkbox" value={d.field} checked={d.checked} disabled={d.disabled || indicator.disabled} id={`dateBox_${d.field}`} className="form-control date-field-box" />
                        <label htmlFor={`dateBox_${d.field}`} className="control-label"><span className="pure-tip pure-tip-top" data-tooltip={d.field}>{d.name}</span></label>
                    </span>
                );
            });
        return (
            <tr id={`row${indicator.id}`} data-indicator-id={indicator.id}>
                <td className="indicator-name">
                    <input onChange={(e) => onIndicatorClick(indicator.id)} type="checkbox" value={indicator.id} checked={indicator.checked} disabled={indicator.disabled} id={`indBox_${indicator.id}`} className="form-control indicator-box" />
                    <label htmlFor={`indBox_${indicator.id}`} className="control-label i-name">{indicator.name}</label>
                </td>
                <td className="dates-list">
                    {dates}
                </td>
            </tr>
        ); 
    }
}

const sortByNameFnc = (row1, row2) =>
{
    return row1.props.indicator.name.localeCompare(row2.props.indicator.name)
};
const sortByIndexFnc = (row1, row2) =>
{
    return row1.props.indicator.order - row2.props.indicator.order;
};

export default injectIntl(ChooseIndicatorsFromServiceDialog, { withRef: true });