import React, { Component } from 'react';
import { isNullOrUndefined } from 'utils/object';

class ListToggleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            on: false
        };
        this.button = React.createRef();
    }

    handleToggleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const onOff = !this.state.on;
        this.setState({
            on: onOff
        });
    };

    componentDidUpdate() {
        if (this.button.current !== undefined) this.button.current.blur(); // UI update outside React...
    }

    render() {
        const { element, className, text, tooltip, children, ...others } = this.props,
            inOut = this.state.on ? 'open' : '',
            containerElementName = !isNullOrUndefined(element) ? element : 'div',
            btnClassName = className !== undefined ? className : 'btn btn-default';
        return React.createElement(
            containerElementName,
            { className: inOut, onClick: this.handleToggleClick },
            <React.Fragment>
                <button
                    ref={this.button}
                    className={btnClassName}
                    type="button"
                    onClick={this.handleToggleClick}
                    data-tooltip={tooltip}
                    {...others}
                >
                    {text}
                </button>
                {children}
            </React.Fragment>
        );
    }
}

export default ListToggleButton;
