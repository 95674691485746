import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { injectIntl, FormattedMessage } from 'react-intl';
import ArcGISUtils, { loadCss, loadModules } from 'utils/arcgis';
import { isNullOrUndefined } from 'utils/object';
import { addClass } from 'utils/dom';

// This is a fairly un-React-y approach to this, all interaction with the map node/DOM has to stay outside the render() cycle,
// otherwise it ends up in a mess that is difficult to debug. So, for ease of use, it's easiest to treat them as entirely separate
// and just use events and internal state to keep them synchronized...
class ArcWebMap extends Component {
    constructor(props) {
        super(props);
        this._arcGisUtils = new ArcGISUtils();
    }

    componentDidMount() {
        this.rebuildMap();
        this._isMounted = true;
    }

    componentWillUnmount() {
        if (!isNullOrUndefined(this._map)) this._map.destroy();
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const notMatchMap =
            this.props.map !== nextProps.map ||
            this.props.mapId !== nextProps.mapId ||
            this.props.visibleLayers !== nextProps.visibleLayers ||
            this.props.timestamp !== nextProps.timestamp;
        //console.log('map? ' + (this.props.map !== nextProps.map)); // DEBUG
        //console.log('mapId? ' + (this.props.mapId !== nextProps.mapId)); // DEBUG
        //console.log('layers? ' + (this.props.visibleLayers !== nextProps.visibleLayers)); // DEBUG
        //console.log(this.props.visibleLayers); // DEBUG
        //console.log(nextProps.visibleLayers); // DEBUG
        return notMatchMap; // || super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }

    componentDidUpdate() {
        if (this._isMounted === true) this.rebuildMap();
    }

    clearMap = () => {
        if (!isNullOrUndefined(this._map)) {
            this._map.removeAll();
            /*for (let id of this._map.graphicsLayerIds.slice())
            {
                this._map.removeLayer(this._map.findLayerById(id));
            }*/
        }
    };

    setMapExtent = (extentAsJson) => {
        if (!isNullOrUndefined(this._mapView)) {
            loadModules(['esri/geometry/Extent']).then(([Extent]) => {
                this._mapView.goTo(new Extent(extentAsJson));
            });
        }
    };

    rebuildMap = () => {
        const {
            portalUrl,
            portalType = 'online',
            user,
            token,
            basemap,
            map,
            mapId,
            mapExtent,
            mapBackground = '#fff',
            mapCallbackScript,
            onMapLoad,
            onMapError,
            visibleLayers,
            webMapId = '',
            activeFeature,
            activeLayer,
            use3d = false,
            backgroundStars3d = false,
            atmosphere3d = false,
            viewingMode3d = 'global',
            thematicApplyToLayer = false,
            showMapPopups = 'all'
        } = this.props;
        // Fetch objects from properties - note that "map" is a JSON version of the map definition - this makes it easier
        // for PureComponent to do a shallow compare of properties and to trigger (or not) an update/render()
        const options = portalType.toLowerCase() === 'online' ? { version: '4.16' } : { version: '4.16' },
            activeWebMapId =
                !isNullOrUndefined(webMapId) && webMapId !== '' && webMapId !== '[auto]' && webMapId !== 'auto'
                    ? webMapId
                    : mapId; // Allowing user override

        if (this._map !== undefined && this._map !== null) {
            //const elementId = this._map.id;
            this._map.destroy();
            // Again - ArcGIS and destroy() suck in React world - so ditch it manually
            //if (!isNullOrUndefined(this.mapNode.firstElementChild)) this.mapNode.removeChild(this.mapNode.firstElementChild);
        }
        if (this._legendView !== undefined && this._legendView !== null) this._legendView.destroy();
        if (
            !isNullOrUndefined(this._itemDeferred) &&
            !this._itemDeferred.isResolved() &&
            !this._itemDeferred.isFulfilled()
        )
            this._itemDeferred.cancel('abort:update', false);
        if (
            !isNullOrUndefined(this._mapDeferred) &&
            !this._mapDeferred.isResolved() &&
            !this._mapDeferred.isFulfilled()
        )
            this._mapDeferred.cancel('abort:update', false);
        this._arcGisUtils
            .init(options)
            .then((arcUtils) => {
                loadCss('4.16');
                // Detect the ones we will use...
                // 'esri/config', 'esri/Map', 'esri/WebMap', 'esri/views/MapView', 'esri/identity/IdentityManager', 'esri/geometry/Extent', 'esri/portal/Portal', 'esri/portal/PortalItem', 'esri/layers/FeatureLayer', 'esri/geometry/webMercatorUtils', 'esri/geometry/projection', 'esri/Color', 'esri/widgets/Legend', 'esri/widgets/LayerList', 'esri/widgets/BasemapGallery'
                const [esriConfig, , WebMap, MapView, SceneView, esriId, Extent, , , FeatureLayer, , , , Legend] =
                    arcUtils.modules;
                // But keep the other ones around!
                this._modules = arcUtils.modules;
                // Portal?
                if (portalType.toLowerCase() !== 'online') {
                    // Set the hostname to the on-premise portal
                    esriConfig.portalUrl = portalUrl.replace(/\/sharing\/rest(\/)?$/, '');
                }
                // Auth?
                if (user !== undefined && user !== null && !isNullOrUndefined(token)) {
                    esriId.destroyCredentials();
                    esriId.registerToken({
                        //expires: tknFull.expires,
                        server: portalUrl,
                        ssl: true,
                        token: token,
                        userId: user.username
                    });
                }
                // Create map with the given options at a DOM node w/ id 'mapNode'
                const webMapOptions = {};
                //if ((mapExtent !== undefined) && (mapExtent !== null)) webMapOptions.extent = webMercatorUtils.webMercatorToGeographic(new Extent(mapExtent)).toJson();
                let agoWebMap = null; //(!isNullOrUndefined(map) ? JSON.parse(map) : null);
                //if (isNullOrUndefined(agoWebMap) || isNullOrUndefined(agoWebMap.itemData)) agoWebMap = ArcGISUtils.getVanillaWebMap(webMapOptions);
                if (true) {
                    const mapArgs =
                            activeWebMapId !== undefined && activeWebMapId !== null
                                ? {
                                      portalItem: {
                                          id: activeWebMapId
                                      }
                                  }
                                : {
                                      basemap: basemap || 'gray',
                                      layers: [
                                          new FeatureLayer({
                                              url: activeLayer
                                          })
                                      ]
                                  },
                        mapDef =
                            map !== undefined && map !== null
                                ? typeof map === 'string'
                                    ? JSON.parse(map)
                                    : map
                                : null;
                    if (use3d) {
                        mapArgs.ground = 'world-elevation';
                    }
                    this._map = mapDef !== null ? WebMap.fromJSON(mapDef.itemData) : new WebMap(mapArgs);
                    this._mapView = use3d
                        ? new SceneView({
                              map: this._map,
                              container: this.mapNode,
                              environment: {
                                  background: {
                                      type: 'color',
                                      color: mapBackground
                                  },
                                  starsEnabled: backgroundStars3d,
                                  atmosphereEnabled: atmosphere3d
                              }
                          })
                        : new MapView({
                              map: this._map,
                              container: this.mapNode
                          });
                    this._mapView.when(
                        () => {
                            const viz = !isNullOrUndefined(visibleLayers)
                                ? Array.isArray(visibleLayers)
                                    ? visibleLayers
                                    : visibleLayers.split(',')
                                : activeLayer !== undefined
                                ? [activeLayer]
                                : [];
                            if (viz.length > 0) {
                                const lowerLayers = viz.map((i) => i.toLowerCase().split('[')[0]),
                                    layerIndices = viz.map((i) => parseInt(i)), // Could be lots of NaN, but that's OK...
                                    showAll = viz[0] === '*';
                                let lyrId,
                                    lyrName,
                                    lyrVis,
                                    lyrUrl,
                                    lyrAt = 0,
                                    lyrIdx;
                                for (let lyr of this._mapView.map.layers.items) {
                                    if (lyr.type === 'feature') {
                                        lyrUrl = !isNullOrUndefined(lyr.layerId)
                                            ? `${lyr.url}/${lyr.layerId}`
                                            : !isNullOrUndefined(lyr.url)
                                            ? lyr.url
                                            : 'xxxxx_impossible_id_xxxxx';
                                        lyrId = lyr.id;
                                        lyrName = !isNullOrUndefined(lyr.title)
                                            ? lyr.title
                                            : !isNullOrUndefined(lyr.name)
                                            ? lyr.name
                                            : '<>';
                                        lyrIdx = Math.max(
                                            lowerLayers.indexOf(lyrUrl.toLowerCase()),
                                            lowerLayers.indexOf(lyrId.toLowerCase()),
                                            lowerLayers.indexOf(lyrName.toLowerCase()),
                                            lowerLayers
                                                .filter((v) => v.indexOf('*') > 0)
                                                .findIndex(
                                                    (v) =>
                                                        lyrId.toLowerCase().indexOf(v.substring(0, v.indexOf('*'))) ===
                                                        0
                                                )
                                        );
                                        lyrVis = lyrIdx >= 0 || layerIndices.indexOf(lyrAt) >= 0;
                                        if (
                                            lyrIdx >= 0 &&
                                            viz[lyrIdx].indexOf('[') > 0 &&
                                            !isNullOrUndefined(activeFeature)
                                        ) {
                                            // Filter...
                                            const fld = viz[lyrIdx].split('[')[1].replace(']', '');
                                            lyr.definitionExpression = (
                                                Array.isArray(activeFeature) ? activeFeature : [activeFeature]
                                            )
                                                .map((f) => `${fld} = '${f.id}'`)
                                                .join(' OR ');
                                        }
                                        lyr.visible = lyrVis || showAll;
                                        lyr.popupEnabled =
                                            lyr.visible && showMapPopups.toString().toLowerCase() === 'all';
                                        lyrAt++;
                                    }
                                }
                            }
                            //this._mapDeferred = arcgisUtils.createMap(JSON.parse(JSON.stringify(agoWebMap)), this.mapNode, {
                            //    mapOptions: {
                            //        slider: true
                            //    }
                            //});
                            //this._mapDeferred.then((response) =>
                            //{
                            let mx = null,
                                mxLyr;
                            if (!isNullOrUndefined(this._mapView.map.portalItem)) {
                                mx = !isNullOrUndefined(this._mapView.map.portalItem.extent.xmin)
                                    ? this._mapView.map.portalItem.extent
                                    : new Extent(
                                          this._mapView.map.portalItem.extent[0][0],
                                          this._mapView.map.portalItem.extent[0][1],
                                          this._mapView.map.portalItem.extent[1][0],
                                          this._mapView.map.portalItem.extent[1][1]
                                      );
                            } else if (!isNullOrUndefined(mapDef) && !isNullOrUndefined(mapDef.item)) {
                                mx = !isNullOrUndefined(mapDef.item.extent.xmin)
                                    ? Extent.fromJSON(mapDef.item.extent)
                                    : new Extent(
                                          mapDef.item.extent[0][0],
                                          mapDef.item.extent[0][1],
                                          mapDef.item.extent[1][0],
                                          mapDef.item.extent[1][1]
                                      );
                            } else if (
                                (mxLyr = this._mapView.map.layers.items.find(
                                    (lyr) => `${lyr.url}/${lyr.layerId}` === activeLayer
                                )) !== undefined
                            ) {
                                mx = mxLyr.fullExtent;
                                mxLyr.when(() => {
                                    this._mapView.goTo(mxLyr.fullExtent);
                                });
                            }
                            console.log(mx); // DEBUG
                            if (mx !== null) this._mapView.goTo(mx);
                            // Pick up any other settings (Report Builder widget-y ones) and act on them
                            //this.applyWidgetSettings(this._mapView, activeLayer);
                            // Any callback?
                            if (!isNullOrUndefined(onMapLoad)) {
                                onMapLoad(
                                    {
                                        type: 'load',
                                        view: this._mapView,
                                        item:
                                            this._mapView.map.portalItem !== null
                                                ? this._mapView.map.portalItem
                                                : map !== undefined && map !== null
                                                ? typeof map === 'string'
                                                    ? JSON.parse(map)
                                                    : map
                                                : null
                                    },
                                    arcUtils
                                );
                            }
                            if (!isNullOrUndefined(mapCallbackScript)) {
                                mapCallbackScript({
                                    type: 'load',
                                    view: this._mapView,
                                    map: this._mapView.map,
                                    item:
                                        this._mapView.map.portalItem !== null
                                            ? this._mapView.map.portalItem
                                            : map !== undefined && map !== null
                                            ? typeof map === 'string'
                                                ? JSON.parse(map)
                                                : map
                                            : null
                                });
                            }
                            this._map = this._mapView.map;
                            //}, (promiseErr) =>
                            //{
                            //    console.log(promiseErr); // DEBUG
                            //});
                        },
                        (promiseErr) => {
                            console.log(promiseErr); // DEBUG
                        }
                    );
                } else {
                    //this._mapDeferred = arcgisUtils.createMap(JSON.parse(JSON.stringify(agoWebMap)), this.mapNode, {
                    //    mapOptions: {
                    //        slider: true
                    //    }
                    //});
                    //this._mapDeferred.then((response) =>
                    //{
                    //    const mx = (!isNullOrUndefined(agoWebMap.item.extent.xmin) ?
                    //        new Extent(agoWebMap.item.extent) :
                    //        new Extent(agoWebMap.item.extent[0][0], agoWebMap.item.extent[0][1], agoWebMap.item.extent[1][0], agoWebMap.item.extent[1][1]));
                    //    response.map.setExtent(mx, true);
                    //    if (!isNullOrUndefined(onMapLoad)) onMapLoad(response.map, agoWebMap, arcUtils);
                    //    this._map = response.map;
                    //});
                }
            })
            .catch((err) => {
                // handle any script or module loading errors
                if (!isNullOrUndefined(onMapError)) onMapError(err);
            });
    };

    renderSimple() {
        return (
            <div
                ref={(c) => {
                    this.mapNode = c;
                }}
                className="arcMap"
                style={{ width: '100%', height: '100%' }}
            />
        );
    }

    render() {
        const {
                id,
                mapBackground = '#fff',
                mapPadding = '0',
                showMapToolTips = false,
                borderStyle = 'none',
                borderRadius = '0px',
                borderWidth = '0px',
                borderColor = null,
                legend = false, // Legend may influence the layout if it is requested
                legendAnchor = 'top-right'
            } = this.props,
            boxStyles = {
                borderStyle,
                borderWidth,
                borderRadius,
                borderColor,
                width: '100%',
                height: '100%'
            };
        return (
            <div className="ia-map-box" style={boxStyles}>
                <div className="ia-arc-map-container" style={{ width: '100%', height: '100%' }}>
                    <div
                        ref={(c) => {
                            this.mapNode = c;
                        }}
                        className="arcMap ia-arc-map"
                        style={{ backgroundColor: mapBackground, padding: mapPadding, width: '100%', height: '100%' }}
                    />
                    {legend && legendAnchor.indexOf('sidebar') === 0 ? (
                        <div className={`ia-arc-legend ia-legend-sidebar ia-legend-${legendAnchor}`}>
                            <div id={`mapLegend${id}`} data-updated={new Date().getTime().toFixed(0)}></div>
                        </div>
                    ) : null}
                    {showMapToolTips ? (
                        <div className="ia-map-tooltip">
                            <div className="tooltip-text"></div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ArcWebMap);
