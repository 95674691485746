import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import ListToggleButton from 'components/ListToggleButton';
import { isNullOrUndefined } from 'utils/object';

class UserOptions extends Component {
    render() {
        const { signOutAction, returnUrl, user, portalUrl, showOptions, location } = this.props,
            anon =
                user === undefined ||
                user === null ||
                user.username === undefined ||
                user.username === null ||
                user.username.length === 0,
            showOptionsLink = isNullOrUndefined(showOptions) || showOptions === true;
        const action = () => {
            if (signOutAction) signOutAction();
            else if (returnUrl) window.location.href = returnUrl;
            else window.location.href = (process.env.PUBLIC_URL || '/') + '#sign-out';
        };
        const aTitle = {
            id: 'app.label.user',
            defaultMessage: 'You are signed in @{portal} as {name} ({username})'
        };
        return anon ? null : (
            <ListToggleButton
                element="li"
                text={
                    <span>
                        <i className="fas fa-fw fa-user-circle"></i> <span className="usr-name">{user.fullName}</span>
                    </span>
                }
                tooltip={this.props.intl.formatMessage(aTitle, {
                    name: anon ? '?' : user.fullName,
                    username: anon ? '?' : user.username,
                    portal:
                        portalUrl !== undefined && portalUrl !== null
                            ? portalUrl.replace(/^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/, '$2')
                            : ''
                })}
                className="btn btn-link pure-tip pure-tip-bottom"
                aria-label="User Options"
            >
                <ul className="dropdown-menu">
                    {showOptionsLink ? (
                        <>
                            <li>
                                <Link
                                    to={{
                                        pathname: '/user-options',
                                        state: {
                                            from: location.pathname
                                        }
                                    }}
                                >
                                    <FormattedMessage
                                        id="app.userOptions.button"
                                        defaultMessage="{icon} Options"
                                        values={{
                                            icon: <i className="fas fa-fw fa-user-cog"></i>
                                        }}
                                    />
                                </Link>
                            </li>
                            <li className="divider"></li>
                        </>
                    ) : null}
                    <li>
                        <a href="#sign-out" className="sign-out" onClick={(e) => action(e)}>
                            <FormattedMessage
                                id="app.signout.button"
                                defaultMessage="{icon} Sign Out"
                                values={{
                                    icon: <i className="fas fa-fw fa-power-off"></i>
                                }}
                            />
                        </a>
                    </li>
                </ul>
            </ListToggleButton>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.hubAppSettings.user,
        portalUrl: state.hubAppSettings.portalUrl
    };
}

export default withRouter(connect(mapStateToProps)(injectIntl(UserOptions)));
