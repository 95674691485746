import React, { Component } from 'react';
import { setPageState, setPageSpecificState, setUserOptions } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import esriFavIcon from 'images/esri-favicon-white-border.png';
import { isNullOrUndefined, lowerKeyParams } from 'utils/object';
import { findCatalogTable } from 'api/catalogUtils';
import { ArcGISPortal } from 'data-catalog-js-api';
import WelcomeDialog from 'components/WelcomeDialog';
import { addClass } from 'utils/dom';
import 'pages/Home.scss';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogAvailable: false,
            catalogEditable: false,
            reportBuilderAvailable: false
        };
        this.onSignInClick = this.onSignInClick.bind(this);
        this.handleWelcomeDialogClose = this.handleWelcomeDialogClose.bind(this);
    }

    get defaultPageOptions() {
        return {
            id: 'homePage',
            showWelcomeDialog: true
        };
    }

    componentDidMount() {
        const { setPageState } = this.props;
        setPageState(
            'Data Catalog | Hub',
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-cog"></i>
            </span>,
            null,
            null
        );
        this.setState({
            mounted: true
        });
    }

    componentWillUnmount() {
        this.setState({
            mounted: false
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { user, portalUrl, token, appLinks } = this.props;
        const { mounted } = this.state;
        if (
            prevProps.token === token &&
            prevProps.user !== null &&
            user !== null &&
            prevProps.user.username === user.username &&
            prevProps.portalUrl === portalUrl &&
            prevState.mounted === mounted
        ) {
            return; // No meaningful change, bail...
        }
        if (!isNullOrUndefined(user) && !isNullOrUndefined(token) && token !== '') {
            findCatalogTable({
                home: portalUrl,
                user: user,
                token: token,
                table: null,
                allowViewOnly: true
            })
                .then((catalogItems) => {
                    const tableItem = catalogItems.table,
                        possibles = catalogItems.available;
                    let authPromise;
                    if (
                        !isNullOrUndefined(appLinks) &&
                        !isNullOrUndefined(appLinks.reportBuilder) &&
                        !isNullOrUndefined(appLinks.reportBuilder.appId)
                    ) {
                        //authPromise = fetch(`${portalUrl.replace(/\/$/, '')}/content/items/${appLinks.reportBuilder.appId}?f=json&token=${token}`).then((itemRsp) =>
                        authPromise = fetch(
                            `${portalUrl.replace(/\/$/, '')}/content/listings/${
                                appLinks.reportBuilder.appId
                            }/userEntitlements/${user.username}?f=json&token=${token}`
                        ).then((itemRsp) => {
                            return itemRsp.json().then((itemInfo) => {
                                const okToGo =
                                    isNullOrUndefined(itemInfo.error) &&
                                    !isNullOrUndefined(itemInfo.userEntitlements) &&
                                    !isNullOrUndefined(itemInfo.userEntitlements.entitlements) &&
                                    Array.isArray(itemInfo.userEntitlements.entitlements) &&
                                    itemInfo.userEntitlements.entitlements.indexOf(appLinks.reportBuilder.appId) >= 0;
                                return okToGo;
                            });
                        });
                    } else authPromise = Promise.resolve(false);
                    authPromise.then((rbPlus) => {
                        ArcGISPortal.getInfo(`${portalUrl.replace(/\/$/, '')}/portals/self`, {
                            f: 'json',
                            token: token
                        }).then((portalSelf) => {
                            this.setState({
                                catalogAvailable:
                                    !isNullOrUndefined(tableItem) ||
                                    (!isNullOrUndefined(possibles) && possibles.length > 0),
                                catalogEditable: !isNullOrUndefined(tableItem),
                                reportBuilderAvailable: rbPlus,
                                portal: portalSelf
                            });
                        });
                    });
                })
                .catch((err) => {
                    addClass(document.body, 'full-and-fixed');
                });
        } else addClass(document.body, 'full-and-fixed');
    }

    onSignInClick() {
        const { portalUrl, appAuthId, authExpiry } = this.props;
        window.location.href =
            `${portalUrl.replace(/\/$/, '')}/oauth2/authorize?` +
            `client_id=${appAuthId}` +
            `&response_type=token&redirect_uri=${encodeURIComponent(window.location.href.split('#')[0])}` +
            `&expiration=${(authExpiry ? authExpiry : 2 * 24 * 60).toFixed()}`;
    }

    onLinkButtonClick(url) {
        if (!isNullOrUndefined(url)) {
            const w = window.open(url, 'iaoAppWindow', null, true);
            if (typeof w.focus !== 'undefined') w.focus();
        }
    }

    handleWelcomeDialogClose(dlg, showAtStartup) {
        const { userOptions, setUserOptions, setPageSpecificState } = this.props,
            key = 'showWelcomeDialog',
            hasOptions = !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'homePage') !== undefined,
            pageOptions = hasOptions ? userOptions.find((o) => o.id === 'homePage') : this.defaultPageOptions;
        pageOptions[key] = showAtStartup;
        if (!hasOptions) userOptions.push(pageOptions);
        setPageSpecificState({ dialogShown: true });
        setUserOptions(userOptions);
        this.forceUpdate();
    }

    render() {
        const { user, portalType, portalHome, token, appLinks, userOptions, dialogShown } = this.props,
            { catalogAvailable, catalogEditable, reportBuilderAvailable, portal } = this.state,
            anon = isNullOrUndefined(token) || token.length < 10,
            hosted = !isNullOrUndefined(portalType) && portalType.toLowerCase() !== 'online',
            userIsCreator =
                !isNullOrUndefined(user) &&
                !isNullOrUndefined(user.username) &&
                (user.role === 'org_admin' || user.role === 'org_publisher' || user.role === 'org_user') &&
                !isNullOrUndefined(user.privileges) &&
                user.privileges.indexOf('portal:user:createItem') >= 0,
            qs = lowerKeyParams(new URLSearchParams(window.location.search)),
            rbUrl =
                reportBuilderAvailable && !isNullOrUndefined(appLinks) && !isNullOrUndefined(appLinks.reportBuilder)
                    ? appLinks.reportBuilder.url
                    : null,
            dbUrl =
                reportBuilderAvailable && !isNullOrUndefined(appLinks) && !isNullOrUndefined(appLinks.dashboardBuilder)
                    ? appLinks.dashboardBuilder.url
                    : null,
            hasOptions = !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'homePage') !== undefined,
            pageOptions = hasOptions ? userOptions.find((o) => o.id === 'homePage') : this.defaultPageOptions;

        if (!isNullOrUndefined(qs['returnTo']) && !anon) {
            return <Redirect to={qs['returnTo']} />;
        }

        return (
            <div className="landing simple-central">
                {anon ? (
                    <div className="anon-content">
                        <div id="anonStart" className="pad10 blurb alignCenter big-btns">
                            <div className="pad10 btn-holder row form-horizontal">
                                <div className="col-md-4 anon-content">&nbsp;</div>
                                <div className="col-md-4 anon-content">
                                    <div className="sign-in-link primary-action-link">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-anon btn-sign-in hover-grow"
                                            data-target="sign-in"
                                            onClick={() => this.onSignInClick()}
                                        >
                                            <FormattedMessage id="home.button.login" defaultMessage="Sign in">
                                                {(txt) => <span>{txt}</span>}
                                            </FormattedMessage>
                                        </button>
                                        <div className="app-desc anon-content">
                                            {hosted ? (
                                                <p style={{ lineHeight: '1.4' }}>
                                                    <span className="pull-right" style={{ margin: '0 0 10px 10px' }}>
                                                        <i className="fas fa-server fa-fw fa-2x"></i>
                                                    </span>
                                                    <FormattedMessage
                                                        id="home.label.loginArcGisHosted1"
                                                        defaultMessage="Sign in at {icon}{portal} using your ArcGIS Enterprise/Portal account to start using {catalogLink} &amp; Apps."
                                                        values={{
                                                            catalogLink: (
                                                                <FormattedMessage
                                                                    id="home.marketplaceLink.dataCatalog"
                                                                    defaultMessage="InstantAtlas Data Catalog"
                                                                >
                                                                    {(txt) => (
                                                                        <a
                                                                            href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                                            target="iaoMarketplace"
                                                                        >
                                                                            {txt}{' '}
                                                                            <i className="fas fa-external-link-alt small"></i>
                                                                        </a>
                                                                    )}
                                                                </FormattedMessage>
                                                            ),
                                                            icon: (
                                                                <img src={esriFavIcon} className="inline-icon" alt="" />
                                                            ),
                                                            portal: (
                                                                <a
                                                                    href={portalHome}
                                                                    target="iaoArcWindow"
                                                                    style={{ wordBreak: 'break-all' }}
                                                                >
                                                                    {portalHome}
                                                                </a>
                                                            )
                                                        }}
                                                    />
                                                </p>
                                            ) : (
                                                <div style={{ lineHeight: '1.4' }}>
                                                    <p>
                                                        <span
                                                            className="pull-right"
                                                            style={{ margin: '0 0 10px 10px' }}
                                                        >
                                                            <span className="fa-stack">
                                                                <i className="fas fa-cloud fa-fw fa-stack-2x"></i>
                                                                <i
                                                                    className="fas fa-layer-group fa-fw fa-lg fa-stack-1x"
                                                                    style={{ color: '#666', marginTop: '1px' }}
                                                                ></i>
                                                            </span>
                                                        </span>
                                                        <FormattedMessage
                                                            id="home.label.loginArcGisOnline1"
                                                            defaultMessage="Sign in using your {icon}ArcGIS Online account to start using {catalogLink}."
                                                            values={{
                                                                catalogLink: (
                                                                    <FormattedMessage
                                                                        id="home.marketplaceLink.dataCatalog"
                                                                        defaultMessage="InstantAtlas Data Catalog"
                                                                    >
                                                                        {(txt) => (
                                                                            <a
                                                                                href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                                                target="iaoMarketplace"
                                                                            >
                                                                                {txt}{' '}
                                                                                <i className="fas fa-external-link-alt small"></i>
                                                                            </a>
                                                                        )}
                                                                    </FormattedMessage>
                                                                ),
                                                                icon: (
                                                                    <img
                                                                        src={esriFavIcon}
                                                                        className="inline-icon"
                                                                        alt=""
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="auth-content big-btns">
                        {!catalogEditable && !isNullOrUndefined(portal) ? (
                            <div className="pad-10-hz btn-holder row form-horizontal">
                                <div className="col-xs-12 col-sm-12 col-md-12 auth-content">
                                    <div>
                                        <div className="pull-right primary-action-link">
                                            <Link
                                                type="button"
                                                className={`btn btn-block btn-default btn-account btn-datacatalog btn-datacatalog-creator hover-grow ${
                                                    catalogEditable ? 'disabled' : ''
                                                }`.trim()}
                                                to={{
                                                    pathname: '/new-catalog',
                                                    search: `?token=${token}`
                                                }}
                                                disabled={!userIsCreator}
                                                variant="secondary"
                                            >
                                                <span>
                                                    <span className="ia-page-icon">
                                                        <i className="fas fa-database"></i>
                                                        <i className="fas fa-plus"></i>
                                                    </span>{' '}
                                                    Add New Data Catalog
                                                </span>
                                            </Link>
                                        </div>
                                        <h2>
                                            <FormattedMessage
                                                id="home.noCatalog.titleFormat"
                                                defaultMessage="Welcome to InstantAtlas, {user}"
                                                values={{
                                                    user: user.fullName
                                                }}
                                            />
                                        </h2>
                                        <p>
                                            <FormattedMessage
                                                id="home.noCatalog.messageFormat"
                                                defaultMessage="Your organisation {org} does not appear to have any InstantAtlas Data Catalogs. Before you start using these tools, you will need to create a data catalog. Use the button on the right to get started. A walkthrough of setting up a new data catalog is available on our help pages at {help}."
                                                values={{
                                                    org: (
                                                        <strong className="org-name">
                                                            {!isNullOrUndefined(portal) ? portal.name : '[org]'}
                                                        </strong>
                                                    ),
                                                    help: (
                                                        <a
                                                            href="https://help.instantatlas.com/instantatlas-data-catalog/"
                                                            target="iaHelpWindow"
                                                        >
                                                            https://help.instantatlas.com/instantatlas-data-catalog/{' '}
                                                            <i className="fas fa-external-link-alt"></i>
                                                        </a>
                                                    )
                                                }}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="pad-10-hz btn-holder row form-horizontal auth-content">
                            <div className="col-xs-12 col-sm-6 col-md-4 auth-content">
                                <div>
                                    <div
                                        className="pure-tip pure-tip-bottom"
                                        data-tooltip="InstantAtlas&#8482; Data Catalog Manager"
                                    >
                                        <Link
                                            className={`btn btn-block btn-default btn-primary btn-account btn-datacatalog btn-datacatalog-manager hover-grow ${
                                                !catalogEditable || !userIsCreator ? 'disabled' : ''
                                            }`.trim()}
                                            to={
                                                catalogEditable && userIsCreator
                                                    ? {
                                                          pathname: '/manager',
                                                          search: `?token=${token}`
                                                      }
                                                    : '#'
                                            }
                                            disabled={!userIsCreator}
                                        >
                                            <span className="ia-page-icon">
                                                <i className="fas fa-database"></i>
                                                <i className="fas fa-wrench"></i>
                                            </span>
                                            <FormattedMessage
                                                id="home.auth.catalogManager.button"
                                                defaultMessage="Manage Catalog"
                                                tagName="span"
                                            />
                                        </Link>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.catalogManager.messageFormat"
                                            defaultMessage="Manage themes, indicator, dates and data in your InstantAtlas Data Catalog on ArcGIS Online/Portal. {link}"
                                            values={{
                                                link: (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                        target="iaoMarketplace"
                                                        className="anon-content"
                                                    >
                                                        <FormattedMessage
                                                            id="home.dataCatalog.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about InstantAtlas Data Catalog and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 auth-content">
                                <div>
                                    <div
                                        className="pure-tip pure-tip-bottom"
                                        data-tooltip="InstantAtlas&#8482; Data Catalog Explorer"
                                    >
                                        <Link
                                            type="button"
                                            className={`btn btn-block btn-default btn-primary btn-account btn-datacatalog btn-data-explorer hover-grow`.trim()}
                                            to={{
                                                pathname: '/explorer',
                                                search: `?token=${token}&public=${catalogAvailable ? 'no' : 'yes'}`
                                            }}
                                            disabled={false}
                                            variant="secondary"
                                        >
                                            <span className="ia-page-icon">
                                                <i className="fas fa-database"></i>
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <FormattedMessage
                                                id="home.auth.catalogExplorer.button"
                                                defaultMessage="Explore Catalog"
                                                tagName="span"
                                            />
                                        </Link>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.catalogExplorer.messageFormat"
                                            defaultMessage="Search individual indicators within your InstantAtlas Data Catalog and download them in a specified format. {link}"
                                            values={{
                                                link: (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                        target="iaoMarketplace"
                                                        className="anon-content"
                                                    >
                                                        <FormattedMessage
                                                            id="home.dataCatalog.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about InstantAtlas Data Catalog and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 auth-content">
                                <div>
                                    <div
                                        className="pure-tip pure-tip-bottom"
                                        data-tooltip="InstantAtlas&#8482; Data Catalog Inspector"
                                    >
                                        <Link
                                            type="button"
                                            className={`btn btn-block btn-default btn-primary btn-account btn-datacatalog btn-datacatalog-inspector hover-grow ${
                                                !catalogEditable ? 'disabled' : ''
                                            }`.trim()}
                                            to={
                                                catalogEditable
                                                    ? {
                                                          pathname: '/inspector',
                                                          search: `?token=${token}`
                                                      }
                                                    : '#'
                                            }
                                            disabled={!userIsCreator}
                                            variant="secondary"
                                        >
                                            <span className="ia-page-icon">
                                                <i className="fas fa-database"></i>
                                                <i className="fas fa-clipboard-check"></i>
                                            </span>
                                            <FormattedMessage
                                                id="home.auth.catalogInspector.button"
                                                defaultMessage="Inspect Catalog"
                                                tagName="span"
                                            />
                                        </Link>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.catalogInspector.messageFormat"
                                            defaultMessage="Check the structure, contents and feature services of your InstantAtlas Data Catalog on ArcGIS Online/Portal. {link}"
                                            values={{
                                                link: (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                        target="iaoMarketplace"
                                                        className="anon-content"
                                                    >
                                                        <FormattedMessage
                                                            id="home.dataCatalog.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about InstantAtlas Data Catalog and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pad-10-hz btn-holder row form-horizontal">
                            <div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2 auth-content">
                                <div>
                                    <div
                                        className="pure-tip pure-tip-bottom"
                                        data-tooltip="InstantAtlas&#8482; Web Map Builder"
                                    >
                                        <Link
                                            type="button"
                                            className={`btn btn-block btn-default btn-primary btn-account btn-datacatalog btn-webmap-creator hover-grow ${
                                                !userIsCreator ? 'disabled' : ''
                                            }`.trim()}
                                            to={
                                                userIsCreator
                                                    ? {
                                                          pathname: '/web-map-builder',
                                                          search: `?token=${token}&public=${
                                                              catalogAvailable ? 'no' : 'yes'
                                                          }`
                                                      }
                                                    : '#'
                                            }
                                            disabled={!userIsCreator}
                                            variant="secondary"
                                        >
                                            <span className="ia-page-icon">
                                                <i className="fas fa-map-marked-alt"></i>
                                                <i className="fas fa-hammer fa-flip-horizontal"></i>
                                            </span>
                                            <FormattedMessage
                                                id="home.auth.webMapBuilder.button"
                                                defaultMessage="Build a Web Map"
                                                tagName="span"
                                            />
                                        </Link>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.webMapBuilder.messageFormat"
                                            defaultMessage="Add individual indicators within your InstantAtlas Data Catalog to any of your ArcGIS Online/Portal web maps. {link}"
                                            values={{
                                                link: (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=4e6659d71d8b47608c9e1a95fa6c07f7"
                                                        target="iaoMarketplace"
                                                        className="anon-content"
                                                    >
                                                        <FormattedMessage
                                                            id="home.dataCatalog.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about InstantAtlas Data Catalog and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 auth-content">
                                <div>
                                    <div className="pure-tip pure-tip-bottom" data-tooltip="Report Builder for ArcGIS">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-account btn-report-builder hover-grow"
                                            disabled={isNullOrUndefined(rbUrl)}
                                            onClick={(e) => this.onLinkButtonClick(rbUrl)}
                                            data-item-id={appLinks.reportBuilder.id}
                                        >
                                            <FormattedMessage
                                                id="home.auth.reportBuilder.button"
                                                defaultMessage="Build a Report"
                                                tagName="span"
                                            />
                                        </button>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.reportBuilder.messageFormat"
                                            defaultMessage="Report Builder for ArcGIS allows you to create great looking reports or profiles for sets of features in a web map in ArcGIS Online. {link}"
                                            values={{
                                                link: isNullOrUndefined(rbUrl) ? (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=a0905cde786f4b04a3220b44151845c5"
                                                        target="iaoMarketplace"
                                                    >
                                                        <FormattedMessage
                                                            id="home.reportBuilderPlus.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about Report Builder for ArcGIS and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                ) : null
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 auth-content" style={{ display: 'none' }}>
                                <div>
                                    <div
                                        className="pure-tip pure-tip-bottom"
                                        data-tooltip="InstantAtlas&#8482; Dashboard Builder"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-block btn-account btn-dashboard-builder hover-grow"
                                            data-toggle="tooltip"
                                            disabled={isNullOrUndefined(dbUrl)}
                                            onClick={(e) => this.onLinkButtonClick(dbUrl)}
                                        >
                                            <FormattedMessage
                                                id="home.auth.dashboardBuilder.button"
                                                defaultMessage="Build a Dashboard"
                                                tagName="span"
                                            />
                                        </button>
                                    </div>
                                    <div className="app-desc">
                                        <FormattedMessage
                                            id="home.auth.dashboardBuilder.messageFormat"
                                            defaultMessage="InstantAtlas Dashboard Builder allows you to create great looking, highly-interactive atlases for sets of features in web maps. {link}"
                                            values={{
                                                link: isNullOrUndefined(dbUrl) ? (
                                                    <a
                                                        href="https://marketplace.arcgis.com/listing.html?id=a0905cde786f4b04a3220b44151845c5"
                                                        target="iaoMarketplace"
                                                    >
                                                        <FormattedMessage
                                                            id="home.reportBuilderPlus.marketplaceLink.messageFormat"
                                                            defaultMessage="Learn more about Report Builder and see purchase options in marketplace {icon}."
                                                            values={{
                                                                icon: <i className="fas fa-external-link-alt small"></i>
                                                            }}
                                                        />
                                                    </a>
                                                ) : null
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!anon ? (
                    <div style={{ zIndex: 65536 }}>
                        <WelcomeDialog
                            show={pageOptions['showWelcomeDialog'] && !dialogShown}
                            onClose={this.handleWelcomeDialogClose}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

// Enable access to redux store.
const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalHome: state.hubAppSettings.portalHome,
        portalType: state.hubAppSettings.portalType,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        title: state.pageTitle,
        userOptions: state.userOptions,
        dialogShown: !isNullOrUndefined(state.pageState.custom) ? state.pageState.custom.dialogShown : false
    };
};

const actionCreators = {
    setPageState,
    setPageSpecificState,
    setUserOptions
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(HomePage)));
