import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';

class ConfirmDeleteIndicatorDialog extends PureComponent 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            counts: {
                indicators: -1,
                instances: -1
            },
            action: 'cancel'
        };
        this.applyDeleteForAll = this.applyDeleteForAll.bind(this);
    }

    componentDidMount() 
    {
        this.setState({
            loading: true,
            isError: false
        }, () =>
        {
            this.findCounts();
        });
    }

    //componentDidUpdate(prevProps, prevState)
    //{
    //    // If the change was to the search state, re-run it, otherwise leave well alone...
    //    if ((prevState.geo.id !== this.state.geo.id) || (prevState.indicator.id !== this.state.indicator.id)) this.findCounts();
    //}

    applyDeleteForAll(evt)
    {
        evt.preventDefault();
        evt.stopPropagation(); // Handle it ourseleves
        delete (this.props.item.geo);
        this.findCounts();
    }

    findCounts()
    {
        const { catalog, item } = this.props;
        catalog.getSummary((item.geo !== undefined ? item.geo.id : null), item.id).then(summary =>
        {
            this.setState({
                counts: summary
            });
        });
    }

    onConfirmClick(action) 
    {
        const { item, onConfirm } = this.props;
        if ((action === 'delete') && (onConfirm !== undefined)) onConfirm(item.id, (item.geo !== undefined ? item.geo.id : null));
    }

    render()
    {
        const { item, show, onClose } = this.props,
            { counts } = this.state,
            geo = item.geo,
            buttonSet = [
                <button type="button" className="btn btn-secondary" onClick={(e) => this.onConfirmClick('delete')}>
                    <FormattedMessage id="manager.deleteIndicatorDialog.button.delete" defaultMessage="{icon} Delete" values={{
                        icon: <i className="fas fa-trash-alt"></i>
                    }} />
                </button>,
                <button type="button" className="btn btn-primary">
                    <FormattedMessage id="manager.deleteIndicatorDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                        icon: <i className="fas fa-times"></i>
                    }} />
                </button>
            ];
        if ((geo !== undefined) && (geo.id !== null))
        {
            buttonSet.splice(1, 0, <button type="button" className="btn btn-secondary" onClick={this.applyDeleteForAll}>
                <FormattedMessage id="manager.deleteIndicatorDialog.button.deleteForAll" defaultMessage="{icon} Delete For All" values={{
                    icon: <i className="far fa-trash-alt"></i>
                }} />
            </button>);
        }
        return (!show ? null : <ModalDialog
            title={
                ((geo !== undefined) && (geo.id !== null) ?
                    <FormattedMessage id="manager.deleteIndicatorForGeoDialog.title" defaultMessage="Delete {indicator} for {geo}?"
                        values={{
                            indicator: item.name,
                            geo: geo.name
                        }} /> :
                    <FormattedMessage id="manager.deleteIndicatorDialog.title" defaultMessage="Delete {indicator}?"
                        values={{
                            indicator: item.name
                        }} />
                )
            }
            buttons={buttonSet}
            show={show}
            scroll={true}
            onClose={onClose}>
            <div>
                <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}><i className="fas fa-exclamation-triangle" style={{ fontSize: '48px' }}></i></div>
                <div style={{ marginLeft: 68 + 'px' }}>
                    <div className="i-after">
                        <p>
                            {(geo !== undefined) && (geo.id !== null) ?
                                <FormattedMessage id="manager.deleteIndicatorDialog.oneLayer.messageFormat" defaultMessage="Are you sure you want to delete {indicator} for layer {geo}? This will remove {count} dates from your data catalog. This cannot be undone - you should backup your catalog first."
                                    values={{
                                        indicator: <strong className="i-name">{item.name}</strong>,
                                        geo: <strong className="g-name">{geo.name}</strong>,
                                        count: <strong>{((counts !== undefined) && (counts.instances >= 0) ? <FormattedNumber value={counts.instances} /> : '?')}</strong>
                                    }} />
                                :
                                <FormattedMessage id="manager.deleteIndicatorDialog.allLayers.messageFormat" defaultMessage="Are you sure you want to delete {indicator} for all layers in your catalog? This will remove {indicatorCount} unique indicator/layer combinations and {instanceCount} dates from your data catalog. This cannot be undone - you should backup your catalog first."
                                    values={{
                                        indicator: <strong className="i-name">{item.name}</strong>,
                                        indicatorCount: <strong>{((counts !== undefined) && (counts.indicators >= 0) ? <FormattedNumber value={counts.indicators} /> : '?')}</strong>,
                                        instanceCount: <strong>{((counts !== undefined) && (counts.instances >= 0) ? <FormattedNumber value={counts.instances} /> : '?')}</strong>
                                    }} />
                            }
                        </p>
                    </div>
                </div>
            </div>
        </ModalDialog>);
    }
}

export default injectIntl(ConfirmDeleteIndicatorDialog, { withRef: true });