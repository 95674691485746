import React, { Component } from 'react';
import { setPageState } from 'redux/hubStore';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { lowerKeyParams } from 'utils/object';
import ProgressMessage from 'components/ProgressMessage';
import { DataCatalog, ArcGISPortal } from 'data-catalog-js-api';

class MetadataPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            catalog: null
        };
        this.updateMetadata = this.updateMetadata.bind(this);
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Metadata', this.getTitleIcon(), null, null);
        const qs = lowerKeyParams(new URLSearchParams(window.location.search)),
            { match, portalUrl, token } = this.props;
        let query = `IndicatorID='${match.params.indicatorId}'`;
        if (match.params.instanceId !== undefined) query += ` AND InstanceID='${match.params.instanceId}'`;
        //removeClass(document.getElementById('iaAppRoot'), 'anon');
        if (qs['catalog'] !== undefined || qs['item'] !== undefined) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    const p = { f: 'json' },
                        catalogId = qs['catalog'] !== undefined ? qs['catalog'] : qs['item'],
                        indicatorIds = [match.params.indicatorId];
                    if (qs['token'] !== undefined) p.token = qs['token'];
                    else if (token !== undefined && token !== null) p.token = token;
                    ArcGISPortal.getItemDescription(catalogId, p, portalUrl.replace(/\/sharing\/rest\/$/, '')).then(
                        (catalogInfo) => {
                            const catalog = new DataCatalog(catalogInfo.url + '/0', undefined, p.token);
                            catalog.init(indicatorIds, true).then(() => {
                                catalog.getMetadata(indicatorIds, true).then((metadataSet) => {
                                    this.updateMetadata(metadataSet);
                                });
                            });
                        }
                    );
                }
            );
        } else {
            this.setState(
                {
                    loading: true
                },
                () => {
                    const p = { f: 'json', outFields: '*' };
                    if (qs['token'] !== undefined) p.token = qs['token'];
                    else if (token !== undefined && token !== null) p.token = token;
                    ArcGISPortal.queryFeatures(DataCatalog.instantAtlasMetadataService, query, 10, p, true).then(
                        (metadataSet) => {
                            this.updateMetadata(metadataSet);
                        }
                    );
                }
            );
        }
    }

    updateMetadata(metadataSet) {
        const { match } = this.props,
            metadataItems = [],
            exclusions = ['objectid', 'themenametrail', 'themeidtrail', 'geoid', 'instanceid', 'themetitle'];
        if (metadataSet !== undefined && metadataSet.features !== undefined && metadataSet.fields !== undefined) {
            const aliasLookup = {};
            for (let f of metadataSet.fields) {
                aliasLookup[f.name.toLowerCase()] = f;
            }
            for (let i in metadataSet.features[0].attributes) {
                if (exclusions.indexOf(i.toLowerCase()) < 0) {
                    let f = aliasLookup[i.toLowerCase()];
                    metadataItems.push({
                        id: i,
                        label: f.alias !== undefined ? f.alias : i,
                        value:
                            f.type === 'esriFieldTypeDate'
                                ? new Date(metadataSet.features[0].attributes[i])
                                      .toISOString()
                                      .substring(0, 16)
                                      .replace('T', ' ')
                                      .replace('1970-01-01 00:00', '')
                                : metadataSet.features[0].attributes[i]
                    });
                }
            }
        }
        //else
        //{
        //    for (var f of catalog.meta.info.fields)
        //    {
        //        if (exclusions.indexOf(f.name.toLowerCase()) < 0)
        //        {
        //            metadataItems.push({
        //                id: f.name,
        //                label: f.alias,
        //                value: ''
        //            });
        //        }
        //    }
        //}
        this.setState({
            loading: false,
            values: metadataItems,
            indicator: {
                id: match.params.indicatorId,
                name:
                    metadataItems.find((i) => i.id === 'Title') !== undefined
                        ? metadataItems.find((i) => i.id === 'Title').value
                        : `Indicator #${match.params.indicatorId}`
            }
        });
    }

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-info-circle"></i>
            </span>
        );
    }

    render() {
        const { loading, values, indicator } = this.state,
            rows =
                values !== undefined
                    ? values.map((mi, index) => {
                          return (
                              <tr key={index} className={`metadata-row`} data-item-id={mi.id}>
                                  <td data-metadata-key={mi.id}>{mi.label}</td>
                                  <td dangerouslySetInnerHTML={{ __html: mi.value }}></td>
                              </tr>
                          );
                      })
                    : null;
        return loading ? (
            <ProgressMessage />
        ) : (
            <div className="simple-central metadata-items">
                <h1>
                    <i className="fas fa-fw fa-info-circle"></i>
                    <FormattedMessage
                        id="metadata.pageTitle"
                        defaultMessage="{name} | Metadata"
                        values={{
                            name: indicator.name
                        }}
                    />
                </h1>
                <table className="table table-striped iao-metadata-table">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '33%' }}>
                                <a
                                    href="https://help.instantatlas.com/instantatlas-data-catalog/data-catalog-metadata/"
                                    target="iaHelpWindow"
                                >
                                    Key <i className="fas fa-external-link-alt"></i>
                                </a>
                            </th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(MetadataPage)));
