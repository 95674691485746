import React, { PureComponent } from 'react';

export default class CoreLayerListItem extends PureComponent
{
    //static propTypes = {
    //    onActiveChange: PropTypes.func
    //}

    constructor(props) 
    {
        super(props);
        this.state = {
            active: this.props.active !== undefined ? this.props.active : false, 
            editing: false,
            id: this.props.geo.id, 
            values: {
                name: this.props.geo.name
            }
        };
        this.handleActivate = this.handleActivate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleControlClick = this.handleControlClick.bind(this);
        this.handleEditCommit = this.handleEditCommit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleActivate(evt)
    {
        const { geo, onActiveChange } = this.props,
            isActive = !this.state.active;
        this.setState({
            active: isActive
        });
        if (onActiveChange !== undefined) onActiveChange(evt, geo.id, isActive);
    }

    handleControlClick(evt, action)
    {
        const { controls, geo } = this.props,
            { active, editing } = this.state;
        if ((action !== undefined) && (action === 'rename') && !editing && active)
        {
            this.setState({
                editing: true
            });
        }
        if (controls !== undefined)
        {
            if ((action !== undefined) && (controls[action] !== undefined)) controls[action](evt, geo.id);
            else if (controls.click !== undefined) controls.click(evt, geo.id);
        }
        else if ((action !== undefined) && (this.props[action] !== undefined)) this.props[action](geo.id);
    }

    handleEdit(evt, key)
    {
        const { values } = this.state,
            v = evt.currentTarget.value,
            isEnter = evt.keyCode === 13;
        values[key] = v;
        this.setState({
            values: values
        }, () =>
        {
            if (isEnter) this.handleEditCommit(evt);
            });
    }

    handleEditCommit(evt)
    {
        const { save } = this.props,
            { values, id } = this.state,
            change = {
                id: id,
                action: 'rename',
                value: values['name'],
                itemType: 'Geo'
            };
        if (save !== undefined) save([change], this);
        this.handleCancel(evt); // Close editor but stay active
    }

    handleCancel(evt)
    {
        const { geo, onActiveChange } = this.props;
        // Still active but no longer in edit mode
        this.setState({
            active: true,
            editing: false
        });
        if (onActiveChange !== undefined) onActiveChange(evt, geo.id, true);
    }

    componentDidUpdate(prevProps, prevState) 
    {
        if (prevProps.active !== this.props.active) this.setState({ active: this.props.active });
    }

    render() 
    {
        const { geo } = this.props,
            geoId = geo.id,
            geoName = geo.name,
            geoLink = geo.url,
            geoSlug = geoName.replace(/[^0-9a-zA-Z]/g, '-').toLowerCase(),
            { active, editing } = this.state;

        return (
            <li key={`g${geoId}`} className={'geo-selector' + (active ? ' active' : '')} data-geo-id={geoId}>
                <a href={`#${geoSlug}`} className="nodef" onClick={this.handleActivate}>{geoName}</a>
                <div className="iao-controls btn-group btn-group-sm">
                    <button type="button" className="btn btn-default pure-tip pure-tip-bottom" data-action="rename" data-tooltip="Rename" onClick={(e) => this.handleControlClick(e, 'rename')}><i className="fas fa-pencil-alt fa-lg"></i><span className="sr-only">Rename</span></button>
                    <a href={`${geoLink}`} className="btn btn-default pure-tip pure-tip-bottom" data-action="link" data-tooltip="View" target="iaoLayerWindow"><i className="fas fa-external-link-alt fa-lg"></i><span className="sr-only">View</span></a>
                    <button type="button" className="btn btn-default pure-tip pure-tip-bottom" data-action="delete" data-tooltip="Delete" onClick={(e) => this.handleControlClick(e, 'delete')}><i className="fas fa-trash-alt fa-lg"></i><span className="sr-only">Delete</span></button>
                </div>
                {editing && active ?
                    <span className="form-horizontal form-inline popupEditor">
                        <label className="control-label box-label" htmlFor={`${geoId}-nameEditorBox`}><span>full name</span></label>
                        <input className="form-control" id={`${geoId}-nameEditorBox`} type="text" size="35" defaultValue={geoName} onKeyUp={(e) => this.handleEdit(e, 'name')} />
                        <span className="btn-group btn-group-sm" style={{ marginLeft: '10px' }}>
                            <button className="btn btn-default" onClick={this.handleEditCommit}><i className="fas fa-lg fa-save"></i></button>
                            <button className="btn btn-default" onClick={this.handleCancel}><i className="fas fa-lg fa-times"></i></button>
                        </span>
                    </span>
                    : null}
            </li>
        );
    }
};