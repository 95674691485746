import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'utils/object';
import { ArcGISPortal } from 'data-catalog-js-api';

class ArcItemSaveDialog extends PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            selected: null, 
            folders: null
        };
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onTagsChange = this.onTagsChange.bind(this);
    }

    componentDidMount()
    {
        const { user, portalUrl, token, text, tags } = this.props;
        this.setState({
            loading: true,
            text: text,
            tags: tags
        }, () =>
            {
                ArcGISPortal.getInfo(`${portalUrl.replace(/\/$/, '')}/content/users/${user.username}`, { f: 'json', token: token }).then(userDetails =>
                {
                    this.setState({
                        loading: false,
                        folders: [{ id: '', title: user.username }].concat(userDetails.folders)
                    });
                });
            });
    }

    onSelectionChange(evt)
    {
        const itemId = evt.currentTarget.value;
        this.setState({
            selected: itemId
        });
    }

    onTextChange(evt)
    {
        const text = evt.currentTarget.value;
        this.setState({
            text: text
        });
    }

    onTagsChange(evt)
    {
        const text = evt.currentTarget.value;
        this.setState({
            tags: text
        });
    }
    
    onChosenClick()
    {
        const { selected, text, tags } = this.state,
            { onChoose, params } = this.props,
            customArgs = (!isNullOrUndefined(params) ? params.customArgs : null); // customArgs - allows pass through of data from somewhere else, easing re-use of a single dialog...
        if (onChoose !== undefined) onChoose(text, tags, selected, customArgs);
    }

    render()
    {
        const { selected, folders, loading } = this.state,
            { title, icon, message, large, show, params, itemType, onClose, text, tags } = this.props,
            items = (!isNullOrUndefined(params) && !isNullOrUndefined(params.items) ? params.items : folders),
            listItems = (!isNullOrUndefined(items) ? items.map((mi, index) =>
            {
                return (
                    <option id={`itemOption${mi.id}`} key={index} value={mi.id}>{mi.title}</option>
                );
            }) : null),
            active = (selected !== null ? selected : (!isNullOrUndefined(items) && (items.length > 0) ? items[0].id : null)),
            iconStyle = (!isNullOrUndefined(icon) ? { float: 'left', marginRight: '10px', marginBottom: 10 + 'px', fontSize: '48px' } : { display: 'none' }),
            bodyStyle = (!isNullOrUndefined(icon) ? { marginLeft: '68px' } : { marginLeft: '0' });
        
        return (!show ? null : <ModalDialog
            title={
                (title !== undefined ? title :
                    <FormattedMessage id="saveItemDialog.title" defaultMessage="Save {itemTypeName} To..."
                        values={{
                            itemTypeName: itemType
                        }} />)
            }
            buttons={
                [
                    <button type="button" className="btn btn-default btn-primary" onClick={(e) => this.onChosenClick(e)} disabled={(active === null)}>
                        <FormattedMessage id="saveItemDialog.button.choose" defaultMessage="{icon} Save" values={{
                            icon: <i className="fas fa-save"></i>
                        }} />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage id="saveItemDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                            icon: <i className="fas fa-times"></i>
                        }} />
                    </button>
                ]
            }
            show={show}
            large={large}
            scroll={true}
            onClose={onClose}>
            <div>
                {loading ?
                    <div className="text-center" style={{
                        padding: '30px', fontSize: '64px', minHeight: 'calc(100vh - 210px)'
                    }}>
                        <ProgressMessage message={''} />
                    </div>
                    :
                    <div>
                        <div style={iconStyle}>{icon}</div>
                        <div className="form-horizontal" style={bodyStyle}>
                            {(message !== undefined) ? <p>{message}</p> : null}
                            <div className="row spaced">
                                <label htmlFor="saveItemNameBox" className="control-label col-md-3">
                                    <FormattedMessage id="saveItemDialog.label.name" defaultMessage="Name" values={{
                                        icon: <i className="fas fa-folder-open"></i>
                                    }} />
                                </label>
                                <div className="col-md-9">
                                    <input id="saveItemNameBox" className="form-control" defaultValue={text} onChange={this.onTextChange} />
                                </div>
                            </div>
                            <div className="row spaced">
                                <label htmlFor="saveItemTagsBox" className="control-label col-md-3">
                                    <FormattedMessage id="saveItemDialog.label.tags" defaultMessage="{icon} Tags" values={{
                                        icon: <i className="fas fa-tags"></i>
                                    }} />
                                </label>
                                <div className="col-md-9">
                                    <input id="saveItemTagsBox" className="form-control" defaultValue={tags} onChange={this.onTagsChange} />
                                </div>
                            </div>
                            <div className="row spaced">
                                <label htmlFor="saveItemFolderBox" className="control-label col-md-3">
                                    <FormattedMessage id="saveItemDialog.label.folder" defaultMessage="{icon} Folder" values={{
                                        icon: <i className="fas fa-folder-open"></i>
                                    }} />
                                </label>
                                <div className="col-md-9">
                                    <select id="saveItemFolderBox" className="form-control" defaultValue={selected} onChange={this.onSelectionChange}>
                                    {listItems}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </ModalDialog>);
    }
}
const mapStateToProps = state =>
{
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(injectIntl(ArcItemSaveDialog, { withRef: true }));