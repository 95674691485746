import React, { Component } from 'react';
import { setPageState, setUserOptions } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'utils/object';
import { addClass, removeClass } from 'utils/dom';
import 'pages/UserOptions.scss';

class UserOptionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsOverride: [],
            saveRequired: false
        };
        this.saveOptions = this.saveOptions.bind(this);
        this.updateOption = this.updateOption.bind(this);
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Options', this.getTitleIcon(), null, null);
    }

    getTitleIcon() {
        return (
            <span className="small ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-user-cog"></i>
            </span>
        );
    }

    updateOption(topKey, optionKey, evt) {
        const { userOptions } = this.props,
            { optionsOverride } = this.state,
            value = evt.target.value,
            isChecked = evt.target.checked;
        let opts = null,
            elm = null;
        if (topKey === 'app') {
            // All the app keys are (currently) <something>-or-<normal> so this is simple
            if (!isNullOrUndefined(optionsOverride) && !isNullOrUndefined(optionsOverride.find((o) => o.id === 'app')))
                opts = optionsOverride.find((o) => o.id === 'app');
            else if (!isNullOrUndefined(userOptions) && !isNullOrUndefined(userOptions.find((o) => o.id === 'app')))
                opts = userOptions.find((o) => o.id === 'app');
            else {
                opts = {
                    id: 'app',
                    skin: 'normal',
                    layout: 'normal'
                };
            }
            // Apply...
            opts[optionKey] = isChecked ? 'normal' : value;
            // Store...
            if (isNullOrUndefined(optionsOverride.find((o) => o.id === 'app'))) optionsOverride.push(opts);
            this.setState(
                {
                    optionsOverride: optionsOverride,
                    saveRequired: true
                },
                () => {
                    // UI update?
                    elm = document.getElementById('iaAppRoot');
                    removeClass(elm, `${optionKey}-${value}`);
                    removeClass(elm, `${optionKey}-normal`);
                    addClass(elm, `${optionKey}-${opts[optionKey]}`);
                }
            );
        } else if (topKey !== undefined) {
            // All the app keys are (currently) <something>-or-<normal> so this is simple
            if (!isNullOrUndefined(optionsOverride) && !isNullOrUndefined(optionsOverride.find((o) => o.id === topKey)))
                opts = optionsOverride.find((o) => o.id === topKey);
            else if (!isNullOrUndefined(userOptions) && !isNullOrUndefined(userOptions.find((o) => o.id === topKey)))
                opts = userOptions.find((o) => o.id === topKey);
            else {
                // No defaults - should maybe make some but...
                opts = {
                    id: topKey
                };
            }
            // Apply... presume boolean if value is null or empty
            opts[optionKey] = value === '' ? isChecked : isChecked ? 'normal' : value;
            // Store...
            if (isNullOrUndefined(optionsOverride.find((o) => o.id === topKey))) optionsOverride.push(opts);
            this.setState(
                {
                    optionsOverride: optionsOverride,
                    saveRequired: true
                },
                () => {}
            );
        }
    }

    saveOptions() {
        const { userOptions, history, setUserOptions } = this.props,
            { optionsOverride } = this.state,
            updatedOptions = [...userOptions],
            ffnc = (o) => updatedOptions.findIndex((uo) => uo.id === o.id);
        if (optionsOverride.length > 0) {
            for (let o of optionsOverride) {
                let i = ffnc(o);
                if (i >= 0) updatedOptions.splice(i, 1, o);
                else updatedOptions.push(o);
            }
            setUserOptions(updatedOptions);
        }
        const fwd =
            !isNullOrUndefined(history.state) && !isNullOrUndefined(history.state.from) ? history.state.from : '/';
        setTimeout(() => {
            history.push(fwd);
        }, 100);
    }

    render() {
        const { user, userOptions } = this.props,
            { optionsOverride, saveRequired } = this.state,
            hasOptions = !isNullOrUndefined(userOptions),
            hasOver = !isNullOrUndefined(optionsOverride),
            auth = user !== null && user.username !== undefined && user.username !== null,
            appOptions = {
                skin: 'normal',
                layout: 'normal',
                ...(hasOver && !isNullOrUndefined(optionsOverride.find((o) => o.id === 'app'))
                    ? optionsOverride.find((o) => o.id === 'app')
                    : hasOptions && !isNullOrUndefined(userOptions.find((o) => o.id === 'app'))
                    ? userOptions.find((o) => o.id === 'app')
                    : {})
            },
            homeOptions = {
                showWelcomeDialog: true,
                ...(hasOver && !isNullOrUndefined(optionsOverride.find((o) => o.id === 'homePage'))
                    ? optionsOverride.find((o) => o.id === 'homePage')
                    : !isNullOrUndefined(userOptions) &&
                      !isNullOrUndefined(userOptions.find((o) => o.id === 'homePage'))
                    ? userOptions.find((o) => o.id === 'homePage')
                    : {})
            };

        return auth ? (
            <>
                <div className="catalog-switcher-top">
                    <button
                        className={`btn btn-link pure-tip pure-tip-bottom ${
                            saveRequired ? 'btn-call-action' : ''
                        }`.trim()}
                        type="button"
                        data-tooltip="Save"
                        onClick={this.saveOptions}
                    >
                        <i className="fas fa-save fa-fw"></i>
                    </button>
                </div>
                <div className="simple-central options-content form-horizontal">
                    <h3>
                        <FormattedMessage id="userOptions.app.header" defaultMessage="General" />
                    </h3>
                    <div className="row spaced">
                        <label htmlFor="optionsAppSkinBox" className="control-label col-md-2 col-md-offset-1">
                            <FormattedMessage
                                id="userOptions.app.label.skin"
                                defaultMessage="{icon} Style:"
                                values={{
                                    icon: <i className="fas fa-adjust"></i>
                                }}
                            />
                        </label>
                        <div className="col-md-7 discrete-toggle">
                            <input
                                type="checkbox"
                                id="optionsAppSkinBox"
                                value="dark"
                                defaultChecked={appOptions.skin === 'normal'}
                                onChange={(e) => this.updateOption('app', 'skin', e)}
                                className="form-control native"
                            />
                            <label htmlFor="optionsAppSkinBox" className="control-label slider-prefix">
                                <FormattedMessage id="userOptions.app.label.skin.normal" defaultMessage="Normal" />
                            </label>
                            <label htmlFor="optionsAppSkinBox" className="control-label slider-suffix">
                                <FormattedMessage id="userOptions.app.label.skin.dark" defaultMessage="Dark" />
                            </label>
                        </div>
                    </div>
                    <div className="row spaced">
                        <label htmlFor="optionsAppLayoutBox" className="control-label col-md-2 col-md-offset-1">
                            <FormattedMessage
                                id="userOptions.app.label.skin"
                                defaultMessage="{icon} Layout:"
                                values={{
                                    icon: <i className="fas fa-object-group"></i>
                                }}
                            />
                        </label>
                        <div className="col-md-7 discrete-toggle">
                            <input
                                type="checkbox"
                                id="optionsAppLayoutBox"
                                value="compact"
                                defaultChecked={appOptions.layout === 'normal'}
                                onChange={(e) => this.updateOption('app', 'layout', e)}
                                className="form-control native"
                            />
                            <label htmlFor="optionsAppLayoutBox" className="control-label slider-prefix">
                                <FormattedMessage id="userOptions.app.label.layout.normal" defaultMessage="Normal" />
                            </label>
                            <label htmlFor="optionsAppLayoutBox" className="control-label slider-suffix">
                                <FormattedMessage id="userOptions.app.label.layout.compact" defaultMessage="Compact" />
                            </label>
                        </div>
                    </div>
                    <h3>
                        <FormattedMessage id="userOptions.homePage.header" defaultMessage="Home Page" />
                    </h3>
                    <div className="row spaced">
                        <label htmlFor="optionsHomeWelcomeBox" className="control-label col-md-2 col-md-offset-1">
                            <FormattedMessage
                                id="userOptions.homePage.label.popup"
                                defaultMessage="Show welcome dialog:"
                            />
                        </label>
                        <div className="col-md-7 discrete-toggle">
                            <input
                                type="checkbox"
                                id="optionsHomeWelcomeBox"
                                value=""
                                defaultChecked={homeOptions.showWelcomeDialog.toString() === 'true'}
                                onChange={(e) => this.updateOption('homePage', 'showWelcomeDialog', e)}
                                className="form-control native"
                            />
                            <label htmlFor="optionsHomeWelcomeBox" className="control-label slider-prefix">
                                <FormattedMessage id="userOptions.homePage.label.popup.normal" defaultMessage="Yes" />
                            </label>
                            <label htmlFor="optionsHomeWelcomeBox" className="control-label slider-suffix">
                                <FormattedMessage id="userOptions.homePage.label.popup.dark" defaultMessage="No" />
                            </label>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        userOptions: state.userOptions
    };
};

const actionCreators = {
    setPageState,
    setUserOptions
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(UserOptionsPage)));
