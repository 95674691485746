import React, { Component } from 'react';
import { setPageState } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { findCatalogTable } from 'api/catalogUtils';
import { ArcGISPortal, DataCatalogManager } from 'data-catalog-js-api';
import ItemsLinkList from 'components/ItemsLinkList';
import ListToggleButton from 'components/ListToggleButton';
import ModalDialog from 'components/ModalDialog';
import { isNullOrUndefined } from 'utils/object';
//import styles from 'pages/DataExplorer.scss';
import ProgressMessage from 'components/ProgressMessage';
import { post } from 'utils/json';

class CreateCatalogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWorking: false,
            catalogs: [],
            folders: [],
            text: 'New Data Catalog',
            messages: []
        };
        this.toggleButton = React.createRef();
    }

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-plus"></i>
            </span>
        );
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Add New Catalog', this.getTitleIcon(), null, null);
        const { user, portalUrl, token } = this.props;
        this.setState(
            {
                isLoading: true
            },
            () => {
                ArcGISPortal.getInfo(`${portalUrl.replace(/\/$/, '')}/portals/self`, { f: 'json', token: token }).then(
                    (portalSelf) => {
                        ArcGISPortal.getInfo(`${portalUrl.replace(/\/$/, '')}/content/users/${user.username}`, {
                            f: 'json',
                            token: token
                        }).then((userDetails) => {
                            this.setState({
                                isLoading: false,
                                folders: [{ id: '', title: user.username }].concat(userDetails.folders),
                                portal: portalSelf
                            });
                            this.loadMasterTableBasics();
                        });
                    }
                );
            }
        );
    }

    loadMasterTableBasics = () => {
        const { user, token, portalUrl } = this.props;
        findCatalogTable({
            home: portalUrl,
            user: user,
            token: token,
            table: null
        }).then((catalogItems) => {
            const possibles = catalogItems.available;
            this.setState({
                isLoading: false,
                isError: false,
                messages: [],
                progress: 0,
                catalogs: possibles
            });
        });
    };

    onSelectionChange = (evt) => {
        const itemId = evt.currentTarget.value;
        this.setState({
            selected: itemId
        });
    };

    onTextChange = (evt) => {
        const text = evt.currentTarget.value;
        this.setState({
            text: text
        });
    };

    onRadioChange = (evt) => {
        const radio = evt.currentTarget.value;
        this.setState({
            files: radio
        });
    };

    uploadCatalogTables = () => {
        const { user, token, portalUrl } = this.props,
            { selected, files } = this.state,
            rootPath = (process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/').replace(/(.*)\/$/, '$1'),
            srcUrl = `${
                rootPath.substring(0, 4) !== 'http' ? `https://${window.location.hostname}` : ''
            }/static/catalogs/`,
            srcMasterFile = files !== undefined ? files.split(';')[0] : 'diyDataCatalogMasterTable.zip',
            srcMetadataFile = files !== undefined ? files.split(';')[1] : 'diyDataCatalogMetadataTable.zip';
        this.setState(
            {
                isWorking: true,
                progress: 0,
                messages: [
                    <FormattedMessage
                        id="newcatalog.progressMessage.uploadingTables"
                        defaultMessage="{icon} Uploading catalog table to {host}. Please wait.."
                        values={{
                            icon: <i className="fas fa-cloud-upload-alt"></i>,
                            host:
                                portalUrl !== undefined && portalUrl !== null
                                    ? portalUrl.replace(/^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/, '$2')
                                    : ''
                        }}
                    />
                ]
            },
            () => {
                ArcGISPortal.addItem(
                    user.username,
                    selected,
                    {
                        f: 'json',
                        title: 'InstantAtlas Data Catalog Source DB ' + new Date().toISOString().substring(0, 16),
                        tags: 'ia-item-type=CatalogMasterTableDB',
                        dataUrl: `${srcUrl}${srcMasterFile}`,
                        type: 'File Geodatabase',
                        overwrite: true,
                        token: token
                    },
                    portalUrl
                ).then((uploadMasterTableResponse) => {
                    this.setState({
                        isWorking: true,
                        progress: 1
                    });
                    ArcGISPortal.addItem(
                        user.username,
                        selected,
                        {
                            f: 'json',
                            title: 'InstantAtlas Data Catalog Metadata DB ' + new Date().toISOString().substring(0, 16),
                            tags: 'ia-item-type=CatalogMasterTableDB',
                            dataUrl: `${srcUrl}${srcMetadataFile}`,
                            type: 'File Geodatabase',
                            overwrite: true,
                            token: token
                        },
                        portalUrl
                    ).then((uploadMetadataTableResponse) => {
                        this.setState({
                            isWorking: true,
                            progress: 2,
                            messages: [
                                <FormattedMessage
                                    id="newcatalog.progressMessage.checkingTables"
                                    defaultMessage="{icon} Checking catalog tables on {host}. Please wait.."
                                    values={{
                                        icon: <i className="fas fa-question-circle fa-spin"></i>,
                                        host:
                                            portalUrl !== undefined && portalUrl !== null
                                                ? portalUrl.replace(
                                                      /^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/,
                                                      '$2'
                                                  )
                                                : ''
                                    }}
                                />
                            ]
                        });
                        if (uploadMasterTableResponse.success && uploadMetadataTableResponse.success) {
                            this.setState({
                                isWorking: true,
                                progress: 2,
                                messages: [
                                    <FormattedMessage
                                        id="newcatalog.progressMessage.publishingTables"
                                        defaultMessage="{icon} Publishing catalog table as a service on {host}. Please wait.."
                                        values={{
                                            icon: <i className="fas fa-table"></i>,
                                            host:
                                                portalUrl !== undefined && portalUrl !== null
                                                    ? portalUrl.replace(
                                                          /^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/,
                                                          '$2'
                                                      )
                                                    : ''
                                        }}
                                    />
                                ]
                            });
                            // This is to deal with ArcGIS Online stupidity...
                            setTimeout(() => {
                                this.publishCatalogTables(uploadMasterTableResponse, uploadMetadataTableResponse);
                            }, 4000);
                        } else {
                            this.setState({
                                isWorking: false,
                                error: {
                                    message: (
                                        <FormattedMessage
                                            id="newcatalog.addItems.errorDialog.messageFormat"
                                            defaultMessage="Could not upload the data catalog to ArcGIS Online. The error message was: {message}"
                                            values={{
                                                message: (
                                                    <span>
                                                        <br />
                                                        <br />
                                                        <span className="bg-danger">
                                                            {uploadMasterTableResponse.error !== null
                                                                ? uploadMasterTableResponse.error.message
                                                                : 'unknown error'}
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </span>
                                                )
                                            }}
                                        />
                                    ),
                                    key:
                                        uploadMasterTableResponse.error !== null
                                            ? uploadMasterTableResponse.error.code.toString()
                                            : 'unknown'
                                }
                            });
                        }
                    });
                });
            }
        );
    };

    publishCatalogTables = (uploadMasterTableResponse, uploadMetadataTableResponse) => {
        const { user, token, portalUrl } = this.props,
            { selected, text, portal } = this.state,
            pubParamsMstr = {
                hasStaticData: false,
                name: 'InstantAtlas_Data_Catalog',
                maxRecordCount: 4000,
                layerInfo: {
                    capabilities: 'Query,Editing'
                }
            },
            pubParamsMeta = {
                hasStaticData: false,
                name: 'InstantAtlas_Data_Catalog_Metadata',
                maxRecordCount: 4000,
                layerInfo: {
                    capabilities: 'Query,Editing'
                }
            };
        if (!isNullOrUndefined(text) && text.length > 5) {
            pubParamsMstr.name = text.replace(/[^0-9a-zA-Z]/g, '_');
            pubParamsMeta.name = text.replace(/[^0-9a-zA-Z]/g, '_') + '_Metadata';
        }
        ArcGISPortal.getInfo(
            `${portalUrl}portals/${portal.id}/isServiceNameAvailable`,
            {
                f: 'json',
                name: pubParamsMstr.name,
                type: 'Feature Service',
                token: token
            },
            portalUrl
        ).then((nameStatus) => {
            this.setState({
                isWorking: true,
                progress: 3
            });
            const tableItemId = uploadMasterTableResponse.id,
                metaItemId = uploadMetadataTableResponse.id;
            if (!nameStatus.available) {
                pubParamsMstr.name =
                    pubParamsMstr.name +
                    '_' +
                    new Date()
                        .toISOString()
                        .substring(0, 16)
                        .replace(/[^0-9]/g, '');
                pubParamsMeta.name =
                    pubParamsMeta.name +
                    '_' +
                    new Date()
                        .toISOString()
                        .substring(0, 16)
                        .replace(/[^0-9]/g, '');
            }
            post(`${portalUrl}content/users/${user.username}/publish`, {
                f: 'json',
                itemId: tableItemId,
                publishParameters: JSON.stringify(pubParamsMstr),
                filetype: 'filegeodatabase',
                //deleteSourceItemUponCompletion: true,
                token: token
            }).then((masterTablePubResponse) => {
                this.setState({
                    isWorking: true,
                    progress: 4
                });
                post(`${portalUrl}content/users/${user.username}/publish`, {
                    f: 'json',
                    itemId: metaItemId,
                    publishParameters: JSON.stringify(pubParamsMeta),
                    filetype: 'filegeodatabase',
                    //deleteSourceItemUponCompletion: true,
                    token: token
                }).then((metaTablePubResponse) => {
                    this.setState({
                        isWorking: true,
                        progress: 5,
                        messages: [
                            <FormattedMessage
                                id="newcatalog.progressMessage.updatingTables"
                                defaultMessage="{icon} Applying final updates to catalog table. Please wait.."
                                values={{
                                    icon: <i className="fas fa-pencil-alt"></i>,
                                    host:
                                        portalUrl !== undefined && portalUrl !== null
                                            ? portalUrl.replace(
                                                  /^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/,
                                                  '$2'
                                              )
                                            : ''
                                }}
                            />
                        ]
                    });
                    let masterTableItemId, masterTableItemUrl, jobId, metaTableItemId, metaTableItemUrl;
                    if (
                        !isNullOrUndefined(masterTablePubResponse.services) &&
                        masterTablePubResponse.services.length > 0 &&
                        !(masterTablePubResponse.services[0].success === false) &&
                        !isNullOrUndefined(metaTablePubResponse.services) &&
                        metaTablePubResponse.services.length > 0 &&
                        !(metaTablePubResponse.services[0].success === false)
                    ) {
                        masterTableItemId = masterTablePubResponse.services[0].serviceItemId;
                        jobId = masterTablePubResponse.services[0].jobId;
                        masterTableItemUrl =
                            masterTablePubResponse.services[0].serviceURL ||
                            masterTablePubResponse.services[0].serviceurl; // Docs unclear...
                        masterTableItemUrl = `${masterTableItemUrl}/0`; // 1 layer
                        metaTableItemId = metaTablePubResponse.services[0].serviceItemId;
                        metaTableItemUrl =
                            metaTablePubResponse.services[0].serviceURL || metaTablePubResponse.services[0].serviceurl; // Docs unclear...
                        metaTableItemUrl = `${metaTableItemUrl}/0`; // 1 layer
                        const timestampArgs = {
                            values: {
                                instantatlas: {
                                    updated: Math.round(new Date(2019, 0, 1).getTime() / 1000.0) // seconds, same as ArcGIS Online uses
                                }
                            }
                        };
                        ArcGISPortal.updateItem(
                            user.username,
                            masterTableItemId,
                            selected,
                            {
                                title: !isNullOrUndefined(text) ? text : pubParamsMstr.name.split('_').join(' '),
                                tags: 'ia-item-type=CatalogMasterTable,ia-item-managed=no,DataCatalog,Index,InstantAtlas',
                                description: '', //iao.getText('NewDataCatalog.Description', 'Manage <strong>{1}<\/strong> using the <a class="btn btn-clear" href="https://hub.instantatlas.com/data-catalog/manager?item={0}" target="_blank">InstantAtlas Data Manager 🔧<\/a>.').replace(/\{0\}/g, masterTableItemId).replace(/\{1\}/g, itemName || pubParamsMstr.name.split('_').join(' ')),
                                text: JSON.stringify(timestampArgs),
                                token: token
                            },
                            portalUrl
                        ).then((updateResponseMstr) => {
                            ArcGISPortal.updateItem(
                                user.username,
                                metaTableItemId,
                                selected,
                                {
                                    title:
                                        (!isNullOrUndefined(text) ? text : pubParamsMstr.name.split('_').join(' ')) +
                                        ' - Metadata',
                                    snippet: `Metadata table for ${text}`,
                                    tags: 'ia-item-type=CatalogMetadataTable,ia-item-managed=no,DataCatalog,Metadata,InstantAtlas',
                                    description: '', //iao.getText('NewDataCatalog.Description', 'Manage <strong>{1}<\/strong> using the <a class="btn btn-clear" href="https://hub.instantatlas.com/data-catalog/manager?item={0}" target="_blank">InstantAtlas Data Manager 🔧<\/a>.').replace(/\{0\}/g, masterTableItemId).replace(/\{1\}/g, itemName || pubParamsMstr.name.split('_').join(' ')),
                                    text: JSON.stringify(timestampArgs),
                                    token: token
                                },
                                portalUrl
                            ).then((updateResponseMeta) => {
                                if (updateResponseMstr.success) {
                                    ArcGISPortal.getItemDescription(
                                        masterTableItemId,
                                        { f: 'json', token: token },
                                        portalUrl
                                    ).then((itemInfo) => {
                                        // Sigh. ArcGIS Online and its wonky updates....
                                        var updateFailed =
                                                !itemInfo ||
                                                itemInfo.tags.indexOf('ia-item-type=CatalogMasterTable') < 0 ||
                                                itemInfo.tags.indexOf('ia-item-managed=no') < 0,
                                            featureRows = [
                                                {
                                                    attributes: {
                                                        ID: 'Metadata',
                                                        Name: 'Metadata Service',
                                                        Item_Type: 'Metadata',
                                                        Service_Url: metaTableItemUrl,
                                                        Item_Order: 0
                                                    }
                                                }
                                            ];
                                        if (!isNullOrUndefined(jobId) && !updateFailed) {
                                            this.setState({
                                                isWorking: true,
                                                progress: 5,
                                                messages: [
                                                    <FormattedMessage
                                                        id="newcatalog.progressMessage.checkingJob"
                                                        defaultMessage="{icon} Waiting for {host} to complete task(s). Please wait.."
                                                        values={{
                                                            icon: <i className="fas fa-sync fa-spin"></i>,
                                                            host:
                                                                portalUrl !== undefined && portalUrl !== null
                                                                    ? portalUrl.replace(
                                                                          /^(http:\/\/|https:\/\/)([0-9a-zA-Z_\-.]+)\/(.*)$/,
                                                                          '$2'
                                                                      )
                                                                    : ''
                                                        }}
                                                    />
                                                ]
                                            });
                                            const workingPage = this;
                                            this.pollJob(
                                                `${portalUrl}content/users/${user.username}/${
                                                    !isNullOrUndefined(selected) && selected !== ''
                                                        ? `${selected}/`
                                                        : ''
                                                }items/${masterTableItemId}/status`,
                                                jobId,
                                                'publish',
                                                token,
                                                (e) => {
                                                    const catalog = new DataCatalogManager(
                                                        masterTableItemUrl,
                                                        metaTableItemUrl,
                                                        user,
                                                        token,
                                                        portalUrl
                                                    );
                                                    catalog.init().then(() => {
                                                        catalog
                                                            .addItems(featureRows)
                                                            .then(function (insertMetaRowResult) {
                                                                workingPage.setState({
                                                                    isWorking: false
                                                                });
                                                                window.location.href = `./manager?item=${masterTableItemId}`; // TODO - detect errors
                                                            });
                                                    });
                                                }
                                            );
                                        } else {
                                            this.setState({
                                                isWorking: false,
                                                error: {
                                                    message: (
                                                        <FormattedMessage
                                                            id="newcatalog.updateItems.errorDialog.messageFormat"
                                                            defaultMessage="Could not update the data catalog service on ArcGIS Online/Portal. You may have to delete the items and try again. The error message was: {message}"
                                                            values={{
                                                                message: (
                                                                    <span>
                                                                        <br />
                                                                        <br />
                                                                        <span className="bg-danger">
                                                                            {masterTablePubResponse.error !== null
                                                                                ? masterTablePubResponse.error.message
                                                                                : 'unknown error'}
                                                                        </span>
                                                                        <br />
                                                                        <br />
                                                                    </span>
                                                                )
                                                            }}
                                                        />
                                                    ),
                                                    key:
                                                        masterTablePubResponse.error !== null
                                                            ? masterTablePubResponse.error.code.toString()
                                                            : 'unknown'
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        });
                    } else {
                        this.setState({
                            isWorking: false,
                            error: {
                                message: (
                                    <FormattedMessage
                                        id="newcatalog.publishItems.errorDialog.messageFormat"
                                        defaultMessage="Could not publish the data catalog as a service on ArcGIS Online. The error message was: {message}"
                                        values={{
                                            message: (
                                                <span>
                                                    <br />
                                                    <br />
                                                    <span className="bg-danger">
                                                        {masterTablePubResponse.error !== null
                                                            ? masterTablePubResponse.error.message
                                                            : 'unknown error'}
                                                    </span>
                                                    <br />
                                                    <br />
                                                </span>
                                            )
                                        }}
                                    />
                                ),
                                key:
                                    masterTablePubResponse.error !== null
                                        ? masterTablePubResponse.error.code.toString()
                                        : 'unknown'
                            }
                        });
                    }
                });
            });
        });
    };

    pollJob = (itemUrl, jobId, jobType, tkn, onCompleteFnc) => {
        post(itemUrl, {
            f: 'json',
            token: tkn,
            jobId: jobId,
            jobType: jobType
        }).then((jobDetails) => {
            if (!isNullOrUndefined(jobDetails) && (jobDetails.status === 'completed' || jobDetails.status === 'failed'))
                onCompleteFnc(jobDetails);
            else {
                setTimeout(() => {
                    this.pollJob(itemUrl, jobId, jobType, tkn, onCompleteFnc);
                }, 10000); // 10 seconds? Too long?
            }
        });
    };

    clearError() {
        this.setState({
            activeModal: null,
            error: null
        });
    }

    render() {
        const { user } = this.props,
            { catalogs, isLoading, isWorking, folders, selected, portal, error, progress, messages } = this.state,
            auth = user !== undefined && user !== null && user.username !== undefined && user.username !== null,
            listItems = !isNullOrUndefined(folders)
                ? folders.map((mi, index) => {
                      return (
                          <option id={`itemOption${mi.id}`} key={index} value={mi.id}>
                              {mi.title}
                          </option>
                      );
                  })
                : null,
            existingCatalogs = !isNullOrUndefined(catalogs) && catalogs.length > 0,
            progressPerc = isWorking ? Math.round((progress * 100.0) / 6.0) : 0;
        return auth ? (
            isLoading ? (
                <ProgressMessage />
            ) : (
                <div>
                    {existingCatalogs ? (
                        <div className="catalog-switcher-top">
                            <ListToggleButton
                                ref={this.toggleButton}
                                text={<i className="fas fa-bars fa-fw"></i>}
                                tooltip="Choose Catalog"
                                className="btn btn-link pure-tip pure-tip-bottom"
                            >
                                <ItemsLinkList
                                    items={catalogs}
                                    linkPattern="/manager?item={itemId}"
                                    linkIcon={
                                        <span>
                                            <i className="fas fa-fw fa-database"></i>
                                            <i className="fas fa-wrench"></i>{' '}
                                        </span>
                                    }
                                />
                            </ListToggleButton>
                        </div>
                    ) : null}
                    <div id="catalogCreateControls" className="row all-available-height">
                        <div className="col col-md-12">
                            <div className="row">
                                <div className="col col-md-1">&nbsp;</div>
                                <div className="col col-md-10 flex-box">
                                    {existingCatalogs ? (
                                        <div className="org-warning-table-present bg-warning pad10">
                                            <i className="fas fa-exclamation-triangle fa-3x pull-left">&nbsp;</i>
                                            {user.role === 'org_admin' ? (
                                                <p className="org-admin">
                                                    <FormattedMessage
                                                        id="newcatalog.existingCatalogs.adminLabel"
                                                        defaultMessage="Your ArcGIS Online organisation {org} already has an InstantAtlas Data Catalog. You can create a new catalog using the controls below but you should think carefully about this - do you need more than one catalog? Are you sure you don't want to manage {catalog} (or one of your other catalogs - click the {icon} above) instead? For more information on creating and managing catalogs consult the {help}."
                                                        values={{
                                                            org: <strong className="org-name">{portal.name}</strong>,
                                                            catalog: (
                                                                <Link
                                                                    className="ia-catalog-link"
                                                                    to={`/manager?item=${catalogs[0].id}`}
                                                                >
                                                                    <span className="catalog-name">
                                                                        {catalogs[0].title}{' '}
                                                                        <i className="fas fa-database"></i>
                                                                        <i className="fas fa-wrench"></i>
                                                                    </span>
                                                                </Link>
                                                            ),
                                                            help: (
                                                                <a
                                                                    href="https://help.instantatlas.com/instantatlas-data-catalog/"
                                                                    target="iaHelpWindow"
                                                                >
                                                                    online help pages{' '}
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </a>
                                                            ),
                                                            icon: <i className="fas fa-bars fa-fw"></i>
                                                        }}
                                                    />
                                                </p>
                                            ) : (
                                                <p className="non-admin">
                                                    <FormattedMessage
                                                        id="newcatalog.existingCatalogs.nonAdminLabel"
                                                        defaultMessage="Your ArcGIS Online organisation {org} has at least one InstantAtlas Data Catalog. You can create a new catalog using the controls below but you should think carefully about this - do you need more than one catalog? Make sure you check with your ArcGIS Online administrator first - it may be that there are other catalogs not been shared with you."
                                                        values={{
                                                            org: <strong className="org-name">{portal.name}</strong>,
                                                            catalog: (
                                                                <Link
                                                                    className="btn btn-primary ia-catalog-link"
                                                                    to={`/manager?item=${catalogs[0].id}`}
                                                                >
                                                                    <span className="catalog-name">
                                                                        {catalogs[0].title}
                                                                    </span>
                                                                </Link>
                                                            )
                                                        }}
                                                    />
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                    {!existingCatalogs ? (
                                        <div className="org-no-table-present pad10">
                                            {user.role === 'org_admin' ? (
                                                <p className="org-admin">
                                                    Your ArcGIS Online organisation{' '}
                                                    <strong className="org-name"></strong> does not appear have an
                                                    InstantAtlas Data Catalog. You can create a new catalog using the
                                                    controls below.
                                                </p>
                                            ) : (
                                                <p className="non-admin">
                                                    Your ArcGIS Online organisation{' '}
                                                    <strong className="org-name"></strong> does not appear have an
                                                    InstantAtlas Data Catalog. You can create a new catalog using the
                                                    controls below, but make sure you check with your ArcGIS Online
                                                    administrator first - it may be that the catalog has just not been
                                                    shared with you.
                                                </p>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col col-md-1">&nbsp;</div>
                            </div>
                            <div className="row spaced">
                                <div className="col col-md-1">&nbsp;</div>
                                <div className="col col-md-10 form-horizontal">
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                            <div className="row row-fluid spaced">
                                                <label
                                                    className="control-label col-md-3 text-right"
                                                    htmlFor="itemNameBox"
                                                >
                                                    Catalog Name:
                                                </label>
                                                <div className="col-md-8 input-group">
                                                    <input
                                                        id="itemNameBox"
                                                        className="form-control"
                                                        defaultValue="New InstantAtlas Data Catalog"
                                                        onChange={this.onTextChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row row-fluid spaced">
                                                <label
                                                    className="control-label col-md-3 text-right"
                                                    htmlFor="saveItemFolderBox"
                                                    id="basic-addon3"
                                                >
                                                    <i className="fas fa-folder-open"></i> Folder:
                                                </label>
                                                <div className="col-md-8 input-group">
                                                    <select
                                                        id="saveItemFolderBox"
                                                        className="form-control"
                                                        defaultValue={selected}
                                                        onChange={this.onSelectionChange}
                                                    >
                                                        {listItems}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row row-fluid spaced">
                                                <label className="control-label col-md-3 text-right">
                                                    <i className="fas fa-database"></i> Data:
                                                </label>
                                                <div className="col-md-8">
                                                    <ul className="list-unstyled">
                                                        <li className="hover-info">
                                                            <input
                                                                name="catalogSelector"
                                                                id="catalogSelector_empty"
                                                                value="diyDataCatalogMasterTable.zip;diyDataCatalogMetadataTable.zip"
                                                                defaultChecked={true}
                                                                className="not-form-control"
                                                                type="radio"
                                                                onChange={this.onRadioChange}
                                                            />
                                                            <label
                                                                htmlFor="catalogSelector_empty"
                                                                className="control-label"
                                                            >
                                                                No data, blank catalog
                                                            </label>
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span>
                                                            <span className="info-box">
                                                                <FormattedMessage
                                                                    id="newcatalog.emptyCatalog.description"
                                                                    defaultMessage="An empty data catalog for you to create you own themes and indicators."
                                                                />
                                                            </span>
                                                        </li>
                                                        <li className="hover-info">
                                                            <input
                                                                name="catalogSelector"
                                                                id="catalogSelector_usa"
                                                                value="diyDataCatalogMasterTable_USA.zip;diyDataCatalogMetadataTable_USA.zip"
                                                                className="not-form-control"
                                                                type="radio"
                                                                onChange={this.onRadioChange}
                                                            />
                                                            <label
                                                                htmlFor="catalogSelector_usa"
                                                                className="control-label"
                                                            >
                                                                Example population data - USA
                                                            </label>
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span>
                                                            <span className="info-box">
                                                                <FormattedMessage
                                                                    id="newcatalog.usaCatalog.description"
                                                                    defaultMessage="{icon} A data catalog which contains population indicators for the USA for the layers County, State and United States from an {layer}. "
                                                                    values={{
                                                                        icon: <i className="fas fa-globe-americas"></i>,
                                                                        layer: (
                                                                            <a
                                                                                href="http://instantatlas.maps.arcgis.com/home/item.html?id=a902e51b82564d96b3ced76edc9b4bd7"
                                                                                target="iaoArcWindow"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                example feature layer{' '}
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </a>
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </li>
                                                        <li className="hover-info">
                                                            <input
                                                                name="catalogSelector"
                                                                id="catalogSelector_world"
                                                                value=""
                                                                className="not-form-control"
                                                                type="radio"
                                                                disabled="disabled"
                                                                onChange={this.onRadioChange}
                                                            />
                                                            <label
                                                                htmlFor="catalogSelector_world"
                                                                className="control-label"
                                                            >
                                                                Example population data - world countries
                                                            </label>
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span>
                                                            <span className="info-box">
                                                                <FormattedMessage
                                                                    id="newcatalog.worldCatalog.description"
                                                                    defaultMessage="{icon} [COMING SOON] A data catalog which contains population indicators for countries of the world from {layer}. Population data is derived from the {source}."
                                                                    values={{
                                                                        icon: <i className="fas fa-tools"></i>,
                                                                        layer: (
                                                                            <a
                                                                                href="http://instantatlas.maps.arcgis.com/home/item.html?id=8c2a5f2efc36454e8d826992cba40842"
                                                                                target="iaoArcWindow"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                an example feature layer{' '}
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </a>
                                                                        ),
                                                                        source: (
                                                                            <a
                                                                                href="https://data.worldbank.org/indicator/SP.POP.TOTL"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                World Bank DataBank{' '}
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </a>
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </li>
                                                        <li className="hover-info">
                                                            <input
                                                                name="catalogSelector"
                                                                id="catalogSelector_ukcensus"
                                                                value=""
                                                                className="not-form-control"
                                                                type="radio"
                                                                disabled="disabled"
                                                                onChange={this.onRadioChange}
                                                            />
                                                            <label
                                                                htmlFor="catalogSelector_ukcensus"
                                                                className="control-label"
                                                            >
                                                                Example Census data - England
                                                            </label>
                                                            <span style={{ marginLeft: '10px' }}>
                                                                <i className="fas fa-info-circle"></i>
                                                            </span>
                                                            <span className="info-box">
                                                                <FormattedMessage
                                                                    id="newcatalog.englandCensusCatalog.description"
                                                                    defaultMessage="{icon} [COMING SOON] A data catalog which contains indicators from the 2011 Census for local authorities in England from  {layer}. Population data is derived from the {source}."
                                                                    values={{
                                                                        icon: <i className="fas fa-tools"></i>,
                                                                        layer: (
                                                                            <a
                                                                                href="http://instantatlas.maps.arcgis.com/home/item.html?id=eb7ae39c503a4928bd6a69aa8275feda"
                                                                                target="iaoArcWindow"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                an example feature layer{' '}
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </a>
                                                                        ),
                                                                        source: (
                                                                            <a
                                                                                href="https://www.ons.gov.uk/census/2011census"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                Office for National Statistics (ONS){' '}
                                                                                <i className="fas fa-external-link-alt"></i>
                                                                            </a>
                                                                        )
                                                                    }}
                                                                />
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row row-fluid spaced">
                                                <div className="col-md-offset-3 col-md-4">
                                                    <button
                                                        id="catalogCreateButton"
                                                        onClick={this.uploadCatalogTables}
                                                        className={`btn btn-default ${
                                                            existingCatalogs ? 'btn-secondary' : 'btn-primary'
                                                        } btn-block`}
                                                        type="button"
                                                        style={{ fontSize: '1.25em' }}
                                                    >
                                                        <div className="pad-10-hz">
                                                            Add New Data Catalog{' '}
                                                            <i className="fas fa-fw fa-database"></i>
                                                            <i className="fas fa-plus"></i>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-1">&nbsp;</div>
                            </div>
                            {isWorking ? (
                                <div id="catalogProgressHolder" className="row spaced">
                                    <div className="col col-md-1">&nbsp;</div>
                                    <div className="col col-md-10 form-horizontal">
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-striped active"
                                                role="progressbar"
                                                aria-valuenow={progressPerc}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: `${progressPerc}%` }}
                                            >
                                                <span className="sr-only">{progressPerc}% Complete</span>
                                            </div>
                                        </div>
                                        <div className="progress-label text-center">{messages[0]}</div>
                                    </div>
                                    <div className="col col-md-1">&nbsp;</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {!isNullOrUndefined(error) && error.message !== undefined ? (
                        <ModalDialog
                            title={error.title !== undefined ? error.title : 'Error'}
                            show={true}
                            onClose={this.clearError}
                            buttons={[
                                <button type="button" className="btn btn-secondary" onClick={this.clearError}>
                                    <FormattedMessage
                                        id="manager.errorDialog.button.close"
                                        defaultMessage="{icon} Close"
                                        values={{
                                            icon: <i className="fas fa-times"></i>
                                        }}
                                    />
                                </button>
                            ]}
                        >
                            <div>
                                <div>
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: 10 + 'px',
                                            marginBottom: 10 + 'px',
                                            fontSize: '48px'
                                        }}
                                    >
                                        {error.type !== undefined && error.type === 'warning' ? (
                                            <i className="fas fa-exclamation-triangle"></i>
                                        ) : (
                                            <i className="fas fa-times-circle"></i>
                                        )}
                                    </div>
                                    <div style={{ marginLeft: 68 + 'px' }}>{error.message}</div>
                                </div>
                            </div>
                        </ModalDialog>
                    ) : null}
                </div>
            )
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(CreateCatalogPage)));
