import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { isNullOrUndefined } from 'utils/object';
import ListToggleButton from 'components/ListToggleButton';

const PageNavigatorButtons = () => {
    const user = useSelector((state) => state.hubAppSettings.user),
        token = useSelector((state) => state.hubAppSettings.token),
        item = useSelector((state) => (!isNullOrUndefined(state.pageState.item) ? state.pageState.item.id : null)),
        qs = new URLSearchParams(window.location.search),
        location = useLocation(),
        intl = useIntl(),
        itemId = !isNullOrUndefined(item)
            ? item
            : qs.has('item') && !isNullOrUndefined(qs.get('item'))
            ? qs.get('item')
            : null,
        publicModif = qs.has('public') && !isNullOrUndefined(qs.get('public')) ? `&public=${qs.get('public')}` : '',
        searchLink = !isNullOrUndefined(itemId)
            ? `?item=${itemId}&token=${token}${publicModif}`
            : `?token=${token}${publicModif}`;

    return isNullOrUndefined(user) || isNullOrUndefined(token) || location.pathname === '/' ? (
        <span></span>
    ) : (
        <div className="product-buttons product-buttons-sm right">
            <ListToggleButton
                element="div"
                text={
                    <>
                        <i className="fas fa-cog"></i>
                        <span className="sr-only">Data Cataloh Quick Links</span>
                    </>
                }
                tooltip="Data Catalog Tools"
                className="btn btn-link hub-button"
            >
                <div className="hub-wrapper">
                    <ul>
                        {location.pathname !== '/inspector' ? (
                            <li>
                                <Link
                                    to={{
                                        pathname: '/inspector',
                                        search: searchLink
                                    }}
                                    className="ia-catalog-link"
                                >
                                    <span
                                        data-tooltip={intl.formatMessage({
                                            id: 'navbar.link.inspector',
                                            defaultMessage: 'Data Catalog | Inspector'
                                        })}
                                    >
                                        <i className="fas fa-clipboard-check"></i>
                                        <span className="sr-only">
                                            <FormattedMessage
                                                id="navbar.link.inspector"
                                                defaultMessage="Data Catalog | Inspector"
                                            />
                                        </span>
                                    </span>
                                </Link>
                            </li>
                        ) : null}
                        {location.pathname !== '/web-map-builder' ? (
                            <li>
                                <Link
                                    to={{
                                        pathname: '/web-map-builder',
                                        search: searchLink
                                    }}
                                    className="ia-catalog-link"
                                >
                                    <span
                                        data-tooltip={intl.formatMessage({
                                            id: 'navbar.link.webmapbuilder',
                                            defaultMessage: 'Data Catalog | Web Map Builder'
                                        })}
                                    >
                                        <i className="fas fa-map-marked-alt"></i>
                                        <span className="sr-only">
                                            <FormattedMessage
                                                id="navbar.link.webmapbuilder"
                                                defaultMessage="Data Catalog | Web Map Builder"
                                            />
                                        </span>
                                    </span>
                                </Link>
                            </li>
                        ) : null}
                        {location.pathname !== '/manager' ? (
                            <li>
                                <Link
                                    to={{
                                        pathname: '/manager',
                                        search: searchLink
                                    }}
                                    className="ia-catalog-link"
                                >
                                    <span
                                        data-tooltip={intl.formatMessage({
                                            id: 'navbar.link.manager',
                                            defaultMessage: 'Data Catalog | Manager'
                                        })}
                                    >
                                        <i className="fas fa-wrench"></i>
                                        <span className="sr-only">
                                            <FormattedMessage
                                                id="navbar.link.manager"
                                                defaultMessage="Data Catalog | Manager"
                                            />
                                        </span>
                                    </span>
                                </Link>
                            </li>
                        ) : null}
                        {location.pathname !== '/explorer' ? (
                            <li>
                                <Link
                                    to={{
                                        pathname: '/explorer',
                                        search: searchLink
                                    }}
                                    className="ia-catalog-link"
                                >
                                    <span
                                        data-tooltip={intl.formatMessage({
                                            id: 'navbar.link.explorer',
                                            defaultMessage: 'Data Catalog | Explorer'
                                        })}
                                    >
                                        <i className="fas fa-search"></i>
                                        <span className="sr-only">
                                            <FormattedMessage
                                                id="navbar.link.explorer"
                                                defaultMessage="Data Catalog | Explorer"
                                            />
                                        </span>
                                    </span>
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </ListToggleButton>
        </div>
    );
};

export default PageNavigatorButtons;
