import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getReactIntlHtmlFuncs } from 'utils/localization';

class WelcomeDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 0,
            pages: 6,
            showAtStartup: true
        };
        this.setPage = this.setPage.bind(this);
        this.movePage = this.movePage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.toggleShowAtStartup = this.toggleShowAtStartup.bind(this);
    }

    componentDidMount() {
        //const { user, portalUrl, token  } = this.props;
        this.setState(
            {
                loading: false
            },
            () => {
                //ArcGISPortal.getInfo(`${portalUrl.replace(/\/$/, '')}/content/users/${user.username}`, { f: 'json', token: token }).then(userDetails =>
                //{
                //    this.setState({
                //        loading: false,
                //        folders: [{ id: '', title: user.username }].concat(userDetails.folders)
                //    });
                //});
            }
        );
    }

    movePage(evt, dir) {
        evt.preventDefault();
        evt.stopPropagation();
        const { page } = this.state,
            index = page + dir;
        this.setPage(index);
    }

    setPage(index) {
        const { pages } = this.state;
        this.setState({
            page: Math.max(0, Math.min(pages - 1, index))
        });
    }

    handleClose() {
        const { onClose } = this.props,
            { showAtStartup } = this.state;

        if (onClose !== undefined) onClose(this, showAtStartup);
    }

    toggleShowAtStartup() {
        this.setState({
            showAtStartup: !this.state.showAtStartup
        });
    }

    render() {
        const { page, pages } = this.state,
            { show, user } = this.props,
            olItems = [];

        for (let i = 0; i < pages; i++) {
            olItems.push(
                <li key={i} onClick={(e) => this.setPage(i)} className={page === i ? 'active' : 'inactive'}></li>
            );
        }

        return !show ? null : (
            <ModalDialog
                title={null}
                buttons={[
                    <div className="pull-left" style={{ margin: '10px 0 0 20px' }}>
                        <span className="small">
                            <input
                                type="checkbox"
                                defaultChecked={true}
                                onChange={this.toggleShowAtStartup}
                                id="welcomePageDialogStartupBox"
                                className="form-control"
                            />
                            <label className="control-label slider" htmlFor="welcomePageDialogStartupBox">
                                <FormattedMessage
                                    id="welcomeDialog.button.startup"
                                    defaultMessage="show at startup"
                                    values={{
                                        icon: <i className="fas fa-pencil-alt"></i>
                                    }}
                                />
                            </label>
                        </span>
                    </div>,
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={(e) => this.movePage(e, -1)}
                        disabled={page < 1}
                    >
                        <FormattedMessage
                            id="welcomeDialog.button.choose"
                            defaultMessage="{icon} Back"
                            values={{
                                icon: <i className="fas fa-chevron-left"></i>
                            }}
                        />
                    </button>,
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={(e) => this.movePage(e, 1)}
                        disabled={page === pages - 1}
                    >
                        <FormattedMessage
                            id="welcomeDialog.button.choose"
                            defaultMessage="Next {icon}"
                            values={{
                                icon: <i className="fas fa-chevron-right"></i>
                            }}
                        />
                    </button>,
                    <button type="button" className="btn btn-link" data-dismiss="modal">
                        <FormattedMessage
                            id="welcomeDialog.button.cancel"
                            defaultMessage="Hide {icon}"
                            values={{
                                icon: <i className="fas fa-times"></i>
                            }}
                        />
                    </button>
                ]}
                show={show}
                large={true}
                scroll={false}
                onClose={this.handleClose}
                bodyClass="modal-body-unpadded"
            >
                <div className="carousel">
                    <ol className="carousel-indicators">{olItems}</ol>
                    <div className={`carousel-items offset-${page}`}>
                        <div className="item" data-key="Page 0, Start">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageStart.title"
                                                defaultMessage="Welcome to InstantAtlas™ Data Catalog"
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageStart.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <div>
                                            <p>New to InstantAtlas Data Catalog <strong>{user}</strong>?</p>
                                            <p>
                                                This is the <strong><icon>fas fa-database</icon><icon>fas fa-cog fa-xs</icon> Hub</strong> - from here you can access all the 
                                                <strong>data catalog</strong> and <strong>builder</strong> apps and tools.
                                            </p>
                                        </div>`}
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                user: user.fullName
                                            }}
                                        />
                                        <div className="bouncy-row">
                                            <div
                                                className="text-center bouncy-over-row"
                                                style={{ justifyContent: 'space-between' }}
                                            >
                                                <span
                                                    className="pure-tip pure-tip-top"
                                                    data-tooltip="Data Catalog | Manager"
                                                >
                                                    <i
                                                        className="fas fa-fw fa-wrench as-link"
                                                        onClick={(e) => this.setPage(1)}
                                                    ></i>
                                                </span>
                                                <span
                                                    className="pure-tip pure-tip-top"
                                                    data-tooltip="Data Catalog | Explorer"
                                                >
                                                    <i
                                                        className="fas fa-fw fa-search as-link"
                                                        onClick={(e) => this.setPage(2)}
                                                    ></i>
                                                </span>
                                                <span
                                                    className="pure-tip pure-tip-top"
                                                    data-tooltip="Data Catalog | Web Map Builder"
                                                >
                                                    <i
                                                        className="fas fa-fw fa-map-marked-alt as-link"
                                                        onClick={(e) => this.setPage(3)}
                                                    ></i>
                                                </span>
                                                <span
                                                    className="pure-tip pure-tip-top"
                                                    data-tooltip="Data Catalog | Inspector"
                                                >
                                                    <i
                                                        className="fas fa-fw fa-clipboard-check as-link"
                                                        onClick={(e) => this.setPage(4)}
                                                    ></i>
                                                </span>
                                                <span
                                                    className="pure-tip pure-tip-top"
                                                    data-tooltip="Report Builder for ArcGIS"
                                                >
                                                    <i onClick={(e) => this.setPage(5)} className="as-link">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/static/images/rb4arcgis-logo.svg`}
                                                            style={{
                                                                height: '1.4em'
                                                            }}
                                                            alt="Report Builder Logo"
                                                        />
                                                    </i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item" data-key="Page 1, Manager">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageManager.title"
                                                defaultMessage="Data Catalog | Manager {icon}"
                                                values={{ icon: <i className="fas fa-fw fa-wrench pull-right"></i> }}
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageManager.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <div>
                                            <p>
                                                The InstantAtlas Data Catalog <strong>Manager</strong> lets you create, move, delete and update all the <strong>indicators</strong> and
                                                <strong>themes</strong> within your data catalog.
                                            </p>
                                            <p>
                                                The easiest way to create your own data catalog is to follow the <a>https://help.instantatlas.com/instantatlas-data-catalog/manager/;iaHelpWindow;❝getting started❞ section in the help pages</a>.
                                                {img}
                                            </p>
                                        </div>`}
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                img: (
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/static/images/manager-shot1.png`}
                                                        className="pull-right"
                                                        style={{ width: '47%', opacity: 0.9 }}
                                                        alt="Manager screenshot"
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="bottom-left-btn">
                                        <i className="fas fa-fw fa-wrench fa-lg"></i>
                                        <Link to="/manager" className="btn btn-primary btn-block">
                                            Manage Catalog
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item" data-key="Page 2, Explorer">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageExplorer.title"
                                                defaultMessage="Data Catalog | Explorer {icon}"
                                                values={{ icon: <i className="fas fa-fw fa-search pull-right"></i> }}
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageExplorer.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <p>
                                                The InstantAtlas Data Catalog <strong>Explorer</strong> lets anyone in your organisation quickly and easily
                            search and find <strong>indicators</strong> within your data catalog and download the data or link it to a map or app in ArcGIS Online.
                        </p>
                                            <p>
                                                <a>https://help.instantatlas.com/instantatlas-data-catalog/explorer/;iaHelpWindow;Find out more in the help pages</a>.
                                            {img}
                        </p>`}
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                img: (
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/static/images/explorer-stack1.png`}
                                                        className="pull-right"
                                                        style={{ width: '47%', opacity: 0.9 }}
                                                        alt="Explorer screenshots"
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="bottom-left-btn">
                                        <i className="fas fa-fw fa-search fa-lg"></i>
                                        <Link to="/explorer" className="btn btn-primary btn-block">
                                            Explore Catalog
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item" data-key="Page 3, Web Map Builder">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageWebMapBuilder.title"
                                                defaultMessage="Data Catalog | Web Map Builder {icon}"
                                                values={{
                                                    icon: <i className="fas fa-fw fa-map-marked-alt pull-right"></i>
                                                }}
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageWebMapBuilder.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <p>
                                            The InstantAtlas Data Catalog <strong>Web Map Builder</strong> lets anyone in your organisation
                                            <strong>build a webmap</strong> using the the themes, indicators and data connections within your data catalog.
                                        </p>
                                        <p>
                                            <a>https://help.instantatlas.com/instantatlas-data-catalog/web-map-builder/;iaHelpWindow";Find out more in the help pages</a>.
                                            {img}
                                        </p>
                                    `}
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                img: (
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/static/images/web-map-builder-shot1.png`}
                                                        className="pull-right"
                                                        style={{ width: '47%', opacity: 0.9 }}
                                                        alt="Web Map Builder screenshot"
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="bottom-left-btn">
                                        <i className="fas fa-fw fa-map-marked-alt fa-lg"></i>
                                        <Link to="/web-map-builder" className="btn btn-primary btn-block">
                                            Build a Web Map
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item" data-key="Page 4, Inspector">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageInspector.title"
                                                defaultMessage="Data Catalog | Inspector {icon}"
                                                values={{
                                                    icon: <i className="fas fa-fw fa-clipboard-check pull-right"></i>
                                                }}
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageInspector.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <p>
                                            The InstantAtlas Data Catalog <strong>Inspector</strong> lets you <strong>inspect & validate</strong> the
                                            themes, indicators and data connections within your data catalog.
                                        </p>
                                    `}
                                            values={{
                                                ...getReactIntlHtmlFuncs()
                                            }}
                                        />
                                    </div>
                                    <div className="bottom-left-btn">
                                        <i className="fas fa-fw fa-clipboard-check fa-lg"></i>
                                        <Link to="/inspector" className="btn btn-primary btn-block">
                                            Inspect Catalog
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item" data-key="Page 5, Report Builder">
                            <div className="ia-welcome-popup-box">
                                <div className="filler">
                                    <div className="text-center pull-left">
                                        <span
                                            className="fa-stack"
                                            style={{
                                                fontSize: '140px',
                                                height: '1em',
                                                width: '1em',
                                                lineHeight: '1em'
                                            }}
                                        >
                                            <i className="fas fa-database fa-stack-1x"></i>
                                            <span className="fa-stack-1x">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/images/InstantAtlas-152px.jpg`}
                                                    style={{ height: '80px', width: 'auto', top: '-.1em' }}
                                                    alt=""
                                                />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="pull-right text-body">
                                        <h2>
                                            <FormattedMessage
                                                id="welcomeDialog.pageReportBuilder.title"
                                                defaultMessage="Build a Report {icon}"
                                                values={{
                                                    icon: (
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/static/images/rb4arcgis-logo192.png`}
                                                            style={{ height: '1.4em' }}
                                                            className="pull-right"
                                                            alt="Report Builder Logo"
                                                        />
                                                    )
                                                }}
                                            />
                                        </h2>
                                        <FormattedMessage
                                            id="welcomeDialog.pageReportBuilder.message"
                                            tagName="div"
                                            defaultMessage={`
                                        <p>
                                            <strong>Report Builder for ArcGIS</strong> lets you build <strong>data-rich reports</strong> that use the
                                            themes, indicators and data connections within your data catalog.
                                        </p>
                                        <p>
                                            To get started with Report Builder & Data Catalog, read the
                                            <a>https://help.reports.esriuk.com/report-builder/new-report-from-data-catalog/;iaHelpWindow;❝getting started❞ section in the help pages</a>.
                                        </p>
                                    `}
                                            values={{
                                                ...getReactIntlHtmlFuncs()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalDialog>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user
    };
};

export default connect(mapStateToProps)(withRouter(injectIntl(WelcomeDialog)));
