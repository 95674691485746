import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';

class ConfirmDeleteCoreLayerDialog extends PureComponent 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            counts: {
                indicators: -1,
                instances: -1
            },
            action: 'cancel'
        };
    }

    componentDidMount() 
    {
        this.setState({
            loading: true,
            isError: false
        }, () =>
        {
            this.findCounts();
        });
    }

    findCounts()
    {
        const { catalog, item } = this.props;
        catalog.getSummary(item.id, null).then(summary =>
        {
            this.setState({
                counts: summary
            });
        });
    }

    onClose(action) 
    {
        const { item, onConfirm } = this.props;
        if ((action === 'delete') && (onConfirm !== undefined)) onConfirm(item.id);
    }

    render()
    {
        const { item, show, onClose } = this.props,
            { counts } = this.state,
            buttonSet = [
                <button type="button" className="btn btn-secondary" onClick={(e) => this.onClose('delete')}>
                    <FormattedMessage id="manager.deleteCoreLayerDialog.button.delete" defaultMessage="{icon} Delete" values={{
                        icon: <i className="fas fa-trash-alt"></i>
                    }} />
                </button>,
                <button type="button" className="btn btn-primary">
                    <FormattedMessage id="manager.deleteCoreLayerDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                        icon: <i className="fas fa-times"></i>
                    }} />
                </button>
            ];
        return (!show ? null : <ModalDialog
            title={
                <FormattedMessage id="manager.deleteCoreLayerDialog.title" defaultMessage="Delete {geo}?"
                    values={{
                        geo: item.name
                    }} />
            }
            buttons={buttonSet}
            show={show}
            scroll={true}
            onClose={onClose}>
            <div>
                <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}><i className="fas fa-exclamation-triangle" style={{ fontSize: '48px' }}></i></div>
                <div style={{ marginLeft: 68 + 'px' }}>
                    <div className="i-after">
                        <p>
                            <FormattedMessage id="manager.deleteCoreLayerDialog.label" defaultMessage="Are you sure you want to delete core layer {geo} from your catalog? This will remove {indicatorCount} unique indicator/layer combinations and {instanceCount} dates from your data catalog. This cannot be undone - you should backup your catalog first."
                                values={{
                                    geo: <strong className="g-name">{item.name}</strong>,
                                    indicatorCount: <strong>{((counts !== undefined) && (counts.indicators >= 0) ? <FormattedNumber value={counts.indicators} /> : '?')}</strong>,
                                    instanceCount: <strong>{((counts !== undefined) && (counts.instances >= 0) ? <FormattedNumber value={counts.instances} /> : '?')}</strong>
                                }} />
                        </p>
                    </div>
                </div>
            </div>
        </ModalDialog>);
    }
}

export default injectIntl(ConfirmDeleteCoreLayerDialog, { withRef: true });