import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

const PageTitle = (props) =>
{
    const { intl, title, icon, className, updateDocument } = props,
        textAvailable = (title !== null) && (title !== undefined), 
        icoAvailable = (icon !== null) && (icon !== undefined),
        namedClass = ((className !== null) && (className !== undefined) ? className : 'inline'),
        txt = (textAvailable ? title.text : null),
        ico = (textAvailable && (title.icon !== '') ? title.icon : (icoAvailable ?icon : 'fas fa-cogs app-header-cogs')),
        keyed = (txt !== null) && (txt.toString().indexOf(';') > 0),
        localeTextKey = (keyed ? txt.toString().split(';')[0] : ''),
        defaultText = (keyed ? txt.toString().split(';')[1] : ((txt !== null) && (txt !== '') ? txt : '')),
        actualText = (localeTextKey !== '' ? intl.formatMessage({ id: localeTextKey, defaultMessage: defaultText }) : defaultText);

    useEffect(() =>
    {
        if (updateDocument && (typeof (actualText) === 'string')) document.title = actualText;
    });

    return (<span className={namedClass}>
        {actualText}
        {(ico !== null) && (typeof (ico) === typeof ('string')) && (ico !== '') ? <i className={ico}></i> : ((ico != null) ? ico : null)}
    </span>);
}

function mapStateToProps(state, ownProps)
{
    return {
        title: state.pageState[(ownProps.textKey ? ownProps.textKey : 'title')]
    }
}

export default connect(mapStateToProps)(injectIntl(PageTitle));
