import React from "react";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { isNullOrUndefined } from 'utils/object';

const ItemsLinkList = ({ items = [], className = 'dropdown-menu dropdown-menu-right', linkPattern = '?item={itemId}', linkIcon = <i className="fas fa-fw fa-database"></i>, action, extraItems = null }) =>
{
    const childList = (!isNullOrUndefined(action) && !isNullOrUndefined(items) ?
        items.map((child, index) =>
        {
            const uri = linkPattern.replace('{itemId}', child.id).replace('{itemName}', child.title);
            return (
                <li key={index}>
                    <a href={`#${child.title.replace(/[^0-9a-zA-Z]/g, '-')}`} onClick={(e) =>
                    {
                        action(e, uri, child.id, child.title);
                        return false;
                    }}>
                        {linkIcon}
                        <span>{child.title}</span>
                    </a>
                </li>
            );
        })
        :
        (!isNullOrUndefined(items) ? items.map((child, index) =>
        {
            const uri = linkPattern.replace('{itemId}', child.id).replace('{itemName}', child.title);
            return (
                <li key={index}>
                    <Link to={uri}>
                        {linkIcon}
                        <span>{child.title}</span>
                    </Link>
                </li>
            );
        }) : null));
    return (
        <ul className={className}>
            {childList}
            {extraItems}
        </ul>
    );
};

export default withRouter(injectIntl(ItemsLinkList));
