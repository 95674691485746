import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'components/Progress.scss';

class ProgressMessage extends Component {
    render() {
        const { message, subMessage, onCancel, type } = this.props,
            msg =
                message !== undefined ? (
                    message
                ) : (
                    <FormattedMessage
                        id="app.label.progress"
                        defaultMessage="Finding data catalog information. Please wait..."
                    />
                );
        return (
            <div className="row-fluid anonContent startup">
                <div className="col-md-10 col-md-offset-1">
                    <div className="spaced">
                        <div className="progress-message-large">
                            {type !== undefined && type !== null && type.toLowerCase() === 'cog' ? (
                                <i className="fas fa-cog fa-spin"></i>
                            ) : (
                                <span className="fa-stack">
                                    <i className="far fa-circle fa-stack-1x"></i>
                                    <i className="far fa-circle fa-stack-1x fa-spin"></i>
                                    {type !== undefined && type !== null && type.toLowerCase() === 'circle-stacked' ? (
                                        <i className="far fa-circle fa-stack-1x central"></i>
                                    ) : null}
                                </span>
                            )}
                            {msg !== '' ? <span style={{ marginLeft: '0.5em' }}>{msg}</span> : null}
                            {onCancel !== undefined ? (
                                <button
                                    type="button"
                                    onClick={onCancel}
                                    className="btn btn-default btn-secondary"
                                    style={{ marginLeft: '0.5em' }}
                                >
                                    <FormattedMessage
                                        id="progress.cancelButton.text"
                                        defaultMessage="{icon} Cancel"
                                        values={{
                                            icon: <i className="fas fa-times"></i>
                                        }}
                                    />
                                </button>
                            ) : null}
                        </div>
                        <p className="startup progress-message" style={{ marginLeft: '36px' }}>
                            {subMessage}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(ProgressMessage));
