import React, { Component } from 'react';
import { setPageState } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import ProgressMessage from 'components/ProgressMessage';
import ItemsLinkList from 'components/ItemsLinkList';
import ListToggleButton from 'components/ListToggleButton';
import ModalDialog from 'components/ModalDialog';
import { lowerKeyParams } from 'utils/object';
import { getReactIntlHtmlFuncs } from 'utils/localization';
import { findCatalogTable } from 'api/catalogUtils';
import 'pages/Inspector.scss';
import { isNullOrUndefined } from 'utils/object';
import { DataCatalogManager, DataCatalog } from 'data-catalog-js-api';
import { getBestToken, getInfo, queryFeatures } from 'utils/auth';

class InspectorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            progress: 0,
            max: 0,
            flags: {
                geos: true,
                themes: false,
                indicators: false,
                instances: false,
                relationships: false,
                errorsOnly: false
            },
            messages: [],
            catalogs: null,
            activeModal: null
        };
        this.goBtn = React.createRef();
        this.cancelBtn = React.createRef();
        this.toggleButton = React.createRef();
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Inspector', this.getTitleIcon(), null, null);
        this.setState({
            isLoading: true,
            isError: false
        });
        const qs = lowerKeyParams(new URLSearchParams(window.location.search));
        if (this.props.user !== null && this.props.user.username !== undefined && this.props.user.username !== null)
            this.loadMasterTableBasics(qs['item'], null, null, null);
    }

    onCatalogChange = (evt, url, itemId) => {
        this.setState({
            isLoading: true,
            isError: false
        });
        this.loadMasterTableBasics(itemId, null, null, null);
        this.toggleButton.current.setState({
            on: false
        });
    };

    loadMasterTableBasics = (itemId, itemInfo, tableItems = null, listHtml = null, ownedByCurrentUser = false) => {
        const { user, portalUrl, token, portalHome } = this.props,
            qs = lowerKeyParams(new URLSearchParams(window.location.search)),
            isAppAuthor =
                !isNullOrUndefined(qs['ia-override']) &&
                qs['ia-override'] === 'managed' &&
                user.orgId === 'HumUw0sDQHwJuboT',
            isTargeted =
                !isNullOrUndefined(qs['ia-override']) &&
                qs['ia-override'] === 'item' &&
                user.orgId === 'HumUw0sDQHwJuboT',
            allowManaged = null; //(isAppAuthor ? null : 'no');
        if (itemId === undefined || itemId === null || itemInfo === undefined || itemInfo === null) {
            const tableArgs = {
                home: portalUrl,
                user: user,
                token: token,
                table: itemId,
                managed: allowManaged,
                allowViewOnly: true
            };
            if (isTargeted && !isNullOrUndefined(itemId))
                tableArgs.query = `(ia-item-type=CatalogMasterTable OR ia-item-type=StoreMasterTable) AND (+type:"Feature Service")`; // AND (+id:${itemId})`;
            findCatalogTable(tableArgs).then((catalogItems) => {
                const tableItem = catalogItems.table,
                    //mapItem = catalogItems.map,
                    possibles = catalogItems.available,
                    listHtml = catalogItems.html;
                if (tableItem !== undefined && tableItem !== null)
                    this.loadMasterTableBasics(tableItem.id, tableItem, possibles, listHtml);
                else {
                    this.setState({
                        isLoading: false,
                        isError: true,
                        messages: [
                            {
                                status: 'error',
                                text: (
                                    <FormattedMessage
                                        id="inspector.startupError.messageFormat"
                                        defaultMessage="Cannot start Inspector for catalog {item}. Check you have access to this item (and that it is tagged correctly), then reload."
                                        values={{
                                            item: (
                                                <strong>
                                                    <a
                                                        href={`${portalHome}/home/item.html?id=${itemId}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {itemId}
                                                    </a>
                                                </strong>
                                            )
                                        }}
                                    />
                                )
                            }
                        ],
                        catalogs: possibles
                    });
                }
            });
        } else {
            this.props.setPageState('Data Catalog | Inspector', this.getTitleIcon(), null, {
                id: itemId,
                text: (
                    <span>
                        {itemInfo.title}{' '}
                        <a href={`https://www.arcgis.com/home/item.html?id=${itemId}`} target="iaoArcWindow">
                            <i className="fas fa-external-link-alt" style={{ fontSize: '0.9em' }}></i>
                            <span className="sr-only">Open {itemInfo.title} on ArcGIS Online</span>
                        </a>
                    </span>
                )
            });
            this.setState({
                isLoading: false,
                isError: false,
                table: itemInfo,
                messages: [],
                progress: 0,
                catalogs: tableItems
            });
            window.history.pushState('', '', '?item=' + itemInfo.id);
        }
    };

    onFlagChange = (flagKey) => {
        const flags = this.state.flags;
        flags[flagKey] = !flags[flagKey];
        this.setState({
            flags: flags
        });
    };

    runValidation = async (evt, preValidationMessages = []) => {
        // State update 1st - others things may involve expense
        this.setState(
            {
                status: 'Building validation tasks. Please wait...',
                count: 0,
                max: 10,
                progress: 0,
                messages: [...preValidationMessages]
            },
            () => {
                this.runValidationProcess(preValidationMessages);
            }
        );
    };

    runValidationProcess = async (preValidationMessages = []) => {
        const { user, token, portalUrl, tokenManager } = this.props,
            { table: tableItem, flags } = this.state,
            validationPromises = [],
            t = this.props.token;
        let processCount = 0;
        // Basic - does it exist?
        validationPromises.push(() => {
            return new Promise((resolve, reject) => {
                getInfo(`${tableItem.url}/0`, t, tokenManager).then((tableJson) => {
                    if (tableJson.error) reject(tableJson.error);
                    else {
                        const messages = [
                                {
                                    status: 'Heading',
                                    text: (
                                        <h3>
                                            <FormattedMessage
                                                id="inspector.label.exists"
                                                defaultMessage="Connectivity"
                                                values={{
                                                    name: (
                                                        <a
                                                            href={`${tableItem.url}/0?token=${getBestToken(
                                                                tableItem.url,
                                                                t,
                                                                tokenManager,
                                                                false
                                                            )}`}
                                                            target="iaoArcWindow"
                                                        >
                                                            {tableItem.title}{' '}
                                                            <i className="fas fa-external-link-alt small"></i>
                                                        </a>
                                                    )
                                                }}
                                            />
                                        </h3>
                                    )
                                },
                                {
                                    status: 'OK',
                                    text: (
                                        <FormattedMessage
                                            id="inspector.label.tableValid"
                                            defaultMessage="Catalog table {name} exists and is accessible (to {access})"
                                            values={{
                                                name: (
                                                    <a
                                                        href={`${tableItem.url}/0?token=${getBestToken(
                                                            tableItem.url,
                                                            t,
                                                            tokenManager,
                                                            false
                                                        )}`}
                                                        target="iaoArcWindow"
                                                    >
                                                        {tableItem.title}{' '}
                                                        <i className="fas fa-external-link-alt small"></i>
                                                    </a>
                                                ),
                                                access:
                                                    tableItem.access === 'public' ? (
                                                        <span>
                                                            <i className="fas fa-users"></i> Everyone
                                                        </span>
                                                    ) : tableItem.access === 'org' ? (
                                                        <span>
                                                            <i className="fas fa-building"></i> Organization
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <i className="fas fa-user-lock"></i> Owner/Admins
                                                        </span>
                                                    )
                                            }}
                                        />
                                    )
                                }
                            ],
                            textFields = 'ID,Name,Short_Name,Theme_ID,Indicator_ID'.toLowerCase().split(',');
                        for (let f of tableJson.fields) {
                            if (textFields.indexOf(f.name.toLowerCase()) >= 0 && f.length < 255) {
                                messages.push({
                                    status: 'Warning',
                                    text: (
                                        <FormattedMessage
                                            id="inspector.label.tableFieldWarning"
                                            defaultMessage="Field {field} in catalog table {name} is smaller ({width}) than normal (255). This may mean your table was built with an older version of InstantAtlas Data Catalog. Contact {email} for help with upgrading."
                                            values={{
                                                name: (
                                                    <a
                                                        href={`${tableItem.url}/0?token=${getBestToken(
                                                            tableItem.url,
                                                            t,
                                                            tokenManager,
                                                            false
                                                        )}`}
                                                        target="iaoArcWindow"
                                                    >
                                                        {tableItem.title}{' '}
                                                        <i className="fas fa-external-link-alt small"></i>
                                                    </a>
                                                ),
                                                field: <strong>{f.name}</strong>,
                                                width: f.length,
                                                email: (
                                                    <a href="mailto:support@instantatlas.com?subject=Data%20Catalog%20Old%20Table%20Upgrade">
                                                        support@instantatlas.com
                                                    </a>
                                                )
                                            }}
                                        />
                                    )
                                });
                            }
                        }
                        resolve(messages);
                    }
                });
            });
        });
        // Geos (Core Layers)?
        this.setState(
            {
                progress: 10
            },
            () => {
                if (flags.geos)
                    validationPromises.push(() => {
                        return buildGeosPromise(tableItem, t, tokenManager);
                    });
                this.setState(
                    {
                        progress: 20
                    },
                    () => {
                        // Themes?
                        if (flags.themes) {
                            validationPromises.push(() => {
                                return buildThemesPromise(tableItem, t, tokenManager).then((messages) => {
                                    if (
                                        messages.filter((m) => m.status !== 'OK' && m.status !== 'Heading').length > 0
                                    ) {
                                        messages.splice(0, 0, {
                                            status: 'action',
                                            text: (
                                                <div>
                                                    <button
                                                        onClick={(e) => this.showModal('confirmThemeRepairsDialog')}
                                                        className="btn btn-default"
                                                    >
                                                        <FormattedMessage
                                                            id="inspector.button.repairThemes"
                                                            defaultMessage="{icon} Repair"
                                                            values={{
                                                                icon: <i className="fas fa-wrench"></i>
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            )
                                        });
                                    }
                                    return messages;
                                });
                            });
                        }
                        this.setState(
                            {
                                progress: 30
                            },
                            () => {
                                // Indicators?
                                if (flags.indicators) {
                                    validationPromises.push(() => {
                                        return buildIndicatorsPromise(tableItem, t, tokenManager).then((messages) => {
                                            if (
                                                messages.filter((m) => m.status !== 'OK' && m.status !== 'Heading')
                                                    .length > 0
                                            ) {
                                                messages.splice(0, 0, {
                                                    status: 'action',
                                                    text: (
                                                        <div>
                                                            <button
                                                                onClick={(e) =>
                                                                    this.showModal('confirmIndicatorRepairsDialog')
                                                                }
                                                                className="btn btn-default"
                                                            >
                                                                <FormattedMessage
                                                                    id="inspector.button.repairIndicators"
                                                                    defaultMessage="{icon} Repair"
                                                                    values={{
                                                                        icon: <i className="fas fa-wrench"></i>
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    )
                                                });
                                            }
                                            return messages;
                                        });
                                    });
                                }
                                this.setState(
                                    {
                                        progress: 40
                                    },
                                    async () => {
                                        // Instances?
                                        if (flags.instances) {
                                            const catalog = new DataCatalogManager(
                                                tableItem,
                                                null,
                                                user,
                                                token,
                                                portalUrl,
                                                (ex) => {
                                                    throw ex;
                                                },
                                                tokenManager
                                            );
                                            await catalog.init();
                                            //const geoList = await catalog.getGeogs();
                                            const insPromises = await buildInstancesPromiseArray(
                                                tableItem,
                                                t,
                                                tokenManager
                                            );
                                            for (let ip of insPromises) {
                                                validationPromises.push(() => {
                                                    return ip;
                                                });
                                            }
                                            //validationPromises.push(() => { return buildInstancesPromise(tableItem, t); });
                                        }
                                        this.setState(
                                            {
                                                progress: 70
                                            },
                                            () => {
                                                // Relationships?
                                                if (flags.relationships)
                                                    validationPromises.push(() => {
                                                        return buildRelationshipsPromise(tableItem, t, tokenManager);
                                                    });
                                                processCount = validationPromises.length;
                                                this.setState(
                                                    {
                                                        status: 'Processing...',
                                                        count: 0,
                                                        max: processCount,
                                                        progress: 0,
                                                        messages: [...preValidationMessages]
                                                    },
                                                    () => {
                                                        this.runTasks(validationPromises).then((arrayOfResults) => {
                                                            this.setState({
                                                                status: ''
                                                            });
                                                        });
                                                    }
                                                );
                                            }
                                        );
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    };

    runTasksNonAwait = (taskPromises = []) => {
        return taskPromises.reduce((promiseChain, currentTask) => {
            const cancelled = this.state.status === 'Cancelling';
            if (cancelled) return promiseChain.reject('Cancelled');
            else {
                return promiseChain.then((chainResults) =>
                    currentTask.then((currentResult) => {
                        const { messages, count, max } = this.state;
                        //chainResults = [...chainResults, currentResult];
                        let offset = count + 1,
                            maxi = max,
                            perc = Math.round((offset * 100.0) / maxi),
                            messagesClone = messages.concat(currentResult);
                        this.setState({
                            count: offset,
                            progress: perc,
                            messages: messagesClone
                        });
                        return [...chainResults, currentResult];
                    })
                );
            }
        }, Promise.resolve([]));
    };

    runTasks = async (updatePromiseChain = []) => {
        return new Promise((resolve, reject) => {
            resolve(this.runTasksBody(updatePromiseChain));
        });
    };

    runTasksBody = async (updatePromiseChain = []) => {
        let resultSet = [...this.state.messages];
        let cancelled = false;
        for (let f of updatePromiseChain) {
            let currentTaskAt = this.state.count;
            let taskCount = currentTaskAt + 1;
            try {
                resultSet = resultSet.concat(await f());
                await new Promise((resolve) => setTimeout(resolve, 200)); // Wait for 200ms to allow for interruption (to give setState a chance)
            } catch (promiseErr) {
                resultSet.push({
                    error: promiseErr
                });
            }
            // Allow it to bail out...
            if (this.state.status !== undefined && this.state.status.substring(0, 6) === 'Cancel') {
                //this.setState({
                //    tasks: {
                //        current: 0,
                //        max: currentTaskState.max
                //    },
                //    messages: ['Cancelling...']
                //});
                //cancelled = true;
                break;
            } else {
                this.setState({
                    count: taskCount,
                    progress: Math.round((taskCount * 100.0) / this.state.max),
                    messages: [...resultSet]
                });
            }
        }
        return resultSet;
    };

    cancelValidation = () => {
        this.setState({
            status: 'Cancelling'
        });
    };

    repairThemes = () => {
        const { user, token, portalUrl, tokenManager } = this.props,
            { table, messages } = this.state,
            themeFailures = messages.filter(
                (m) =>
                    m.type === 'Theme' &&
                    m.status !== 'OK' &&
                    m.status !== 'Heading' &&
                    m.children !== undefined &&
                    m.children.length > 0
            );
        if (themeFailures.length > 0) {
            let duplicateSet = [];
            for (let fail of themeFailures) {
                duplicateSet = duplicateSet.concat(fail.children.filter((f) => f.reason === 'duplicate'));
            }
            if (duplicateSet.length > 0) {
                this.setState(
                    {
                        status: 'Repairing...',
                        count: 0,
                        max: duplicateSet.length,
                        progress: 0,
                        messages: [
                            {
                                status: 'Heading',
                                text: (
                                    <h3>
                                        <FormattedMessage
                                            id="inspector.heading.repairs"
                                            defaultMessage="{icon} Repairs ({type})"
                                            values={{
                                                icon: <i className="fas fa-wrench"></i>,
                                                type: 'Theme'
                                            }}
                                        />
                                    </h3>
                                )
                            }
                        ]
                    },
                    () => {
                        const catalog = new DataCatalogManager(
                            table,
                            null,
                            user,
                            token,
                            portalUrl,
                            (ex) => {
                                throw ex;
                            },
                            tokenManager
                        );
                        catalog.init().then(() => {
                            const deletionPromises = [],
                                deletionIds = [];
                            for (let te of duplicateSet) {
                                if (deletionIds.indexOf(te.id.toString()) < 0) {
                                    const tid = te.id.toString(),
                                        tname = te.name.toString();
                                    deletionPromises.push(() => {
                                        return catalog.removeDuplicateThemes(tid).then((deletionSet) => {
                                            const nFails = deletionSet.deleteResults.filter((dr) => !dr.success).length;
                                            return {
                                                status: nFails < 1 ? 'OK' : 'Warning',
                                                text: (
                                                    <FormattedMessage
                                                        id="inspector.themeRepair.deletionSummary"
                                                        defaultMessage="{deletes} duplicates have been deleted successfully for theme {name}"
                                                        values={{
                                                            name: <strong>{tname}</strong>,
                                                            deletes: deletionSet.deleteResults.length - nFails,
                                                            fails: nFails
                                                        }}
                                                    />
                                                ),
                                                type: 'Theme'
                                            };
                                        });
                                    });
                                }
                                deletionIds.push(te.id.toString());
                            }
                            let taskLength = deletionPromises.length + 10;
                            this.setState(
                                {
                                    count: 10,
                                    max: taskLength,
                                    progress: Math.round(1000.0 / taskLength)
                                },
                                () => {
                                    this.runTasks(deletionPromises).then((deletionResults) => {
                                        this.setState(
                                            {
                                                status: ''
                                            },
                                            () => {
                                                //deletionResults.splice(0, 0, {
                                                //    status: 'Heading',
                                                //    text: <h3><FormattedMessage id="inspector.heading.repairs" defaultMessage="Repairs ({type})" values={{
                                                //        type: 'Theme'
                                                //    }} /></h3>
                                                //})
                                                this.runValidation(null, deletionResults);
                                            }
                                        );
                                    });
                                }
                            );
                        });
                    }
                );
            }
        }
    };

    repairIndicators = () => {
        const { user, token, portalUrl, tokenManager } = this.props,
            { table, messages } = this.state,
            indicatorFailures = messages.filter(
                (m) =>
                    m.type === 'Indicator' &&
                    m.status !== 'OK' &&
                    m.status !== 'Heading' &&
                    m.children !== undefined &&
                    m.children.length > 0
            );
        if (indicatorFailures.length > 0) {
            let duplicateSet = [],
                orphanSet = [];
            for (let fail of indicatorFailures) {
                duplicateSet = duplicateSet.concat(fail.children.filter((f) => f.reason === 'duplicate'));
                orphanSet = orphanSet.concat(fail.children.filter((f) => f.reason === 'orphan'));
            }
            if (duplicateSet.length + orphanSet.length > 0) {
                let taskLength = duplicateSet.length + orphanSet.length + 10;
                this.setState(
                    {
                        status: 'Repairing...',
                        count: 0,
                        max: taskLength,
                        progress: 0,
                        messages: [
                            {
                                status: 'Heading',
                                text: (
                                    <h3>
                                        <FormattedMessage
                                            id="inspector.heading.repairs"
                                            defaultMessage="{icon} Repairs ({type})"
                                            values={{
                                                icon: <i className="fas fa-wrench"></i>,
                                                type: 'Indicator'
                                            }}
                                        />
                                    </h3>
                                )
                            }
                        ]
                    },
                    () => {
                        const catalog = new DataCatalogManager(
                            table,
                            null,
                            user,
                            token,
                            portalUrl,
                            (ex) => {
                                throw ex;
                            },
                            tokenManager
                        );
                        catalog.allowNetworkCache = false;
                        catalog.init().then(() => {
                            this.setState(
                                {
                                    count: 10,
                                    progress: Math.round(1000.0 / taskLength)
                                },
                                () => {
                                    const indicatorRepairPromises = [],
                                        deletionIds = [],
                                        orphanIds = [];
                                    for (let ie of duplicateSet) {
                                        const iid = ie.id.toString(),
                                            iname = ie.name.toString(),
                                            tid = ie.parentTheme.toString(),
                                            geo = ie.geo.toString(),
                                            compositeId = `${iid}T${tid}G${geo}`;
                                        if (deletionIds.indexOf(compositeId) < 0) {
                                            indicatorRepairPromises.push(() => {
                                                return catalog
                                                    .removeDuplicateIndicators(iid, geo, tid)
                                                    .then((deletionSet) => {
                                                        const nFails = deletionSet.deleteResults.filter(
                                                            (dr) => !dr.success
                                                        ).length;
                                                        return {
                                                            status: nFails < 1 ? 'OK' : 'Warning',
                                                            text: (
                                                                <FormattedMessage
                                                                    id="inspector.indicatorRepair.deletionSummary"
                                                                    defaultMessage="{deletes} duplicates have been deleted successfully for indicator {name}"
                                                                    values={{
                                                                        name: <strong>{iname}</strong>,
                                                                        deletes:
                                                                            deletionSet.deleteResults.length - nFails,
                                                                        fails: nFails
                                                                    }}
                                                                />
                                                            ),
                                                            type: 'Indicator'
                                                        };
                                                    });
                                            });
                                        }
                                        deletionIds.push(compositeId);
                                    }
                                    for (let ie of orphanSet) {
                                        const iid = ie.id.toString(),
                                            iname = ie.name.toString();
                                        if (orphanIds.indexOf(iid) < 0) {
                                            indicatorRepairPromises.push(() => {
                                                return catalog.recoverOrphanIndicator(iid).then((restoreSet) => {
                                                    const nFails = restoreSet.addResults.filter(
                                                        (ar) => !ar.success
                                                    ).length;
                                                    return {
                                                        status: nFails < 1 ? 'OK' : 'Warning',
                                                        text:
                                                            restoreSet.addResults.length < 1 ? (
                                                                <FormattedMessage
                                                                    id="inspector.indicatorRepair.themeRestoreSkippedSummary"
                                                                    defaultMessage="Indicator {name} should no longer be an orphan - themes restored earlier"
                                                                    values={{
                                                                        name: <strong>{iname}</strong>
                                                                    }}
                                                                />
                                                            ) : (
                                                                <FormattedMessage
                                                                    id="inspector.indicatorRepair.themeRestoreSummary"
                                                                    defaultMessage="{adds} themes have been restored successfully for indicator {name}"
                                                                    values={{
                                                                        name: <strong>{iname}</strong>,
                                                                        adds: restoreSet.addResults.length - nFails,
                                                                        fails: nFails
                                                                    }}
                                                                />
                                                            ),
                                                        type: 'Indicator'
                                                    };
                                                });
                                            });
                                        }
                                        orphanIds.push(iid);
                                    }
                                    taskLength = indicatorRepairPromises.length + 10;
                                    this.setState(
                                        {
                                            count: 10,
                                            max: taskLength,
                                            progress: Math.round(1000.0 / taskLength)
                                        },
                                        () => {
                                            this.runTasks(indicatorRepairPromises).then((repairResults) => {
                                                this.setState(
                                                    {
                                                        status: ''
                                                    },
                                                    () => {
                                                        //deletionResults.splice(0, 0, {
                                                        //    status: 'Heading',
                                                        //    text: <h3><FormattedMessage id="inspector.heading.repairs" defaultMessage="Repairs ({type})" values={{
                                                        //        type: 'Indicator'
                                                        //    }} /></h3>
                                                        //})
                                                        this.runValidation(null, repairResults);
                                                    }
                                                );
                                            });
                                        }
                                    );
                                }
                            );
                        });
                    }
                );
            }
        }
    };

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-clipboard-check"></i>
            </span>
        );
    }

    showModal = (modalId) => {
        this.setState({
            activeModal: modalId
        });
    };

    hideModal = () => {
        this.setState({
            activeModal: null
        });
    };

    render() {
        const { isLoading, isError, table, group, messages, progress, status, catalogs, activeModal } = this.state,
            isGroup = group !== undefined && group !== null,
            isItem = table !== undefined && table !== null,
            isProcessing = status !== undefined && status !== null && status !== '',
            auth =
                this.props.user !== null && this.props.user.username !== undefined && this.props.user.username !== null;
        let msgListItems = null;
        if (messages !== undefined && messages !== null) {
            msgListItems = messages.map((msg, msgIdx) => {
                if (msg !== undefined && msg.status !== undefined) {
                    const logMessages = (
                            msg.children !== undefined ? msg.children.filter((mc) => mc.log !== undefined) : []
                        ).map((mc) => mc.log),
                        logCsv =
                            logMessages.length > 0
                                ? `Status,ItemType,ErrorType,Message,SourceURL\n${logMessages.join('\n')}`
                                : '',
                        logCsvBlob =
                            logCsv.length > 0 ? URL.createObjectURL(new Blob([logCsv], { type: 'text/csv' })) : '';
                    return (
                        <li key={msgIdx} className={`validation-${msg.status.toLowerCase()}`}>
                            {msg.text}
                            {logCsvBlob !== '' && (
                                <div className="small pull-right">
                                    <a
                                        href={logCsvBlob}
                                        className="btn btn-default"
                                        download={`error-messages-${msg.key || msg.type || msgIdx.toString()}.csv`}
                                    >
                                        <i className="fas fa-fw fa-file-download"></i>
                                        <span>Download Errors &amp; Warnings</span>
                                    </a>
                                </div>
                            )}
                            {msg.children !== undefined ? (
                                <ul className={`${(msg.type !== undefined ? msg.type : 'generic').toLowerCase()}-list`}>
                                    {msg.children.map((mc) => mc.text)}
                                </ul>
                            ) : null}
                        </li>
                    );
                } else {
                    return (
                        <li key={msgIdx} className={`validation-fail uncaught-exception`}>
                            {JSON.stringify(msg)}
                        </li>
                    );
                }
            });
        }
        return auth ? (
            <div className="catalog-inspector">
                {isLoading ? (
                    <ProgressMessage />
                ) : (
                    <div>
                        <div className="catalog-switcher-top">
                            <ListToggleButton
                                ref={this.toggleButton}
                                text={
                                    <>
                                        <i className="fas fa-bars fa-fw"></i>
                                        <span className="sr-only">Choose Catalog</span>
                                    </>
                                }
                                tooltip="Choose Catalog"
                                className="btn btn-link pure-tip pure-tip-bottom"
                            >
                                <ItemsLinkList
                                    items={catalogs}
                                    action={this.onCatalogChange}
                                    linkIcon={
                                        <span>
                                            <i className="fas fa-fw fa-database"></i>
                                            <i className="fas fa-clipboard-check"></i>{' '}
                                        </span>
                                    }
                                />
                            </ListToggleButton>
                        </div>
                        {isError ? (
                            <div className="snug-top">
                                <div className="row form-horizontal">
                                    <div className="col col-md-10 col-md-offset-1">
                                        <div className="authContent pad10">
                                            <i className="fas fa-times-circle fa-3x fa-pull-left"></i>
                                            <ul style={{ listStyle: 'none' }}>{msgListItems}</ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="snug-top">
                                <div className="authContent pad10">
                                    <div className="row form-horizontal">
                                        <div className="col col-md-10 col-md-offset-1">
                                            <p>
                                                <FormattedMessage
                                                    id="inspector.label.catalog"
                                                    defaultMessage="To check the integrity of data catalog {catalogTable} use the Check button below. Messages will be displayed in the box below. Note that the more options you select the longer the check will take (but the more information you will have when it is finished!)."
                                                    values={{
                                                        catalogGroup: isGroup ? (
                                                            <strong className="customer-name"></strong>
                                                        ) : (
                                                            ''
                                                        ),
                                                        catalogTable: isItem ? (
                                                            <strong className="master-table-name master-table-link">
                                                                <span>{table.title} </span>
                                                                <a
                                                                    href={
                                                                        'https://www.arcgis.com/home/item.html?id=' +
                                                                        table.id
                                                                    }
                                                                    target="iaoArcWindow"
                                                                >
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                    <span className="sr-only">
                                                                        Open table on ArcGIS Online
                                                                    </span>
                                                                </a>
                                                            </strong>
                                                        ) : (
                                                            ''
                                                        )
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div id="dsControls" className="row form-horizontal">
                                        <div className="col col-md-1 col-md-offset-1">
                                            <input
                                                type="checkbox"
                                                id="cbExists"
                                                value="exists"
                                                className="form-control"
                                                checked="checked"
                                                disabled="disabled"
                                                readOnly={true}
                                            />
                                            <label htmlFor="cbExists" className="control-label">
                                                <FormattedMessage
                                                    id="inspector.label.exists"
                                                    defaultMessage="Connectivity"
                                                />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <input
                                                type="checkbox"
                                                id="cbGeos"
                                                value="services"
                                                className="form-control"
                                                onChange={(e) => this.onFlagChange('geos', e)}
                                                disabled={isProcessing}
                                                defaultChecked={this.state.flags.geos}
                                            />
                                            <label htmlFor="cbGeos" className="control-label">
                                                <FormattedMessage
                                                    id="inspector.label.coreLayers"
                                                    defaultMessage="Core Layers"
                                                />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <input
                                                type="checkbox"
                                                id="cbThemes"
                                                value="themes"
                                                className="form-control"
                                                onChange={(e) => this.onFlagChange('themes', e)}
                                                disabled={isProcessing}
                                                defaultChecked={this.state.flags.themes}
                                            />
                                            <label htmlFor="cbThemes" className="control-label">
                                                <FormattedMessage id="inspector.label.themes" defaultMessage="Themes" />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <input
                                                type="checkbox"
                                                id="cbIndicators"
                                                value="indicators"
                                                className="form-control"
                                                onChange={(e) => this.onFlagChange('indicators', e)}
                                                disabled={isProcessing}
                                                defaultChecked={this.state.flags.indicators}
                                            />
                                            <label htmlFor="cbIndicators" className="control-label">
                                                <FormattedMessage
                                                    id="inspector.label.indicators"
                                                    defaultMessage="Indicators"
                                                />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <input
                                                type="checkbox"
                                                id="cbInstances"
                                                value="instances"
                                                className="form-control"
                                                onChange={(e) => this.onFlagChange('instances', e)}
                                                disabled={isProcessing}
                                                defaultChecked={this.state.flags.instances}
                                            />
                                            <label htmlFor="cbInstances" className="control-label">
                                                <FormattedMessage
                                                    id="inspector.label.instances"
                                                    defaultMessage="Instances"
                                                />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <input
                                                type="checkbox"
                                                id="cbRelations"
                                                value="relationships"
                                                className="form-control"
                                                onChange={(e) => this.onFlagChange('relationships', e)}
                                                disabled={isProcessing}
                                                defaultChecked={this.state.flags.relationships}
                                            />
                                            <label htmlFor="cbRelations" className="control-label">
                                                <FormattedMessage
                                                    id="inspector.label.relationships"
                                                    defaultMessage="Relationships"
                                                />
                                            </label>
                                        </div>
                                        <div className="col col-md-1">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-block"
                                                ref={this.goBtn}
                                                disabled={isProcessing}
                                                onClick={(e) => this.runValidation(e)}
                                            >
                                                <FormattedMessage
                                                    id="inspector.button.check"
                                                    defaultMessage="Check {icon}"
                                                    values={{
                                                        icon: <i className="fas fa-fw fa-play-circle"></i>
                                                    }}
                                                />
                                            </button>
                                        </div>
                                        <div className="col col-md-1">
                                            <button
                                                type="button"
                                                className="btn btn-default btn-block"
                                                ref={this.cancelBtn}
                                                disabled={!isProcessing}
                                                onClick={(e) => this.cancelValidation(e)}
                                            >
                                                <FormattedMessage
                                                    id="inspector.button.stop"
                                                    defaultMessage="Stop {icon}"
                                                    values={{
                                                        icon: <i className="fas fa-fw fa-stop-circle"></i>
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row form-horizontal">
                                        <div className="col col-md-10 col-md-offset-1 spaced">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar progress-bar-striped"
                                                    style={{ width: progress + '%' }}
                                                >
                                                    <span className="sr-only">
                                                        <FormattedMessage
                                                            id="inspector.label.progressBar"
                                                            defaultMessage="{progress}% complete"
                                                            values={{
                                                                progress: progress.toFixed(0)
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress-message text-center">
                                                <FormattedMessage
                                                    id="inspector.label.progressStatus"
                                                    defaultMessage="{progressStatus} "
                                                    values={{
                                                        progressStatus:
                                                            status !== undefined && status !== null && status !== ''
                                                                ? status
                                                                : ' '
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col col-md-10 col-md-offset-1">
                                            <div className="pull-right" style={{ maxWidth: '49%' }}>
                                                <input
                                                    type="checkbox"
                                                    id="cbErrorsOnly"
                                                    value="errorsOnly"
                                                    className="form-control"
                                                    onChange={(e) => this.onFlagChange('errorsOnly', e)}
                                                    defaultChecked={this.state.flags.errorsOnly}
                                                />
                                                <label htmlFor="cbErrorsOnly" className="control-label small slider">
                                                    Only show errors and warnings
                                                </label>
                                            </div>
                                            <h5 style={{ marginRight: '50%' }}>Messages:</h5>
                                            <div className="message-scroll-panel well" style={{ minHeight: '20vh' }}>
                                                {msgListItems != null ? (
                                                    <ul
                                                        className={`validation-results ${
                                                            this.state.flags.errorsOnly ? 'validation-errors' : ''
                                                        }`.trim()}
                                                    >
                                                        {msgListItems}
                                                    </ul>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {activeModal === 'confirmThemeRepairsDialog' && (
                    <ModalDialog
                        title={
                            <FormattedMessage
                                id="inspector.confirmRepairDialog.themes.title"
                                defaultMessage="{icon} Confirm Repairs | Themes"
                                values={{
                                    icon: <i className="fas fa-wrench"></i>
                                }}
                            />
                        }
                        show={true}
                        onClose={this.hideModal}
                        buttons={[
                            <button type="button" className="btn btn-default btn-primary" onClick={this.repairThemes}>
                                <FormattedMessage
                                    id="inspector.confirmRepairDialog.button.ok"
                                    defaultMessage="{icon} Repair"
                                    values={{
                                        icon: <i className="fas fa-wrench"></i>
                                    }}
                                />
                            </button>,
                            <button type="button" className="btn btn-default" onClick={this.hideModal}>
                                <FormattedMessage
                                    id="inspector.confirmRepairDialog.button.close"
                                    defaultMessage="{icon} Close"
                                    values={{
                                        icon: <i className="fas fa-times"></i>
                                    }}
                                />
                            </button>
                        ]}
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        fontSize: '48px'
                                    }}
                                >
                                    <i className="fas fa-exclamation-triangle"></i>
                                </div>
                                <div style={{ marginLeft: '68px' }}>
                                    <FormattedMessage
                                        id="inspector.confirmRepairDialog.themes.message"
                                        defaultMessage="<p>The Inspector will attempt to auto-repair your themes. To do this, it will:</p><ul><li>Delete duplicate themes</li><li>Recreate themes for themes that are orphans</li></ul><p>More details of what can be repaired (and what cannot) are available on the <help>[/instantatlas-data-catalog/inspector]help pages</help>.</p><p><warn>Note that auto-repairs cannot be undone - you should consider using the backup tool on the <page>[/manager]manager page</page> first.</warn></p>"
                                        values={{
                                            ...getReactIntlHtmlFuncs()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalDialog>
                )}
                {activeModal === 'confirmIndicatorRepairsDialog' && (
                    <ModalDialog
                        title={
                            <FormattedMessage
                                id="inspector.confirmRepairDialog.indicators.title"
                                defaultMessage="{icon} Confirm Repairs | Indicators"
                                values={{
                                    icon: <i className="fas fa-wrench"></i>
                                }}
                            />
                        }
                        show={true}
                        onClose={this.hideModal}
                        buttons={[
                            <button
                                type="button"
                                className="btn btn-default btn-primary"
                                onClick={this.repairIndicators}
                            >
                                <FormattedMessage
                                    id="inspector.confirmRepairDialog.button.ok"
                                    defaultMessage="{icon} Repair"
                                    values={{
                                        icon: <i className="fas fa-wrench"></i>
                                    }}
                                />
                            </button>,
                            <button type="button" className="btn btn-default" onClick={this.hideModal}>
                                <FormattedMessage
                                    id="inspector.confirmRepairDialog.button.close"
                                    defaultMessage="{icon} Close"
                                    values={{
                                        icon: <i className="fas fa-times"></i>
                                    }}
                                />
                            </button>
                        ]}
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        fontSize: '48px'
                                    }}
                                >
                                    <i className="fas fa-exclamation-triangle"></i>
                                </div>
                                <div style={{ marginLeft: '68px' }}>
                                    <FormattedMessage
                                        id="inspector.confirmRepairDialog.indicators.message"
                                        defaultMessage="<p>The Inspector will attempt to auto-repair your indicators. To do this, it will:</p><ul><li>Delete duplicate indicators</li><li>Recreate themes for indicators that are orphans</li></ul><p>More details of what can be repaired (and what cannot) are available on the <help>[/instantatlas-data-catalog/inspector]help pages</help>.</p><p><warn>Note that auto-repairs cannot be undone - you should consider using the backup tool on the <page>[/manager]manager page</page> first.</warn></p>"
                                        values={{
                                            ...getReactIntlHtmlFuncs()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalDialog>
                )}
            </div>
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(InspectorPage)));

const buildGeosPromise = (tableItem, t, tokenManager, giveBackDetails = false) => {
    return new Promise((resolve, reject) => {
        queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type = 'Geo'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Service_Url',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            true,
            t,
            tokenManager
        ).then((geos) => {
            if (geos.error) reject(geos.error);
            else {
                const messages = [];
                messages.push({
                    status: 'Heading',
                    text: (
                        <div>
                            <h3>
                                <FormattedMessage id="inspector.label.geos" defaultMessage="Core Layers" />
                            </h3>
                        </div>
                    )
                });
                if (geos.features !== undefined && geos.fields !== undefined) {
                    messages.push({
                        status: 'OK',
                        text: (
                            <FormattedMessage
                                id="inspector.label.geosValid"
                                defaultMessage="Catalog table {name} contains {count} core layers"
                                values={{
                                    count: <strong>{geos.features.length.toLocaleString()}</strong>,
                                    url: tableItem.url + '/0',
                                    name: (
                                        <a
                                            href={`${tableItem.url}/0?token=${getBestToken(
                                                tableItem.url,
                                                t,
                                                tokenManager,
                                                false
                                            )}`}
                                            target="iaoArcWindow"
                                        >
                                            {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    )
                                }}
                            />
                        ),
                        type: 'geo'
                    });
                    const idf = geos.fields.find((f) => f.name.toUpperCase() === 'ID').name,
                        nmf = geos.fields.find((f) => f.name.toUpperCase() === 'NAME').name,
                        uif = geos.fields.find((f) => f.name.toUpperCase() === 'SERVICE_URL').name;
                    // Assembled the URLs, now check against the feature/data columns (yet more promises)
                    const validationPromises = geos.features.map((g) => {
                        const gurl = g.attributes[uif].split(';')[0];
                        return getInfo(gurl, t, tokenManager).then((lyrInfo) => {
                            return {
                                key: gurl,
                                info: lyrInfo
                            };
                        });
                    });
                    validationPromises
                        .reduce((promiseChain, currentTask) => {
                            return promiseChain.then((chainResults) =>
                                currentTask.then((currentResult) => {
                                    return [...chainResults, currentResult];
                                })
                            );
                        }, Promise.resolve([]))
                        .then((urlsAndDetails) => {
                            const getLyrInfo = (url) => urlsAndDetails.find((u) => u.key === url),
                                geoMsgs = geos.features.map((g) => {
                                    const urlArgs = g.attributes[uif].split(';'),
                                        gurl = urlArgs[0],
                                        status = getLyrInfo(gurl).info,
                                        idFieldName =
                                            urlArgs.length > 1
                                                ? urlArgs[1]
                                                : '^(CODE|' +
                                                  status.name +
                                                  '(_?)CODE|CODIGO|' +
                                                  status.name +
                                                  '(_?)CODIGO|KODE|' +
                                                  status.name +
                                                  '(_?)KODE)$',
                                        nameFieldName =
                                            urlArgs.length > 2
                                                ? urlArgs[2]
                                                : '^(NAME|' +
                                                  status.name +
                                                  '(_?)NAME|NOMBRE|' +
                                                  status.name +
                                                  '(_?)NOMBRE|NOM|' +
                                                  status.name +
                                                  '(_?)NOM|NAAM|' +
                                                  status.name +
                                                  '(_?)NAAM)$',
                                        isErr = !isNullOrUndefined(status.error),
                                        isWarn =
                                            !isErr &&
                                            !isNullOrUndefined(status.fields) &&
                                            (isNullOrUndefined(
                                                status.fields.find((f) => new RegExp(idFieldName, 'gmi').test(f.name))
                                            ) ||
                                                isNullOrUndefined(
                                                    status.fields.find((f) =>
                                                        new RegExp(nameFieldName, 'gmi').test(f.name)
                                                    )
                                                )),
                                        msg = {
                                            status: isErr ? 'Error' : isWarn ? 'Warning' : 'OK',
                                            text: (
                                                <li
                                                    key={g.attributes[idf]}
                                                    className={`geo ${
                                                        isErr || isWarn ? 'validation-warning' : ''
                                                    }`.trim()}
                                                >
                                                    <span>{g.attributes[idf]}, </span>
                                                    <span className="geo-name">{g.attributes[nmf]} </span>(
                                                    <a
                                                        href={`${gurl}?token=${getBestToken(
                                                            gurl,
                                                            t,
                                                            tokenManager,
                                                            false
                                                        )}`}
                                                        className="small"
                                                        target="iaoArcWindow"
                                                    >
                                                        {g.attributes[uif]}
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                    {isErr ? (
                                                        <strong className="small">
                                                            {' '}
                                                            - error: {status.error.code}, {status.error.message}
                                                        </strong>
                                                    ) : null}
                                                    {isWarn ? (
                                                        <strong className="small">
                                                            {' '}
                                                            - error: fields missing {idFieldName}, {nameFieldName}
                                                        </strong>
                                                    ) : null}
                                                    )
                                                </li>
                                            )
                                        };
                                    if (isErr || isWarn)
                                        msg.log = `${isErr ? 'Error' : 'Warning'},Geo,,"${
                                            isErr
                                                ? `${status.error.code} ${status.error.message}`
                                                : `fields missing ${idFieldName}, ${nameFieldName}`
                                        }","${gurl}"`;
                                    return msg;
                                });
                            messages[messages.length - 1].children = geoMsgs;
                            if (geoMsgs.filter((gm) => gm.status !== 'OK').length > 0)
                                messages[messages.length - 1].status = 'Warning';
                            resolve(giveBackDetails ? urlsAndDetails : messages);
                        });
                }
            }
        });
    });
};

const buildThemesPromise = (tableItem, t, tokenManager, repairAction = null) => {
    return new Promise((resolve, reject) => {
        queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type='Metadata' OR Item_Type='HostedShadow'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Service_Url,Item_Type',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            false,
            t,
            tokenManager
        ).then((metadataSet) => {
            if (metadataSet.error) reject(metadataSet.error);
            const metadataRecord =
                    metadataSet.length > 0 ? metadataSet.find((i) => i['Item_Type'] === 'Metadata') : null,
                metadataUrl = !isNullOrUndefined(metadataRecord)
                    ? metadataRecord['Service_Url']
                    : DataCatalog.instantAtlasMetadataService ||
                      'https://hub.instantatlas.com/data-catalog-metadata-service/',
                hostedRecord =
                    metadataSet.length > 0 ? metadataSet.find((i) => i['Item_Type'] === 'HostedShadow') : null,
                hostedUrl = !isNullOrUndefined(hostedRecord) ? hostedRecord['Service_Url'] : null;
            queryFeatures(
                `${tableItem.url}/0`,
                "Item_Type = 'Theme'",
                Number.MAX_SAFE_INTEGER,
                {
                    outFields: 'ID,Name,Theme_ID',
                    orderByFields: 'Theme_ID,ID,Item_Order',
                    f: 'json',
                    token: t,
                    _t: new Date().getTime().toString(16)
                },
                true,
                t,
                tokenManager
            ).then((themes) => {
                if (themes.error) reject(themes.error);
                else {
                    const messages = [];
                    messages.push({
                        status: 'Heading',
                        text: (
                            <div>
                                <h3>
                                    <FormattedMessage id="inspector.label.themes" defaultMessage="Themes" />
                                </h3>
                            </div>
                        )
                    });
                    if (themes.features) {
                        messages.push({
                            status: 'OK',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.themesValid"
                                    defaultMessage="Catalog table {name} contains {count} themes"
                                    values={{
                                        count: <strong>{themes.features.length.toLocaleString()}</strong>,
                                        url: tableItem.url + '/0',
                                        name: (
                                            <a
                                                href={`${tableItem.url}/0?token=${getBestToken(
                                                    tableItem.url,
                                                    t,
                                                    tokenManager,
                                                    false
                                                )}`}
                                                target="iaoArcWindow"
                                            >
                                                {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        )
                                    }}
                                />
                            ),
                            type: 'Theme'
                        });
                        const idf = themes.fields.find((f) => f.name.toUpperCase() === 'ID').name,
                            nmf = themes.fields.find((f) => f.name.toUpperCase() === 'NAME').name,
                            pif = themes.fields.find((f) => f.name.toUpperCase() === 'THEME_ID').name,
                            availables = themes.features.map((tf) => tf.attributes[idf]),
                            uniques = [],
                            details = [];
                        let tid, tname, pid;
                        for (let i in themes.features) {
                            tid = themes.features[i].attributes[idf];
                            tname = themes.features[i].attributes[nmf];
                            pid = themes.features[i].attributes[pif];
                            if (uniques.indexOf(tid) >= 0) {
                                details.push({
                                    status: 'Fail',
                                    text: (
                                        <FormattedMessage
                                            id="inspector.label.themeDuplicate"
                                            defaultMessage="Theme {name} ({id}) is duplicated in the catalog"
                                            values={{
                                                id: tid,
                                                name: <strong>{tname}</strong>
                                            }}
                                        />
                                    ),
                                    reason: 'duplicate',
                                    type: 'Theme',
                                    id: tid,
                                    name: tname
                                });
                            }
                            if (pid !== null && availables.indexOf(pid) < 0) {
                                details.push({
                                    status: 'Fail',
                                    text: (
                                        <FormattedMessage
                                            id="inspector.label.themeOrphan"
                                            defaultMessage="Theme {name} ({id}) is an orphan - cannot find its parent theme {parent}"
                                            values={{
                                                id: tid,
                                                name: <strong>{tname}</strong>,
                                                parent: pid
                                            }}
                                        />
                                    ),
                                    reason: 'orphan',
                                    type: 'Theme',
                                    id: tid,
                                    name: tname,
                                    parent: pid,
                                    log: `Fail,Theme,Orphan,"${tid}/${tname} is an orphan - cannot find parent theme ${pid}","${tableItem.url}/0}"`
                                });
                            }
                            if (tid === tname) {
                                details.push({
                                    status: 'Warning',
                                    text: (
                                        <FormattedMessage
                                            id="inspector.label.themeUnlikelyName"
                                            defaultMessage="Theme #{id}'s name is the same as its ID - is this correct?"
                                            values={{
                                                id: tid,
                                                name: <strong>{tname}</strong>,
                                                parent: pid
                                            }}
                                        />
                                    ),
                                    reason: 'badname',
                                    type: 'Theme',
                                    id: tid,
                                    name: tname,
                                    log: `Warning,Theme,Name,"Theme's name (${tname}) and ID (${tid}) are the same","${tableItem.url}/0}"`
                                });
                            }
                            uniques.push(tid);
                        }
                        if (details.length > 0) {
                            const detailHtml = details.map((msg, msgIdx) => {
                                return {
                                    ...msg,
                                    text: (
                                        <li key={msgIdx} className={`validation-${msg.status.toLowerCase()}`}>
                                            {msg.text}
                                        </li>
                                    )
                                };
                            });
                            if (!isNullOrUndefined(hostedUrl) && !isNullOrUndefined(repairAction)) {
                                detailHtml.push(
                                    <li key={`themeRepair`} className={`validation-warning repair-btn-container`}>
                                        <button className="btn btn-default" onClick={(e) => repairAction(details)}>
                                            <FormattedMessage
                                                id="inspector.button.repairThemes"
                                                defaultMessage="{icon} Repair"
                                                values={{
                                                    icon: <i className="fas fa-tools"></i>
                                                }}
                                            />
                                        </button>
                                    </li>
                                );
                            }
                            messages[messages.length - 1].children = detailHtml;
                            messages[messages.length - 1].status = 'Warning';
                        }
                    }
                    resolve(messages);
                }
            });
        });
    });
};

const buildIndicatorsPromise = (tableItem, t, tokenManager) => {
    return new Promise((resolve, reject) => {
        queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type='Metadata' OR Item_Type='HostedShadow'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Service_Url,Item_Type',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            false,
            t,
            tokenManager
        ).then((metadataSet) => {
            if (metadataSet.error) reject(metadataSet.error);
            const metadataRecord =
                    metadataSet.length > 0 ? metadataSet.find((i) => i['Item_Type'] === 'Metadata') : null,
                metadataUrl = !isNullOrUndefined(metadataRecord)
                    ? metadataRecord['Service_Url']
                    : DataCatalog.instantAtlasMetadataService ||
                      'https://hub.instantatlas.com/data-catalog-metadata-service/',
                hostedRecord =
                    metadataSet.length > 0 ? metadataSet.find((i) => i['Item_Type'] === 'HostedShadow') : null,
                hostedUrl = !isNullOrUndefined(hostedRecord) ? hostedRecord['Service_Url'] : null;
            // Get the indicators from the metadata table, we might use them...
            queryFeatures(
                metadataUrl,
                'IndicatorID IS NOT NULL AND Title IS NOT NULL AND InstanceID IS NULL',
                Number.MAX_SAFE_INTEGER,
                {
                    outFields: 'IndicatorID,Title',
                    f: 'json',
                    token: t,
                    _t: new Date().getTime().toString(16)
                },
                false,
                t,
                tokenManager
            ).then((indMetadataSet) => {
                queryFeatures(
                    `${tableItem.url}/0`,
                    "Item_Type = 'Theme'",
                    Number.MAX_SAFE_INTEGER,
                    {
                        outFields: 'ID,Name,Theme_ID',
                        orderByFields: 'Theme_ID,ID,Item_Order',
                        f: 'json',
                        token: t,
                        _t: new Date().getTime().toString(16)
                    },
                    true,
                    t,
                    tokenManager
                ).then((themes) => {
                    if (themes.error) reject(themes.error);
                    else {
                        const themeIds = [],
                            tidf = themes.fields.find((f) => f.name.toUpperCase() === 'ID').name;
                        let tid;
                        for (let i in themes.features) {
                            tid = themes.features[i].attributes[tidf];
                            themeIds.push(tid);
                        }
                        queryFeatures(
                            `${tableItem.url}/0`,
                            "Item_Type = 'Indicator'",
                            Number.MAX_SAFE_INTEGER,
                            {
                                outFields: 'ID,Name,Theme_ID,Geo_ID,Item_Order',
                                orderByFields: 'Theme_ID,ID,Item_Order',
                                f: 'json',
                                token: t,
                                _t: new Date().getTime().toString(16)
                            },
                            true,
                            t,
                            tokenManager
                        ).then((indicators) => {
                            if (indicators.error) reject(indicators.error);
                            else {
                                const messages = [];
                                messages.push({
                                    status: 'Heading',
                                    text: (
                                        <div>
                                            <h3>
                                                <FormattedMessage
                                                    id="inspector.label.indicators"
                                                    defaultMessage="Indicators"
                                                />
                                            </h3>
                                        </div>
                                    )
                                });
                                if (!isNullOrUndefined(indicators.features) && indicators.features.length > 0) {
                                    const idf = indicators.fields.find((f) => f.name.toUpperCase() === 'ID').name,
                                        nmf = indicators.fields.find((f) => f.name.toUpperCase() === 'NAME').name,
                                        pif = indicators.fields.find((f) => f.name.toUpperCase() === 'THEME_ID').name,
                                        gif = indicators.fields.find((f) => f.name.toUpperCase() === 'GEO_ID').name,
                                        iof = indicators.fields.find((f) => f.name.toUpperCase() === 'ITEM_ORDER').name,
                                        uniques = [],
                                        indicatorIds = [],
                                        details = [];
                                    let iid, iname, pid, gid, iio;
                                    for (let i in indicators.features) {
                                        iid = indicators.features[i].attributes[idf];
                                        iname = indicators.features[i].attributes[nmf];
                                        pid = indicators.features[i].attributes[pif];
                                        gid = indicators.features[i].attributes[gif];
                                        iio = indicators.features[i].attributes[iof];
                                        if (uniques.indexOf(iid + '|' + gid + '|' + pid) >= 0) {
                                            details.push({
                                                status: 'Fail',
                                                text: (
                                                    <FormattedMessage
                                                        id="inspector.label.indicatorDuplicate"
                                                        defaultMessage="Indicator {name} ({id}) is duplicated in the catalog for core layer {geo}, theme {theme}"
                                                        values={{
                                                            id: iid,
                                                            name: <strong>{iname}</strong>,
                                                            geo: gid,
                                                            theme: pid
                                                        }}
                                                    />
                                                ),
                                                reason: 'duplicate',
                                                id: iid,
                                                name: iname,
                                                parentTheme: pid,
                                                geo: gid,
                                                type: 'Indicator',
                                                log: `Fail,Indicator,Duplicate,"${iid}/${iname} is duplicated in theme ${pid}, geo ${gid}","${tableItem.url}/0}"`
                                            });
                                        }
                                        if (iio === undefined || iio === null || isNaN(iio)) {
                                            details.push({
                                                status: 'Warning',
                                                text: (
                                                    <FormattedMessage
                                                        id="inspector.label.indicatorNoOrder"
                                                        defaultMessage="Indicator {name} ({id}) does not have a valid Item_Order in the catalog for core layer {geo}, theme {theme}"
                                                        values={{
                                                            id: iid,
                                                            name: <strong>{iname}</strong>,
                                                            geo: gid,
                                                            theme: pid
                                                        }}
                                                    />
                                                ),
                                                reason: 'no-order',
                                                id: iid,
                                                name: iname,
                                                parentTheme: pid,
                                                geo: gid,
                                                type: 'Indicator',
                                                log: `Warning,Indicator,Order,"${iid}/${iname} does not have an Item_Order","${tableItem.url}/0}"`
                                            });
                                        }
                                        if (pid != null && themeIds.indexOf(pid) < 0) {
                                            details.push({
                                                status: 'Fail',
                                                text: (
                                                    <FormattedMessage
                                                        id="inspector.label.indicatorOrphan"
                                                        defaultMessage="Indicator {name} ({id}/{geo}) is an orphan - cannot find its parent theme {parent}"
                                                        values={{
                                                            id: iid,
                                                            name: <strong>{iname}</strong>,
                                                            parent: pid,
                                                            geo: gid
                                                        }}
                                                    />
                                                ),
                                                reason: 'orphan',
                                                id: iid,
                                                name: iname,
                                                parentTheme: pid,
                                                geo: gid,
                                                type: 'Indicator',
                                                log: `Fail,Indicator,Orphan,"${iid}/${iname} is an orphan - cannot find parent theme ${pid}","${tableItem.url}/0}"`
                                            });
                                        }
                                        uniques.push(iid + '|' + gid + '|' + pid);
                                        if (indicatorIds.indexOf(iid) < 0) indicatorIds.push(iid);
                                    }
                                    if (
                                        indMetadataSet.length > 0 &&
                                        metadataUrl !== DataCatalog.instantAtlasMetadataService
                                    ) {
                                        for (let mi of indMetadataSet) {
                                            if (indicatorIds.indexOf(mi['IndicatorID']) < 0) {
                                                iid = mi['IndicatorID'].toString();
                                                iname = mi['Title'].toString();
                                                details.push({
                                                    status:
                                                        metadataUrl === DataCatalog.instantAtlasMetadataService
                                                            ? 'OK'
                                                            : 'Warning',
                                                    text: (
                                                        <FormattedMessage
                                                            id="inspector.label.indicatorRefMissing"
                                                            defaultMessage="Indicator {name} ({id}) is listed in the {metadata} but is not found in the catalog - this may cause update issues"
                                                            values={{
                                                                id: iid,
                                                                name: <strong>{iname}</strong>,
                                                                metadata: (
                                                                    <a
                                                                        href={`./metadata/${iid}?item=${tableItem.id}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <FormattedMessage
                                                                            id="inspector.label.metadataTable"
                                                                            defaultMessage="metadata table"
                                                                        />
                                                                    </a>
                                                                ),
                                                                url: metadataUrl
                                                            }}
                                                        />
                                                    )
                                                });
                                            }
                                        }
                                    }
                                    messages.push({
                                        status:
                                            details.length > 0 && details.filter((d) => d.status !== 'OK').length > 0
                                                ? 'Warning'
                                                : 'OK',
                                        text: (
                                            <FormattedMessage
                                                id="inspector.label.indicatorsValid"
                                                defaultMessage="Catalog table {name} contains {count} unique indicators ({total} theme/indicator/geo combinations) {warning}"
                                                values={{
                                                    count: <strong>{indicatorIds.length.toLocaleString()}</strong>,
                                                    total: uniques.length.toLocaleString(),
                                                    url: tableItem.url + '/0',
                                                    name: (
                                                        <a
                                                            href={`${tableItem.url}/0?token=${getBestToken(
                                                                tableItem.url,
                                                                t,
                                                                tokenManager,
                                                                false
                                                            )}`}
                                                            target="iaoArcWindow"
                                                        >
                                                            {tableItem.title}{' '}
                                                            <i className="fas fa-external-link-alt small"></i>
                                                        </a>
                                                    ),
                                                    warning:
                                                        details.length > 0 &&
                                                        details.filter((d) => d.status !== 'OK').length > 0 ? (
                                                            <span className="text-danger">
                                                                <FormattedMessage
                                                                    id="inspector.message.indicatorErrors"
                                                                    defaultMessage="({errCount} errors or warnings were found)"
                                                                    values={{
                                                                        errCount: details.filter(
                                                                            (d) => d.status !== 'OK'
                                                                        ).length
                                                                    }}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span></span>
                                                        )
                                                }}
                                            />
                                        ),
                                        type: 'Indicator'
                                    });
                                    if (details.length > 0) {
                                        const detailHtml = details.map((msg, msgIdx) => {
                                            return {
                                                ...msg,
                                                text: (
                                                    <li
                                                        key={msgIdx}
                                                        className={`validation-${msg.status.toLowerCase()}`}
                                                    >
                                                        {msg.text}
                                                    </li>
                                                )
                                            };
                                        });
                                        messages[messages.length - 1].children = detailHtml;
                                    }
                                } else {
                                    messages.push({
                                        status: 'Warning',
                                        text: (
                                            <FormattedMessage
                                                id="inspector.label.noIndicators"
                                                defaultMessage="There are no indicators in data catalog {name}"
                                                values={{
                                                    url: `${tableItem.url}/0`,
                                                    name: (
                                                        <a href={`${tableItem.url}/0`} target="iaoArcWindow">
                                                            {tableItem.title}{' '}
                                                            <i className="fas fa-external-link-alt small"></i>
                                                        </a>
                                                    )
                                                }}
                                            />
                                        )
                                    });
                                    resolve(messages);
                                }
                                resolve(messages);
                            }
                        });
                    }
                });
            });
        });
    });
};

const buildInstanceCheckPromise = (geoId, url, t, tokenManager, fields) => {
    const messages = [];
    return new Promise((resolve, reject) => {
        getInfo(url, t, tokenManager)
            .then((layerServiceInfo) => {
                if (layerServiceInfo.error) {
                    messages.push({
                        status: 'Fail',
                        text: (
                            <FormattedMessage
                                id="inspector.label.dataServiceError"
                                defaultMessage="Data layer {name} ({url}) is not available - error was {code}, {message}"
                                values={{
                                    name: (
                                        <strong>
                                            {layerServiceInfo.name !== undefined
                                                ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                                : url}
                                        </strong>
                                    ),
                                    url: (
                                        <a
                                            href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                            target="iaoArcWindow"
                                        >
                                            {url} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    ),
                                    code: layerServiceInfo.error.code,
                                    message: layerServiceInfo.error.message
                                }}
                            />
                        ),
                        log: `Fail,Instance,InvalidURL,"${layerServiceInfo.error.code}: ${layerServiceInfo.error.message}","${url}"`
                    });
                } else {
                    messages.push({
                        status: 'OK',
                        text: (
                            <FormattedMessage
                                id="inspector.label.dataServiceOK"
                                defaultMessage="Data layer {name} ({url}) is available for {count} fields"
                                values={{
                                    name: (
                                        <strong>
                                            {layerServiceInfo.name !== undefined
                                                ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                                : url}
                                        </strong>
                                    ),
                                    url: (
                                        <a
                                            href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                            target="iaoArcWindow"
                                        >
                                            {url} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    ),
                                    fields: fields.join(', '),
                                    count: fields.length
                                }}
                            />
                        )
                    });
                    const mi = messages.length - 1,
                        indicatorErrIds = [];
                    let ff,
                        oks = 0;
                    const findField = (flds, f2f) => {
                        const isRgx = f2f instanceof RegExp;
                        return flds.find((f) =>
                            isRgx
                                ? f2f.test(f.name)
                                : f.name.toLowerCase() === f2f.toLowerCase() ||
                                  (f2f.indexOf('*') > 0 &&
                                      !isNullOrUndefined(f.alias) &&
                                      f.alias.toLowerCase() === f2f.replace('*', '').toLowerCase())
                        );
                    };
                    for (let i in fields) {
                        ff = findField(layerServiceInfo.fields, fields[i].name);
                        if (ff === undefined) {
                            messages.push({
                                status: 'Fail',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.instanceMissing"
                                        defaultMessage="Field {name} ({label}) not found"
                                        values={{
                                            name: (
                                                <strong>
                                                    <Link to={`/metadata/${fields[i].indicator}`} target="metaWindow">
                                                        {fields[i].name.toString()}
                                                    </Link>
                                                </strong>
                                            ),
                                            label: <em>{fields[i].label.toString()}</em>,
                                            url: (
                                                <a
                                                    href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                                    target="iaoArcWindow"
                                                >
                                                    {layerServiceInfo.name !== undefined ? layerServiceInfo.name : url}{' '}
                                                    <i className="fas fa-external-link-alt small"></i>
                                                </a>
                                            )
                                        }}
                                    />
                                ),
                                log: `Fail,Instance,MissingField,"${fields[i].name} not found for ${fields[i].indicator}","${url}"`
                            });
                            messages[mi].status = 'Warning';
                            if (indicatorErrIds.indexOf(fields[i].indicator) < 0)
                                indicatorErrIds.push(fields[i].indicator);
                        } else if (
                            ff !== undefined &&
                            ((fields[i].order !== undefined && fields[i].order < 0) ||
                                (fields[i].iid !== undefined &&
                                    fields[i].iid.lastIndexOf('_XA') === fields[i].iid.length - 3))
                        ) {
                            messages.splice(mi + 1, 0, {
                                status: 'Warning',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.instanceArchived"
                                        defaultMessage="Field {name} ({label}) is an archived or duplicate instance ({iid})"
                                        values={{
                                            name: <strong>{fields[i].name.toString()}</strong>,
                                            label: <em>{fields[i].label.toString()}</em>,
                                            iid: fields[i].iid
                                        }}
                                    />
                                ),
                                log: `Warning,Instance,ArchivedField,"${fields[i].name} (${fields[i].iid}) is an archived or duplicate instance for ${fields[i].indicator}","${url}"`
                            });
                            messages[mi].status = 'Warning';
                            if (indicatorErrIds.indexOf(fields[i].indicator) < 0)
                                indicatorErrIds.push(fields[i].indicator);
                        } else oks++;
                    }
                    if (messages[mi].status === 'Warning') {
                        messages[mi] = {
                            status: 'Warning',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.dataServiceOKMostly"
                                    defaultMessage="Data layer {name} ({url}) is available for {count} fields and {fails} failed"
                                    values={{
                                        name: (
                                            <strong>
                                                {layerServiceInfo.name !== undefined
                                                    ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                                    : url}
                                            </strong>
                                        ),
                                        url: (
                                            <a
                                                href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                                target="iaoArcWindow"
                                            >
                                                {url} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        ),
                                        fields: fields.join(', '),
                                        count: <strong>{oks}</strong>,
                                        fails: <strong>{fields.length - oks}</strong>
                                    }}
                                />
                            ),
                            log: `⚠️ Indicators with issues for core layer ${
                                layerServiceInfo.name
                            } = [${indicatorErrIds.join(', ')}]`
                        };
                    }
                }
                resolve({
                    id: geoId,
                    messages: messages
                });
            })
            .catch((err) => {
                messages.push({
                    status: 'Fail',
                    text: (
                        <FormattedMessage
                            id="inspector.label.dataServiceMissing"
                            defaultMessage="Data layer {url} is not available"
                            values={{
                                name: <strong>{url}</strong>,
                                url: (
                                    <a
                                        href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                        target="iaoArcWindow"
                                    >
                                        {url} <i className="fas fa-external-link-alt small"></i>
                                    </a>
                                )
                            }}
                        />
                    ),
                    log: `Fail,Instance,MissingURL,"${url} not found for core layer ${geoId}","${url}}"`
                });
                resolve({
                    id: geoId,
                    messages: messages
                }); // Silent fail, don't want to clobber the enclosing promise chain
            });
    });
};

const buildInstancesPromise = (tableItem, t, tokenManager) => {
    return new Promise((resolve, reject) => {
        let messages = [],
            idf,
            nmf,
            gif,
            uif,
            uniques = {},
            indicatorIds = [],
            urlsAndDetails;
        buildGeosPromise(tableItem, t, true)
            .then((urlsAndDetailsJson) => {
                urlsAndDetails = urlsAndDetailsJson;
                return queryFeatures(
                    `${tableItem.url}/0`,
                    "Item_Type = 'Theme'",
                    Number.MAX_SAFE_INTEGER,
                    {
                        outFields: 'ID,Name,Theme_ID',
                        orderByFields: 'Theme_ID,ID,Item_Order',
                        f: 'json',
                        token: t,
                        _t: new Date().getTime().toString(16)
                    },
                    true,
                    t,
                    tokenManager
                );
            })
            .then((themes) => {
                if (themes.error) reject(themes.error);
                else {
                    const themeIds = [],
                        tidf = themes.fields.find((f) => f.name.toUpperCase() === 'ID').name;
                    let tid;
                    for (let i in themes.features) {
                        tid = themes.features[i].attributes[tidf];
                        themeIds.push(tid);
                    }
                    return queryFeatures(
                        `${tableItem.url}/0`,
                        "Item_Type = 'Indicator'",
                        Number.MAX_SAFE_INTEGER,
                        {
                            outFields: 'ID,Name,Theme_ID,Geo_ID',
                            orderByFields: 'Theme_ID,ID,Item_Order',
                            f: 'json',
                            token: t,
                            _t: new Date().getTime().toString(16)
                        },
                        true,
                        t,
                        tokenManager
                    );
                }
            })
            .then((indicators) => {
                if (indicators.error) reject(indicators.error);
                else {
                    if (
                        indicators.features !== undefined &&
                        indicators.fields !== undefined &&
                        indicators.features.length > 0
                    ) {
                        idf = indicators.fields.find((f) => f.name.toUpperCase() === 'ID').name;
                        nmf = indicators.fields.find((f) => f.name.toUpperCase() === 'NAME').name;
                        gif = indicators.fields.find((f) => f.name.toUpperCase() === 'GEO_ID').name;
                        let iid, gid;
                        for (let i in indicators.features) {
                            iid = indicators.features[i].attributes[idf];
                            gid = indicators.features[i].attributes[gif];
                            uniques[iid + '|' + gid] = indicators.features[i];
                            if (indicatorIds.indexOf(iid) < 0) indicatorIds.push(iid);
                        }
                        return queryFeatures(
                            `${tableItem.url}/0`,
                            "Item_Type = 'Geo'",
                            Number.MAX_SAFE_INTEGER,
                            {
                                outFields: 'ID,Name,Service_Url',
                                f: 'json',
                                token: t
                            },
                            true,
                            t,
                            tokenManager
                        );
                    } else {
                        messages.push({
                            status: 'Heading',
                            text: (
                                <div>
                                    <h3>
                                        <FormattedMessage id="inspector.label.instances" defaultMessage="Instances" />
                                    </h3>
                                </div>
                            )
                        });
                        messages.push({
                            status: 'Warning',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.noIndicators"
                                    defaultMessage="There are no indicators in data catalog {name}"
                                    values={{
                                        url: `${tableItem.url}/0`,
                                        name: (
                                            <a href={`${tableItem.url}/0`} target="iaoArcWindow">
                                                {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        });
                        resolve(messages);
                    }
                }
            })
            .then((geos) => {
                if (geos.error) reject(geos.error);
                else if (geos.features !== undefined && geos.fields !== undefined) {
                    messages.push({
                        status: 'OK',
                        text: (
                            <FormattedMessage
                                id="inspector.label.geosValid"
                                defaultMessage="Catalog table {name} contains {count} core layers"
                                values={{
                                    count: <strong>{geos.features.length.toLocaleString()}</strong>,
                                    url: tableItem.url + '/0',
                                    name: (
                                        <a
                                            href={`${tableItem.url}/0?token=${getBestToken(
                                                tableItem.url,
                                                t,
                                                tokenManager,
                                                false
                                            )}`}
                                            target="iaoArcWindow"
                                        >
                                            {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    )
                                }}
                            />
                        )
                    });
                    const geoLookup = {};
                    idf = geos.fields.find((f) => f.name.toUpperCase() === 'ID').name;
                    nmf = geos.fields.find((f) => f.name.toUpperCase() === 'NAME').name;
                    uif = geos.fields.find((f) => f.name.toUpperCase() === 'SERVICE_URL').name;
                    for (let f of geos.features) {
                        const gid = f.attributes[idf],
                            urlArgs = f.attributes[uif].split(';');
                        geoLookup[gid] = {
                            name: f.attributes[nmf],
                            url: urlArgs[0],
                            idField:
                                urlArgs.length > 1
                                    ? urlArgs[1]
                                    : new RegExp(
                                          '^(CODE|' +
                                              f.attributes[nmf] +
                                              '(_?)CODE|CODIGO|' +
                                              f.attributes[nmf] +
                                              '(_?)CODIGO|KODE|' +
                                              f.attributes[nmf] +
                                              '(_?)KODE)$',
                                          'gmi'
                                      ),
                            nameField:
                                urlArgs.length > 2
                                    ? urlArgs[2]
                                    : new RegExp(
                                          '^(NAME|' +
                                              f.attributes[nmf] +
                                              '(_?)NAME|NOMBRE|' +
                                              f.attributes[nmf] +
                                              '(_?)NOMBRE|NOM|' +
                                              f.attributes[nmf] +
                                              '(_?)NOM|NAAM|' +
                                              f.attributes[nmf] +
                                              '(_?)NAAM)$',
                                          'gmi'
                                      ),
                            messages: []
                        };
                        if (!isNullOrUndefined(urlsAndDetails.find((d) => d.key === urlArgs[0]))) {
                            // Get better layer info...
                            const lyrInfo = urlsAndDetails.find((d) => d.key === urlArgs[0]).info,
                                rxIdField = new RegExp(
                                    urlArgs.length > 1
                                        ? urlArgs[1]
                                        : '^(CODE|' +
                                          lyrInfo.name +
                                          '(_?)CODE|CODIGO|' +
                                          lyrInfo.name +
                                          '(_?)CODIGO|KODE|' +
                                          lyrInfo.name +
                                          '(_?)KODE)$',
                                    'gmi'
                                ),
                                rxNameField = new RegExp(
                                    urlArgs.length > 2
                                        ? urlArgs[2]
                                        : '^(NAME|' +
                                          lyrInfo.name +
                                          '(_?)NAME|NOMBRE|' +
                                          lyrInfo.name +
                                          '(_?)NOMBRE|NOM|' +
                                          lyrInfo.name +
                                          '(_?)NOM|NAAM|' +
                                          lyrInfo.name +
                                          '(_?)NAAM)$',
                                    'gmi'
                                );
                            // Lookup - just skip wonky ones
                            if (!isNullOrUndefined(lyrInfo.fields)) {
                                let ff = lyrInfo.fields.find((f) => rxIdField.test(f.name));
                                if (!isNullOrUndefined(ff)) geoLookup[gid].idField = ff.name;
                                ff = lyrInfo.fields.find((f) => rxNameField.test(f.name));
                                if (!isNullOrUndefined(ff)) geoLookup[gid].nameField = ff.name;
                            }
                        }
                    }
                    const validationPromises = [];
                    for (let g in geoLookup) {
                        validationPromises.push(
                            buildInstancesForGeoPromise(tableItem, t, tokenManager, g, geoLookup, uniques)
                        );
                    }
                    return validationPromises.reduce((promiseChain, currentTask) => {
                        return promiseChain.then((chainResults) =>
                            currentTask.then((currentResult) => {
                                return [...chainResults, currentResult];
                            })
                        );
                    }, Promise.resolve([]));
                } else {
                    messages.push({
                        status: 'Warning',
                        text: (
                            <FormattedMessage
                                id="inspector.label.noCoreLayers"
                                defaultMessage="There are no core layers in data catalog {name}, master table {url}"
                                values={{
                                    url: `${tableItem.url}/0`,
                                    name: tableItem.title
                                }}
                            />
                        )
                    });
                    resolve(messages);
                }
            })
            .then((arrayOfResults) => {
                let instanceCount = 0,
                    uriCount = 0;
                for (let giDetail of arrayOfResults) {
                    instanceCount += giDetail.instanceCount;
                    uriCount += giDetail.uriCount;
                }
                messages.push({
                    status: 'Heading',
                    text: (
                        <div>
                            <h3>
                                <FormattedMessage id="inspector.label.instances" defaultMessage="Instances" />
                            </h3>
                        </div>
                    )
                });
                messages.push({
                    status: 'OK',
                    text: (
                        <FormattedMessage
                            id="inspector.label.instancesValid"
                            defaultMessage="Catalog table {name} contains {count} unique instances (in {services} feature layers, for {total} indicator/geo combinations)"
                            values={{
                                count: <strong>{instanceCount.toLocaleString()}</strong>,
                                total: indicatorIds.length.toLocaleString(),
                                services: uriCount,
                                url: tableItem.url + '/0',
                                name: (
                                    <a
                                        href={`${tableItem.url}/0?token=${getBestToken(
                                            tableItem.url,
                                            t,
                                            tokenManager,
                                            false
                                        )}`}
                                        target="iaoArcWindow"
                                    >
                                        {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                    </a>
                                )
                            }}
                        />
                    )
                });
                for (let giDetail of arrayOfResults) {
                    messages = messages.concat(giDetail.messages);
                }
                resolve(messages);
            })
            .catch((err) => {
                messages.push({
                    status: 'Error',
                    text: (
                        <FormattedMessage
                            id="inspector.label.coreLayersError"
                            defaultMessage="An unexpected error occurred when fetching core layers from data catalog {name}, master table {url} - error message was {error}."
                            values={{
                                url: `${tableItem.url}/0`,
                                name: tableItem.title,
                                error: <strong className="text-error">{err.toString()}</strong>
                            }}
                        />
                    )
                });
                resolve(messages);
            });
    });
};

const buildInstancesPromiseArray = async (tableItem, t, tokenManager) => {
    let idf, nmf, gif, uif;
    const promises = [],
        uniques = {},
        indicatorIds = [],
        urlsAndDetails = await buildGeosPromise(tableItem, t, tokenManager, true),
        themes = await queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type = 'Theme'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Theme_ID',
                orderByFields: 'Theme_ID,ID,Item_Order',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            true,
            t,
            tokenManager
        );
    try {
        promises.push(
            new Promise((resolve, reject) => {
                resolve([
                    {
                        status: 'Heading',
                        text: (
                            <div>
                                <h3>
                                    <FormattedMessage id="inspector.label.instances" defaultMessage="Instances" />
                                </h3>
                            </div>
                        )
                    }
                ]);
            })
        );
        if (themes.error) throw new Error(themes.error);
        const themeIds = [],
            tidf = themes.fields.find((f) => f.name.toUpperCase() === 'ID').name;
        let tid;
        for (let i in themes.features) {
            tid = themes.features[i].attributes[tidf];
            themeIds.push(tid);
        }
        const indicators = await queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type = 'Indicator'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Theme_ID,Geo_ID',
                orderByFields: 'Theme_ID,ID,Item_Order',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            true,
            t,
            tokenManager
        );
        if (indicators.error) throw new Error(indicators.error);
        let geos = null;
        if (indicators.features !== undefined && indicators.fields !== undefined && indicators.features.length > 0) {
            idf = indicators.fields.find((f) => f.name.toUpperCase() === 'ID').name;
            nmf = indicators.fields.find((f) => f.name.toUpperCase() === 'NAME').name;
            gif = indicators.fields.find((f) => f.name.toUpperCase() === 'GEO_ID').name;
            let iid, gid;
            for (let i in indicators.features) {
                iid = indicators.features[i].attributes[idf];
                gid = indicators.features[i].attributes[gif];
                uniques[iid + '|' + gid] = indicators.features[i];
                if (indicatorIds.indexOf(iid) < 0) indicatorIds.push(iid);
            }
            geos = await queryFeatures(
                `${tableItem.url}/0`,
                "Item_Type = 'Geo'",
                Number.MAX_SAFE_INTEGER,
                {
                    outFields: 'ID,Name,Service_Url',
                    f: 'json',
                    token: t
                },
                true,
                t,
                tokenManager
            );
            if (geos.error) throw new Error(geos.error);
            else if (geos.features !== undefined && geos.fields !== undefined) {
                promises.push(
                    new Promise((resolve, reject) => {
                        resolve([
                            {
                                status: 'OK',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.geosValid"
                                        defaultMessage="Catalog table {name} contains {count} core layers"
                                        values={{
                                            count: <strong>{geos.features.length.toLocaleString()}</strong>,
                                            url: tableItem.url + '/0',
                                            name: (
                                                <a
                                                    href={`${tableItem.url}/0?token=${getBestToken(
                                                        tableItem.url,
                                                        t,
                                                        tokenManager,
                                                        false
                                                    )}`}
                                                    target="iaoArcWindow"
                                                >
                                                    {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                                </a>
                                            )
                                        }}
                                    />
                                )
                            }
                        ]);
                    })
                );
                const geoLookup = {};
                idf = geos.fields.find((f) => f.name.toUpperCase() === 'ID').name;
                nmf = geos.fields.find((f) => f.name.toUpperCase() === 'NAME').name;
                uif = geos.fields.find((f) => f.name.toUpperCase() === 'SERVICE_URL').name;
                for (let f of geos.features) {
                    const gid = f.attributes[idf],
                        urlArgs = f.attributes[uif].split(';');
                    geoLookup[gid] = {
                        name: f.attributes[nmf],
                        url: urlArgs[0],
                        idField:
                            urlArgs.length > 1
                                ? urlArgs[1]
                                : new RegExp(
                                      '^(CODE|' +
                                          f.attributes[nmf] +
                                          '(_?)CODE|CODIGO|' +
                                          f.attributes[nmf] +
                                          '(_?)CODIGO|KODE|' +
                                          f.attributes[nmf] +
                                          '(_?)KODE)$',
                                      'gmi'
                                  ),
                        nameField:
                            urlArgs.length > 2
                                ? urlArgs[2]
                                : new RegExp(
                                      '^(NAME|' +
                                          f.attributes[nmf] +
                                          '(_?)NAME|NOMBRE|' +
                                          f.attributes[nmf] +
                                          '(_?)NOMBRE|NOM|' +
                                          f.attributes[nmf] +
                                          '(_?)NOM|NAAM|' +
                                          f.attributes[nmf] +
                                          '(_?)NAAM)$',
                                      'gmi'
                                  ),
                        messages: []
                    };
                    if (!isNullOrUndefined(urlsAndDetails.find((d) => d.key === urlArgs[0]))) {
                        // Get better layer info...
                        const lyrInfo = urlsAndDetails.find((d) => d.key === urlArgs[0]).info,
                            rxIdField = new RegExp(
                                urlArgs.length > 1
                                    ? urlArgs[1]
                                    : '^(CODE|' +
                                      lyrInfo.name +
                                      '(_?)CODE|CODIGO|' +
                                      lyrInfo.name +
                                      '(_?)CODIGO|KODE|' +
                                      lyrInfo.name +
                                      '(_?)KODE)$',
                                'gmi'
                            ),
                            rxNameField = new RegExp(
                                urlArgs.length > 2
                                    ? urlArgs[2]
                                    : '^(NAME|' +
                                      lyrInfo.name +
                                      '(_?)NAME|NOMBRE|' +
                                      lyrInfo.name +
                                      '(_?)NOMBRE|NOM|' +
                                      lyrInfo.name +
                                      '(_?)NOM|NAAM|' +
                                      lyrInfo.name +
                                      '(_?)NAAM)$',
                                'gmi'
                            );
                        // Lookup - just skip wonky ones
                        if (!isNullOrUndefined(lyrInfo.fields)) {
                            let ff = lyrInfo.fields.find((f) => rxIdField.test(f.name));
                            if (!isNullOrUndefined(ff)) geoLookup[gid].idField = ff.name;
                            ff = lyrInfo.fields.find((f) => rxNameField.test(f.name));
                            if (!isNullOrUndefined(ff)) geoLookup[gid].nameField = ff.name;
                        }
                    }
                }
                for (let g in geoLookup) {
                    promises.push(
                        buildInstancesForGeoPromise(tableItem, t, tokenManager, g, geoLookup, uniques).then(
                            (r) => r.messages
                        )
                    );
                }
            } else {
                promises.push(
                    new Promise((resolve, reject) => {
                        resolve([
                            {
                                status: 'Warning',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.noCoreLayers"
                                        defaultMessage="There are no core layers in data catalog {name}, master table {url}"
                                        values={{
                                            url: `${tableItem.url}/0`,
                                            name: tableItem.title
                                        }}
                                    />
                                )
                            }
                        ]);
                    })
                );
            }
        } else {
            promises.push(
                new Promise((resolve, reject) => {
                    resolve([
                        {
                            status: 'Heading',
                            text: (
                                <div>
                                    <h3>
                                        <FormattedMessage id="inspector.label.instances" defaultMessage="Instances" />
                                    </h3>
                                </div>
                            )
                        },
                        {
                            status: 'Warning',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.noIndicators"
                                    defaultMessage="There are no indicators in data catalog {name}"
                                    values={{
                                        url: `${tableItem.url}/0`,
                                        name: (
                                            <a href={`${tableItem.url}/0`} target="iaoArcWindow">
                                                {tableItem.title} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        }
                    ]);
                })
            );
        }
    } catch (err) {
        promises.push(
            new Promise((resolve, reject) => {
                resolve([
                    {
                        status: 'Error',
                        text: (
                            <FormattedMessage
                                id="inspector.label.coreLayersError"
                                defaultMessage="An unexpected error occurred when fetching core layers from data catalog {name}, master table {url} - error message was {error}."
                                values={{
                                    url: `${tableItem.url}/0`,
                                    name: tableItem.title,
                                    error: <strong className="text-error">{err.toString()}</strong>
                                }}
                            />
                        )
                    }
                ]);
            })
        );
    }
    return promises;
};
/*            
        .then(arrayOfResults =>
        {
            let instanceCount = 0, uriCount = 0;
            for (let giDetail of arrayOfResults)
            {
                instanceCount += giDetail.instanceCount;
                uriCount += giDetail.uriCount;
            }
            messages.push({
                status: 'OK',
                text: <FormattedMessage id="inspector.label.instancesValid" defaultMessage="Catalog table {name} contains {count} unique instances (in {services} feature layers, for {total} indicator/geo combinations)" values={{
                    count: <strong>{instanceCount.toLocaleString()}</strong>,
                    total: indicatorIds.length.toLocaleString(),
                    services: uriCount,
                    url: tableItem.url + '/0',
                    name: <a href={`${tableItem.url}/0?token=${t}`} target="iaoArcWindow">{tableItem.title} <i className="fas fa-external-link-alt small"></i></a>
                }} />
            });
*/

const buildInstancesForGeoPromise = (tableItem, t, tokenManager, geoId, geoLookup, uniques) => {
    return new Promise((resolve, reject) => {
        let gid,
            inid,
            uri,
            fid,
            uriCount = 0,
            iname,
            iid,
            iio,
            services = {},
            indicatorInstanceCounts = {},
            indicatorLookup = {},
            instanceIds = [];
        queryFeatures(
            `${tableItem.url}/0`,
            `Item_Type = 'Indicator' AND Geo_ID = '${geoId}'`,
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Indicator_ID,Geo_ID,Service_Url,Field_ID,Item_Order',
                orderByFields: 'Item_Order,ID,Name',
                f: 'json',
                token: t,
                _t: new Date().getTime().toString(16)
            },
            true,
            t,
            tokenManager
        ).then((indicators) => {
            if (indicators.error) reject(indicators.error);
            else if (indicators.fields !== undefined && indicators.features !== undefined) {
                const iidf = indicators.fields.find((f) => f.name.toUpperCase() === 'ID').name,
                    inmf = indicators.fields.find((f) => f.name.toUpperCase() === 'NAME').name;
                for (let i in indicators.features) {
                    iid = indicators.features[i].attributes[iidf];
                    indicatorInstanceCounts[iid] = 0;
                    indicatorLookup[iid] = indicators.features[i].attributes[inmf];
                }
            }
            queryFeatures(
                `${tableItem.url}/0`,
                `Item_Type = 'Instance' AND Geo_ID = '${geoId}'`,
                Number.MAX_SAFE_INTEGER,
                {
                    outFields: 'ID,Name,Indicator_ID,Geo_ID,Service_Url,Field_ID,Item_Order',
                    orderByFields: 'Indicator_ID,Item_Order',
                    f: 'json',
                    token: t,
                    _t: new Date().getTime().toString(16)
                },
                true,
                t,
                tokenManager
            )
                .then((instances) => {
                    if (instances.error) reject(instances.error);
                    //else  reject(new Error(`Cannot extract fields from query to master table "${tableItem.url}/0" "?where=Item_Type = 'Instance' AND Geo_ID = '${geoId}"`));
                    else if (instances.fields !== undefined && instances.features !== undefined) {
                        const idf = instances.fields.find((f) => f.name.toUpperCase() === 'ID').name,
                            nmf = instances.fields.find((f) => f.name.toUpperCase() === 'NAME').name,
                            gif = instances.fields.find((f) => f.name.toUpperCase() === 'GEO_ID').name,
                            uif = instances.fields.find((f) => f.name.toUpperCase() === 'SERVICE_URL').name,
                            pif = instances.fields.find((f) => f.name.toUpperCase() === 'INDICATOR_ID').name,
                            fif = instances.fields.find((f) => f.name.toUpperCase() === 'FIELD_ID').name,
                            iof = instances.fields.find((f) => f.name.toUpperCase() === 'ITEM_ORDER').name;
                        for (let i in instances.features) {
                            inid = instances.features[i].attributes[idf];
                            if (instanceIds.indexOf(inid) >= 0) {
                            } else instanceIds.push(inid);
                            iname = instances.features[i].attributes[nmf];
                            gid = instances.features[i].attributes[gif];
                            iid = instances.features[i].attributes[pif];
                            if (indicatorInstanceCounts[iid] === undefined) indicatorInstanceCounts[iid] = 1;
                            else indicatorInstanceCounts[iid] = indicatorInstanceCounts[iid] + 1;
                            uri = instances.features[i].attributes[uif];
                            fid = instances.features[i].attributes[fif];
                            iio = instances.features[i].attributes[iof];
                            if (services[uri + '|' + gid] === undefined) {
                                services[uri + '|' + gid] = [];
                                if (geoLookup[gid] !== undefined) {
                                    services[uri + '|' + gid].push({
                                        name: geoLookup[gid].idField,
                                        label: geoLookup[gid].idField
                                    });
                                    services[uri + '|' + gid].push({
                                        name: geoLookup[gid].nameField,
                                        label: geoLookup[gid].nameField
                                    });
                                }
                                uriCount++;
                            }
                            services[uri + '|' + gid].push({
                                name: fid,
                                label: iname,
                                indicator: iid,
                                iid: inid,
                                order: iio
                            });
                        }
                        //console.log(services); // DEBUG
                        // Assembled the URLs, now check against the feature/data columns (yet more promises)
                        const validationPromises = [];
                        for (let k in services) {
                            uri = k.split('|')[0];
                            gid = k.split('|')[1];
                            validationPromises.push(buildInstanceCheckPromise(gid, uri, t, tokenManager, services[k]));
                        }
                        return validationPromises.reduce((promiseChain, currentTask) => {
                            return promiseChain.then((chainResults) =>
                                currentTask.then((currentResult) => {
                                    return [...chainResults, currentResult];
                                })
                            );
                        }, Promise.resolve([]));
                    } else {
                        return [];
                    }
                })
                .then((arrayOfResults) => {
                    for (let a of arrayOfResults) {
                        // Catch null/missing data
                        if (geoLookup[a.id] === undefined) {
                            geoLookup[a.id] = {
                                name: `MISSING CORE LAYER ${a.id}`,
                                url: `#${a.id}`,
                                messages: [],
                                missing: true
                            };
                        }
                        geoLookup[a.id].messages = geoLookup[a.id].messages.concat(a.messages);
                    }
                    const messages = [];
                    if (geoLookup[geoId].messages.length > 0) {
                        uriCount = 0;
                        let iCount = 0,
                            iiCount = 0;
                        for (let i in indicatorInstanceCounts) {
                            if (uniques[`${i}|${geoId}`] === undefined) {
                                geoLookup[geoId].messages.push({
                                    status: 'Fail',
                                    text: `Core layer ${geoLookup[geoId].name} has ${indicatorInstanceCounts[i]} orphan instances that should have a parent indicator '${i}'`
                                });
                            } else if (indicatorInstanceCounts[i] < 1) {
                                geoLookup[geoId].messages.push({
                                    status: 'Fail',
                                    text: `Core layer ${geoLookup[geoId].name} has NO instances for indicator ${i}/${indicatorLookup[i]}`,
                                    log: `Fail,Indicator,Empty,"Core layer ${geoId}/${geoLookup[geoId].name} has NO instances for indicator ${i}/${indicatorLookup[i]}","${tableItem.url}/0"`
                                });
                            }
                        }
                        for (let j in services) {
                            if (j.indexOf('|' + geoId) > 0) {
                                uriCount++;
                                iiCount += services[j].length;
                            }
                        }
                        for (let j in uniques) {
                            if (j.indexOf('|' + geoId) > 0) {
                                iCount++;
                            }
                        }
                        const detailHtml = geoLookup[geoId].messages.map((msg, msgIdx) => {
                                return {
                                    ...msg,
                                    text: (
                                        <li key={msgIdx} className={`validation-${msg.status.toLowerCase()}`}>
                                            {msg.text}
                                        </li>
                                    )
                                };
                            }),
                            nIssues = geoLookup[geoId].messages.filter((m) => m.status.toLowerCase() !== 'ok').length;
                        if (geoLookup[geoId].missing !== undefined) {
                            messages.push({
                                status: 'Warning',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.instancesMissingGeo"
                                        defaultMessage="{count} unique instances are recorded against core layer {name}, but it is MISSING (in {services} feature layers, for {total} indicators)"
                                        values={{
                                            count: <strong>{iiCount.toLocaleString()}</strong>,
                                            total: iCount.toLocaleString(),
                                            services: uriCount,
                                            url: geoLookup[geoId].url,
                                            name: <strong>{geoId}</strong>
                                        }}
                                    />
                                ),
                                children: detailHtml
                            });
                        } else {
                            messages.push({
                                status: nIssues > 0 ? 'Warning' : 'OK',
                                text: (
                                    <FormattedMessage
                                        id="inspector.label.instancesValidGeo"
                                        defaultMessage="Core layer {name} contains {count} unique instances (in {services} feature layers, for {total} indicators) {warning}"
                                        values={{
                                            count: <strong>{iiCount.toLocaleString()}</strong>,
                                            total: iCount.toLocaleString(),
                                            services: uriCount,
                                            url: geoLookup[geoId].url,
                                            name: (
                                                <a href={`${geoLookup[geoId].url}?token=${t}`} target="iaoArcWindow">
                                                    <strong>{geoLookup[geoId].name}</strong>{' '}
                                                    <i className="fas fa-external-link-alt small"></i>
                                                </a>
                                            ),
                                            warning:
                                                nIssues > 0 ? (
                                                    <span className="text-danger">
                                                        <FormattedMessage
                                                            id="inspector.message.instanceErrors"
                                                            defaultMessage="({errCount} errors or warnings were found)"
                                                            values={{
                                                                errCount: nIssues
                                                            }}
                                                        />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )
                                        }}
                                    />
                                ),
                                children: detailHtml,
                                key: `data-layers-${geoId}`
                            });
                        }
                    } else {
                        messages.push({
                            status: 'Warning',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.instancesInvalidGeo"
                                    defaultMessage="Core layer {name} does not have any data associated with it (no instances)"
                                    values={{
                                        url: geoLookup[geoId].url,
                                        name: (
                                            <a href={`${geoLookup[geoId].url}?token=${t}`} target="iaoArcWindow">
                                                <strong>{geoLookup[geoId].name}</strong>{' '}
                                                <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        });
                    }
                    resolve({
                        geo: geoId,
                        instanceCount: instanceIds.length,
                        uriCount: uriCount,
                        messages: messages
                    });
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    });
};

const buildRelationshipsPromise = (tableItem, t, tokenManager) => {
    return new Promise((resolve, reject) => {
        queryFeatures(
            `${tableItem.url}/0`,
            "Item_Type = 'Geo'",
            Number.MAX_SAFE_INTEGER,
            {
                outFields: 'ID,Name,Service_Url',
                f: 'json',
                token: t
            },
            true,
            t,
            tokenManager
        ).then((geos) => {
            if (geos.error) reject(geos.error);
            else {
                let messages = [];
                messages.push({
                    status: 'Heading',
                    text: (
                        <div>
                            <h3>
                                <FormattedMessage id="inspector.label.relationships" defaultMessage="Relationships" />
                            </h3>
                        </div>
                    )
                });
                if (geos.features) {
                    const validationPromises = [];
                    for (let g of geos.features) {
                        validationPromises.push(
                            buildGeoLayerCheckPromise(
                                g.attributes['ID'],
                                g.attributes['Name'],
                                g.attributes['Service_Url'],
                                tableItem,
                                t,
                                tokenManager
                            )
                        );
                    }
                    validationPromises
                        .reduce((promiseChain, currentTask) => {
                            return promiseChain.then((chainResults) =>
                                currentTask.then((currentResult) => {
                                    return [...chainResults, currentResult];
                                })
                            );
                        }, Promise.resolve([]))
                        .then((arrayOfMessageArrays) => {
                            for (let a of arrayOfMessageArrays) {
                                messages = messages.concat(a);
                            }
                            resolve(messages);
                        });
                } else resolve([]);
            }
        });
    });
};

const buildGeoLayerCheckPromise = (geoId, geoName, layerUrl, tableItem, t, tokenManager) => {
    return new Promise((resolve, reject) => {
        const messages = [];
        getInfo(layerUrl.split(';')[0], t, tokenManager)
            .then((layerServiceInfo) => {
                if (layerServiceInfo.error) {
                    messages.push({
                        status: 'Fail',
                        text: (
                            <FormattedMessage
                                id="inspector.label.coreServiceError"
                                defaultMessage="Core layer {name} ({url}) is not available - error was {code}, {message}"
                                values={{
                                    name: (
                                        <strong>
                                            {geoName != null
                                                ? geoName
                                                : layerServiceInfo.name !== undefined
                                                ? layerServiceInfo.name
                                                : layerUrl}
                                        </strong>
                                    ),
                                    url: (
                                        <a href={layerUrl} target="iaoArcWindow">
                                            {layerUrl} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    ),
                                    code: layerServiceInfo.error.code,
                                    message: layerServiceInfo.error.message
                                }}
                            />
                        )
                    });
                } else {
                    const relationshipIds = [];
                    if (layerServiceInfo.relationships) {
                        for (let i in layerServiceInfo.relationships) {
                            relationshipIds.push(layerServiceInfo.relationships[i].name);
                        }
                    }
                    if (relationshipIds.length > 0) {
                        messages.push({
                            status: 'OK',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.coreServiceOKHasRelationships"
                                    defaultMessage="Core layer {name} ({url}) has {count} relationships"
                                    values={{
                                        name: (
                                            <strong>
                                                {geoName != null
                                                    ? geoName
                                                    : layerServiceInfo.name !== undefined
                                                    ? layerServiceInfo.name
                                                    : layerUrl}
                                            </strong>
                                        ),
                                        url: (
                                            <a href={layerUrl} target="iaoArcWindow">
                                                {layerUrl} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        ),
                                        count: relationshipIds.length
                                    }}
                                />
                            )
                        });
                        queryFeatures(
                            `${tableItem.url}/0`,
                            `Item_Type = 'Instance' AND Geo_ID = '${geoId}'`,
                            Number.MAX_SAFE_INTEGER,
                            {
                                outFields: 'ID,Service_Url',
                                orderByFields: 'Item_Order',
                                f: 'json',
                                token: t
                            },
                            true,
                            t,
                            tokenManager
                        ).then((instances) => {
                            if (instances.error) reject(instances.error);
                            else {
                                const services = [],
                                    details = [];
                                if (instances.features !== undefined && instances.fields !== undefined) {
                                    const uif = instances.fields.find(
                                        (f) => f.name.toUpperCase() === 'SERVICE_URL'
                                    ).name;
                                    let uri;
                                    for (let i in instances.features) {
                                        uri = instances.features[i].attributes[uif];
                                        if (services.indexOf(uri) < 0) services.push(uri);
                                    }
                                }
                                // Got a list of URIs, deal with them (more promises)
                                if (services.length > 0) {
                                    const validationPromises = [];
                                    for (let i in services) {
                                        validationPromises.push(
                                            buildRelationshipCheckPromise(
                                                services[i],
                                                relationshipIds,
                                                t,
                                                tokenManager,
                                                details
                                            )
                                        );
                                    }
                                    validationPromises
                                        .reduce((promiseChain, currentTask) => {
                                            return promiseChain.then((chainResults) =>
                                                currentTask.then((currentResult) => {
                                                    return [...chainResults, currentResult];
                                                })
                                            );
                                        }, Promise.resolve([]))
                                        .then((arrayOfResults) => {
                                            if (details.length > 0) {
                                                const detailHtml = details.map((msg, msgIdx) => {
                                                    return {
                                                        ...msg,
                                                        text: (
                                                            <li
                                                                key={msgIdx}
                                                                className={`validation-${msg.status.toLowerCase()}`}
                                                            >
                                                                {msg.text}
                                                            </li>
                                                        )
                                                    };
                                                });
                                                messages[messages.length - 1].children = detailHtml;
                                                messages[messages.length - 1].status = 'Warning';
                                            }
                                            resolve(messages);
                                        });
                                } else {
                                    messages[messages.length - 1] = {
                                        status: 'Warning',
                                        text: (
                                            <FormattedMessage
                                                id="inspector.label.coreServiceOKNoData"
                                                defaultMessage="Core layer {name} ({url}) has no data associated with it"
                                                values={{
                                                    name: (
                                                        <strong>
                                                            {geoName != null
                                                                ? geoName
                                                                : layerServiceInfo.name !== undefined
                                                                ? layerServiceInfo.name
                                                                : layerUrl}
                                                        </strong>
                                                    ),
                                                    url: (
                                                        <a href={layerUrl} target="iaoArcWindow">
                                                            {layerUrl}{' '}
                                                            <i className="fas fa-external-link-alt small"></i>
                                                        </a>
                                                    )
                                                }}
                                            />
                                        )
                                    };
                                    resolve(messages);
                                }
                            }
                        });
                    } else {
                        messages.push({
                            status: 'OK',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.coreServiceOKNoRelationships"
                                    defaultMessage="Core layer {name} ({url}) has no relationships"
                                    values={{
                                        name: (
                                            <strong>
                                                {geoName != null
                                                    ? geoName
                                                    : layerServiceInfo.name !== undefined
                                                    ? layerServiceInfo.name
                                                    : layerUrl}
                                            </strong>
                                        ),
                                        url: (
                                            <a href={layerUrl} target="iaoArcWindow">
                                                {layerUrl} <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        ),
                                        count: relationshipIds.length
                                    }}
                                />
                            )
                        });
                        resolve(messages);
                    }
                }
            })
            .catch((err) => {
                messages.push({
                    status: 'Fail',
                    text: (
                        <FormattedMessage
                            id="inspector.label.coreServiceMissing"
                            defaultMessage="Core layer {name} ({url}) is not available"
                            values={{
                                name: <strong>{geoName != null ? geoName : layerUrl}</strong>,
                                url: (
                                    <a href={layerUrl} target="iaoArcWindow">
                                        {layerUrl} <i className="fas fa-external-link-alt small"></i>
                                    </a>
                                )
                            }}
                        />
                    )
                });
            });
    });
};

const buildRelationshipCheckPromise = (url, relationshipIds, t, tokenManager, messages) => {
    return getInfo(url, t, tokenManager)
        .then((layerServiceInfo) => {
            if (layerServiceInfo.error) {
                messages.push({
                    status: 'Fail',
                    text: (
                        <FormattedMessage
                            id="inspector.label.dataServiceError"
                            defaultMessage="Data layer {name} ({url}) is not available - error was {code}, {message}"
                            values={{
                                name: (
                                    <strong>
                                        {layerServiceInfo.name !== undefined
                                            ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                            : url}
                                    </strong>
                                ),
                                url: (
                                    <a
                                        href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                        target="iaoArcWindow"
                                    >
                                        {url} <i className="fas fa-external-link-alt small"></i>
                                    </a>
                                ),
                                code: layerServiceInfo.error.code,
                                message: layerServiceInfo.error.message
                            }}
                        />
                    )
                });
            } else {
                messages.push({
                    status: 'OK',
                    text: (
                        <FormattedMessage
                            id="inspector.label.dataServiceRelationshipsOK"
                            defaultMessage="Data layer {name} ({url}) has {count} relationships, matching core layer"
                            values={{
                                name: (
                                    <strong>
                                        {layerServiceInfo.name !== undefined
                                            ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                            : url}
                                    </strong>
                                ),
                                url: (
                                    <a
                                        href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                        target="iaoArcWindow"
                                    >
                                        {url} <i className="fas fa-external-link-alt small"></i>
                                    </a>
                                ),
                                count: relationshipIds.length
                            }}
                        />
                    )
                });
                const mi = messages.length - 1;
                let ff,
                    oks = 0;
                for (let i in relationshipIds) {
                    ff = layerServiceInfo.relationships.find(
                        (f) =>
                            f.name.toLowerCase() === relationshipIds[i].toLowerCase() ||
                            f.name.toLowerCase().indexOf(relationshipIds[i].toLowerCase() + '_') === 0
                    );
                    if (ff === undefined) {
                        messages.push({
                            status: 'Fail',
                            text: (
                                <FormattedMessage
                                    id="inspector.label.relationshipMissing"
                                    defaultMessage="Relationship {name}* not found in feature layer {url}"
                                    values={{
                                        name: <strong>{relationshipIds[i]}</strong>,
                                        url: (
                                            <a
                                                href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                                target="iaoArcWindow"
                                            >
                                                {layerServiceInfo.name !== undefined ? layerServiceInfo.name : url}{' '}
                                                <i className="fas fa-external-link-alt small"></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        });
                        messages[mi].status = 'Warning';
                    } else oks++;
                }
                if (messages[mi].status === 'Warning') {
                    messages[mi] = {
                        status: 'Warning',
                        text: (
                            <FormattedMessage
                                id="inspector.label.dataServiceRelationshipsOKMostly"
                                defaultMessage="Data layer {name} ({url}) has {count} relationships that match the core layer and {fails} failed"
                                values={{
                                    name: (
                                        <strong>
                                            {layerServiceInfo.name !== undefined
                                                ? `${extractServerName(url)}/${layerServiceInfo.name}`
                                                : url}
                                        </strong>
                                    ),
                                    url: (
                                        <a
                                            href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                            target="iaoArcWindow"
                                        >
                                            {url} <i className="fas fa-external-link-alt small"></i>
                                        </a>
                                    ),
                                    count: <strong>{oks}</strong>,
                                    fails: <strong>{relationshipIds.length - oks}</strong>
                                }}
                            />
                        )
                    };
                }
            }
        })
        .catch((err) => {
            messages.push({
                status: 'Fail',
                text: (
                    <FormattedMessage
                        id="inspector.label.dataServiceMissing"
                        defaultMessage="Data layer {name} ({url}) is not available"
                        values={{
                            name: <strong>{url}</strong>,
                            url: (
                                <a
                                    href={`${url}?token=${getBestToken(url, t, tokenManager, false)}`}
                                    target="iaoArcWindow"
                                >
                                    {url} <i className="fas fa-external-link-alt small"></i>
                                </a>
                            )
                        }}
                    />
                )
            });
        });
};

const extractServerName = (url) => {
    const parts = url.split('/'),
        fsIndex = parts.findIndex((p) => p.toLowerCase() === 'featureserver' || p.toLowerCase() === 'mapserver'),
        name = fsIndex > 0 ? parts[fsIndex - 1] : '?';
    return name;
};
