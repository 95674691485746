export const lowerKeys = (o) => {
    const low = { ...o };
    Object.keys(o).reduce((c, k) => ((c[k.toLowerCase()] = o[k]), c), low);
    return low;
};

export const isNullOrUndefined = (o) => {
    return o === undefined || o === null;
};

export const lowerKeyParams = (o) => {
    const low = {};
    for (const key of Array.from(o.keys())) {
        low[key.toLowerCase()] = o.get(key);
    }
    return low;
};
