import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragItemTypes from 'components/DragItemTypes';

// type DraggableRowProps = {
//     id: string;
//     index: number;
//     moveRow: (dragIndex: number, hoverIndex: number) => void;
//     children?: any;
//     [id: string]: any;
// };

// interface DragItem {
//     index: number;
//     id: string;
//     type: string;
// }

const DraggableRow = ({ id, index, moveRow, children, className, style, ...others }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        type: DragItemTypes.ROW,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const rowRef = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: DragItemTypes.ROW,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!rowRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = rowRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });
    const dragClass = isDragging ? '' : 'dragging',
        opacity = isDragging ? 1 : 1; // TODO - work out why this is behaving strangely!
    drop(preview(rowRef));
    return (
        <div ref={rowRef} className={(className + ' ' + dragClass).trim()} {...others} style={{ opacity, ...style }}>
            {children}
            <span ref={drag} className="btn drag-handle">
                <i className="fas fa-lg fa-grip-horizontal"></i>
            </span>
        </div>
    );
};

export default DraggableRow;

/*
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import DragItemTypes from 'components/DragItemTypes';

const itemDragSource = {
    beginDrag(props)
    {
        return {
            id: props.id,
            index: props.index,
            children: props.children
        };
    },
};

const itemDropTarget = {
    hover(props, monitor, component)
    {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex)
        {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
        {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
        {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

class DraggableRow extends Component
{
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        children: PropTypes.array.isRequired,
        isDragging: PropTypes.bool.isRequired,
        id: PropTypes.any.isRequired,
        moveRow: PropTypes.func.isRequired,
    };

    render()
    {
        const {
            index,
            children,
            className,
            isDragging,
            connectDragSource,
            connectDropTarget,
            connectDragPreview,
            moveRow,
            style,
            ...others
        } = this.props;
        const dragClass = isDragging ? '' : 'dragging',
            opacity = isDragging ? 1 : 1; // TODO - work out why this is behaving strangely!

        return connectDropTarget(connectDragPreview(
            <div className={(className + ' ' + dragClass).trim()} {...others} style={{ opacity, ...style }}>
                {children}
                {connectDragSource(<span className="btn drag-handle"><i className="fas fa-lg fa-grip-horizontal"></i></span>)}
            </div>    
            ));
        }
    }
    
    export default
        DropTarget(DragItemTypes.ROW,
            itemDropTarget,
        connect => ({
            connectDropTarget: connect.dropTarget(),
        })
    )(DragSource(DragItemTypes.ROW,
        itemDragSource,
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }),
        )(DraggableRow));
*/
