import { ArcGISPortal } from 'data-catalog-js-api';

export const findCatalogTable = (options) => {
    const userSelf = options.user,
        catalogTable = options.table,
        tkn = options.token,
        includeHostedShadow = options.shadow,
        agoRestHome = options.home || 'https://www.arcgis.com/sharing/rest/',
        allowViewOnly = options.allowViewOnly,
        orgClause = options.public !== undefined && options.public === true ? '' : ` AND (+orgid:${userSelf.orgId})`,
        maxRecords = options.max !== undefined && options.max > 0 ? options.max : 100;
    return findCatalogGroup(options).then((groupInfo) => {
        let query =
            options.query !== undefined
                ? options.query
                : groupInfo.groups !== undefined && groupInfo.groups.length > 0
                ? `(ia-item-type=CatalogMasterTable OR ia-item-type=StoreMasterTable) AND (+type:"Feature Service") AND ((+orgid:${
                      userSelf.orgId
                  }) OR ${
                      Array.isArray(groupInfo.groups)
                          ? groupInfo.groups.map((g) => `(+group:${g.id})`).join(' OR ')
                          : `(+group:${groupInfo.groups.split(';')[0]})`
                  })`
                : `(ia-item-type=CatalogMasterTable OR ia-item-type=StoreMasterTable) AND (+type:"Feature Service")${orgClause}`;
        if (options.managed !== undefined && options.managed !== null)
            query += ` AND ia-item-managed=${options.managed}`;
        // Search for any other catalog tables associated with this account (not groups, that's a special one)...
        let userAdminLevel =
                userSelf.role === 'org_admin' ||
                (userSelf.privileges && userSelf.privileges.indexOf('portal:admin:updateItems') >= 0),
            idf = 'ID',
            itf = 'Item_Type',
            urlf = 'Service_Url',
            catalogPromise = new Promise((resolve, reject) => {
                //fetch(agoRestHome + 'search',
                //    {
                //        method: 'post',
                //        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                //        body: `&q=${query}&f=json&token=${tkn}&sortField=${(options.sort !== undefined ? options.sort : 'title')}&sortOrder=${(options.sortDir !== undefined ? options.sortDir : 'asc')}&num=100`
                //    }).then(searchRsp => searchRsp.json().then(searchData =>
                ArcGISPortal.getItems(
                    query,
                    {
                        token: tkn,
                        sortField: options.sort !== undefined ? options.sort : 'title',
                        sortOrder: options.sortDir !== undefined ? options.sortDir : 'asc',
                        num: maxRecords
                    },
                    agoRestHome
                ).then((searchResults) => {
                    let mapItem,
                        tableItem,
                        itemTags,
                        possibleTableItems = [];
                    for (let i in searchResults) {
                        itemTags = searchResults[i].tags;
                        if (
                            itemTags.indexOf('ia-item-type=CatalogIndexMap') >= 0 ||
                            itemTags.indexOf('ia-item-type=StoreIndexMap') >= 0
                        )
                            mapItem = searchResults[i];
                        if (
                            itemTags.indexOf('ia-item-type=CatalogMasterTable') >= 0 ||
                            itemTags.indexOf('ia-item-type=StoreMasterTable') >= 0
                        ) {
                            possibleTableItems.push(searchResults[i]);
                            const accessGranted =
                                userAdminLevel || allowViewOnly || searchResults[i].owner === userSelf.username;
                            if (catalogTable && catalogTable === searchResults[i].id && accessGranted)
                                tableItem = searchResults[i];
                            else if (
                                catalogTable &&
                                (catalogTable.substring(0, 6) === 'https:' ||
                                    catalogTable.substring(0, 5) === 'http:') &&
                                catalogTable === searchResults[i].url &&
                                accessGranted
                            )
                                tableItem = searchResults[i];
                            else if (!catalogTable && !tableItem && accessGranted) tableItem = searchResults[i];
                        }
                    }
                    let html = '';
                    if (possibleTableItems.length > 1) {
                        for (let i in possibleTableItems) {
                            html +=
                                '<li><a href="' +
                                (userAdminLevel || possibleTableItems[i].owner === userSelf.username
                                    ? window.location.pathname + '?item=' + possibleTableItems[i].id + '"'
                                    : '#" class="disabled"') +
                                '>' +
                                (possibleTableItems[i].hostedShadow
                                    ? '<i class="fas fa-cloud"></i><i class="fas fa-server"></i>'
                                    : '<i class="fas fa-database fa-fw"></i>') +
                                ' <span>' +
                                possibleTableItems[i].title +
                                '</span></a></li>';
                        }
                    }
                    if (includeHostedShadow && catalogTable && tableItem && catalogTable === tableItem.id) {
                        fetch(
                            `${tableItem.url.replace(/^\/(.*)\/$/, '$1')}/0/query?where=${encodeURIComponent(
                                "ITEM_TYPE = 'HostedShadow'"
                            )}&f=json&token=${tkn}&outFields=ID,SERVICE_URL,ITEM_TYPE`
                        ).then((shdRsp) =>
                            shdRsp.json().then((metaItems) => {
                                let shadow;
                                if (metaItems && metaItems.features && metaItems.features.length >= 1) {
                                    for (let i in metaItems.fields) {
                                        if (metaItems.fields[i].name.toLowerCase() === idf.toLowerCase())
                                            idf = metaItems.fields[i].name;
                                        if (metaItems.fields[i].name.toLowerCase() === urlf.toLowerCase())
                                            urlf = metaItems.fields[i].name;
                                        if (metaItems.fields[i].name.toLowerCase() === itf.toLowerCase())
                                            itf = metaItems.fields[i].name;
                                    }
                                    for (let i in metaItems.features) {
                                        if (metaItems.features[i].attributes[itf] === 'HostedShadow')
                                            shadow = metaItems.features[i];
                                    }
                                    if (shadow) {
                                        fetch(`${agoRestHome.replace(/^\/(.*)\/$/, '$1')}/search`, {
                                            method: 'post',
                                            headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                                            },
                                            body: `&q=id:${shadow.attributes[idf]} OR ${shadow.attributes[urlf]}&f=json&token=${tkn}&sortField=modified&sortOrder=desc&num=100`
                                        })((searchRsp) =>
                                            searchRsp.json.then((searchData) => {
                                                shadow = null;
                                                if (searchData && searchResults && searchResults.length === 1) {
                                                    searchResults[0].hostedShadow = true;
                                                    possibleTableItems.push(searchResults[0]);
                                                }
                                                resolve({
                                                    table: tableItem,
                                                    map: mapItem,
                                                    available: possibleTableItems,
                                                    html: html
                                                });
                                            })
                                        );
                                    } else
                                        resolve({
                                            table: tableItem,
                                            map: mapItem,
                                            available: possibleTableItems,
                                            html: html
                                        });
                                } else
                                    resolve({
                                        table: tableItem,
                                        map: mapItem,
                                        available: possibleTableItems,
                                        html: html
                                    });
                            })
                        );
                    } else resolve({ table: tableItem, map: mapItem, available: possibleTableItems, html: html });
                    //}));
                });
            });
        return catalogPromise;
    });
};

export const findCatalogGroup = (options) => {
    const opts = options || {},
        targetCatalogId = opts.catalog,
        tkn = opts.token,
        //skipGroup = opts.group && (opts.group === 'skip'),
        //skipApp = opts.app && (opts.app === 'skip'),
        agoRestHome = opts.home || 'https://www.arcgis.com/sharing/rest/';
    let catalogPromise = new Promise((resolve, reject) => {
        fetch(`${agoRestHome.replace(/^\/(.*)\/$/, '$1')}/community/self?f=json&token=${tkn}`).then((userSelfRsp) =>
            userSelfRsp.json().then((userSelf) => {
                if (
                    !userSelf ||
                    !userSelf.appInfo ||
                    (userSelf && userSelf.error && userSelf.error > 497 && userSelf.error < 500)
                ) {
                    reject(userSelf);
                } else {
                    fetch(
                        `${agoRestHome.replace(/^\/(.*)\/$/, '$1')}/content/users/${
                            userSelf.username
                        }?f=json&token=${tkn}`
                    ).then((udRsp) =>
                        udRsp.json().then((userDetails) => {
                            // Get the IAO group
                            let iaoDataGroup,
                                iaoDataGroupTags,
                                lookForSpecificCatalog =
                                    targetCatalogId !== undefined && targetCatalogId !== null && targetCatalogId !== '';
                            // Only check for IA Data Group if not part of IA...?
                            if (userSelf && userSelf.groups && userSelf.orgId) {
                                if (userSelf.orgId === 'HumUw0sDQHwJuboT') iaoDataGroup = [];
                                for (var i in userSelf.groups) {
                                    if (
                                        userSelf.groups[i].tags &&
                                        (userSelf.groups[i].tags.indexOf('ia-item-type=CatalogGroup') >= 0 ||
                                            userSelf.groups[i].tags.indexOf('ia-item-type=StoreGroup') >= 0)
                                    ) {
                                        if (userSelf.orgId === 'HumUw0sDQHwJuboT') {
                                            iaoDataGroup.push({
                                                id: userSelf.groups[i].id,
                                                name: userSelf.groups[i].title,
                                                tags: userSelf.groups[i].tags
                                            });
                                        } else if (
                                            !lookForSpecificCatalog ||
                                            userSelf.groups[i].id === targetCatalogId
                                        ) {
                                            iaoDataGroup = userSelf.groups[i].id + ';' + userSelf.groups[i].title;
                                            iaoDataGroupTags = userSelf.groups[i].tags;
                                            break;
                                        }
                                    }
                                }
                            }
                            resolve({
                                self: userSelf,
                                details: userDetails,
                                groups: iaoDataGroup,
                                tags: iaoDataGroupTags
                            });
                        })
                    );
                }
            })
        );
    });
    return catalogPromise;
};
