import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import { ArcGISPortal } from 'data-catalog-js-api';
import { debounce } from 'utils/dom';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from './ProgressMessage';

class ChooseArcItemDialog extends PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            itemType: (this.props.itemType !== undefined ? this.props.itemType : 'Layer'), 
            items: [], 
            selected: null, 
            token: (this.props.token !== undefined ? this.props.token : null),
            portalUrl: (this.props.portalUrl !== undefined ? this.props.portalUrl : ArcGISPortal.arcgisOnlineHost), 
            user: null, 
            filter: {
                org: true,
                text: null
            },
            sort: 'modified desc', 
            dirty: false, 
            showUrlBox: false,
            customArgs: null // Allows pass through of data from somewhere else, easing re-use of a single dialog...
        };
        this.onFilterTextChange = debounce(this.onFilterTextChange.bind(this), 750);
    }

    componentWillMount()
    {
        const { show } = this.props;
        if (show) this.runSearch();
    }

    componentDidUpdate(prevProps, prevState)
    {
        // If the change was to the search state, re-run it, otherwise leave well alone...
        if ((prevState.dirty === false) && (this.state.dirty === true)) this.runSearch();
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.show)
        {
            this.setState({
                loading: true
            }, () =>
            {
                this.runSearch();
                });
        }
    }

    onSelectionChange = (evt) =>
    {
        const itemId = evt.currentTarget.getAttribute('data-item-uuid');
        this.setState({
            selected: itemId
        });
    }

    onSortChange = (itemSort, evt) =>
    {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            sort: itemSort,
            loading: true
        }, () =>
            {
                this.runSearch();
            });
    }

    onFilterOrgChange = () =>
    {
        const { filter } = this.state;
        filter.org = !filter.org;
        this.setState({
            filter: filter,
            loading: true
        }, () =>
            {
                this.runSearch();
            });
    }

    onFilterTextChange = (txt) =>
    {
        const { filter } = this.state;
        filter.text = txt;
        this.setState({
            filter: filter,
            loading: true
        }, () =>
            {
                this.runSearch();
            });
    }

    runSearch = () =>
    {
        const { itemType, filter, sort } = this.state,
            { maxItems, catalog, user, portalUrl } = this.props,
            queryParams = [];
        if (itemType === 'Layer') queryParams.push('(type:("Feature Collection" OR "Feature Service" OR "Stream Service" OR "WFS") -typekeywords:"Table")');
        if (itemType === 'Web Map') queryParams.push('(type:"Web Map" -type:"Web Mapping Application")');
        if (filter.org && (user !== undefined) && (user !== null)) queryParams.push('+orgid:' + user.orgId);
        if (filter.text !== null) queryParams.push(filter.text);
        let p = {
            sortField: sort.split(' ')[0],
            sortOrder: sort.split(' ')[1],
            num: 100
        };
        this.setState({
            loading: true
        }, () =>
            {
            ArcGISPortal.getItems(queryParams.join(' '), catalog.paramsWithToken(p), portalUrl.replace(/(.*)\/sharing\/rest\/$/, '$1'), (maxItems !== undefined ? maxItems : 500))
                    .then(results =>
                    {
                        if (results.error)
                        {
                            // TODO - handle it
                        }
                        else
                        {
                            this.setState({
                                loading: false,
                                items: results,
                                dirty: false
                            });
                        }
                    });
            });
    }

    toggleUrlBox = () =>
    {
        this.setState({
            showUrlBox: !this.state.showUrlBox
        });
    }

    onUrlEntry = (e) =>
    {
        const url = e.target.value;
        this.setState({
            selected: url
        });
    }

    onChosenClick = () =>
    {
        const { selected, items } = this.state,
            { onChoose, customArgs } = this.props;
        if (onChoose !== undefined) onChoose(selected, (selected !== null ? items.find(i => i.id === selected) : null), customArgs);
    }

    render()
    {
        const { items, itemType, loading, selected, filter, sort, showUrlBox = false } = this.state,
            { title, onClose, show, intl, portalHome, allowUrl = false } = this.props,
            portalHomeStem = portalHome.replace(/(.*)\/$/, '$1'),
            thumbIcon = `${portalHomeStem}/home/js/arcgisonline/img/item-types/features16.svg`,
            listClassName = "layer-list",
            listItems = items.map((mi, index) =>
            {
                const ac = (mi.id === selected ? 'arcItemSelected' : '');
                return (
                    <li className={`dataset webItem ${ac}`.trim()}
                        id={`arcItem${mi.id}`}
                        key={index}
                        onClick={this.onSelectionChange}
                        data-item-uuid={mi.id}
                        data-item-url={mi.url}
                        data-item-modified={mi.modified}>
                        <div className="text-center thumbnail-holder">
                            <img className="thumbnail-image" src={thumbIcon} alt="thumbnail" />
                        </div>
                        <div className="dsName">{mi.title}</div>
                        <div className="arc-link">
                            <a href={`${portalHomeStem}/home/item.html?id=${mi.id}`} target="iaoArcWindow"><i className="fas fa-info-circle"></i></a>
                        </div>
                    </li>
                );
            });
        
        return (!show ? null :
            <ModalDialog
                title={
                    <>
                        {title !== undefined ? title :
                            <FormattedMessage id="arcgisonline.chooseItemDialog.title" defaultMessage="Choose {itemTypeName}..."
                                values={{
                                    itemTypeName: itemType
                                }} />}
                   </>
            }
            buttons={
                [
                    <div className="pull-left form-inline">
                        <div className="input-group">
                            <input onChange={(e) => this.onFilterTextChange(e.target.value)} type="text" size="10"
                                className="form-control search-box pure-tip pure-tip-top"
                                data-tooltip={intl.formatMessage({
                                    id: "arcgisonline.chooseItemDialog.filterBox.tooltip", defaultMessage: "Start typing here to search for an item by name or tags"
                                })} defaultValue={ filter.text } />
                            <div className="input-group-btn btn-group" style={{ fontSize: '1em' }}>
                                <button onClick={(e) => this.onSortChange('title asc', e)} type="button" className={'btn btn-default btn-sorter pure-tip pure-tip-top' + (sort === 'title asc' ? ' active' : '')}
                                    data-tooltip={intl.formatMessage({
                                        id: "arcgisonline.chooseItemDialog.button.sortNameUp.tooltip", defaultMessage: "Sort by name, A-Z"
                                    })}><i className="fas fa-fw fa-sort-alpha-down"></i></button>
                                <button onClick={(e) => this.onSortChange('title desc', e)} type="button" className={'btn btn-default btn-sorter pure-tip pure-tip-top' + (sort === 'title desc' ? ' active' : '')}
                                    data-tooltip={intl.formatMessage({
                                        id: "arcgisonline.chooseItemDialog.button.sortNameDown.tooltip", defaultMessage: "Sort by name, Z-A"
                                    })}><i className="fas fa-fw fa-sort-alpha-up"></i></button>
                                <button onClick={(e) => this.onSortChange('modified asc', e)} type="button" className={'btn btn-default btn-sorter pure-tip pure-tip-top' + (sort === 'modified asc' ? ' active' : '')}
                                    data-tooltip={intl.formatMessage({
                                        id: "arcgisonline.chooseItemDialog.button.sortDateUp.tooltip", defaultMessage: "Sort by date modified, oldest 1st"
                                    })}><i className="fas fa-fw fa-sort-amount-up"></i></button>
                                <button onClick={(e) => this.onSortChange('modified desc', e)} type="button" className={'btn btn-default btn-sorter pure-tip pure-tip-top' + (sort === 'modified desc' ? ' active' : '')}
                                    data-tooltip={intl.formatMessage({
                                        id: "arcgisonline.chooseItemDialog.button.sortDateDown.tooltip", defaultMessage: "Sort by date modified, newest 1st"
                                    })}><i className="fas fa-fw fa-sort-amount-down"></i></button>
                            </div>
                        </div>
                        <div className="btn-group org-only-btn-group">
                            <input id="chooseArcItemPopup_OrgToggleBox" type="checkbox" onChange={(e) => this.onFilterOrgChange()} className="form-control tick org-toggle-box" value="" defaultChecked={filter.org} />
                            <label htmlFor="chooseArcItemPopup_OrgToggleBox" className="slider" style={{ marginLeft: '25px' }}>
                                <FormattedMessage id="arcgisonline.chooseItemDialog.button.orgOnly" defaultMessage="only search in my organisation" values={{
                                    name: ''
                                }} />
                            </label>
                        </div>
                    </div>,
                    <button type="button" className="btn btn-default btn-primary" onClick={(e) => this.onChosenClick(e)} disabled={(selected === null)}>
                        <FormattedMessage id="arcgisonline.chooseItemDialog.button.choose" defaultMessage="{icon} Choose" values={{
                            icon: <i className="fas fa-check"></i>
                        }} />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage id="arcgisonline.chooseItemDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                            icon: <i className="fas fa-times"></i>
                        }} />
                    </button>
                ]
            }
            show={show}
            large={true}
            scroll={true}
            onClose={onClose}>
            <div className={listClassName}>
                {loading ?
                    <div className="text-center" style={{
                            padding: '30px', fontSize: '64px', minHeight: 'calc(100vh - 210px)'
                        }}>
                            <ProgressMessage message={''} />
                    </div>
                        :
                        <>
                            {(allowUrl && showUrlBox) ?
                                <div className="url-box-holder">
                                    <input type="url" id="itemUrlBox" className="form-control" onChange={this.onUrlEntry} placeholder={
                                        intl.formatMessage({
                                            id: 'arcgisonline.chooseItemDialog.input.url.placeholder', defaultMessage: 'URL of feature service, layer, image or similar...'
                                        })
                                    } />
                                </div> : null}
                            {allowUrl ?
                                <div style={{ position: 'absolute', zIndex: '101', right: '-0.5em', top: '-0.5em' }}>
                                    <button type="button" className="btn btn-xs btn-link pure-tip pure-tip-bottom-left"
                                        data-tooltip={
                                            intl.formatMessage({
                                                id: 'arcgisonline.chooseItemDialog.button.toggleUrl', defaultMessage: 'Show/hide direct URL form'
                                            })
                                        }
                                        onClick={this.toggleUrlBox}><i className={`fas ${(showUrlBox ? 'fa-unlink' : 'fa-link')}`}></i></button></div> : null}
                            <ul>{listItems}</ul>
                        </>
                }
            </div>
        </ModalDialog>)
    }
}

export default injectIntl(ChooseArcItemDialog, { withRef: true });