import { createStore, combineReducers } from 'redux';

const SET_HUB_APP_SETTINGS = 'SET_HUB_APP_SETTINGS';
const SET_APPLICATION_STATE = 'SET_APPLICATION_STATE';
const SET_PAGE_STATE = 'SET_PAGE_STATE';
const SET_TREE_STATE = 'SET_TREE_STATE';
const SET_USER_OPTIONS = 'SET_USER_OPTIONS';

const hubAppSettings = (
    state = {
        appId: '',
        appAuthId: '',
        portalUrl: '',
        portalType: 'online',
        portalHome: '',
        token: '',
        tokenExpiry: -1,
        user: {}
    },
    action
) => {
    switch (action.type) {
        case SET_HUB_APP_SETTINGS:
            return {
                appId: action.appId,
                appAuthId: action.appAuthId,
                portalUrl: action.portalUrl,
                portalType: action.portalType,
                portalHome: action.portalHome,
                token: action.token,
                tokenExpiry: action.tokenExpiry,
                user: action.user
            };
        default:
            return state;
    }
};

export const setAppSettings = (appId, appAuthId, portalUrl, portalType, portalHome, token, tokenExpiry, user) => ({
    type: SET_HUB_APP_SETTINGS,
    appId: appId,
    appAuthId: appAuthId,
    portalUrl: portalUrl,
    portalType: portalType,
    portalHome: portalHome,
    token: token,
    tokenExpiry: tokenExpiry,
    user: user
});

const applicationState = (
    state = {
        tokenManager: null
    },
    action
) => {
    let currentState = { ...state };
    switch (action.type) {
        case SET_APPLICATION_STATE:
            // Shallow copy - works fine for this because we are not addressing nested fields...
            if (action.tokenManager !== undefined) currentState.tokenManager = action.tokenManager;
            return currentState;
        default:
            return currentState;
    }
};

export const setApplicationState = (tokenManager) => ({
    type: SET_APPLICATION_STATE,
    tokenManager: tokenManager
});

const pageState = (
    state = {
        title: {
            text: '',
            icon: ''
        }
    },
    action
) => {
    let currentState = { ...state };
    switch (action.type) {
        case SET_PAGE_STATE:
            // Shallow copy - works fine for this because we are not addressing nested fields...
            if (action.title !== undefined) currentState.title = action.title;
            if (action.group !== undefined) currentState.group = action.group;
            if (action.item !== undefined) currentState.item = action.item;
            if (action.custom !== undefined) currentState.custom = action.custom;
            return currentState;
        default:
            return currentState;
    }
};

export const setPageState = (title, titleIcon, groupName, itemName, pageCustomState) => ({
    type: SET_PAGE_STATE,
    title: {
        text: title,
        icon: titleIcon
    },
    group: {
        text: groupName,
        icon: ''
    },
    item:
        itemName !== undefined && itemName !== null && typeof itemName === 'object'
            ? itemName
            : {
                  text: itemName,
                  icon: ''
              },
    custom: pageCustomState
});

export const setPageSpecificState = (pageCustomStateObject) => ({
    type: SET_PAGE_STATE,
    custom: pageCustomStateObject
});

const treeState = (
    state = {
        expanded: []
    },
    action
) => {
    switch (action.type) {
        case SET_TREE_STATE:
            return {
                expanded: action.expanded
            };
        default:
            return state;
    }
};

export const setTreeState = (expanded = []) => ({
    type: SET_TREE_STATE,
    expanded: expanded
});

// Deliberately vague - each page or the app can choose what it does - idea would be an array of options like { id: <page-key>, options: <options-as-json-or-object> }
const userOptions = (state = [], action) => {
    switch (action.type) {
        case SET_USER_OPTIONS:
            return [...action.options];
        default:
            return state;
    }
};

export const setUserOptions = (options = []) => ({
    type: SET_USER_OPTIONS,
    options: options
});

export const fetchSessionState = (name) => {
    try {
        const str = sessionStorage.getItem(name);
        if (str === null) return undefined;
        return JSON.parse(str);
    } catch (err) {
        return undefined;
    }
};

const saveSessionState = (name, json) => {
    try {
        sessionStorage.setItem(name, JSON.stringify(json));
    } catch (err) {
        console.log(err);
    }
};

export const fetchLocalState = (name) => {
    try {
        const str = localStorage.getItem(name);
        if (str === null) return undefined;
        return JSON.parse(str);
    } catch (err) {
        return undefined;
    }
};

const saveLocalState = (name, json) => {
    try {
        localStorage.setItem(name, JSON.stringify(json));
    } catch (err) {
        console.log(err);
    }
};

const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const me = this,
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(me, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || 250);
        if (callNow) func.apply(me, args);
    };
};

// Fetch any state stored in session/local storage and use this when creating the store.
const persistedState = fetchSessionState('dataCatalogHubState'),
    //persistedExplorerState = fetchLocalState('dataCatalogHubExplorerState'),
    persistedOptions = fetchLocalState('dataCatalogHubOptions');

const hubStore = createStore(
    combineReducers({
        hubAppSettings: hubAppSettings,
        pageState: pageState,
        treeState: treeState,
        userOptions: userOptions,
        applicationState: applicationState
    }),
    { ...persistedState, ...persistedOptions }
);

// Subscribe to changes to the store so we can update local storage.
hubStore.subscribe(
    debounce(() => {
        saveSessionState('dataCatalogHubState', {
            hubAppSettings: hubStore.getState().hubAppSettings,
            treeState: hubStore.getState().treeState
        });
        saveLocalState('dataCatalogHubOptions', {
            userOptions: hubStore.getState().userOptions
        });
    }),
    1000
);

export default hubStore;
