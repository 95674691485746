import React, { Component } from 'react';
import { setPageState, setTreeState, setUserOptions } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ProgressMessage from 'components/ProgressMessage';
import ItemsLinkList from 'components/ItemsLinkList';
import ListToggleButton from 'components/ListToggleButton';
import { lowerKeyParams } from 'utils/object';
import { getReactIntlHtmlFuncs } from 'utils/localization';
import { findCatalogTable } from 'api/catalogUtils';
import { ArcGISPortal, DataCatalogManager, DataModel, Theme, Indicator } from 'data-catalog-js-api';
//import ThemeTreePanel from 'components/ReduxThemeTreePanel';
import { ThemeTreePanel } from 'data-catalog-js-api';
import CoreLayerListItem from 'components/manager/CoreLayerListItem';
import ModalDialog from 'components/ModalDialog';
import ChooseArcItemDialog from 'components/ChooseArcItemDialog';
import ChooseItemsFromSetDialog from 'components/ChooseItemsFromSetDialog';
import ChooseFieldsFromDropDownDialog from 'components/manager/ChooseFieldsFromDropDownDialog';
import DatesDialog from 'components/manager/DatesDialog';
import MetadataDialog from 'components/manager/MetadataDialog';
import ConfirmDeleteIndicatorDialog from 'components/manager/ConfirmDeleteIndicatorDialog';
import ChooseDeleteIndicatorActionDialog from 'components/manager/ChooseDeleteIndicatorActionDialog';
import ConfirmDeleteCoreLayerDialog from 'components/manager/ConfirmDeleteCoreLayerDialog';
import ChooseIndicatorsFromServiceDialog from 'components/manager/ChooseIndicatorsFromServiceDialog';
import ChooseHostedIndicatorsToUpdateDialog from 'components/manager/ChooseHostedIndicatorsToUpdateDialog';
import { isNullOrUndefined } from 'utils/object';
import { PageActivityStatus } from 'pages/pageConstants';
import { Promise } from 'q';
import { getInfo, queryFeatures } from 'utils/auth';

class ManagerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: PageActivityStatus.INACTIVE,
            tasks: {
                current: 0,
                max: 0
            },
            error: null,
            messages: [],
            catalogs: [],
            userOnly: false,
            activeModal: null, // Simple lookup by ID/key to show one modal at a time - this page is the controller of what is showing
            activeModalProps: null, // Deliberately vague set of properties that can be passed to each modal - no real type checking but...
            view: 'default', // default|expanded
            hideEmptyFolders: false
        };
        this.toggleButton = React.createRef();
        this.optionsButton = React.createRef();
    }

    get defaultPageOptions() {
        return {
            id: 'managerPage',
            instances: {
                recordLastUpdated: false
            },
            connections: {
                disableParallel: false
            }
        };
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Manager', this.getTitleIcon(), null, null);
        this.setState({
            status: PageActivityStatus.LOADING_CATALOG,
            error: null
        });
        const qs = lowerKeyParams(new URLSearchParams(window.location.search));
        if (this.props.user !== null && this.props.user.username !== undefined && this.props.user.username !== null)
            this.loadMasterTableBasics(qs['item'], null, null, null, false);
    }

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-wrench"></i>
            </span>
        );
    }

    hideModal = () => {
        this.setState({
            activeModal: null
        });
    };

    showCoreLayerDialog = () => {
        this.setState({
            activeModal: 'arcCoreLayerDialog',
            activeModalProps: {}
        });
    };

    showDataLayerDialog = (indicatorArgs) => {
        this.setState({
            activeModal: 'arcDataLayerDialog',
            activeModalProps: indicatorArgs
        });
    };

    showMetadataDialog = (indicatorOrInstance) => {
        //const itemIds = [];
        // Have to have an indicator...
        //itemIds.push(indicatorOrInstance.indicator.id);
        //console.log(indicatorOrInstance); // DEBUG
        // Instance? Working on it...
        this.setState({
            activeModal: 'metadataDialog',
            activeModalProps: indicatorOrInstance
        });
    };

    onCatalogChange = (evt, url, itemId) => {
        this.setState(
            {
                status: PageActivityStatus.LOADING_CATALOG,
                catalog: null,
                error: null
            },
            () => {
                this.loadMasterTableBasics(itemId, null, null, null, this.state.userOnly);
                this.toggleButton.current.setState({
                    on: false
                });
            }
        );
    };

    onGeoChange = (evt, geoId, activeState) => {
        const { catalog, geo } = this.state,
            activeGeo = !isNullOrUndefined(geoId) && activeState ? geoId : geo;
        // Update needed?
        if (activeGeo !== geo) {
            this.setState(
                {
                    status: PageActivityStatus.LOADING_TREE,
                    geo: activeGeo
                },
                () => {
                    this.rebindModelFromCatalog(catalog, activeGeo);
                }
            );
        }
    };

    onOwnerListChange = (evt) => {
        const { userOnly, table } = this.state;
        if (!isNullOrUndefined(table)) this.loadMasterTableBasics(table.id, null, null, null, !userOnly); // Force an update - ugly but...
    };

    loadMasterTableBasics = (itemId, itemInfo, tableItems = null, listHtml = null, ownedByCurrentUser = false) => {
        const { user, portalUrl, token, portalHome, userOptions, tokenManager } = this.props,
            qs = lowerKeyParams(new URLSearchParams(window.location.search)),
            isAppAuthor =
                !isNullOrUndefined(qs['ia-override']) &&
                qs['ia-override'] === 'managed' &&
                user.orgId === 'HumUw0sDQHwJuboT',
            isTargeted =
                !isNullOrUndefined(qs['ia-override']) &&
                qs['ia-override'] === 'item' &&
                user.orgId === 'HumUw0sDQHwJuboT',
            allowManaged = isAppAuthor ? null : 'no',
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            disableParallel =
                pageOptions.connections !== undefined &&
                pageOptions.connections.disableParallel !== undefined &&
                pageOptions.connections.disableParallel === true;
        if (itemId === undefined || itemId === null || itemInfo === undefined || itemInfo === null) {
            const tableArgs = {
                home: portalUrl,
                user: user,
                token: token,
                table: itemId,
                managed: allowManaged
            };
            if (isTargeted && !isNullOrUndefined(itemId))
                tableArgs.query = `(ia-item-type=CatalogMasterTable OR ia-item-type=StoreMasterTable) AND (+type:"Feature Service")`; // AND (+id:${itemId})`;
            findCatalogTable(tableArgs).then((catalogItems) => {
                const tableItem = catalogItems.table,
                    //mapItem = catalogItems.map,
                    possibles = catalogItems.available,
                    listHtml = catalogItems.html;
                if (tableItem !== undefined && tableItem !== null)
                    this.loadMasterTableBasics(tableItem.id, tableItem, possibles, listHtml, ownedByCurrentUser);
                else {
                    const errMsg = !isNullOrUndefined(itemId) ? (
                        <FormattedMessage
                            id="manager.invalidItemDialog.messageFormat"
                            defaultMessage="<p>You cannot use {item} with the Data Catalog Manager - either:</p><ul><li>it is part of a managed service and cannot be edited directly</li><li>you do not have update rights</li><li>it cannot be found</li></ul><p>Please contact {email} for more information.</p>"
                            values={{
                                ...getReactIntlHtmlFuncs(),
                                item: (
                                    <a href={`${portalHome}/home/item.html?id=${itemId}`} target="iaoArcWindow">
                                        <strong>
                                            {itemId} <i className="fas fa-external-link-alt"></i>
                                        </strong>
                                    </a>
                                ),
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="manager.noValidItemsDialog.messageFormat"
                            defaultMessage="No suitable catalog items were found on {portal}. Data Catalog Manager cannot start. Sometimes this is caused by temporary network issues - you could try to {reload}. If this error is persistent please contact {email} for more information."
                            values={{
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>,
                                portal: (
                                    <a href={portalHome} target="iaoArcWindow">
                                        {portalHome} <i className="fas fa-external-link-alt"></i>
                                    </a>
                                ),
                                reload: (
                                    <a
                                        href="?action=reload"
                                        onClick={() => {
                                            window.location.reload();
                                            return false;
                                        }}
                                    >
                                        <FormattedMessage
                                            id="manager.noValidItemsDialog.button.reload"
                                            defaultMessage="Reload {icon}"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                )
                            }}
                        />
                    );

                    this.setState(
                        {
                            status: PageActivityStatus.INACTIVE,
                            error: {
                                message: errMsg,
                                key: 'NoMasterTable',
                                buttons: [
                                    <Link to="/new-catalog" className="btn btn-default btn-primary">
                                        <FormattedMessage
                                            id="manager.errorDialog.button.newCatalog"
                                            defaultMessage="{icon} Add Data Catalog"
                                            values={{
                                                icon: (
                                                    <span>
                                                        <i className="fas fa-fw fa-database"></i>
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                )
                                            }}
                                        />
                                    </Link>,
                                    <Link to="/" className="btn btn-default btn-secondary">
                                        <FormattedMessage
                                            id="manager.errorDialog.button.close"
                                            defaultMessage="{icon} Cancel"
                                            values={{
                                                icon: <i className="fas fa-times"></i>
                                            }}
                                        />
                                    </Link>
                                ]
                            },
                            catalogs: possibles
                        },
                        () => {
                            window.history.pushState('', '', '?#reset');
                            //this.onCatalogChange(null, null, null);
                        }
                    );
                }
            });
        } else {
            const activeTableItems =
                ownedByCurrentUser && !isNullOrUndefined(tableItems)
                    ? tableItems.filter((t) => t.owner === user.username)
                    : tableItems;
            this.props.setPageState('Data Catalog | Manager', this.getTitleIcon(), null, {
                id: itemId,
                text: (
                    <span>
                        {itemInfo.title}{' '}
                        <a href={`https://www.arcgis.com/home/item.html?id=${itemId}`} target="iaoArcWindow">
                            <i className="fas fa-external-link-alt" style={{ fontSize: '0.9em' }}></i>
                        </a>
                    </span>
                )
            });
            this.setState(
                {
                    status: PageActivityStatus.LOADING_CATALOG,
                    error: null,
                    table: itemInfo,
                    messages: [],
                    progress: 0,
                    catalogs: activeTableItems,
                    userOnly: ownedByCurrentUser,
                    isManaged:
                        allowManaged === null &&
                        (isNullOrUndefined(itemInfo.tags) || itemInfo.tags.indexOf('ia-item-managed=no') < 0) // Set here because we are about to lose the query string...
                },
                () => {
                    window.history.pushState('', '', '?item=' + itemInfo.id);
                    const catalog = new DataCatalogManager(
                        itemInfo,
                        null,
                        user,
                        token,
                        portalUrl,
                        (ex) => {
                            throw ex;
                        },
                        tokenManager
                    );
                    catalog.allowNetworkCache = false;
                    catalog
                        .init(undefined, false, undefined, !disableParallel)
                        .then(() => {
                            this.setState(
                                {
                                    catalog: catalog,
                                    status: PageActivityStatus.LOADING_TREE
                                },
                                () => {
                                    this.rebindModelFromCatalog(catalog, null);
                                }
                            );
                        })
                        .catch((err) => {
                            this.setState({
                                status: PageActivityStatus.INACTIVE,
                                error: {
                                    message: (
                                        <FormattedMessage
                                            id="manager.errorDialog.unexpectedFailMessage"
                                            defaultMessage="An error occurred when establishing a connection to your data catalog {catalog}. The error message was: {msg} Please check the integrity of your catalog using the {inspector} tool."
                                            values={{
                                                catalog: <strong>{itemInfo.title}</strong>,
                                                msg: (
                                                    <>
                                                        <br />
                                                        <br />
                                                        <span className="error-message">
                                                            {err.code || ''} {err.message}
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </>
                                                ),
                                                inspector: (
                                                    <Link to="/inspector">
                                                        <FormattedMessage
                                                            id="manager.errorDialog.inspectorLink"
                                                            defaultMessage="{icon} Inspector"
                                                            values={{
                                                                icon: <i className="fas fa-fw fa-clipboard-check"></i>
                                                            }}
                                                        />
                                                    </Link>
                                                )
                                            }}
                                        />
                                    ),
                                    key: 'MasterTableFail',
                                    buttons: [
                                        <Link to="/new-catalog" className="btn btn-default btn-primary">
                                            <FormattedMessage
                                                id="manager.errorDialog.button.newCatalog"
                                                defaultMessage="{icon} Add Data Catalog"
                                                values={{
                                                    icon: (
                                                        <span>
                                                            <i className="fas fa-fw fa-database"></i>
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    )
                                                }}
                                            />
                                        </Link>,
                                        <Link to="/" className="btn btn-default btn-secondary">
                                            <FormattedMessage
                                                id="manager.errorDialog.button.close"
                                                defaultMessage="{icon} Cancel"
                                                values={{
                                                    icon: <i className="fas fa-times"></i>
                                                }}
                                            />
                                        </Link>
                                    ]
                                }
                            });
                        });
                }
            );
        }
    };

    rebindModelFromCatalog = (catalog, geoId) => {
        const activeGeo = !isNullOrUndefined(geoId)
            ? geoId
            : !isNullOrUndefined(catalog.master.geos) && !isNullOrUndefined(catalog.master.geos[0])
            ? catalog.master.geos[0]['ID']
            : null;
        if (activeGeo !== null) {
            catalog.getDataModel(activeGeo).then((dataModel) => {
                this.setState({
                    status: PageActivityStatus.INACTIVE,
                    model: dataModel,
                    geo: activeGeo,
                    messages: null
                });
            });
        } else {
            this.setState({
                status: PageActivityStatus.INACTIVE,
                model: new DataModel(),
                geo: activeGeo,
                messages: null
            });
        }
    };

    togglePageView = (viewType) => {
        this.setState({
            view: viewType
        });
    };

    toggleTreeFolderView = () => {
        this.setState({
            hideEmptyFolders: !this.state.hideEmptyFolders
        });
    };

    changeUserOption = (evt) => {
        const { userOptions, setUserOptions } = this.props,
            box = evt.currentTarget,
            key = box.getAttribute('data-option-key'),
            hasOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined,
            pageOptions = hasOptions ? userOptions.find((o) => o.id === 'managerPage') : this.defaultPageOptions;
        if (!isNullOrUndefined(key)) {
            const [key1, key2] = key.split('.');
            if (!isNullOrUndefined(pageOptions[key1])) {
                pageOptions[key1][key2] =
                    !isNullOrUndefined(pageOptions[key1][key2]) && pageOptions[key1][key2] === true ? false : true;
                if (!hasOptions) userOptions.push(pageOptions);
            }
            setUserOptions(userOptions);
        }
        this.optionsButton.current.setState({
            on: false
        });
    };

    render() {
        const {
                status,
                tasks,
                error,
                catalog,
                model,
                geo,
                catalogs,
                messages,
                activeModal,
                activeModalProps,
                view,
                hideEmptyFolders,
                isManaged,
                backgroundProgress = -1
            } = this.state,
            { user, token, portalUrl, portalHome, userOptions, intl, tokenManager } = this.props,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            auth = user !== null && user.username !== undefined && user.username !== null,
            geosHtml =
                !isNullOrUndefined(catalog) && status !== PageActivityStatus.PROCESSING_CORE ? (
                    catalog.master.geos.map((g, index) => {
                        return (
                            <CoreLayerListItem
                                key={index}
                                geo={{
                                    id: g['ID'],
                                    name: g['Name'],
                                    url: `${g['Service_Url'].split(';')[0]}?token=${token}`
                                }}
                                active={g['ID'] === geo}
                                onActiveChange={this.onGeoChange}
                                delete={this.showDeleteCoreLayerDialog}
                                save={this.commitCoreLayerChanges}
                            />
                        );
                    })
                ) : status === PageActivityStatus.PROCESSING_CORE ? (
                    <div className="lockout">&nbsp;</div>
                ) : (
                    <ProgressMessage />
                ),
            activeCoreLayer = !isNullOrUndefined(catalog) ? catalog.master.geos.find((g) => g['ID'] === geo) : null,
            activeCoreLayerName = !isNullOrUndefined(activeCoreLayer) ? activeCoreLayer['Name'] : null,
            themeControls = {
                click: this.onControlClick,
                rename: this.onControlClick,
                save: this.commitThemeChanges
            },
            indicatorControls = {
                click: this.onControlClick,
                rename: this.onControlClick,
                save: this.commitIndicatorChanges,
                copy: undefined
            },
            activeTasks = !isNullOrUndefined(tasks) && tasks.max > 0,
            progress = activeTasks ? Math.round((100.0 * tasks.current) / tasks.max) : backgroundProgress,
            blocking = status !== PageActivityStatus.INACTIVE;

        return auth ? (
            <div className="catalog-manager in">
                <div className="catalog-switcher-top">
                    <ListToggleButton
                        ref={this.toggleButton}
                        text={<i className="fas fa-bars fa-fw"></i>}
                        tooltip="Choose Catalog"
                        className="btn btn-link pure-tip pure-tip-bottom"
                        disabled={blocking}
                    >
                        <ItemsLinkList
                            items={catalogs}
                            action={this.onCatalogChange}
                            linkIcon={
                                <span>
                                    <i className="fas fa-fw fa-database"></i>
                                    <i className="fas fa-wrench"></i>{' '}
                                </span>
                            }
                            extraItems={[
                                <li key="divider1" className="divider"></li>,
                                <li key="newlink">
                                    <Link to="/new-catalog">
                                        <FormattedMessage
                                            id="manager.newCatalogLink"
                                            defaultMessage="{icon} New Data Catalog..."
                                            values={{
                                                icon: (
                                                    <span>
                                                        <i className="fas fa-fw fa-database"></i>
                                                        <i className="fas fa-plus"></i>{' '}
                                                    </span>
                                                )
                                            }}
                                        />
                                    </Link>
                                </li>,
                                <li key="divider2" className="divider"></li>,
                                <li key="checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={this.onOwnerListChange}
                                        id="userCatalogsBox"
                                        defaultChecked={false}
                                        className="form-control"
                                    />
                                    <label htmlFor="userCatalogsBox" className="control-label slider">
                                        <FormattedMessage
                                            id="manager.myCatalogsLabel"
                                            defaultMessage="My catalogs only"
                                        />
                                    </label>
                                </li>
                            ]}
                        />
                    </ListToggleButton>
                    <span className="divider">&nbsp;</span>
                    <button
                        className="btn btn-link pure-tip pure-tip-bottom"
                        type="button"
                        data-tooltip="Archive Catalog"
                        onClick={this.createBackup}
                        disabled={blocking}
                    >
                        <i className="fas fa-archive fa-fw"></i>
                    </button>
                    <button
                        className="btn btn-link pure-tip pure-tip-bottom"
                        type="button"
                        onClick={() => this.checkForHostedUpdates(6)}
                        data-tooltip="Check for Updates from Data Service"
                        disabled={blocking}
                        style={{ paddingRight: '1px' }}
                    >
                        <i className="fas fa-history fa-flip-horizontal"></i>
                    </button>
                    <ListToggleButton
                        text={<i className="fas fa-caret-down"></i>}
                        tooltip="Check for Updates from Data Service (advanced)"
                        className="btn btn-link pure-tip pure-tip-bottom"
                        style={{ paddingLeft: '1px' }}
                        disabled={blocking}
                    >
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li className="dropdown-header">
                                <h6>
                                    <FormattedMessage
                                        id="manager.updates.header"
                                        defaultMessage="Check for updates in the last..."
                                    ></FormattedMessage>
                                </h6>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(6)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months6"
                                        defaultMessage="6 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(12)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months12"
                                        defaultMessage="12 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(18)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months18"
                                        defaultMessage="18 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(24)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months24"
                                        defaultMessage="24 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(36)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months36"
                                        defaultMessage="36 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                            <li>
                                <button
                                    className="btn btn-link btn-block"
                                    type="button"
                                    onClick={() => this.checkForHostedUpdates(48)}
                                    disabled={blocking}
                                >
                                    <FormattedMessage
                                        id="manager.updates.months48"
                                        defaultMessage="48 months"
                                    ></FormattedMessage>
                                </button>
                            </li>
                        </ul>
                    </ListToggleButton>
                    <span className="divider">&nbsp;</span>
                    <ListToggleButton
                        ref={this.optionsButton}
                        text={<i className="fas fa-ellipsis-v fa-fw small"></i>}
                        tooltip="Options"
                        className="btn btn-link pure-tip pure-tip-bottom"
                    >
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li>
                                <input
                                    type="checkbox"
                                    onChange={this.changeUserOption}
                                    id="instanceUpdateRecordBox"
                                    data-option-key="instances.recordLastUpdated"
                                    defaultChecked={pageOptions.instances.recordLastUpdated}
                                    className="form-control"
                                />
                                <label htmlFor="instanceUpdateRecordBox" className="control-label slider">
                                    Record "last updated" at date level
                                </label>
                            </li>
                            <li>
                                <input
                                    type="checkbox"
                                    onChange={this.changeUserOption}
                                    id="disableParallelBox"
                                    data-option-key="connections.disableParallel"
                                    defaultChecked={pageOptions.connections.disableParallel}
                                    className="form-control"
                                />
                                <label htmlFor="disableParallelBox" className="control-label slider">
                                    Use "safe" connections to catalog
                                </label>
                            </li>
                        </ul>
                    </ListToggleButton>
                    <span className="divider">&nbsp;</span>
                </div>
                <div id="catalogControls" className="row all-available-height">
                    <div className="col col-md-1"></div>
                    <div className="col col-md-10 flex-box">
                        {isManaged ? (
                            <div className="non-editable pad10 bg-warning spaced">
                                <i
                                    className="pull-left fas fa-exclamation-triangle fa-2x"
                                    style={{ marginRight: '10px' }}
                                ></i>
                                <p>
                                    <FormattedMessage
                                        id="manager.managedCatalogWarning.messageFormat"
                                        defaultMessage={`This data catalog is part of a <strong>managed service</strong>. You should <strong>not</strong> edit it via this tool - any changes you make are at risk of being removed by core data updates. Please contact <a href="mailto:support@instantatlas.com">support@instantatlas.com</a> for more information.`}
                                        values={{
                                            ...getReactIntlHtmlFuncs(),
                                            email: ''
                                        }}
                                    />
                                </p>
                            </div>
                        ) : null}
                        <div className="row">
                            <h4 className="col-md-9">
                                <i className="fas fa-layer-group"></i> Core Layers
                            </h4>
                            <div className="col-md-3 text-right">
                                <span className="btn-group btn-group-sm">
                                    <button
                                        type="button"
                                        onClick={(e) => this.onGeoChange(null)}
                                        className="btn btn-default pure-tip pure-tip-bottom"
                                        data-action="refresh"
                                        data-tooltip="Refresh layer list"
                                        disabled={blocking}
                                    >
                                        <i className="fas fa-sync fa-lg"></i>
                                        <span className="sr-only">Refresh</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(e) => this.showCoreLayerDialog('Layer', e)}
                                        className="btn btn-default btn-editor pure-tip pure-tip-bottom"
                                        data-action="add-layer"
                                        data-tooltip="Add layer to data catalog..."
                                        disabled={blocking}
                                    >
                                        <i className="fas fa-plus-circle fa-lg"></i>
                                        <span className="sr-only">Add Layer</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div className="explorer-layers-panel form-inline">
                            <ul className="geo-list explorer-layers-list">{geosHtml}</ul>
                            {status !== PageActivityStatus.INACTIVE ? <div className="lockout">&nbsp;</div> : null}
                        </div>
                        <div className="row" data-item-type="Catalog">
                            <h4 className="col-md-9">
                                <FormattedMessage
                                    id="manager.dataModel.label"
                                    defaultMessage="{icon} Data Model {geo}"
                                    values={{
                                        icon: <i className="fas fa-database"></i>,
                                        geo: <span className="small geo-name">[ {activeCoreLayerName} ]</span>
                                    }}
                                />
                            </h4>
                            <div className="col-md-3 text-right">
                                <span className="btn-group btn-group-sm">
                                    <button
                                        type="button"
                                        onClick={this.onControlClick}
                                        className="btn btn-default btn-editor btn-geo-dep pure-tip pure-tip-top"
                                        data-action="add-theme"
                                        data-tooltip="Add root theme to data catalog..."
                                        disabled={blocking}
                                    >
                                        <i className="fas fa-folder-plus fa-lg"></i>
                                        <span className="sr-only">Add Theme</span>
                                    </button>
                                </span>
                                <span>&nbsp;</span>
                                <span className="explorer-tree-panel-controls btn-group btn-group-sm">
                                    <button
                                        onClick={(e) => this.toggleTreeFolderView(e)}
                                        type="button"
                                        className={`btn btn-default pure-tip pure-tip-top ${
                                            hideEmptyFolders ? 'active' : ''
                                        }`.trim()}
                                        data-action="empties-toggle"
                                        data-tooltip="Show/hide folders with no children"
                                    >
                                        <i className="fas fa-folder-minus"></i>
                                    </button>
                                    <button
                                        onClick={(e) => this.togglePageView('default', e)}
                                        type="button"
                                        className={`btn btn-default pure-tip pure-tip-top ${
                                            view === 'default' ? 'active' : ''
                                        }`.trim()}
                                        data-action="restore"
                                        data-tooltip="Standard view"
                                    >
                                        <i className="fas fa-window-restore"></i>
                                    </button>
                                    <button
                                        onClick={(e) => this.togglePageView('expanded', e)}
                                        type="button"
                                        className={`btn btn-default pure-tip pure-tip-top ${
                                            view === 'expanded' ? 'active' : ''
                                        }`.trim()}
                                        data-tooltip="Fill window"
                                    >
                                        <i className="fas fa-window-maximize"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div
                            className={`explorer-tree-panel ${view === 'expanded' ? 'full-screen' : ''} ${
                                hideEmptyFolders ? 'hide-empty' : ''
                            }`.trim()}
                        >
                            <div className="row spaced explorer-tree-holder">
                                <div className="col-md-12">
                                    {status > PageActivityStatus.INACTIVE &&
                                    status !== PageActivityStatus.PROCESSING_TASK ? (
                                        <div className="explorerTree">
                                            {status >= PageActivityStatus.PROCESSING_CORE ? (
                                                <div>
                                                    <ProgressMessage
                                                        message={
                                                            <FormattedMessage
                                                                id="manager.progressMessage.updating"
                                                                defaultMessage="Updating catalog. Please wait..."
                                                            />
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    {status > PageActivityStatus.LOADING_CATALOG ? (
                                                        <ProgressMessage
                                                            message={
                                                                status === PageActivityStatus.LOADING_REMOTE_SERVICE ? (
                                                                    <FormattedMessage
                                                                        id="manager.progressMessage.queryingHostedShadow"
                                                                        defaultMessage="Fetching updates from hosted data catalog. Please wait..."
                                                                    />
                                                                ) : undefined
                                                            }
                                                        />
                                                    ) : null}
                                                </div>
                                            )}
                                            {progress >= 0 ? (
                                                <div className="row-fluid">
                                                    <div className="col-md-9 col-md-offset-1">
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar progress-bar-striped active"
                                                                role="progressbar"
                                                                style={{ width: progress + '%' }}
                                                            >
                                                                <span className="sr-only">{progress}% Complete</span>
                                                            </div>
                                                        </div>
                                                        <div className="progress-label text-left">
                                                            {!isNullOrUndefined(messages) && messages.length > 0 ? (
                                                                <span>{messages[0]}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {activeTasks ? (
                                                        <div className="col-md-1">
                                                            <button
                                                                onClick={this.cancelTasks}
                                                                className="btn btn-default btn-secondary btn-sm"
                                                                disabled={tasks.cancel}
                                                            >
                                                                <FormattedMessage
                                                                    id="manager.cancelTasks.button"
                                                                    defaultMessage="{icon} Cancel"
                                                                    values={{
                                                                        icon: <i className="fas fa-times"></i>
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <ThemeTreePanel
                                            model={model}
                                            dragDropEnabled={true}
                                            onDrop={this.handleTreeDragAndDrop}
                                            intl={intl}
                                            controls={{
                                                theme: themeControls,
                                                indicator: indicatorControls
                                            }}
                                        />
                                    )}
                                    <span className="explorer-tree-panel-controls btn-group btn-group-sm">
                                        <button
                                            onClick={(e) => this.togglePageView('default', e)}
                                            type="button"
                                            className={`btn btn-default pure-tip pure-tip-bottom-left ${
                                                view === 'default' ? 'active' : ''
                                            }`.trim()}
                                            data-action="restore"
                                            data-tooltip="Standard view"
                                        >
                                            <i className="fas fa-window-restore"></i>
                                        </button>
                                    </span>
                                    {status === PageActivityStatus.PROCESSING_TASK ? (
                                        <div className="task-in-progress-message">
                                            <div>
                                                <ProgressMessage
                                                    message={
                                                        <FormattedMessage
                                                            id="manager.backgroundTask.popupLabel"
                                                            defaultMessage="Task in progress... {message}"
                                                            values={{
                                                                message: messages.length > 0 ? messages[0] : null
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-1"></div>
                </div>
                {activeModal === 'datesDialog' && (
                    <DatesDialog
                        show={true}
                        onSave={this.commitInstanceChanges}
                        item={{ ...activeModalProps }}
                        catalog={catalog}
                        onMetadataClick={this.showMetadataDialog}
                        onNewConnectionClick={this.addConnectionToIndicator}
                        onClose={this.hideModal}
                        tokenManager={tokenManager}
                    />
                )}
                {activeModal === 'metadataDialog' && (
                    <MetadataDialog
                        show={true}
                        editable={true}
                        onSave={this.commitMetadataChanges}
                        item={{ ...activeModalProps }}
                        catalog={catalog}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'confirmDeleteIndicatorDialog' && (
                    <ConfirmDeleteIndicatorDialog
                        show={true}
                        catalog={catalog}
                        item={{ ...activeModalProps }}
                        onConfirm={this.deleteIndicator}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'chooseDeleteIndicatorActionDialog' && (
                    <ChooseDeleteIndicatorActionDialog
                        show={true}
                        item={{ ...activeModalProps }}
                        onConfirm={this.deleteOrRemoveIndicator}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'confirmDeleteCoreLayerDialog' && (
                    <ConfirmDeleteCoreLayerDialog
                        show={true}
                        catalog={catalog}
                        item={{ ...activeModalProps }}
                        onConfirm={this.deleteCoreLayer}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'arcCoreLayerDialog' && (
                    <ChooseArcItemDialog
                        show={true}
                        title={
                            <FormattedMessage
                                id="manager.chooseCoreLayerDialog.title"
                                defaultMessage="{icon} Choose Core Layer..."
                                values={{
                                    icon: <i className="fas fa-layer-group"></i>
                                }}
                            />
                        }
                        onChoose={this.addCoreLayersFromService}
                        onClose={this.hideModal}
                        itemType="Layer"
                        catalog={catalog}
                        user={user}
                        portalUrl={portalUrl}
                        portalHome={portalHome}
                        allowUrl={true}
                        customArgs={{ ...activeModalProps }}
                    />
                )}
                {activeModal === 'dropDownCoreLayersDialog' && (
                    <ChooseItemsFromSetDialog
                        show={true}
                        title={
                            <FormattedMessage
                                id="manager.chooseCoreLayersFromServiceDialog.title"
                                defaultMessage="{icon} Choose Layer(s)..."
                                values={{
                                    icon: <i className="fas fa-layer-group"></i>
                                }}
                            />
                        }
                        message={
                            <FormattedMessage
                                id="manager.chooseCoreLayersFromServiceDialog.message"
                                defaultMessage="Choose the layers that you want to import as core layers from those available in the feature service."
                                values={{
                                    core: <strong></strong>
                                }}
                            />
                        }
                        icon={<i className="fas fa-layer-group"></i>}
                        itemType="Layer"
                        params={{ ...activeModalProps }}
                        onChoose={this.commitCoreLayerSet}
                        onClose={this.hideModal}
                        multiple={true}
                        mode="buttons"
                    />
                )}
                {activeModal === 'chooseFieldsCoreLayerDialog' && (
                    <ChooseFieldsFromDropDownDialog
                        show={true}
                        icon={
                            <span>
                                <i className="fas fa-table"></i>
                                <i className="fas fa-question" style={{ fontSize: '0.4em' }}></i>
                            </span>
                        }
                        params={{ ...activeModalProps }}
                        onChoose={this.commitToCoreLayer}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'arcDataLayerDialog' && (
                    <ChooseArcItemDialog
                        show={true}
                        title={
                            <FormattedMessage
                                id="manager.chooseDataLayerDialog.title"
                                defaultMessage="{icon} Choose Data Layer..."
                                values={{
                                    icon: <i className="fas fa-database"></i>
                                }}
                            />
                        }
                        onChoose={this.addIndicatorConnection}
                        onClose={this.hideModal}
                        itemType="Layer"
                        catalog={catalog}
                        user={user}
                        portalUrl={portalUrl}
                        portalHome={portalHome}
                        allowUrl={true}
                        customArgs={{ ...activeModalProps }}
                    />
                )}
                {activeModal === 'dropDownLayerDialog' && (
                    <ChooseItemsFromSetDialog
                        show={true}
                        title={
                            <FormattedMessage
                                id="manager.chooseLayerFromServiceDialog.title"
                                defaultMessage="{icon} Choose Layer..."
                                values={{
                                    icon: <i className="fas fa-layer-group"></i>
                                }}
                            />
                        }
                        message={
                            <FormattedMessage
                                id="manager.chooseLayerFromServiceDialog.message"
                                defaultMessage="Choose the layer that matches your core layer {core} from those available in the feature service."
                                values={{
                                    core: <strong>{activeCoreLayerName}</strong>
                                }}
                            />
                        }
                        icon={<i className="fas fa-layer-group"></i>}
                        itemType="Layer"
                        params={{ ...activeModalProps }}
                        onChoose={this.commitIndicatorConnection}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'chooseIndicatorsDialog' && (
                    <ChooseIndicatorsFromServiceDialog
                        show={true}
                        service={{ ...activeModalProps }}
                        onChoose={this.saveIndicatorConnection}
                        onClose={this.hideModal}
                    />
                )}
                {activeModal === 'chooseHostedCoreLayersDialog' && (
                    <ChooseItemsFromSetDialog
                        show={true}
                        title={
                            <FormattedMessage
                                id="manager.chooseHostedCoreLayersDialog.title"
                                defaultMessage="{icon} Import Core Layer(s)?"
                                values={{
                                    icon: <i className="fas fa-layer-group"></i>
                                }}
                            />
                        }
                        message={
                            <FormattedMessage
                                id="manager.chooseHostedCoreLayersDialog.message"
                                defaultMessage="The hosted catalog contains core layers that are not in your catalog. Choose the layers you want to import as core layers from those available. If you do not select any these layers will be ignored when checking for updates."
                                values={{
                                    hosted: <strong></strong>
                                }}
                            />
                        }
                        icon={<i className="fas fa-layer-group"></i>}
                        itemType="Layer"
                        params={{ ...activeModalProps }}
                        onChoose={this.importHostedCoreLayers}
                        onClose={this.hideModal}
                        multiple={true}
                        allowEmptySelection={true}
                        mode="buttons"
                    />
                )}
                {activeModal === 'chooseHostedIndicatorsToUpdateDialog' && (
                    <ChooseHostedIndicatorsToUpdateDialog
                        show={true}
                        {...activeModalProps}
                        onChoose={this.updateIndicatorsFromShadow}
                        onClose={this.hideModal}
                        catalog={catalog}
                    />
                )}
                {error !== null && error.message !== undefined ? (
                    <ModalDialog
                        title={error.title !== undefined ? error.title : 'Error'}
                        show={true}
                        onClose={this.clearError}
                        buttons={
                            !isNullOrUndefined(error.buttons)
                                ? error.buttons
                                : [
                                      <button type="button" className="btn btn-secondary" onClick={this.clearError}>
                                          <FormattedMessage
                                              id="manager.errorDialog.button.close"
                                              defaultMessage="{icon} Close"
                                              values={{
                                                  icon: <i className="fas fa-times"></i>
                                              }}
                                          />
                                      </button>
                                  ]
                        }
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: 10 + 'px',
                                        marginBottom: 10 + 'px',
                                        fontSize: '48px'
                                    }}
                                >
                                    {error.type !== undefined && error.type === 'warning' ? (
                                        <i className="fas fa-exclamation-triangle"></i>
                                    ) : (
                                        <i className="fas fa-times-circle"></i>
                                    )}
                                </div>
                                <div style={{ marginLeft: 68 + 'px' }}>{error.message}</div>
                            </div>
                        </div>
                    </ModalDialog>
                ) : null}
            </div>
        ) : (
            <Redirect to="/" />
        );
    }

    handleUnexpectedError = (err) => {
        this.setState({
            error: {
                title: <FormattedMessage id="manager.unexpectedError.title" defaultMessage="Unexpected Error" />,
                message: (
                    <div>
                        <p>
                            <FormattedMessage
                                id="manager.unexpectedError.messageFormat"
                                defaultMessage="Manager encountered an unexpected error whilst carrying out your request. The details are shown below. Sometimes errors are caused by temporary network issues - please try your request again. If this error is persistent please contact {email} for advice on how to resolve it."
                                values={{
                                    error: (
                                        <strong>
                                            {!isNullOrUndefined(err) && !isNullOrUndefined(err.message)
                                                ? err.message
                                                : err}
                                        </strong>
                                    ),
                                    email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>
                                }}
                            />
                        </p>
                        {!isNullOrUndefined(err) && !isNullOrUndefined(err.message) ? (
                            <div className="error-message bg-danger">{err.message}</div>
                        ) : null}
                    </div>
                ),
                key: 'UnexpectedError'
            },
            status: PageActivityStatus.INACTIVE
        });
        console.log(err); // Deliberate - let them see if required
    };

    clearError = () => {
        this.setState({
            activeModal: null,
            error: null
        });
    };

    onControlClick = (evt, item = null) => {
        const actionKey = evt.currentTarget.getAttribute('data-action'),
            itemType =
                item !== null
                    ? item instanceof Indicator
                        ? 'Indicator'
                        : item instanceof Theme
                        ? 'Theme'
                        : 'Unknown'
                    : evt.currentTarget.parentElement.parentElement.parentElement.getAttribute('data-item-type');
        if ((itemType === 'Indicator' || itemType === 'Theme') && actionKey === 'edit') {
            // This is handled by the child itself, so just exit this function
            return;
        }
        // Got this far - we are handling the event, stop it...
        evt.preventDefault();
        evt.stopPropagation();
        const itemId =
                item !== null
                    ? item.id
                    : evt.currentTarget.parentElement.parentElement.parentElement.getAttribute('data-item-uuid'),
            itemName =
                item !== null
                    ? item.name
                    : evt.currentTarget.parentElement.parentElement.parentElement.getAttribute('data-item-name'),
            itemShortName =
                item !== null
                    ? item.shortName
                    : evt.currentTarget.parentElement.parentElement.parentElement.getAttribute('data-item-short-name'),
            parentId = item !== null ? item.parentTheme : null,
            { catalog, geo, model } = this.state,
            { intl } = this.props;
        //console.log(actionKey + ',' + itemType + ',' + itemId + ',' + parentId); // DEBUG
        if (itemId !== null && itemType === 'Indicator' && actionKey === 'dates') {
            this.setState({
                activeModal: 'datesDialog',
                activeModalProps: {
                    id: itemId,
                    name: itemName,
                    shortName: itemShortName,
                    geo: {
                        id: geo,
                        name: catalog.master.geos.find((g) => g['ID'] === geo)['Name'],
                        url: catalog.master.geos.find((g) => g['ID'] === geo)['Service_Url'].split(';')[0]
                    }
                }
            });
        } else if (itemId !== null && itemType === 'Indicator' && actionKey === 'metadata') {
            this.showMetadataDialog({
                indicator: {
                    id: itemId,
                    name: itemName
                }
            });
        } else if (itemId !== null && itemType === 'Indicator' && actionKey === 'delete') {
            this.setState(
                {
                    status: PageActivityStatus.PROCESSING_TASK,
                    messages: [
                        <FormattedMessage
                            id="manager.taskLabel.deleteTheme"
                            defaultMessage="Deleting {name}"
                            values={{ name: itemName }}
                        />
                    ]
                },
                () => {
                    const indPromise =
                        parentId !== null
                            ? catalog.queryMasterTable(`ID='${itemId}' AND Geo_ID='${geo}'`, {}, true)
                            : Promise.resolve(null);
                    indPromise
                        .then((indicatorSet) => {
                            const geoInfo = {
                                id: geo,
                                name: catalog.master.geos.find((g) => g['ID'] === geo)['Name'],
                                url: catalog.master.geos.find((g) => g['ID'] === geo)['Service_Url'].split(';')[0]
                            };
                            if (
                                !isNullOrUndefined(indicatorSet) &&
                                !isNullOrUndefined(indicatorSet.features) &&
                                indicatorSet.features.length > 1
                            ) {
                                // Duplicate indicator(s) - must be in more than one theme, need a double confirmation
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    activeModal: 'chooseDeleteIndicatorActionDialog',
                                    activeModalProps: {
                                        id: itemId,
                                        name: itemName,
                                        geo: geoInfo,
                                        theme: {
                                            id: parentId,
                                            name: model.getTheme(parentId).name
                                        }
                                    }
                                });
                            } else {
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    activeModal: 'confirmDeleteIndicatorDialog',
                                    activeModalProps: {
                                        id: itemId,
                                        name: itemName,
                                        geo: geoInfo
                                    }
                                });
                            }
                        })
                        .catch((err) => this.handleUnexpectedError(err));
                }
            );
        } else if (itemId !== null && itemType === 'Theme' && actionKey === 'delete') {
            let t = model.getTheme(itemId);
            if (t !== null) {
                this.setState(
                    {
                        status: PageActivityStatus.PROCESSING_TASK,
                        messages: [
                            <FormattedMessage
                                id="manager.taskLabel.deleteTheme"
                                defaultMessage="Deleting {name}"
                                values={{ name: itemName }}
                            />
                        ]
                    },
                    () => {
                        catalog
                            .isEmptyTheme(itemId)
                            .then((isEmpty) => {
                                if (isEmpty) {
                                    t = model.deleteTheme(itemId);
                                    catalog.deleteItems([t.id]).then((deleteRsp) => {
                                        this.setState({
                                            model: model,
                                            status: PageActivityStatus.INACTIVE
                                        });
                                    });
                                } else {
                                    this.setState({
                                        error: {
                                            message: (
                                                <FormattedMessage
                                                    id="manager.notEmptyThemeError.messageFormat"
                                                    defaultMessage="You cannot delete theme {theme} because it has child themes or indicators (perhaps for other core layers)."
                                                    values={{
                                                        theme: <strong>{t.name}</strong>
                                                    }}
                                                />
                                            ),
                                            key: 'NotEmptyThemeCrossGeo'
                                        },
                                        status: PageActivityStatus.INACTIVE
                                    });
                                }
                            })
                            .catch((err) => this.handleUnexpectedError(err));
                    }
                );
            }
        } else if (
            (itemId !== null && itemType === 'Theme' && actionKey === 'add-theme') ||
            (itemId === null && itemType === 'Catalog' && actionKey === 'add-theme')
        ) {
            const now = new Date().toISOString(),
                orphan = itemId === null && itemType === 'Catalog' && actionKey === 'add-theme',
                tid = 'T' + now.substring(0, 20).replace(/[^0-9]/g, ''),
                tname = 'New Theme ' + now.substring(0, 16).replace('T', ' '),
                existing = orphan ? model.rootThemes : model.getTheme(itemId).themes;
            this.setState(
                {
                    status: PageActivityStatus.PROCESSING_TASK,
                    messages: [
                        <FormattedMessage
                            id="manager.taskLabel.addTheme"
                            defaultMessage="Adding new theme {name}"
                            values={{ name: tname }}
                        />
                    ]
                },
                () => {
                    let o = -1;
                    if (!isNullOrUndefined(existing)) {
                        for (let t of existing) {
                            if (t.order !== undefined && !isNaN(t.order)) o = Math.max(o, t.order);
                        }
                    }
                    catalog
                        .addItems([
                            {
                                attributes: {
                                    ID: tid,
                                    Name: tname,
                                    Theme_ID: orphan ? null : itemId,
                                    Item_Type: 'Theme',
                                    Item_Order: o + 1
                                }
                            }
                        ])
                        .then((updateRsp) => {
                            if (updateRsp.error === undefined) {
                                model.addTheme(new Theme(tid, tname, o + 1), itemId);
                                const { treeState, setTreeState } = this.props,
                                    expanded = treeState.expanded;
                                if (!isNullOrUndefined(itemId) && expanded.indexOf(itemId) < 0) expanded.push(itemId);
                                this.setState(
                                    {
                                        model: model,
                                        status: PageActivityStatus.INACTIVE
                                    },
                                    () => {
                                        setTreeState(expanded);
                                    }
                                );
                            }
                        })
                        .catch((err) => this.handleUnexpectedError(err));
                }
            );
        } else if (itemId !== null && itemType === 'Theme' && actionKey === 'add-indicator-connection') {
            this.showDataLayerDialog({
                theme: itemId
            });
        } else if (itemId !== null && itemType === 'Theme' && actionKey === 'add-indicator-empty') {
            const generatedName = intl.formatMessage(
                    {
                        id: 'manager.newEmptyIndicator.nameFormat',
                        defaultMessage: 'Indicator {date}'
                    },
                    {
                        date: new Date().toISOString().substring(0, 16).replace('T', ' ')
                    }
                ),
                standardRow = {
                    attributes: {
                        ID: `I${generatedName.replace(/[^0-9a-zA-Z_]/g, '')}`,
                        Name: generatedName,
                        Short_Name: generatedName,
                        Geo_ID: null,
                        Theme_ID: itemId,
                        Item_Type: 'Indicator',
                        Item_Order: 999 // Presuming that indicators are order of 100-based, so 999 should put it at the end...
                    }
                },
                rows = [];
            for (let g of catalog.master.geos) {
                rows.push({
                    attributes: {
                        ...standardRow.attributes,
                        Geo_ID: g['ID']
                    }
                });
            }
            if (rows.length > 0) {
                this.setState(
                    {
                        status: PageActivityStatus.PROCESSING_TASK,
                        messages: [
                            <FormattedMessage
                                id="manager.taskLabel.addEmptyIndicator"
                                defaultMessage="Adding new indicator..."
                                values={{ name: generatedName }}
                            />
                        ]
                    },
                    () => {
                        catalog
                            .addItems(rows)
                            .then((addResults) => {
                                this.rebindModelFromCatalog(this.state.catalog, this.state.geo); // Harsh - may be able to refactor to more subtle update...
                            })
                            .catch((err) => this.handleUnexpectedError(err));
                    }
                );
            }
        }
    };

    createBackup = () => {
        const { catalog } = this.state;
        // Silent?
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_TASK,
                messages: [<FormattedMessage id="manager.taskLabel.tableArchive" defaultMessage="Creating backup..." />]
            },
            () => {
                catalog
                    .createBackup()
                    .then((bkupDetail) => {
                        console.log(bkupDetail);
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            messages: []
                        });
                    })
                    .catch((err) => this.handleUnexpectedError(err));
            }
        );
    };

    cancelTasks = () => {
        const { tasks } = this.state;
        this.setState({
            tasks: {
                cancel: true,
                ...tasks
            }
        });
    };

    commitUpdates = async (updatePromiseChain, onCompleteFunc, promiseChainMessages = null) => {
        this.setState({
            status: PageActivityStatus.PROCESSING_CORE,
            tasks: {
                current: 0,
                max: updatePromiseChain.length
            }
        });
        const resultSet = [],
            failureSet = [];
        let cancelled = false;
        const executor = async () => {
            const f = updatePromiseChain.shift();
            let currentTaskState = this.state.tasks;
            let taskCount = currentTaskState.current + 1,
                msg =
                    promiseChainMessages !== null && promiseChainMessages.length > 0
                        ? promiseChainMessages.splice(0, 1)
                        : [];
            try {
                resultSet.push(await f());
                await new Promise((resolve) => window.setTimeout(resolve, 200)); // Wait for 200ms to allow for interruption (to give setState a chance)
            } catch (promiseErr) {
                failureSet.push({
                    action: this.state.messages,
                    error: promiseErr
                });
            }
            // Allow it to bail out...
            if (currentTaskState.cancel !== undefined && currentTaskState.cancel === true) {
                this.setState(
                    (currentState) => {
                        return {
                            ...currentState,
                            tasks: {
                                current: 0,
                                max: currentState.tasks.max
                            },
                            messages: ['Cancelling...']
                        };
                    },
                    () => {
                        cancelled = true;
                        onCompleteFunc({
                            status: cancelled ? 'Cancelled' : 'Complete',
                            results: resultSet,
                            failures: failureSet
                        });
                    }
                );
            } else {
                this.setState(
                    (currentState) => {
                        return {
                            ...currentState,
                            tasks: {
                                current: taskCount,
                                max: currentState.tasks.max
                            },
                            messages: msg
                        };
                    },
                    () => {
                        if (updatePromiseChain.length > 0) {
                            executor();
                        } else {
                            onCompleteFunc({
                                status: cancelled ? 'Cancelled' : 'Complete',
                                results: resultSet,
                                failures: failureSet
                            });
                        }
                    }
                );
            }
        };
        executor();
    };

    commitIndicatorChanges = (changes) => {
        if (changes.length > 0) {
            const { catalog, model } = this.state;
            const updatePromiseChain = createIndicatorChangeActions(changes, catalog, model);
            this.commitUpdates(updatePromiseChain, () => {
                this.rebindModelFromCatalog(this.state.catalog, this.state.geo); // Harsh - may be able to refactor to more subtle update...
            });
        }
    };

    commitInstanceChanges = async (changes) => {
        if (changes.length > 0) {
            const { userOptions } = this.props,
                { catalog } = this.state,
                pageOptions =
                    !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                        ? userOptions.find((o) => o.id === 'managerPage')
                        : this.defaultPageOptions,
                deletes = [],
                updates = [],
                additions = [],
                metadataPromiseChain = [],
                indMetaKeys = [],
                updatePromiseChain = [],
                updateMessageList = [],
                now = new Date().toISOString();
            for (let c of changes) {
                if (c.action === 'delete') deletes.push(c.id);
                else if (c.action === 'add') additions.push(c);
                else if (c.action === 'rename' || c.action === 'reorder') updates.push(c);
            }
            // Add and delete first, because renames might act on something brand new...
            if (additions.length > 0) {
                for (let a of additions) {
                    const itemAttributes = a.value,
                        qs = `IndicatorID='${itemAttributes['Indicator_ID']}' AND InstanceID='${itemAttributes['ID']}' AND GeoID='${itemAttributes['Geo_ID']}'`;
                    updatePromiseChain.push(() => {
                        return catalog.addItems([{ attributes: itemAttributes }]).then((updateDetails) => {
                            return updateDetails;
                        });
                    });
                    updateMessageList.push(a.label !== undefined ? `Adding ${a.label}` : 'Adding dates');
                    if (pageOptions.instances.recordLastUpdated) {
                        metadataPromiseChain.push(() => {
                            return catalog.updateMetadataItems(qs, {
                                LastUpdated: now,
                                IndicatorID: itemAttributes['Indicator_ID'],
                                InstanceID: itemAttributes['ID'],
                                GeoID: itemAttributes['Geo_ID']
                            });
                        });
                    }
                    if (indMetaKeys.indexOf(itemAttributes['Indicator_ID']) < 0) {
                        metadataPromiseChain.push(() => {
                            return catalog.updateMetadataItems(
                                `IndicatorID='${itemAttributes['Indicator_ID']}' AND InstanceID IS NULL`,
                                {
                                    LastUpdated: now,
                                    IndicatorID: itemAttributes['Indicator_ID']
                                }
                            );
                        });
                        indMetaKeys.push(itemAttributes['Indicator_ID']);
                    }
                }
            }
            if (deletes.length > 0) {
                updatePromiseChain.push(() => {
                    return catalog.deleteItems(deletes).then((updateDetails) => {
                        return updateDetails;
                    });
                });
                updateMessageList.push(`Deleting ${deletes.length} dates`);
            }
            if (updates.length > 0) {
                for (let r of updates) {
                    updatePromiseChain.push(() => {
                        return catalog
                            .updateItems(
                                `ID='${r.id}' AND Item_Type='Instance'`,
                                r.action === 'rename'
                                    ? { Name: r.value }
                                    : r.action === 'reorder'
                                    ? { Item_Order: r.value }
                                    : {}
                            )
                            .then((updateDetails) => {
                                return updateDetails;
                            });
                    });
                }
            }
            this.commitUpdates(
                updatePromiseChain,
                () => {
                    if (metadataPromiseChain.length > 0) {
                        this.commitUpdates(metadataPromiseChain, (updateMetaDetailsSet) => {
                            //console.log(updateMetaDetailsSet); // DEBUG
                            this.setState({
                                status: PageActivityStatus.INACTIVE
                            }); // No visual change, so just reset
                        });
                    } else {
                        this.setState({
                            status: PageActivityStatus.INACTIVE
                        }); // No visual change, so just reset
                    }
                },
                updateMessageList
            );
        }
    };

    commitCoreLayerChanges = (changes) => {
        const { catalog, geo } = this.state,
            { userOptions } = this.props,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            disableParallel =
                pageOptions.connections !== undefined &&
                pageOptions.connections.disableParallel !== undefined &&
                pageOptions.connections.disableParallel === true;
        this.commitItemChanges(changes, 'Geo', () => {
            catalog
                .init(undefined, false, undefined, !disableParallel)
                .then(() => {
                    this.rebindModelFromCatalog(catalog, geo); // Harsh - may be able to refactor to more subtle update...
                })
                .catch((err) => this.handleUnexpectedError(err));
        });
    };

    commitThemeChanges = (changes) => {
        this.commitItemChanges(changes, 'Theme', () => {
            this.rebindModelFromCatalog(this.state.catalog, this.state.geo); // Harsh - may be able to refactor to more subtle update...
        });
    };

    commitItemChanges = (changes, itemType, callbackFunc) => {
        if (changes.length > 0) {
            const { catalog } = this.state,
                deletes = [],
                updates = [],
                additions = [];
            for (let c of changes) {
                if (c.action === 'delete') deletes.push(c.id);
                else if (c.action === 'add') additions.push(c);
                else if (c.action === 'rename' || c.action === 'reorder' || c.action === 'move') updates.push(c);
            }
            const updatePromiseChain = [];
            // Add and delete first, because renames might act on something brand new...
            if (additions.length > 0) {
                for (let a of additions) {
                    updatePromiseChain.push(() => {
                        return catalog.addItems([{ attributes: a.value }]).then((updateDetails) => {
                            return updateDetails;
                        });
                    });
                }
            }
            if (deletes.length > 0) {
                updatePromiseChain.push(() => {
                    return catalog.deleteItems(deletes).then((updateDetails) => {
                        return updateDetails;
                    });
                });
            }
            if (updates.length > 0) {
                for (let r of updates) {
                    r.attributes =
                        r.action === 'rename'
                            ? { Name: r.value }
                            : r.action === 'reorder'
                            ? { Item_Order: r.value }
                            : r.action === 'move'
                            ? { Theme_ID: r.value }
                            : {};
                    updatePromiseChain.push(() => {
                        return catalog
                            .updateItems(`ID='${r.id}' AND Item_Type='${itemType}'`, r.attributes)
                            .then((updateDetails) => {
                                return updateDetails;
                            });
                    });
                    // Move? - goes into metadata as well... but for all the child indicators, eeek
                    if (r.action === 'move' && itemType === 'Theme') {
                        // TODO...
                    }
                }
            }
            this.commitUpdates(updatePromiseChain, callbackFunc);
        }
    };

    commitMetadataChanges = (changes, sourceItem) => {
        const { model } = this.state;
        if (changes.length > 0) {
            const { catalog } = this.state,
                updates = [];
            for (let c of changes) {
                if (c.action === 'change') updates.push(c);
            }
            const updatePromiseChain = [];
            if (updates.length > 0) {
                let featureWhere,
                    metaAttributes = {};
                // These may be redundant for an update, but critical for a new entry...
                metaAttributes['IndicatorID'] = sourceItem.indicator.id;
                metaAttributes['Title'] = sourceItem.indicator.name;
                if (model !== null) {
                    const ii = model.getIndicator(sourceItem.indicator.id),
                        tpath = model.getPathToTheme(ii.parentTheme);
                    if (tpath !== null) {
                        metaAttributes['ThemeIdTrail'] = tpath.map((e) => e.id).join(' ~ ');
                        metaAttributes['ThemeNameTrail'] = tpath.map((e) => e.name).join(' ~ ');
                    }
                }
                for (let r of updates) {
                    featureWhere = [r.id];
                    if (r.instance !== undefined && r.instance !== null && r.geo !== undefined && r.geo !== null) {
                        featureWhere = `(IndicatorID='${r.id}') AND (GeoID='${r.geo}') AND (InstanceID='${r.instance}')`;
                        metaAttributes['InstanceID'] = r.instance;
                        metaAttributes['GeoID'] = r.geo;
                    } else {
                        featureWhere = `(IndicatorID='${r.id}') AND (GeoID IS NULL) AND (InstanceID IS NULL)`;
                    }
                    metaAttributes[r.key] = r.value;
                }
                // Only one just now, but leaving the code in to deal with possible future complexity
                updatePromiseChain.push(() => {
                    return catalog.updateMetadataItems(featureWhere, metaAttributes).then((updateDetails) => {
                        return updateDetails;
                    });
                });
            }
            this.commitUpdates(updatePromiseChain, (updateDetailsSet) => {
                console.log(updateDetailsSet); // DEBUG
                this.setState({
                    status: PageActivityStatus.INACTIVE
                }); // No visual change, so just reset
            });
        }
    };

    addCoreLayersFromService = (itemId, itemInfo) => {
        const { catalog, tokenManager } = this.state,
            itemUrl = /^(http:\/\/|https:\/\/)/.test(itemId) ? itemId : itemInfo.url,
            itemLabel = !isNullOrUndefined(itemInfo) ? itemInfo.title : itemUrl;
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_TASK,
                messages: [
                    <FormattedMessage
                        id="manager.taskLabel.checkFeatureService"
                        defaultMessage="Checking service {name}..."
                        values={{ name: itemLabel }}
                    />
                ]
            },
            () => {
                getInfo(itemUrl, catalog.token, tokenManager)
                    .then((svcInfo) => {
                        const lyrItems = [];
                        for (let lyr of svcInfo.layers) {
                            lyrItems.push({
                                id: lyr.id,
                                title: lyr.name,
                                url: `${itemInfo.url}/${lyr.id}`
                            });
                        }
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            activeModal: 'dropDownCoreLayersDialog',
                            activeModalProps: {
                                items: lyrItems,
                                customArgs: {
                                    id: itemId,
                                    info: itemInfo
                                }
                            }
                        });
                    })
                    .catch((err) => this.handleUnexpectedError(err));
            }
        );
    };

    addCoreLayers = (
        itemId,
        itemInfo,
        customArgs,
        codeFieldName = null,
        nameFieldName = null,
        selectedLayerIds = []
    ) => {
        const { catalog, geo } = this.state,
            { userOptions, portalUrl, token } = this.props,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            disableParallel =
                pageOptions.connections !== undefined &&
                pageOptions.connections.disableParallel !== undefined &&
                pageOptions.connections.disableParallel === true;
        // Let the catalog manager component deal with this and act on response...
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_CORE,
                messages: []
            },
            () => {
                // Sigh. Legacy of shoddy behaviour...
                if (itemInfo.url.indexOf('http:') === 0 && itemInfo.url.indexOf('arcgis.com') > 0)
                    itemInfo.url = `https:${itemInfo.url.substring(5)}`;
                catalog
                    .addCoreLayer(itemInfo, codeFieldName, nameFieldName, { includes: selectedLayerIds })
                    .then((addResults) => {
                        let overall = true,
                            firstErr = null,
                            firstLayer = null,
                            stillToGo = [];
                        for (let r of addResults) {
                            overall = overall && r.success;
                            if (!r.success && r.error !== undefined) {
                                if (firstErr === null) {
                                    firstErr = r.error;
                                    firstLayer = r.data;
                                }
                                stillToGo.push(r.data.id);
                            }
                        }
                        if (overall) {
                            // ia-item-type=CatalogFeatureService
                            if (
                                isNullOrUndefined(itemInfo.tags) ||
                                itemInfo.tags.indexOf('ia-item-type=CatalogFeatureService') < 0
                            ) {
                                // Launch as a side-update - not critical but quite important for RB and DB!
                                const tags = !isNullOrUndefined(itemInfo.tags) ? [...itemInfo.tags] : [];
                                tags.push('ia-item-type=CatalogFeatureService');
                                // Owner + folder is important but will fail if current user doesn't have privileges - do we need to deal with this?
                                ArcGISPortal.updateItem(
                                    itemInfo.owner,
                                    itemId,
                                    itemInfo.ownerFolder !== undefined ? itemInfo.ownerFolder : null,
                                    {
                                        tags: tags.join(','),
                                        token: token
                                    },
                                    portalUrl
                                );
                            }
                            catalog.init(undefined, false, undefined, !disableParallel).then(() => {
                                this.rebindModelFromCatalog(catalog, geo); // Harsh - may be able to refactor to more subtle update...
                            });
                        } else if (
                            firstErr !== null &&
                            firstErr.key === 'NoCodeAndNameFieldsInService' &&
                            !isNullOrUndefined(firstLayer) &&
                            !isNullOrUndefined(firstLayer.fields)
                        ) {
                            firstLayer.title = `${itemInfo.title}/${firstLayer.name}`; // Because ArcGIS isn't consistent!
                            firstLayer.url = `${itemInfo.url}/${firstLayer.id}`; // Because ArcGIS isn't consistent!
                            this.setState({
                                status: PageActivityStatus.INACTIVE,
                                activeModal: 'chooseFieldsCoreLayerDialog',
                                activeModalProps: {
                                    layer: firstLayer,
                                    customArgs: {
                                        id: itemId,
                                        info: itemInfo,
                                        selectedLayerIds: stillToGo
                                    }
                                }
                            });
                        } else {
                            this.setState({
                                status: PageActivityStatus.INACTIVE,
                                error: {
                                    title: (
                                        <FormattedMessage
                                            id="manager.addCoreLayer.errorDialog.title"
                                            defaultMessage="Cannot Add Layer"
                                            values={{
                                                name: <strong>{itemInfo.title}</strong>
                                            }}
                                        />
                                    ),
                                    message: (
                                        <FormattedMessage
                                            id="manager.addCoreLayer.errorDialog.messageFormat"
                                            defaultMessage="Layer {name} could not be added to the catalog. Please try again with a different layer. The error message was: {message}"
                                            values={{
                                                name: <strong>{itemInfo.title}</strong>,
                                                message: (
                                                    <span>
                                                        <br />
                                                        <br />
                                                        <span className="bg-danger">
                                                            {firstErr !== null ? firstErr.message : 'unknown error'}
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </span>
                                                )
                                            }}
                                        />
                                    ),
                                    key: firstErr !== null ? firstErr.key : 'unknown'
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            error: {
                                title: (
                                    <FormattedMessage
                                        id="manager.addCoreLayer.errorDialog.title"
                                        defaultMessage="Cannot Add Layer"
                                        values={{
                                            name: <strong>{itemInfo.title}</strong>
                                        }}
                                    />
                                ),
                                message: (
                                    <FormattedMessage
                                        id="manager.addCoreLayer.errorDialog.messageFormat"
                                        defaultMessage="Layer {name} could not be added to the catalog. Please try again with a different layer. The error message was: {message}"
                                        values={{
                                            name: <strong>{itemInfo.title}</strong>,
                                            message: (
                                                <span>
                                                    <br />
                                                    <br />
                                                    <span className="bg-danger">
                                                        {err !== null ? err.message : 'unknown error'}
                                                    </span>
                                                    <br />
                                                    <br />
                                                </span>
                                            )
                                        }}
                                    />
                                ),
                                key: err !== null ? err.code : 'unknown'
                            }
                        });
                    });
            }
        );
    };

    commitCoreLayerSet = (layerIdsCommaDelimited, selectedLayerItems, customArgs) => {
        this.hideModal();
        this.addCoreLayers(customArgs.id, customArgs.info, null, null, null, layerIdsCommaDelimited.split(','));
    };

    commitToCoreLayer = (idField, nameField, layerInfo, customArgs) => {
        this.hideModal();
        this.addCoreLayers(
            customArgs.id,
            customArgs.info,
            null,
            idField.name,
            nameField.name,
            customArgs.selectedLayerIds
        );
    };

    showDeleteCoreLayerDialog = (geoId) => {
        const { catalog } = this.state;
        this.setState({
            activeModal: 'confirmDeleteCoreLayerDialog',
            activeModalProps: {
                id: geoId,
                name: catalog.master.geos.find((g) => g['ID'] === geoId)['Name']
            }
        });
    };

    deleteCoreLayer = (geoId) => {
        const { catalog } = this.state,
            { userOptions } = this.props,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            disableParallel =
                pageOptions.connections !== undefined &&
                pageOptions.connections.disableParallel !== undefined &&
                pageOptions.connections.disableParallel === true;
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_CORE
            },
            () => {
                catalog
                    .getIndicatorsForGeo(geoId)
                    .then((indList) => {
                        // Try for some feedback - push a wrapper into the update chain, rather than the small actions...
                        const updatePromiseChain = [],
                            updateMessages = [];
                        if (indList !== undefined && indList.length > 0) {
                            for (let ig of indList) {
                                let indicatorId = ig['ID'];
                                updatePromiseChain.push(() => {
                                    return catalog
                                        .deleteItems(
                                            `Indicator_ID='${indicatorId}' AND Item_Type='Instance' AND Geo_ID='${geoId}'`
                                        )
                                        .then((ue) => {
                                            return catalog.deleteItems(
                                                `ID='${indicatorId}' AND Item_Type='Indicator' AND Geo_ID='${geoId}'`
                                            );
                                        });
                                });
                                updateMessages.push(`Deleting dates for ${ig['Name']}...`);
                            }
                        }
                        updatePromiseChain.push(() => {
                            return catalog.deleteItems(`ID='${geoId}' AND Item_Type='Geo'`);
                        });
                        updateMessages.push(`Deleting core layer...`);
                        this.commitUpdates(
                            updatePromiseChain,
                            (deleteResultSet) => {
                                if (deleteResultSet.status !== 'Complete') {
                                    const ups =
                                            deleteResultSet.results !== undefined ? deleteResultSet.results.length : 0,
                                        skips = updatePromiseChain.length - ups;
                                    this.setState({
                                        error: {
                                            type: 'warning',
                                            title: (
                                                <FormattedMessage
                                                    id="manager.taskCancelledWarningDialog.title"
                                                    defaultMessage="Warning | Update Cancelled"
                                                />
                                            ),
                                            message: (
                                                <FormattedMessage
                                                    id="manager.taskCancelledWarningDialog.message"
                                                    defaultMessage="You cancelled an update to the catalog. You should carefully review the status of your catalog - use the {inspector} tool. {commits} updates were made, {discards} were skipped."
                                                    values={{
                                                        commits: <strong>{ups}</strong>,
                                                        discards: <strong>{skips}</strong>,
                                                        inspector: (
                                                            <Link to="/inspector">
                                                                <FormattedMessage
                                                                    id="manager.inspectorLink.text"
                                                                    defaultMessage="Inspector"
                                                                />
                                                            </Link>
                                                        )
                                                    }}
                                                />
                                            )
                                        },
                                        status: PageActivityStatus.INACTIVE
                                    });
                                }
                                catalog.init(undefined, false, undefined, !disableParallel).then(() => {
                                    this.rebindModelFromCatalog(catalog, null); // Harsh - may be able to refactor to more subtle update...
                                });
                            },
                            updateMessages
                        );
                    })
                    .catch((err) => this.handleUnexpectedError(err));
            }
        );
    };

    deleteOrRemoveIndicator = (indicatorItem, deleteOrRemoveAction) => {
        if (deleteOrRemoveAction === 'delete') {
            window.setTimeout(() => {
                this.setState({
                    status: PageActivityStatus.INACTIVE,
                    activeModal: 'confirmDeleteIndicatorDialog',
                    activeModalProps: indicatorItem
                });
            }, 200); // Delay to allow close of previous dialog
        } else if (deleteOrRemoveAction === 'remove') {
            const { catalog, model } = this.state;
            this.setState(
                {
                    status: PageActivityStatus.PROCESSING_TASK,
                    messages: [
                        <FormattedMessage
                            id="manager.taskLabel.deleteIndicator"
                            defaultMessage="Deleting indicator #{id}"
                            values={{ id: indicatorItem.id }}
                        />
                    ]
                },
                () => {
                    catalog
                        .deleteItems(
                            `ID='${indicatorItem.id}' AND Geo_ID='${indicatorItem.geo.id}' AND Theme_ID='${indicatorItem.theme.id}'`
                        )
                        .then((deleteResultSet) => {
                            let err = null;
                            console.log(deleteResultSet); // DEBUG
                            const t = model.getTheme(indicatorItem.theme.id);
                            if (isNullOrUndefined(t)) this.rebindModelFromCatalog(this.state.catalog, this.state.geo);
                            // Harsh - may be able to refactor to more subtle update...
                            else {
                                t.deleteIndicator(indicatorItem.id);
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    model: model,
                                    geo: indicatorItem.geo.id,
                                    error: err
                                });
                            }
                        });
                }
            );
        }
    };

    deleteIndicator = (indicatorId, geoId) => {
        const { catalog, model } = this.state;
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_TASK,
                messages: [
                    <FormattedMessage
                        id="manager.taskLabel.deleteIndicator"
                        defaultMessage="Deleting indicator #{id}"
                        values={{ id: indicatorId }}
                    />
                ]
            },
            () => {
                catalog
                    .getDates(indicatorId, geoId)
                    .then((instances) => {
                        const instanceIds = [],
                            updatePromiseChain = [];
                        for (var i of instances) {
                            instanceIds.push(i['ID']);
                        }
                        if (instanceIds.length > 0)
                            updatePromiseChain.push(() => {
                                return catalog.deleteItems(instanceIds).then((ue) => ue);
                            });
                        // Delete the indicator as well...?
                        if (geoId === null) {
                            updatePromiseChain.push(() => {
                                return catalog.deleteIndicators([indicatorId]).then((ue) => ue);
                            });
                            if (!catalog.metadataIsReadOnly) {
                                updatePromiseChain.push(() => {
                                    return catalog.deleteMetadataItems([indicatorId]).then((ue) => ue);
                                });
                            }
                        } else
                            updatePromiseChain.push(() => {
                                return catalog.deleteItems(`ID='${indicatorId}' AND Geo_ID='${geoId}'`);
                            });
                        this.commitUpdates(updatePromiseChain, (deleteResultSet) => {
                            let err = null;
                            console.log(deleteResultSet); // DEBUG
                            const t = catalog.getThemeForIndicator(indicatorId);
                            if (isNullOrUndefined(t) || model.getTheme(t) === null)
                                this.rebindModelFromCatalog(this.state.catalog, this.state.geo);
                            // Harsh - may be able to refactor to more subtle update...
                            else {
                                model.getTheme(t).deleteIndicator(indicatorId);
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    model: model,
                                    geo: geoId,
                                    error: err
                                });
                            }
                        });
                    })
                    .catch((err) => this.handleUnexpectedError(err));
            }
        );
    };

    addConnectionToIndicator = (indicatorId, geoId, indicatorName, geoName) => {
        this.setState(
            {
                activeModal: null
            },
            () => {
                this.showDataLayerDialog({
                    id: indicatorId,
                    name: indicatorName,
                    geo: geoId
                });
            }
        );
    };

    addIndicatorConnection = (itemId, itemInfo, indicatorArgs) => {
        const { catalog, tokenManager } = this.state,
            itemUrl = /^(http:\/\/|https:\/\/)/.test(itemId) ? itemId : itemInfo.url,
            itemLabel = !isNullOrUndefined(itemInfo) ? itemInfo.title : itemUrl;
        // Let the catalog manager component deal with this and act on response...
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_TASK,
                messages: [
                    <FormattedMessage
                        id="manager.taskLabel.addDataLayer"
                        defaultMessage="Checking {layer} details"
                        values={{ layer: itemLabel }}
                    />
                ]
            },
            () => {
                getInfo(itemUrl, catalog.token, tokenManager)
                    .then((layerInfo) => {
                        this.setState({
                            status: PageActivityStatus.INACTIVE
                        });
                        if (layerInfo.error) {
                            this.setState({
                                error: {
                                    title: (
                                        <FormattedMessage
                                            id="manager.addDataLayer.errorDialog.title"
                                            defaultMessage="Cannot Add Data Layer"
                                            values={{
                                                name: <strong>{itemLabel}</strong>
                                            }}
                                        />
                                    ),
                                    message: (
                                        <FormattedMessage
                                            id="manager.addDataLayer.errorDialog.messageFormat"
                                            defaultMessage="Layer {name} could not be added to the catalog. Please try again with a different layer. The error message was: {message}"
                                            values={{
                                                name: <strong>{itemLabel}</strong>,
                                                message: (
                                                    <span>
                                                        <br />
                                                        <br />
                                                        <span className="bg-danger">
                                                            {layerInfo.error !== null
                                                                ? layerInfo.error.message
                                                                : 'unknown error'}
                                                        </span>
                                                        <br />
                                                        <br />
                                                    </span>
                                                )
                                            }}
                                        />
                                    ),
                                    key: layerInfo.error !== null ? layerInfo.error.code.toString() : 'unknown'
                                },
                                status: PageActivityStatus.INACTIVE
                            });
                        } else if (layerInfo.layers === undefined) {
                            this.commitIndicatorConnection(
                                itemUrl,
                                { id: 'lyr0', title: itemLabel, url: itemUrl },
                                indicatorArgs,
                                layerInfo
                            );
                        } else {
                            const items = [];
                            for (let lyr of layerInfo.layers) {
                                items.push({
                                    id: 'lyr' + lyr.id,
                                    title: lyr.name,
                                    url: itemUrl + '/' + lyr.id
                                });
                            }
                            this.setState({
                                activeModal: 'dropDownLayerDialog',
                                activeModalProps: {
                                    items: items,
                                    customArgs: indicatorArgs
                                }
                            });
                        }
                    })
                    .catch((err) => this.handleUnexpectedError(err));
            }
        );
    };

    commitIndicatorConnection = (layerId, layerItem, indicatorArgs, layerInfo) => {
        const { catalog, geo, tokenManager } = this.state,
            coreLayer = catalog.master.geos.find((g) => g['ID'] === geo),
            fldSpecd = coreLayer['Service_Url'].split(';').length > 1,
            coreFields = fldSpecd ? coreLayer['Service_Url'].split(';').slice(1) : ['CODE', 'NAME'];
        this.setState({
            status: PageActivityStatus.PROCESSING_TASK,
            messages: [
                <FormattedMessage
                    id="manager.taskLabel.processDataLayer"
                    defaultMessage="Checking {layer} against core layer {core}."
                    values={{
                        layer: layerItem.title,
                        core: coreLayer['Name']
                    }}
                />
            ]
        });
        // Self referential?
        let resolvable = null;
        if (layerInfo === undefined || layerInfo === null) {
            resolvable = getInfo(layerItem.url, catalog.token, tokenManager);
        } else {
            resolvable = Promise.resolve(layerInfo);
        }
        resolvable
            .then((activeLayerInfo) => {
                catalog.crossMatchFeatures(geo, layerItem.url, activeLayerInfo).then((matchEvt) => {
                    // Match? Allow to next stage...
                    if (matchEvt.match) {
                        const found = DataCatalogManager.processFieldsIntoIndicatorsAndInstances({
                            info: activeLayerInfo,
                            fields: [...coreFields, 'SHAPE__Area', 'SHAPE__Length']
                        });
                        //console.log(found); // DEBUG
                        catalog
                            .getIndicatorsForGeo(coreLayer['ID'])
                            .then((alreadyGot) => {
                                const empty = alreadyGot.length < 1;
                                if (
                                    indicatorArgs !== undefined &&
                                    !isNullOrUndefined(indicatorArgs.id) &&
                                    found.indicators !== undefined
                                ) {
                                    // Squeeze out the indicators
                                    //found.indicators = found.indicators.filter(i => (i.id === indicatorArgs.id) || (i.name.toLowerCase() === indicatorArgs.name.toLowerCase()));
                                    found.indicators.map((i, idx) => {
                                        return Object.assign(i, {
                                            disabled: false, // Allow all of them if they really must, but discourage it...
                                            checked:
                                                i.id === indicatorArgs.id ||
                                                i.name.toLowerCase() === indicatorArgs.name.toLowerCase(),
                                            order: idx
                                        });
                                    });
                                } else {
                                    found.indicators.map((i, idx) => {
                                        const dis =
                                            !empty &&
                                            alreadyGot.findIndex(
                                                (ii) =>
                                                    ii['ID'] === i.id ||
                                                    (!isNullOrUndefined(ii['Name']) &&
                                                        ii['Name'].toLowerCase() === i.name.toLowerCase())
                                            ) >= 0;
                                        return Object.assign(i, {
                                            checked: !dis && i.dates !== undefined && i.dates.length > 1,
                                            disabled: dis,
                                            order: idx
                                        });
                                    });
                                }
                                let atLeastOne = false;
                                for (let i of found.indicators) {
                                    atLeastOne = atLeastOne || !i.disabled;
                                    i.dates.map((d) => {
                                        return Object.assign(d, {
                                            checked: i.checked
                                        });
                                    });
                                }
                                this.setState({
                                    status: PageActivityStatus.INACTIVE
                                });
                                if (atLeastOne && found.indicators.length > 0) {
                                    // Handle the updates - need to farm out to another dialog
                                    this.setState({
                                        activeModal: 'chooseIndicatorsDialog',
                                        activeModalProps: {
                                            indicators: [...found.indicators],
                                            geo: {
                                                id: coreLayer['ID'],
                                                name: coreLayer['Name']
                                            },
                                            layer: layerItem,
                                            target: indicatorArgs,
                                            force: new Date().getTime()
                                        }
                                    });
                                } else {
                                    const errMsg = atLeastOne ? (
                                        <FormattedMessage
                                            id="manager.addDataLayer.noIndicatorsDialog.messageFormat"
                                            defaultMessage="Layer {name} could not be added to the catalog because it either has no indicators or it only contains indicators that are already in the catalog for your selected core layer {source}."
                                            values={{
                                                name: <strong>{layerItem.title}</strong>,
                                                source: <strong>{coreLayer['Name']}</strong>
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="manager.addDataLayer.duplicateIndicatorsDialog.messageFormat"
                                            defaultMessage="Layer {name} could not be added to the catalog because it only contains indicators that are already in the catalog for your selected core layer {source}. Either delete the indicators and try again, or use the 'Add Indicator Connection' tool for each indicator in turn."
                                            values={{
                                                name: <strong>{layerItem.title}</strong>,
                                                source: <strong>{coreLayer['Name']}</strong>
                                            }}
                                        />
                                    );
                                    this.setState({
                                        status: PageActivityStatus.INACTIVE,
                                        error: {
                                            title: (
                                                <FormattedMessage
                                                    id="manager.addDataLayer.noIndicatorsDialog.title"
                                                    defaultMessage="Cannot Add Data Layer"
                                                    values={{
                                                        name: <strong>{layerItem.title}</strong>
                                                    }}
                                                />
                                            ),
                                            message: errMsg,
                                            key: 'NoIndicatorsMatch'
                                        }
                                    });
                                }
                            })
                            .catch((err) => this.handleUnexpectedError(err));
                    } else {
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            error: {
                                title: (
                                    <FormattedMessage
                                        id="manager.addDataLayer.noMatchDialog.title"
                                        defaultMessage="Cannot Add Data Layer"
                                        values={{
                                            name: <strong>{layerItem.title}</strong>
                                        }}
                                    />
                                ),
                                message: (
                                    <FormattedMessage
                                        id="manager.addDataLayer.noMatchDialog.messageFormat"
                                        defaultMessage="Layer {name} could not be added to the catalog because it does not match your selected core layer {source}. Either the feature IDs do not match or the number of features do not match."
                                        values={{
                                            name: <strong>{layerItem.title}</strong>,
                                            source: <strong>{coreLayer['Name']}</strong>
                                        }}
                                    />
                                ),
                                key: 'NoFeaturesMatch'
                            }
                        });
                    }
                });
            })
            .catch((err) => this.handleUnexpectedError(err));
    };

    saveIndicatorConnection = (coreLayer, dataService, indicators, indicatorArgs) => {
        const { userOptions } = this.props,
            { model, catalog } = this.state,
            itemRows = [],
            isTargeted = !isNullOrUndefined(indicatorArgs) && !isNullOrUndefined(indicatorArgs.id),
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions;
        this.setState({
            status: PageActivityStatus.PROCESSING_CORE
        });
        for (let i of indicators) {
            if (i.checked && !i.disabled) {
                let io = 99; // Default order of indicators is 100, so start just below
                if (!isTargeted) {
                    const t = model.getTheme(indicatorArgs.theme);
                    for (let sibling of t.indicators) {
                        if (
                            catalog.master.indicatorOrders[sibling.id] !== undefined &&
                            !isNaN(catalog.master.indicatorOrders[sibling.id])
                        )
                            io = Math.max(catalog.master.indicatorOrders[sibling.id], io);
                    }
                    io++;
                    itemRows.push({
                        attributes: {
                            ID: i.id,
                            Geo_ID: coreLayer.id,
                            Name: i.name,
                            Short_Name: i.name,
                            Item_Type: 'Indicator',
                            Data_Type: i.dataType,
                            Theme_ID: indicatorArgs.theme,
                            Item_Order: io
                        }
                    });
                }
                io = 1000; // Default order of instances is 1000
                const noExplicitSelection = i.dates.filter((d) => d.checked === true).length < 1;
                for (let ii of i.dates) {
                    if (ii.checked || noExplicitSelection) {
                        itemRows.push({
                            attributes: {
                                ID: `${isTargeted ? indicatorArgs.id : i.id}D${ii.name}`.replace(/[^0-9a-zA-Z]/g, ''),
                                Geo_ID: coreLayer.id,
                                Name: ii.name,
                                Short_Name: ii.name,
                                Item_Type: 'Instance',
                                Indicator_ID: isTargeted ? indicatorArgs.id : i.id,
                                Service_Url: dataService.url,
                                Field_ID: ii.field,
                                Item_Order: io
                            }
                        });
                        io++;
                    }
                }
            }
        }

        if (itemRows.length > 0) {
            this.setState(
                {
                    status: PageActivityStatus.PROCESSING_CORE
                },
                () => {
                    catalog
                        .addItems(itemRows)
                        .then((ue) => {
                            const errors = ue.addResults !== undefined ? ue.addResults.filter((r) => !r.success) : [],
                                metadataPromiseChain = [],
                                now = new Date().toISOString();
                            if (errors.length > 0) {
                                console.log('⚠️ Warning - errors occurred when linking indicator(s) - details below:');
                                console.log(errors);
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    error: {
                                        type: 'warning',
                                        message: (
                                            <FormattedMessage
                                                id="manager.updatePartial.message"
                                                defaultMessage="At least {fails} of your {count} requests failed when updating indicators in your catalog. An error message has been logged to your browser console (press F12 to see this). Contact {email} for help on what to do with this information."
                                                values={{
                                                    count: itemRows.length,
                                                    fails: <strong>{errors.length}</strong>,
                                                    catalog: <strong>{catalog}</strong>,
                                                    email: (
                                                        <a href="mailto:support@instantatlas.com">
                                                            support@instantatlas.com
                                                        </a>
                                                    )
                                                }}
                                            />
                                        )
                                    }
                                });
                            } else {
                                let isInd;
                                for (let item of pageOptions.instances.recordLastUpdated
                                    ? itemRows
                                    : itemRows.filter((i) => i.attributes['Item_Type'] === 'Indicator')) {
                                    isInd = item.attributes['Item_Type'] === 'Indicator';
                                    const qs = isInd
                                            ? `IndicatorID='${item.attributes['ID']}' AND InstanceID IS NULL`
                                            : `IndicatorID='${item.attributes['Indicator_ID']}' AND InstanceID='${item.attributes['ID']}' AND GeoID='${coreLayer.id}'`,
                                        metaAttributes = {
                                            LastUpdated: now,
                                            IndicatorID: isInd
                                                ? item.attributes['ID']
                                                : item.attributes['Indicator_ID'],
                                            InstanceID: isInd ? undefined : item.attributes['ID'],
                                            GeoID: isInd ? undefined : coreLayer.id
                                        };
                                    if (isInd && item.attributes['Theme_ID'] !== null && model !== null) {
                                        const tpath = model.getPathToTheme(item.attributes['Theme_ID']);
                                        if (tpath !== null) {
                                            metaAttributes['ThemeIdTrail'] = tpath.map((e) => e.id).join(' ~ ');
                                            metaAttributes['ThemeNameTrail'] = tpath.map((e) => e.name).join(' ~ ');
                                        }
                                    }
                                    metadataPromiseChain.push(() => {
                                        return catalog.updateMetadataItems(qs, metaAttributes);
                                    });
                                }
                                this.commitUpdates(metadataPromiseChain, (updateMetaDetailsSet) => {
                                    console.log(updateMetaDetailsSet); // DEBUG
                                    this.rebindModelFromCatalog(catalog, coreLayer.id);
                                });
                            }
                        })
                        .catch((err) => this.handleUnexpectedError(err));
                }
            );
        } else {
            this.setState({
                status: PageActivityStatus.INACTIVE
            });
        }
    };

    showBackgroundProgress = (e) => {
        const { step = 0, max = 0, status } = e;
        this.setState({
            status: step < max ? PageActivityStatus.LOADING_TREE : PageActivityStatus.INACTIVE,
            backgroundProgress: step < max ? Math.round((100.0 * step) / max) : -1
        });
    };

    checkForHostedUpdates = (offsetInMonths = 6) => {
        this.setState(
            {
                status: PageActivityStatus.LOADING_REMOTE_SERVICE,
                backgroundProgress: 5,
                tasks: {
                    current: 0,
                    max: 0
                }
            },
            () => {
                const { catalog } = this.state,
                    coreLayerIds = catalog.master.geos.map((g) => g['ID']);
                catalog.findHostedCoreLayers(coreLayerIds).then((coreLayerList) => {
                    if (!isNullOrUndefined(coreLayerList.features) && coreLayerList.features.length > 0) {
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            activeModal: 'chooseHostedCoreLayersDialog',
                            activeModalProps: {
                                items: coreLayerList.features.map((c) => {
                                    return {
                                        id: c.attributes['ID'],
                                        title: `<span>${c.attributes['Name']}, <a href="${
                                            c.attributes['Service_Url']
                                        }" target="_blank" style="font-size:.85em;">${c.attributes[
                                            'Service_Url'
                                        ].replace(/[/]/g, '/\u00AD')}</a></span>`,
                                        attributes: { ...c.attributes }
                                    };
                                }),
                                customArgs: {
                                    items: [...coreLayerList.features],
                                    offsetInMonths
                                }
                            }
                        });
                    } else this.checkForHostedIndicatorUpdates(undefined, offsetInMonths);
                });
            }
        );
    };

    importHostedCoreLayers = (layerIdsCommaDelimited = '', selectedLayerItems = [], customArgs = {}) => {
        this.hideModal();
        this.setState(
            {
                status: PageActivityStatus.LOADING_TREE,
                backgroundProgress: 10,
                tasks: {
                    current: 1,
                    max: 0
                }
            },
            () => {
                const { catalog } = this.state,
                    chosen = layerIdsCommaDelimited !== null ? layerIdsCommaDelimited.split(',') : [],
                    discards = customArgs.items
                        .filter((lyr) => chosen.indexOf(lyr.attributes['ID']) < 0)
                        .map((lyr) => lyr.attributes['ID']),
                    commitPromise =
                        !isNullOrUndefined(selectedLayerItems) && selectedLayerItems.length > 0
                            ? catalog.addItems(selectedLayerItems)
                            : Promise.resolve([]);
                commitPromise.then((results) => {
                    // TODO - check results?
                    this.checkForHostedIndicatorUpdates(discards, customArgs.offsetInMonths);
                });
            }
        );
    };

    checkForHostedIndicatorUpdates = (skipGeoIds = [], offsetInMonths = 6) => {
        this.setState(
            {
                status: PageActivityStatus.LOADING_REMOTE_SERVICE,
                backgroundProgress: 5,
                tasks: {
                    current: 0,
                    max: 0
                }
            },
            () => {
                const { catalog, table } = this.state,
                    { intl } = this.props,
                    offset = new Date(),
                    offsetYears = Math.floor(offsetInMonths / 12.0),
                    offsetMonths = offsetInMonths % 12;
                offset.setFullYear(new Date().getFullYear() - offsetYears);
                offset.setMonth(new Date().getMonth() - offsetMonths);
                const showCheckProgress = (e) => {
                    const { step = 0, max = 0, status = 'Running', detail = {} } = e,
                        { shadowMetadata, shadow, numberOfItems } = detail,
                        count = numberOfItems !== undefined ? new Intl.NumberFormat().format(numberOfItems) : '?',
                        since = offset.toLocaleDateString(), //.toISOString().substring(0, 10),
                        progressMsg =
                            status === 'ShadowFound'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.shadowFound',
                                          defaultMessage:
                                              '{icon} Checking data service for indicator updates. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadow:
                                              shadowMetadata !== undefined && shadowMetadata.info !== undefined
                                                  ? shadowMetadata.info.name
                                                  : '',
                                          icon: <i className="far fa-fw fa-calendar"></i>
                                      }
                                  )
                                : status === 'ShadowMetadataFound'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.shadowMetadataFound',
                                          defaultMessage:
                                              '{icon} Checking data service <em>{shadow}</em> for indicators updated since {date}. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadow:
                                              shadowMetadata !== undefined && shadowMetadata.info !== undefined
                                                  ? shadowMetadata.info.name
                                                  : '',
                                          icon: <i className="far fa-fw fa-calendar-alt"></i>,
                                          date: since
                                      }
                                  )
                                : status === 'ShadowMetadataChecked'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.shadowMetadataFound',
                                          defaultMessage:
                                              '{icon} There are {count} updates in <em>{shadowMetadata}</em> since {since}. Checking against indicator lists. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadowMetadata:
                                              shadowMetadata.info !== undefined ? shadowMetadata.info.name : '',
                                          count: count,
                                          since: since,
                                          icon: <i className="fas fa-fw fa-calendar-check"></i>
                                      }
                                  )
                                : status === 'ShadowIndicatorsChecked'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.shadowIndicatorsChecked',
                                          defaultMessage:
                                              '{icon} Data service <em>{shadow}</em> has <strong>{count} indicator updates</strong> since {since}. Comparing with this catalog. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadow: shadow.info !== undefined ? shadow.info.name : shadow.url,
                                          count: count,
                                          since: since,
                                          icon: <i className="fas fa-fw fa-exchange-alt"></i>
                                      }
                                  )
                                : status === 'LocalMetadataChecked'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.localMetadataChecked',
                                          defaultMessage:
                                              '{icon} Checking for updates and additions to the <strong>{count} indicators</strong> in this catalog <em>{catalog}</em>. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadow: shadow.info !== undefined ? shadow.info.name : shadow.url,
                                          catalog:
                                              catalog.master.item !== undefined
                                                  ? catalog.master.item.title
                                                  : catalog.master.info.name,
                                          count: count,
                                          since: since,
                                          icon: <i className="fas fa-fw fa-exchange-alt"></i>
                                      }
                                  )
                                : status === 'IndicatorUpdatesChecked'
                                ? intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.indicatorUpdatesChecked',
                                          defaultMessage:
                                              '{icon} Comparison of updates between <em>{shadow}</em> and <em>{catalog}</em> complete. Finding obsolete indicators. Please wait...'
                                      },
                                      {
                                          ...getReactIntlHtmlFuncs(),
                                          shadow: shadow.info !== undefined ? shadow.info.name : shadow.url,
                                          shadowMetadata:
                                              shadowMetadata.info !== undefined ? shadowMetadata.info.name : '',
                                          catalog:
                                              catalog.master.item !== undefined
                                                  ? catalog.master.item.title
                                                  : catalog.master.info.name,
                                          icon: <i className="fas fa-fw fa-calendar-times"></i>
                                      }
                                  )
                                : intl.formatMessage(
                                      {
                                          id: 'manager.indicatorUpdates.progress.defaultMessage',
                                          defaultMessage: '{icon} Finding data service details. Please wait...'
                                      },
                                      {
                                          icon: <i className="fas fa-history fa-flip-horizontal fa-fw"></i>
                                      }
                                  );
                    this.setState({
                        status: step < max ? PageActivityStatus.LOADING_REMOTE_SERVICE : PageActivityStatus.INACTIVE,
                        backgroundProgress: step < max ? Math.round((100.0 * step) / max) : -1,
                        messages: [progressMsg]
                    });
                };
                catalog
                    .findHostedModifiedIndicators(offset, false, skipGeoIds, showCheckProgress)
                    .then((foundOnHosted) => {
                        /* {
                        status: 'not-checked',
                        detail: 'no-shadow',
                        target: {
                            catalog: this.master.url,
                            metadata: localMetadataUrl
                        },
                        time: targetUpdateTime
                    } */
                        //console.log(foundOnHosted); // DEBUG
                        let mod = '',
                            modProps = {};
                        if (!isNullOrUndefined(foundOnHosted) && !isNullOrUndefined(foundOnHosted.status)) {
                            if (foundOnHosted.status === 'not-checked') {
                                this.setState({
                                    status: PageActivityStatus.INACTIVE,
                                    error: {
                                        type: 'warning',
                                        message: (
                                            <FormattedMessage
                                                id="manager.noShadowCatalog.message"
                                                defaultMessage="Catalog {catalog} does not contain a reference to a hosted service - if you think it should, please contact {email} for instructions on how to enable this funcationality for your organisation."
                                                values={{
                                                    catalog: <strong>{foundOnHosted.target.catalog}</strong>,
                                                    email: (
                                                        <a href="mailto:support@instantatlas.com">
                                                            support@instantatlas.com
                                                        </a>
                                                    )
                                                }}
                                            />
                                        )
                                    }
                                });
                                return;
                            } else {
                                const hostedIids = (foundOnHosted.updates || []).map((iu) => iu.id);
                                let themeCheckPromise = Promise.resolve([]);
                                if (hostedIids.length > 0) {
                                    themeCheckPromise = catalog.getIndicators(hostedIids);
                                }
                                themeCheckPromise
                                    .then((localInds) => {
                                        mod = 'chooseHostedIndicatorsToUpdateDialog';
                                        modProps = {
                                            ...foundOnHosted,
                                            existing: [...localInds],
                                            table
                                        };
                                        this.setState({
                                            status: PageActivityStatus.INACTIVE,
                                            activeModal: mod,
                                            activeModalProps: modProps,
                                            backgroundProgress: -1
                                        });
                                    })
                                    .catch((terr) => {
                                        this.handlePromiseChainError(terr);
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err); // DEBUG
                        this.handlePromiseChainError(err);
                    });
            }
        );
    };

    handlePromiseChainError = (err) => {
        this.setState({
            status: PageActivityStatus.INACTIVE,
            error: {
                type: 'warning',
                message: (
                    <FormattedMessage
                        id="manager.checkUpdatesGenericErrorWarningDialog.message"
                        defaultMessage="An unexpected error occurred when checking for updates. The error message was: {error}More details of the errors can be found in the developer console (F12) - contact {email} for more help with this."
                        values={{
                            error: (
                                <>
                                    <br />
                                    <br />
                                    <span className="error-message">
                                        {err.code !== undefined ? err.code.toString() : ''}{' '}
                                        {err.message !== undefined ? err.message : err.toString()}
                                    </span>
                                    <br />
                                    <br />
                                </>
                            ),
                            email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>
                        }}
                    />
                )
            },
            backgroundProgress: -1
        });
    };

    updateIndicatorsFromShadow = (
        possibleAdditions,
        possibleUpdates,
        possibleDeletes,
        target,
        source,
        existingIndicators = [],
        updateThemesToMatchShadow = false
    ) => {
        const { catalog, model } = this.state,
            { intl, token, userOptions } = this.props,
            updateActions = [],
            updateActionsMessages = [],
            themeLookups = new Map(),
            themeUpdateKeys = [],
            updates = possibleUpdates, //.filter(u => u.checked), // Pre-filtered by calling class
            additions = possibleAdditions, //.filter(a => a.checked);,
            deletes = possibleDeletes,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'managerPage') !== undefined
                    ? userOptions.find((o) => o.id === 'managerPage')
                    : this.defaultPageOptions,
            disableParallel =
                pageOptions.connections !== undefined &&
                pageOptions.connections.disableParallel !== undefined &&
                pageOptions.connections.disableParallel === true;
        this.setState(
            {
                status: PageActivityStatus.PROCESSING_CORE,
                tasks: {
                    current: 0,
                    max: additions.length + updates.length
                },
                messages: [
                    intl.formatMessage(
                        {
                            id: 'manager.importedStartFromHosted.messageFormat',
                            defaultMessage:
                                'Processing {updates} updates and {additions} new indicators. Please wait...'
                        },
                        {
                            updates: updates.length + deletes.length,
                            additions: additions.length
                        }
                    )
                ]
            },
            () => {
                catalog.getThemes().then((existingThemeSet) => {
                    for (let t of existingThemeSet) {
                        themeLookups.set(t['ID'], t['Name']);
                    }
                    let tid, tname;
                    // Additions
                    for (let a of additions) {
                        //if (a.checked)
                        //{
                        tid = a.path !== undefined ? a.path.split(' ~ ') : [];
                        tname = a.themeTrail !== undefined ? a.themeTrail.split(' ~ ') : [];
                        for (let i = 0; i < tid.length; i++) {
                            const localTid = tid[i].trim();
                            if (!themeLookups.has(localTid) && themeUpdateKeys.indexOf(localTid) < 0) {
                                themeUpdateKeys.push(localTid);
                                updateActions.push(() => {
                                    return createNewThemeAction(source.catalog.url, localTid, catalog, token);
                                });
                                updateActionsMessages.push(
                                    intl.formatMessage(
                                        {
                                            id: 'manager.importedThemeFromHosted.messageFormat',
                                            defaultMessage: 'Theme import complete: {theme}'
                                        },
                                        {
                                            theme: tname.join(' / ')
                                        }
                                    )
                                );
                            }
                        }
                        updateActions.push(() => {
                            return catalog.createImportDataFromHostedAction(
                                a.id,
                                'add',
                                source.catalog.url,
                                source.metadata.url,
                                true
                            );
                        });
                        updateActionsMessages.push(
                            intl.formatMessage(
                                {
                                    id: 'manager.importedIndicatorFromHosted.messageFormat',
                                    defaultMessage: 'Import complete: {indicator} ({theme})'
                                },
                                {
                                    indicator: a.name,
                                    theme: a.theme
                                }
                            )
                        );
                        //}
                    }
                    // Deletions
                    for (let d of deletes) {
                        updateActions.push(() => {
                            return catalog.getDates(d.id).then((instances) => {
                                const instanceIds = [];
                                for (var i of instances) {
                                    instanceIds.push(i['ID']);
                                }
                                const insPromise =
                                    instanceIds.length > 0 ? catalog.deleteItems(instanceIds) : Promise.resolve({});
                                return insPromise.then(() => {
                                    return catalog.deleteIndicators([d.id]).then((ud) => {
                                        if (ud.errors !== undefined)
                                            throw new Error(`Error deleting indicator ${d.id}: ${ud.errors}`);
                                        return catalog.deleteMetadataItems([d.id]);
                                    });
                                });
                            });
                        });
                        updateActionsMessages.push(
                            intl.formatMessage(
                                {
                                    id: 'manager.deleteIndicatorFromHosted.messageFormat',
                                    defaultMessage: 'Deletion complete: {indicator} ({theme})'
                                },
                                {
                                    indicator: d.name,
                                    theme: d.theme
                                }
                            )
                        );
                        //}
                    }
                    // Updates
                    for (let u of updates) {
                        //if (u.checked)
                        //{
                        tid = u.path !== undefined ? u.path.split(' ~ ') : [];
                        tname = u.themeTrail !== undefined ? u.themeTrail.split(' ~ ') : [];
                        for (let i = 0; i < tid.length; i++) {
                            const localTid = tid[i].trim();
                            if (!themeLookups.has(localTid) && themeUpdateKeys.indexOf(localTid) < 0) {
                                themeUpdateKeys.push(localTid);
                                updateActions.push(() => {
                                    return createNewThemeAction(source.catalog.url, localTid, catalog, token);
                                });
                                updateActionsMessages.push(
                                    intl.formatMessage(
                                        {
                                            id: 'manager.importedThemeFromHosted.messageFormat',
                                            defaultMessage: 'Theme import complete: {theme}'
                                        },
                                        {
                                            theme: tname.join(' / ')
                                        }
                                    )
                                );
                            }
                        }
                        const localInd = existingIndicators.find((ei) => ei['ID'] === u.id),
                            hostedThemeId = u.path.split('~').pop().trim();
                        if (
                            updateThemesToMatchShadow &&
                            localInd !== undefined &&
                            localInd['Theme_ID'] !== hostedThemeId
                        ) {
                            const themeChanges = createIndicatorChangeActions(
                                [
                                    {
                                        action: 'move',
                                        id: u.id,
                                        value: hostedThemeId,
                                        theme: hostedThemeId,
                                        values: {
                                            ...localInd
                                        }
                                    }
                                ],
                                catalog,
                                model
                            );
                            for (let itc of themeChanges) {
                                updateActions.push(itc);
                                updateActionsMessages.push(
                                    intl.formatMessage(
                                        {
                                            id: 'manager.updatedIndicatorThemeFromHosted.messageFormat',
                                            defaultMessage: 'Theme update complete: {indicator} 🠖 {theme}'
                                        },
                                        {
                                            indicator: u.name,
                                            theme: u.theme
                                        }
                                    )
                                );
                            }
                        }
                        // Instances
                        const activeThemeId = tid[tid.length - 1].toString();
                        // Name - if it is from the data service, take the name from there (because it may contain important updates)
                        updateActions.push(() => {
                            return catalog
                                .createImportDataFromHostedAction(
                                    u.id,
                                    'update',
                                    source.catalog.url,
                                    source.metadata.url,
                                    true
                                )
                                .then((importResults) => {
                                    const importRequests =
                                        !isNullOrUndefined(importResults.detail) &&
                                        !isNullOrUndefined(importResults.detail.instances)
                                            ? importResults.detail.instances.requests
                                            : null;
                                    // Double check - did we ask for indicators?
                                    let extraIndicatorsPromise = Promise.resolve({});
                                    if (!isNullOrUndefined(importRequests)) {
                                        extraIndicatorsPromise = new Promise((resolve, reject) => {
                                            catalog.getIndicators([u.id], 'Item_Order ASC').then((indGeoList) => {
                                                const reqPairs = [
                                                        ...new Set(
                                                            importRequests
                                                                .map((ir) => ir.attributes)
                                                                .map((ir) => `${ir.Indicator_ID}|${ir.Geo_ID}`)
                                                        )
                                                    ],
                                                    iPairs = indGeoList.map((ig) => `${ig.ID}|${ig.Geo_ID}`),
                                                    missing = [];
                                                for (let p of reqPairs) {
                                                    if (iPairs.indexOf(p) < 0) {
                                                        missing.push(
                                                            catalog.addItems([
                                                                {
                                                                    attributes: {
                                                                        ID: p.split('|')[0],
                                                                        Geo_ID: p.split('|')[1],
                                                                        Item_Type: 'Indicator',
                                                                        Theme_ID: activeThemeId,
                                                                        Name: u.name,
                                                                        Data_Type:
                                                                            indGeoList[0] !== undefined &&
                                                                            indGeoList[0].Data_Type !== undefined
                                                                                ? indGeoList[0].Data_Type
                                                                                : 'numeric',
                                                                        Item_Order:
                                                                            indGeoList[0] !== undefined &&
                                                                            indGeoList[0].Item_Order !== undefined
                                                                                ? indGeoList[0].Item_Order
                                                                                : 100 // 100, reasonable for an indicator?
                                                                    }
                                                                }
                                                            ])
                                                        );
                                                    }
                                                }
                                                if (missing.length > 0)
                                                    Promise.all(missing).then((missingResults) =>
                                                        resolve(missingResults)
                                                    );
                                                else resolve([]);
                                            });
                                        });
                                    }
                                    const catalogAttributes = { Name: u.name };
                                    // Make sure that indicators are percolated across for new core layers...
                                    return extraIndicatorsPromise.then(() => {
                                        return catalog
                                            .updateItems(`ID='${u.id}' AND Item_Type='Indicator'`, catalogAttributes)
                                            .then(() => {
                                                const metaAttributes = {
                                                    IndicatorID: u.id,
                                                    Title: u.name,
                                                    LastUpdated: u.updated.toISOString()
                                                }; // TODO - pull from national data service? Or reconcile?
                                                return catalog.updateMetadataItems([u.id], metaAttributes).then(() => {
                                                    return importResults;
                                                });
                                            });
                                    });
                                });
                        });
                        updateActionsMessages.push(
                            intl.formatMessage(
                                {
                                    id: 'manager.updatedIndicatorFromHosted.messageFormat',
                                    defaultMessage: 'Update complete: {indicator} ({theme})'
                                },
                                {
                                    indicator: u.name,
                                    theme: u.theme
                                }
                            )
                        );
                        //}
                    }
                    this.commitUpdates(
                        updateActions,
                        (updateResults) => {
                            const warnings = [];
                            if (updateResults.status === 'Cancelled') {
                                const ups = updateResults.results !== undefined ? updateResults.results.length : 0,
                                    skips = updateActions.length - ups;
                                this.setState({
                                    error: {
                                        type: 'warning',
                                        title: (
                                            <FormattedMessage
                                                id="manager.taskCancelledWarningDialog.title"
                                                defaultMessage="Warning | Update Cancelled"
                                            />
                                        ),
                                        message: (
                                            <FormattedMessage
                                                id="manager.taskCancelledWarningDialog.message"
                                                defaultMessage="You cancelled an update to the catalog. You should carefully review the status of your catalog - use the {inspector} tool. {commits} updates were made, {discards} were skipped."
                                                values={{
                                                    commits: <strong>{ups}</strong>,
                                                    discards: <strong>{skips}</strong>,
                                                    inspector: (
                                                        <Link to="/inspector">
                                                            <FormattedMessage
                                                                id="manager.inspectorLink.text"
                                                                defaultMessage="Inspector"
                                                            />
                                                        </Link>
                                                    )
                                                }}
                                            />
                                        )
                                    },
                                    status: PageActivityStatus.INACTIVE
                                });
                            } else if (updateResults.failures !== undefined && updateResults.failures.length > 0) {
                                const ups = updateResults.results !== undefined ? updateResults.results.length : 0,
                                    skips = updateActions.length - ups,
                                    fails = updateResults.failures.length;
                                if (console.group !== undefined) console.group('Catalog update errors and warnings');
                                console.log(updateResults.failures);
                                if (console.groupEnd !== undefined) console.groupEnd();
                                this.setState({
                                    error: {
                                        type: 'warning',
                                        message: (
                                            <FormattedMessage
                                                id="manager.taskHasErrorsWarningDialog.message"
                                                defaultMessage="{fails} errors occurred during the update of the catalog. You should carefully review the status of your catalog - use the {inspector} tool. {commits} updates were made successfully, but {discards} were unsuccessful or only partially successful (for example, data was imported but updating metadata failed). Details of the errors can be found in the developer console (F12) - contact {email} for more help with this."
                                                values={{
                                                    fails: <strong>{fails}</strong>,
                                                    commits: <strong>{ups}</strong>,
                                                    discards: <strong>{skips}</strong>,
                                                    inspector: (
                                                        <Link to="/inspector">
                                                            <FormattedMessage
                                                                id="manager.inspectorLink.text"
                                                                defaultMessage="Inspector"
                                                            />
                                                        </Link>
                                                    ),
                                                    email: (
                                                        <a href="mailto:support@instantatlas.com">
                                                            support@instantatlas.com
                                                        </a>
                                                    )
                                                }}
                                            />
                                        )
                                    },
                                    status: PageActivityStatus.INACTIVE
                                });
                            } else if (updateResults.results !== undefined) {
                                // Check for warnings "deeper" in the results
                                for (let u of updateResults.results) {
                                    if (u.detail !== undefined) {
                                        if (!isNullOrUndefined(u.detail.indicator) && u.detail.indicator.error) {
                                            warnings.push({
                                                id: u.id,
                                                type: `${u.action.substring(0, 1).toUpperCase()}${u.action.substring(
                                                    1
                                                )}IndicatorError`,
                                                messages: [...u.detail.indicator.messages],
                                                results: [...u.detail.indicator.results]
                                            });
                                        }
                                        if (!isNullOrUndefined(u.detail.instances) && u.detail.instances.error) {
                                            warnings.push({
                                                id: u.id,
                                                type: `${u.action.substring(0, 1).toUpperCase()}${u.action.substring(
                                                    1
                                                )}InstancesError`,
                                                messages: [...u.detail.instances.messages],
                                                results: [...u.detail.instances.results]
                                            });
                                        }
                                        if (!isNullOrUndefined(u.detail.metadata)) {
                                            for (let m of u.detail.metadata) {
                                                if (m.error) {
                                                    warnings.push({
                                                        id: u.id,
                                                        type: `${u.action
                                                            .substring(0, 1)
                                                            .toUpperCase()}${u.action.substring(
                                                            1
                                                        )}IndicatorMetadataError`,
                                                        messages: [...m.messages],
                                                        results: [...m.results]
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                                if (warnings.length > 0) {
                                    const ups = updateResults.results.length,
                                        skips = updateActions.length - ups,
                                        warns = warnings.length;
                                    if (console.group !== undefined)
                                        console.group('Catalog update errors and warnings');
                                    console.log(updateResults.failures);
                                    if (console.groupEnd !== undefined) console.groupEnd();
                                    this.setState({
                                        error: {
                                            type: 'warning',
                                            message: (
                                                <FormattedMessage
                                                    id="manager.taskHasDetailedErrorsWarningDialog.message"
                                                    defaultMessage="{ups} records were updated in your catalog, but {warns} warnings were generated during the update. You should carefully review the status of your catalog - use the {inspector} tool. Details of the errors can be found in the developer console (F12) - contact {email} for more help with this."
                                                    values={{
                                                        warns: <strong>{warns}</strong>,
                                                        commits: <strong>{ups}</strong>,
                                                        discards: <strong>{skips}</strong>,
                                                        inspector: (
                                                            <Link to="/inspector">
                                                                <FormattedMessage
                                                                    id="manager.inspectorLink.text"
                                                                    defaultMessage="Inspector"
                                                                />
                                                            </Link>
                                                        ),
                                                        email: (
                                                            <a href="mailto:support@instantatlas.com">
                                                                support@instantatlas.com
                                                            </a>
                                                        )
                                                    }}
                                                />
                                            )
                                        },
                                        status: PageActivityStatus.INACTIVE
                                    });
                                }
                            }
                            catalog.init(undefined, false, undefined, !disableParallel).then(() => {
                                this.rebindModelFromCatalog(catalog, this.state.geo); // Harsh - may be able to refactor to more subtle update...
                            });
                        },
                        updateActionsMessages
                    );
                });
            }
        );
    };

    handleTreeDragAndDrop = (dropTarget, droppedItem) => {
        if (isNullOrUndefined(dropTarget) || isNullOrUndefined(droppedItem)) return;
        const { catalog, geo, model } = this.state;
        // Should we warn them???
        if (dropTarget.itemType === 'Theme') {
            this.setState(
                {
                    status: PageActivityStatus.PROCESSING_CORE
                },
                () => {
                    let dropAllowed = true;
                    // Double check... can't drag a Theme into its child or descendant...
                    if (droppedItem.itemType === 'Theme') {
                        const dropPath = dropTarget.id === null ? [] : model.getPathToTheme(dropTarget.id);
                        dropAllowed =
                            dropTarget.id === null || dropPath.find((t) => t.id === droppedItem.id) === undefined;
                        if (!dropAllowed) {
                            this.setState({
                                status: PageActivityStatus.INACTIVE,
                                error: {
                                    message: (
                                        <FormattedMessage
                                            id="manager.noDragOfThemeIntoChild.messageFormat"
                                            defaultMessage="You cannot move theme {item} into theme {target} because {target} is a child (or grandchild, or great-grandchild etc.) of {item}."
                                            values={{
                                                target: <strong>{dropTarget.name}</strong>,
                                                item: <strong>{droppedItem.name}</strong>
                                            }}
                                        />
                                    ),
                                    key: 'ThemeDragCancelIntoChild'
                                }
                            });
                            return;
                        }
                    }
                    if (dropAllowed) {
                        // Easy - move then do a full refresh...
                        let q = `Item_Type='${droppedItem.itemType}' AND ID='${droppedItem.id}'`;
                        if (!isNullOrUndefined(droppedItem.parentTheme))
                            q += ` AND Theme_ID='${droppedItem.parentTheme}'`;
                        // Deal with theme move/order rather than move _into_, with the special case of "not null" for the root...
                        if (
                            droppedItem.itemType === 'Theme' &&
                            dropTarget.action === 'order' &&
                            dropTarget.id !== null
                        ) {
                            this.reorderItemsWithinParent(dropTarget, droppedItem);
                        }
                        // Normal 'move' - changing the parent of something
                        else if (dropTarget.dropEffect !== 'copy') {
                            // === 'move')
                            if (droppedItem.itemType === 'Indicator') {
                                // Indicators need a double step - handled elsewhere, of updating metadata...
                                this.commitIndicatorChanges([
                                    {
                                        action: 'move',
                                        id: droppedItem.id,
                                        value: dropTarget.id,
                                        theme: dropTarget.id,
                                        values: {
                                            ...droppedItem
                                        }
                                    }
                                ]);
                            } else {
                                catalog.updateItems(q, { Theme_ID: dropTarget.id }).then((ue) => {
                                    this.rebindModelFromCatalog(catalog, geo); // Harsh - may be able to refactor to more subtle update...
                                });
                            }
                        }
                        // Special 'move' - a copy of an Indicator into a different Theme
                        else if (droppedItem.itemType === 'Indicator' && dropTarget.dropEffect === 'copy') {
                            this.copyIndicatorToTheme(droppedItem, dropTarget);
                        }
                    }
                }
            );
        } else if (dropTarget.itemType === 'Indicator' && droppedItem.itemType === 'Indicator') {
            if (dropTarget.parentTheme !== droppedItem.parentTheme) {
                const t = model.getTheme(dropTarget.parentTheme);
                this.setState({
                    status: PageActivityStatus.INACTIVE,
                    error: {
                        message: (
                            <FormattedMessage
                                id="manager.noDragOfIndicatorToNonSibling.messageFormat"
                                defaultMessage="You cannot move indicator {item} above/below indicator {target} because they are in different themes. Move {item} to theme {theme} first, then re-order them."
                                values={{
                                    target: <strong>{dropTarget.name}</strong>,
                                    item: <strong>{droppedItem.name}</strong>,
                                    theme: <strong>{t !== null ? t.name : dropTarget.parentTheme}</strong>
                                }}
                            />
                        ),
                        key: 'IndicatorDragCancelIntoNonSibling'
                    }
                });
                return;
            } else {
                // Re-order - this gets, err, interesting...
                this.setState(
                    {
                        status: PageActivityStatus.PROCESSING_CORE
                    },
                    () => {
                        this.reorderItemsWithinParent(dropTarget, droppedItem);
                    }
                );
            }
        }
    };

    copyIndicatorToTheme = (indicator = {}, theme = {}) => {
        const { catalog, geo, model } = this.state;
        catalog.queryMasterTable(`Item_Type='Indicator' AND ID='${indicator.id}'`, {}, true).then((indicatorSet) => {
            if (isNullOrUndefined(indicatorSet.error) && !isNullOrUndefined(indicatorSet.fields)) {
                const tif = indicatorSet.fields.find((f) => f.name.toLowerCase() === 'Theme_ID'.toLowerCase()).name,
                    clones = [];
                let alreadyThere = false;
                for (let i of indicatorSet.features) {
                    alreadyThere = alreadyThere || i.attributes[tif] === theme.id;
                    if (i.attributes[tif] === indicator.parentTheme) clones.push(i);
                }
                if (!alreadyThere && clones.length > 0) {
                    for (let i of clones) {
                        i.attributes[tif] = theme.id;
                    }
                    catalog.addItems(clones).then((ue) => {
                        const errors = ue.addResults !== undefined ? ue.addResults.filter((r) => !r.success) : [];
                        if (errors.length > 0) {
                            console.log('⚠️ Warning - errors occurred when linking indicator(s) - details below:');
                            console.log(errors);
                            this.setState({
                                status: PageActivityStatus.INACTIVE,
                                error: {
                                    type: 'warning',
                                    message: (
                                        <FormattedMessage
                                            id="manager.updatePartial.message"
                                            defaultMessage="At least {fails} of your {count} requests failed when updating indicators in your catalog. An error message has been logged to your browser console (press F12 to see this). Contact {email} for help on what to do with this information."
                                            values={{
                                                count: clones.length,
                                                fails: <strong>{errors.length}</strong>,
                                                catalog: <strong>{catalog}</strong>,
                                                email: (
                                                    <a href="mailto:support@instantatlas.com">
                                                        support@instantatlas.com
                                                    </a>
                                                )
                                            }}
                                        />
                                    )
                                }
                            });
                        }
                        this.rebindModelFromCatalog(catalog, geo); // Harsh - may be able to refactor to more subtle update...
                    });
                } else {
                    this.setState({
                        status: PageActivityStatus.INACTIVE,
                        error: {
                            message: (
                                <FormattedMessage
                                    id="manager.noDragOfIndicatorToDuplicate.messageFormat"
                                    defaultMessage="You cannot copy indicator {item} into theme {target} because it is already there."
                                    values={{
                                        target: <strong>{theme.name}</strong>,
                                        item: <strong>{indicator.name}</strong>
                                    }}
                                />
                            ),
                            key: 'IndicatorDragCancelIntoDuplicate'
                        }
                    });
                }
            }
        });
    };

    reorderItemsWithinParent = (dropTarget, droppedItem) => {
        const { catalog, geo, model } = this.state,
            dropPath = model.getPathToTheme(dropTarget.parentTheme),
            powerOf10 = dropTarget.itemType === 'Theme' ? 0 : 100,
            childQuery = !isNullOrUndefined(dropTarget.parentTheme)
                ? `Item_Type='${dropTarget.itemType}' AND Theme_ID='${dropTarget.parentTheme}'`
                : `Item_Type='${dropTarget.itemType}' AND Theme_ID IS NULL`,
            oidf = catalog.master.info.objectIdField || 'OBJECTID';
        catalog
            .queryMasterTable(
                childQuery,
                { f: 'json', outFields: `${oidf},ID,Item_Order`, orderByFields: 'Item_Order ASC' },
                true
            )
            .then((itemSet) => {
                if (itemSet.fields !== undefined) {
                    const idf = itemSet.fields.find((f) => f.name.toLowerCase() === 'id').name,
                        iof = itemSet.fields.find((f) => f.name.toLowerCase() === 'item_order').name,
                        distinctSet = [];
                    let min = Number.MAX_SAFE_INTEGER,
                        max = 0;
                    for (let f of itemSet.features) {
                        min = Math.min(min, f.attributes[iof]);
                        max = Math.max(max, f.attributes[iof]);
                        if (distinctSet.findIndex((i) => i.attributes[idf] === f.attributes[idf]) < 0)
                            distinctSet.push(f);
                    }
                    let current = distinctSet.findIndex((i) => i.attributes[idf] === droppedItem.id),
                        target = distinctSet.findIndex((i) => i.attributes[idf] === dropTarget.id);
                    // S(p)lice them around... in the distinct set
                    let swapper = distinctSet.splice(current, 1)[0];
                    distinctSet.splice(target - (target > current ? 1 : 0), 0, swapper); // - 1 because we took one out, and we always go "before"
                    // Update the ordering (uniques)
                    for (let f of distinctSet) {
                        f.attributes[iof] = powerOf10 + min; // Indicators 10^2 + offset, instances 10^3 + offset
                        min++;
                    }
                    // Apply unique ordering to full set (geo-specific)
                    for (let f of itemSet.features) {
                        f.attributes[iof] = distinctSet.find((i) => i.attributes[idf] === f.attributes[idf]).attributes[
                            iof
                        ];
                    }
                    catalog.updateFeatures(itemSet.features).then((ue) => {
                        let err = null;
                        if (ue.error !== undefined) {
                            console.log(ue.error); // DEBUG
                            err = {
                                message: (
                                    <FormattedMessage
                                        id="manager.unexpectedReorderError.messageFormat"
                                        defaultMessage="Unexpected error when updating {itemType} {name} - re-ordering cannot proceed. Please check the status of your catalog. The error message was: {msg}"
                                        values={{
                                            name: droppedItem.name,
                                            itemType: droppedItem.itemType,
                                            message: (
                                                <span>
                                                    <br />
                                                    <br />
                                                    <span className="bg-danger">{ue.error.message}</span>
                                                    <br />
                                                    <br />
                                                </span>
                                            )
                                        }}
                                    />
                                ),
                                key: 'UnexpectedReorderError'
                            };
                        }
                        this.setState(
                            {
                                status: PageActivityStatus.PROCESSING_CORE,
                                error: err
                            },
                            () => {
                                this.rebindModelFromCatalog(catalog, geo); // Harsh - may be able to refactor to more subtle update...
                            }
                        );
                    });
                } else {
                    this.setState({
                        status: PageActivityStatus.INACTIVE,
                        error: {
                            message: (
                                <FormattedMessage
                                    id="manager.noSiblingsInThemeError.messageFormat"
                                    defaultMessage="Unexpected error: no siblings were found for {itemType} {name} - re-ordering cannot proceed. Please check the status of your catalog."
                                    values={{
                                        name: droppedItem.name,
                                        itemType: droppedItem.itemType
                                    }}
                                />
                            ),
                            key: 'NoSiblingItemsFoundInTheme'
                        }
                    });
                }
            });
    };
}

const createNewThemeAction = (sourceUrl, themeId, catalog, token) => {
    return ArcGISPortal.queryFeatures(
        sourceUrl,
        `Item_Type = 'Theme' AND ID = '${themeId}'`,
        1,
        {
            f: 'json',
            outFields: 'ID,Name,Theme_ID,Item_Type,Item_Order',
            token
        },
        false
    ).then((themeSet) => {
        return catalog.addItems(
            themeSet.map((t) => {
                return {
                    attributes: {
                        ...t
                    }
                };
            })
        );
    });
};

const createIndicatorChangeActions = (changes, catalog, model = null) => {
    const updatePromiseChain = [];
    if (changes.length > 0) {
        const deletes = [],
            updates = [],
            additions = [];
        for (let c of changes) {
            if (c.action === 'delete') deletes.push(c.id);
            else if (c.action === 'add') additions.push(c);
            else if (c.action === 'rename' || c.action === 'reorder' || c.action === 'move') updates.push(c);
        }
        // Add and delete first, because renames might act on something brand new...
        if (additions.length > 0) {
            for (let a of additions) {
                updatePromiseChain.push(() => {
                    return catalog.addItems([{ attributes: a.value }]).then((updateDetails) => {
                        return updateDetails;
                    });
                });
            }
        }
        if (deletes.length > 0) {
            updatePromiseChain.push(() => {
                return catalog.deleteItems(deletes).then((updateDetails) => {
                    return updateDetails;
                });
            });
        }
        if (updates.length > 0) {
            for (let r of updates) {
                const catalogAttributes =
                    r.action === 'rename'
                        ? { Name: r.values.name, Short_Name: r.values.shortName, Data_Type: r.values.dataType }
                        : r.action === 'reorder'
                        ? { Item_Order: r.value }
                        : r.action === 'move'
                        ? { Theme_ID: r.value }
                        : {};
                updatePromiseChain.push(() => {
                    return catalog
                        .updateItems(`ID='${r.id}' AND Item_Type='Indicator'`, catalogAttributes)
                        .then((updateDetails) => {
                            return updateDetails;
                        });
                });
                // Rename - goes into metadata as well...
                if (r.action === 'rename' || r.action === 'move') {
                    const metaAttributes = { IndicatorID: r.id, Title: r.values.name };
                    if (r.theme !== null && model !== null) {
                        const tpath =
                            r.action === 'move' ? model.getPathToTheme(r.value) : model.getPathToTheme(r.theme);
                        if (tpath !== null) {
                            metaAttributes['ThemeIdTrail'] = tpath.map((e) => e.id).join(' ~ ');
                            metaAttributes['ThemeNameTrail'] = tpath.map((e) => e.name).join(' ~ ');
                        }
                    }
                    updatePromiseChain.push(() => {
                        return catalog.updateMetadataItems([r.id], metaAttributes).then((updateDetails) => {
                            return updateDetails;
                        });
                    });
                }
            }
        }
    }
    return updatePromiseChain;
};

// Enable access to redux store.
const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        treeState: state.treeState, // Allow the manager page to get involved in the tree state too...
        userOptions: state.userOptions,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState, // Standard, all options for page state
    setTreeState, // Allow the manager page to get involved in the tree state too...
    setUserOptions
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(ManagerPage)));
