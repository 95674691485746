import React, { Component } from "react";

class ModalDialog extends Component
{
    render() 
    {
        const { large, scroll, buttons, show, onClose, bodyClass, title, children } = this.props,
            inOut = (show ? 'show' : ''), 
            isLarge = (large ? 'modal-lg' : ''),
            isScroll = (scroll ? 'modal-body-scrollable' : ''),
            btns = (buttons !== undefined ? buttons : 
                [<button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">Close</button>]),
            btnSet = btns.map((b, index) =>
            {
                if ((b.type !== undefined) && (b.type === 'button'))
                {
                    return <span style={{ marginLeft: '0.2em' }} onClick={onClose} key={index}>{b}</span>;
                }
                else
                {
                    return <span style={{ marginLeft: '0.2em' }} key={index}>{b}</span>;
                }
            });
        return (!show ? null : (
            <div className={`modal ${inOut}`} tabIndex="-1" role="dialog">
                <div className={`modal-dialog ${isLarge}`} role="document">
                    <div className="modal-content">
                        {(title !== undefined) && (title !== null) ?
                            <div className="modal-header">
                                <button type="button" className="close" onClick={onClose} data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times"></i>
                                </button>
                                <h4 className="modal-title">{title}</h4>
                            </div> : null}
                        <div className={`modal-body ${isScroll} ${bodyClass}`.trim()}>
                            {children}
                        </div>
                        <div className="modal-footer">
                            {btnSet}
                        </div>
                    </div>
                </div>
            </div>
        ));
    }
}

export default ModalDialog;
