import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import { isNullOrUndefined } from 'utils/object';

class ChooseFieldsFromDropDownDialog extends PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            selectedIdField: null, 
            selectedNameField: null,
            itemType: 'Item'
        };
        this.onIdSelectionChange = this.onIdSelectionChange.bind(this);
        this.onNameSelectionChange = this.onNameSelectionChange.bind(this);
    }

    onIdSelectionChange(evt)
    {
        const itemId = evt.currentTarget.value;
        this.setState({
            selectedIdField: itemId
        });
    }

    onNameSelectionChange(evt)
    {
        const itemId = evt.currentTarget.value;
        this.setState({
            selectedNameField: itemId
        });
    }
    
    onChosenClick()
    {
        const { selectedIdField, selectedNameField } = this.state,
            { onChoose, params } = this.props,
            layer = params.layer,
            fields = layer.fields,
            customArgs = params.customArgs; // customArgs - allows pass through of data from somewhere else, easing re-use of a single dialog...
        if (onChoose !== undefined)
        {
            onChoose(
                (selectedIdField !== null ? fields.find(i => i.name === selectedIdField) : fields[0]),
                (selectedNameField !== null ? fields.find(i => i.name === selectedNameField) : fields[0]),
                layer,
                customArgs);
        }
    }

    render()
    {
        const { loading, selectedIdField, selectedNameField, itemType } = this.state,
            { title, icon, message, large, show, params, onClose } = this.props,
            layer = params.layer,
            fields = (!isNullOrUndefined(layer) ? layer.fields : []),
            listItems = (fields !== undefined ? fields.map((mi, index) =>
            {
                return (
                    <option id={`itemOption${mi.name}`} key={index} value={mi.name}>{mi.alias}</option>
                );
            }) : null),
            active = (selectedIdField !== null ? selectedIdField : ((fields !== undefined) && (fields.length > 0) ? fields[0].name : null)),
            selectors =
                <div className="form-horizontal">
                    <div className="row spaced">
                        <label className="control-label col-md-3" htmlFor="idFieldBox">
                            <FormattedMessage id="chooseFieldsDialog.idField.label" defaultMessage="CODE Field:" />
                        </label>
                        <div className="col-md-9">
                            <select id="idFieldBox" className="form-control" defaultValue={selectedIdField} onChange={this.onIdSelectionChange} data-field-key="id">
                                {listItems}
                            </select>
                        </div>
                    </div>
                    <div className="row spaced">
                        <label className="control-label col-md-3" htmlFor="nameFieldBox">
                            <FormattedMessage id="chooseFieldsDialog.nameField.label" defaultMessage="NAME Field:" />
                        </label>
                        <div className="col-md-9">
                            <select id="nameFieldBox" className="form-control" defaultValue={selectedNameField} onChange={this.onNameSelectionChange} data-field-key="name">
                                {listItems}
                            </select>
                        </div>
                    </div>
                </div>;
        
        return (!show ? null : <ModalDialog
            title={
                (title !== undefined ? title :
                    <FormattedMessage id="chooseFieldsDialog.title" defaultMessage="Choose CODE and NAME Fields..."
                        values={{
                            itemTypeName: itemType
                        }} />)
            }
            buttons={
                [
                    <button type="button" className="btn btn-default btn-primary" onClick={(e) => this.onChosenClick(e)} disabled={(active === null)}>
                        <FormattedMessage id="chooseFieldsDialog.button.choose" defaultMessage="{icon} Choose" values={{
                            icon: <i className="fas fa-check"></i>
                        }} />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage id="chooseFieldsDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                            icon: <i className="fas fa-times"></i>
                        }} />
                    </button>
                ]
            }
            show={show}
            large={large}
            scroll={true}
            onClose={onClose}>
            <div>
                {loading ?
                    <div className="text-center" style={{
                        padding: '30px', fontSize: '64px', minHeight: 'calc(100vh - 210px)'
                    }}>
                        <ProgressMessage message={''} />
                    </div>
                    :
                    ((icon !== undefined) ?
                        <div>
                            <div style={{ float: 'left', marginRight: '10px', marginBottom: 10 + 'px', fontSize: '48px' }}>{icon}</div>
                            <div style={{ marginLeft: '68px' }}>
                                {(message !== undefined) ? <p>{message}</p> : <FormattedMessage id="chooseFieldsDialog.messageFormat" defaultMessage="Your feature layer {layer} does not have obvious CODE and NAME fields that uniquely identify the features within the layer. Choose the correct fields from the drop-downs below, then click Choose."
                                    values={{
                                        layer: <a href={layer.url} target="iaoArcWindow"><strong>{(layer.title ? layer.title : layer.name)} <i className="fas fa-external-link-alt"></i></strong></a>
                                    }}
                                />}
                                {selectors}
                            </div>
                        </div>
                        :
                        <div>
                            {(message !== undefined) ? <p>{message}</p> : 
                                <FormattedMessage id="chooseFieldsDialog.messageFormat" defaultMessage="Your feature service {layer} does not have obvious CODE and NAME fields that uniquely identify the features within the layer. Choose the correct fields from the drop-downs below, then click Choose."
                                    values={{
                                        layer: <a href={layer.url} target="iaoArcWindow"><strong>{(layer.title ? layer.title : layer.name)} <i className="fas fa-external-link-alt"></i></strong></a>
                                    }}
                                />}
                            {selectors}
                        </div>
                    )
                }
            </div>
        </ModalDialog>);
    }
}

export default injectIntl(ChooseFieldsFromDropDownDialog, { withRef: true });