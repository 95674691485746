import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import { isNullOrUndefined } from 'utils/object';
import ListToggleButton from 'components/ListToggleButton';
import { getReactIntlHtmlFuncs } from 'utils/localization';
import MetadataDialog from './MetadataDialog';

const ChooseHostedIndicatorsToUpdateDialog = ({
    show = false,
    additions = [],
    updates = [],
    orphans = [],
    source,
    target,
    found,
    table,
    existing,
    onChoose,
    onClose,
    catalog
}) => {
    const [loading, setLoading] = useState(false),
        [sortOn, setSortOn] = useState('name'),
        [sortDir, setSortDir] = useState('asc'),
        [filter, setFilter] = useState(''),
        [rows, setRows] = useState(200),
        [sortedUpdates, setSortedUpdates] = useState([...updates]),
        [sortedAdditions, setSortedAdditions] = useState([...additions]),
        [sortedOrphans, setSortedOrphans] = useState([...orphans]),
        [checked, setChecked] = useState({}),
        [overwriteThemes, setOverwriteThemes] = useState(false),
        [fullLabels, setFullLabels] = useState(true),
        [tab, setTab] = useState('updates'),
        [activeModal, setActiveModal] = useState(''),
        [activeModalProps, setActiveModalProps] = useState({});

    const handleSortClick = (e) => {
        const onField = e.target.getAttribute('data-sort-on');
        if (sortOn === onField) setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
        else {
            setSortOn(onField);
            setSortDir('asc');
        }
    };

    useEffect(() => {
        setLoading(true);
        const sortedUpdates = !isNullOrUndefined(updates) ? getSortedBy(updates, sortOn, sortDir) : [],
            sortedExtras = !isNullOrUndefined(additions) ? getSortedBy(additions, sortOn, sortDir) : [],
            sortedLocals = !isNullOrUndefined(orphans) ? getSortedBy(orphans, sortOn, sortDir) : [];
        if (sortedUpdates.length > 0) setSortedUpdates(sortedUpdates);
        if (sortedExtras.length > 0) setSortedAdditions(sortedExtras);
        if (sortedLocals.length > 0) setSortedOrphans(sortedLocals);
        setLoading(false);
    }, [sortOn, sortDir, updates, additions, orphans]);

    const handleFilterClick = (themeId, e) => {
        setFilter(themeId);
    };

    const handleLabelClick = () => {
        setFullLabels(!fullLabels);
    };

    const handleToggleThemeOverwriteClick = () => {
        setOverwriteThemes(!overwriteThemes);
    };

    const handleIndicatorClick = (e) => {
        const indicatorId = e.target.value;
        let indicator = additions.find((i) => i.id === indicatorId);
        if (indicator === undefined) indicator = updates.find((i) => i.id === indicatorId);
        if (indicator === undefined) indicator = orphans.find((i) => i.id === indicatorId);
        if (indicator !== undefined) {
            checked[indicatorId] = !checked[indicatorId];
            setChecked({ ...checked });
        }
    };

    const handleIndicatorMetadataClick = (e) => {
        const indicatorId = e.currentTarget.value;
        let indicator = additions.find((i) => i.id === indicatorId);
        if (indicator === undefined) indicator = updates.find((i) => i.id === indicatorId);
        if (indicator === undefined) indicator = orphans.find((i) => i.id === indicatorId);
        if (indicator !== undefined) {
            setActiveModalProps({
                indicator
            });
            setActiveModal('metadataDialog');
        }
    };

    const loadMoreRows = () => {
        setRows((r) => r + 200);
    };

    const setSelectionState = (onOrOff, target = 'updates', maxRecordCount = 99999) => {
        const noFilter = filter === undefined || filter === null || filter === '';
        if (target === 'extras') {
            let at = 0;
            for (let indicator of getSortedBy(
                noFilter ? additions : additions.filter((ind) => ind.path === filter),
                sortOn,
                sortDir
            )) {
                checked[indicator.id] = at < maxRecordCount ? onOrOff : !onOrOff;
                at++;
            }
        }
        if (target === 'updates') {
            let at = 0;
            for (let indicator of getSortedBy(
                noFilter ? updates : updates.filter((ind) => ind.path === filter),
                sortOn,
                sortDir
            )) {
                checked[indicator.id] = at < maxRecordCount ? onOrOff : !onOrOff;
                at++;
            }
        }
        if (target === 'orphans') {
            let at = 0;
            for (let indicator of getSortedBy(
                noFilter ? orphans : orphans.filter((ind) => ind.path === filter),
                sortOn,
                sortDir
            )) {
                checked[indicator.id] = at < maxRecordCount ? onOrOff : !onOrOff;
                at++;
            }
        }
        setChecked({ ...checked });
    };

    const handleClose = () => {
        const activeAdditions = additions.filter((a) => checked[a.id] === true),
            activeUpdates = updates.filter((u) => checked[u.id] === true),
            activeDeletes = orphans.filter((u) => checked[u.id] === true);
        if (onChoose)
            onChoose(activeAdditions, activeUpdates, activeDeletes, target, source, existing, overwriteThemes);
    };

    if (!show) return null; // bail early...
    const uCount = Math.min(rows, !isNullOrUndefined(sortedUpdates) ? sortedUpdates.length : 0),
        eCount = Math.min(rows, !isNullOrUndefined(sortedAdditions) ? sortedAdditions.length : 0),
        lCount = Math.min(rows, !isNullOrUndefined(sortedOrphans) ? sortedOrphans.length : 0),
        noFilter = filter === undefined || filter === undefined || filter === null || filter === '',
        themeSet = new Set(
            (!isNullOrUndefined(sortedAdditions) && tab === 'extras' ? sortedAdditions : [])
                .concat(!isNullOrUndefined(sortedUpdates) && tab === 'updates' ? sortedUpdates : [])
                .concat(!isNullOrUndefined(sortedOrphans) && tab === 'orphans' ? sortedOrphans : [])
                .map((i) => {
                    return i.path !== undefined && i.path !== null ? `${i.themeTrail};${i.path}` : '';
                })
        ),
        themes = Array.from(themeSet)
            .sort()
            .filter((t) => t !== '')
            .map((t) => {
                return (
                    <li key={t}>
                        <button
                            className="btn btn-block btn-link"
                            onClick={(e) => {
                                handleFilterClick(t.split(';')[1], e);
                            }}
                        >
                            {t.split(';')[0].replace(/[~]/g, '/')}
                        </button>
                    </li>
                );
            }),
        updateRows = !isNullOrUndefined(sortedUpdates)
            ? sortedUpdates
                  .filter((i) => {
                      return noFilter || i.path === filter;
                  })
                  .slice(0, uCount)
                  .map((i, index) => {
                      const local = existing.find((li) => li['ID'] === i.id),
                          changedTheme = local !== undefined && local['Theme_ID'] !== i.path.split('~').pop().trim();
                      //if (changedTheme) console.log(i); // DEBUG
                      return (
                          <HostedIndicatorSelectionRow
                              key={index}
                              indicator={i}
                              onIndicatorClick={handleIndicatorClick}
                              checked={checked[i.id] === true}
                              moved={changedTheme}
                              showMetadata={handleIndicatorMetadataClick}
                          />
                      );
                  })
            : [],
        extraRows = !isNullOrUndefined(sortedAdditions)
            ? sortedAdditions
                  .filter((i) => {
                      return noFilter || i.path === filter;
                  })
                  .slice(0, eCount)
                  .map((i, index) => {
                      return (
                          <HostedIndicatorSelectionRow
                              key={index}
                              indicator={i}
                              onIndicatorClick={handleIndicatorClick}
                              checked={checked[i.id] === true}
                              showMetadata={handleIndicatorMetadataClick}
                          />
                      );
                  })
            : [],
        orphanIndicatorRows = !isNullOrUndefined(sortedOrphans)
            ? sortedOrphans
                  .filter((i) => {
                      return noFilter || i.path === filter;
                  })
                  .slice(0, lCount)
                  .map((i, index) => {
                      return (
                          <HostedIndicatorSelectionRow
                              key={index}
                              indicator={i}
                              onIndicatorClick={handleIndicatorClick}
                              checked={checked[i.id] === true}
                              showUrl={true}
                              showMetadata={handleIndicatorMetadataClick}
                          />
                      );
                  })
            : [],
        changesAvailable =
            (!isNullOrUndefined(sortedAdditions) ? sortedAdditions.length : 0) +
            (!isNullOrUndefined(sortedUpdates) ? sortedUpdates.length : 0) +
            (!isNullOrUndefined(sortedOrphans) ? sortedOrphans.length : 0),
        changesRequested = changesAvailable && Object.entries(checked).filter(([k, v]) => v === true).length > 0,
        sortMarker =
            sortDir === 'asc' ? <i className="fas fa-fw fa-caret-up"></i> : <i className="fas fa-fw fa-caret-down"></i>,
        moreMarker = (
            <FormattedMessage
                id="manager.chooseHostedIndicatorsDialog.button.more"
                defaultMessage="{icon} more... {icon}"
                values={{
                    icon: <i className="fas fa-caret-down"></i>
                }}
            />
        ),
        themeFilterButton =
            tab === 'orphansx' ? null : (
                <ListToggleButton
                    text={<i className="fas fa-filter"></i>}
                    element="span"
                    className="btn btn-link pure-tip pure-tip-top"
                    tooltip="Filter"
                >
                    <ul className="dropdown-menu dropdown-menu-right">
                        {!noFilter && (
                            <li>
                                <button
                                    className="btn btn-block btn-link"
                                    onClick={(e) => {
                                        handleFilterClick('', e);
                                    }}
                                >
                                    <span>Clear Filter </span>
                                    <i className="fas fa-times fa-sm"></i>
                                </button>
                            </li>
                        )}
                        {themes}
                    </ul>
                </ListToggleButton>
            );
    if (uCount < sortedUpdates.length)
        updateRows.push(
            <tr key="moreRowsRow1">
                <td colSpan="3" style={{ textAlign: 'center' }}>
                    <button className="btn btn-link" onClick={loadMoreRows}>
                        {moreMarker}
                    </button>
                </td>
            </tr>
        );
    if (eCount < additions.length)
        extraRows.push(
            <tr key="moreRowsRow2">
                <td colSpan="3" style={{ textAlign: 'center' }}>
                    <button className="btn btn-link" onClick={loadMoreRows}>
                        {moreMarker}
                    </button>
                </td>
            </tr>
        );
    if (lCount < orphans.length)
        orphanIndicatorRows.push(
            <tr key="moreRowsRow2">
                <td colSpan="4" style={{ textAlign: 'center' }}>
                    <button className="btn btn-link" onClick={loadMoreRows}>
                        {moreMarker}
                    </button>
                </td>
            </tr>
        );

    const tableHeaders = (
        <thead>
            <tr>
                <th>
                    <span className="small">
                        <button className="btn btn-link" onClick={(e) => setSelectionState(true, tab)}>
                            <FormattedMessage
                                id="manager.chooseHostedIndicatorsDialog.selectAll"
                                defaultMessage="select all"
                            />
                        </button>
                        <span> | </span>
                        <button className="btn btn-link" onClick={(e) => setSelectionState(true, tab, 100)}>
                            <FormattedMessage
                                id="manager.chooseHostedIndicatorsDialog.selectFirst100"
                                defaultMessage="select first 100"
                            />
                        </button>
                        <span> | </span>
                        <button className="btn btn-link" onClick={(e) => setSelectionState(false, tab)}>
                            <FormattedMessage
                                id="manager.chooseHostedIndicatorsDialog.selectNone"
                                defaultMessage="select none"
                            />
                        </button>
                    </span>
                </th>
                <th colSpan="2"> </th>
            </tr>
            <tr>
                <th className="sortable-header" data-sort-on="name" onClick={handleSortClick}>
                    <FormattedMessage
                        id="manager.chooseHostedIndicatorsDialog.column.indicator"
                        defaultMessage="Indicator"
                    />
                    {sortOn === 'name' ? sortMarker : null}
                </th>
                <th className="sortable-header" data-sort-on="date" onClick={handleSortClick}>
                    <FormattedMessage
                        id="manager.chooseHostedIndicatorsDialog.column.updated"
                        defaultMessage="Updated"
                    />
                    {sortOn === 'date' ? sortMarker : null}
                </th>
                <th className="sortable-header" data-sort-on="theme" onClick={handleSortClick}>
                    <FormattedMessage id="manager.chooseHostedIndicatorsDialog.column.theme" defaultMessage="Theme" />
                    {sortOn === 'theme' ? sortMarker : null}
                    {themeFilterButton}
                </th>
                {tab === 'orphans' && (
                    <th className="sortable-header" data-sort-on="url" onClick={handleSortClick}>
                        <FormattedMessage
                            id="manager.chooseHostedIndicatorsDialog.column.url"
                            defaultMessage="Service URL"
                        />
                        {sortOn === 'url' ? sortMarker : null}
                    </th>
                )}
            </tr>
        </thead>
    );

    return (
        <>
            <ModalDialog
                title={
                    <FormattedMessage
                        id="manager.chooseHostedIndicatorsDialog.title"
                        defaultMessage="InstantAtlas Data Services | Choose Indicators to Update"
                        values={{
                            catalog: target.name
                        }}
                    />
                }
                buttons={[
                    tab === 'updates' ? (
                        <div className="pull-left" style={{ margin: '5px 15px' }}>
                            <input
                                onChange={handleToggleThemeOverwriteClick}
                                type="checkbox"
                                value="true"
                                checked={overwriteThemes}
                                id="themeOverwriteBox"
                                className="form-control date-field-box"
                            />
                            <label htmlFor="themeOverwriteBox" className="control-label">
                                <span
                                    className="pure-tip pure-tip-top"
                                    data-tooltip="Override indicator/theme membership with values from the hosted service"
                                >
                                    Override Themes?
                                </span>
                            </label>
                        </div>
                    ) : null,
                    <button
                        type="button"
                        className="btn btn-default btn-primary"
                        onClick={(e) => handleClose(e)}
                        disabled={changesAvailable < 1 || !changesRequested}
                    >
                        <FormattedMessage
                            id="manager.chooseHostedIndicatorsDialog.button.import"
                            defaultMessage="{icon} Import & Update"
                            values={{
                                icon: <i className="fas fa-download fa-rotate-270"></i>
                            }}
                        />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage
                            id="manager.chooseHostedIndicatorsDialog.button.cancel"
                            defaultMessage="{icon} Cancel"
                            values={{
                                icon: <i className="fas fa-times"></i>
                            }}
                        />
                    </button>
                ].filter((i) => i !== null)}
                show={show}
                large={true}
                scroll={true}
                onClose={onClose}
            >
                {loading ? (
                    <ProgressMessage message="" />
                ) : (
                    <div>
                        <div style={{ float: 'left', marginRight: '10px', marginBottom: '10px' }}>
                            <i className="fas fa-history fa-flip-horizontal" style={{ fontSize: '48px' }}></i>
                        </div>
                        {updates !== undefined && changesAvailable > 0 ? (
                            <div style={{ marginLeft: 68 + 'px' }}>
                                <div className="i-after">
                                    <p>
                                        <FormattedMessage
                                            id="manager.chooseHostedIndicatorsDialog.messageFormat"
                                            defaultMessage="There are {changesCount} indicator changes between {link} and your catalog <em>{catalog}</em>. These are shown in the tables below. Choose the data you wish to update, add, or remove, then click {icon} <em>Import & Update</em> to copy those data into your data catalog. {matchCount} indicators are up to date."
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                catalog:
                                                    catalog.master.item !== undefined
                                                        ? catalog.master.item.title
                                                        : catalog.master.info.name,
                                                changesCount: (
                                                    <strong>{new Intl.NumberFormat().format(changesAvailable)}</strong>
                                                ),
                                                updateCount:
                                                    updates.length > 0 ? (
                                                        <a href="#iaModalDialogHosted_Updates">
                                                            <strong>
                                                                {new Intl.NumberFormat().format(updates.length)}
                                                            </strong>
                                                        </a>
                                                    ) : (
                                                        <span>{updates.length}</span>
                                                    ),
                                                newCount:
                                                    additions.length > 0 ? (
                                                        <a href="#iaModalDialogHosted_Extras">
                                                            <strong>
                                                                {new Intl.NumberFormat().format(additions.length)}
                                                            </strong>
                                                        </a>
                                                    ) : (
                                                        <span>{additions.length}</span>
                                                    ),
                                                matchCount: <span>{new Intl.NumberFormat().format(found.length)}</span>,
                                                link: (
                                                    <a
                                                        href={source.catalog.url}
                                                        target="iaoArcWindow"
                                                        style={{ wordBreak: 'break-all' }}
                                                    >
                                                        {source.catalog.info !== undefined
                                                            ? source.catalog.info.name
                                                            : source.catalog.url}{' '}
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                ),
                                                icon: <i className="fas fa-download fa-rotate-270"></i>
                                            }}
                                        />
                                    </p>
                                    <p className="bg-danger pad10">
                                        <FormattedMessage
                                            id="manager.chooseHostedIndicatorsDialog.warningMessage"
                                            defaultMessage="{warningIcon} Note that the import process <strong>will significantly alter your data catalog</strong> - you should create a {backupIcon} <em>Backup</em> first before you import data - you can always come back to this screen by using the {updatesIcon} <em>Check for Updates</em> button."
                                            values={{
                                                ...getReactIntlHtmlFuncs(),
                                                backupIcon: <i className="fas fa-archive"></i>,
                                                updatesIcon: <i className="fas fa-history fa-flip-horizontal"></i>,
                                                warningIcon: <i className="fas fa-exclamation-triangle"></i>
                                            }}
                                        />
                                    </p>
                                </div>
                                <div>
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li
                                            role="presentation"
                                            className={`tab-item ${tab === 'updates' ? 'active' : ''}`}
                                        >
                                            <a
                                                href="#iaModalDialogHosted_Updates"
                                                onClick={(e) => {
                                                    setTab('updates');
                                                    e.preventDefault();
                                                }}
                                            >
                                                <FormattedMessage
                                                    id="chooseHostedIndicatorsToUpdateDialog.tab.updates"
                                                    defaultMessage="{icon} Updated {count}"
                                                    values={{
                                                        icon: <i className="fas fa-calendar-check"></i>,
                                                        count: `(${new Intl.NumberFormat().format(updates.length)})`
                                                    }}
                                                />
                                            </a>
                                        </li>
                                        <li
                                            role="presentation"
                                            className={`tab-item ${tab === 'extras' ? 'active' : ''}`}
                                        >
                                            <a
                                                href="#iaModalDialogHosted_Extras"
                                                onClick={(e) => {
                                                    setTab('extras');
                                                    e.preventDefault();
                                                }}
                                            >
                                                <FormattedMessage
                                                    id="chooseHostedIndicatorsToUpdateDialog.tab.additions"
                                                    defaultMessage="{icon} New {count}"
                                                    values={{
                                                        icon: <i className="fas fa-calendar-plus"></i>,
                                                        count: `(${new Intl.NumberFormat().format(additions.length)})`
                                                    }}
                                                />
                                            </a>
                                        </li>
                                        <li
                                            role="presentation"
                                            className={`tab-item ${tab === 'orphans' ? 'active' : ''}`}
                                        >
                                            <a
                                                href="#iaModalDialogHosted_Orphans"
                                                onClick={(e) => {
                                                    setTab('orphans');
                                                    e.preventDefault();
                                                }}
                                            >
                                                <FormattedMessage
                                                    id="chooseHostedIndicatorsToUpdateDialog.tab.orphans"
                                                    defaultMessage="{icon} Removed {count}"
                                                    values={{
                                                        icon: <i className="fas fa-calendar-times"></i>,
                                                        count: `(${new Intl.NumberFormat().format(orphans.length)})`
                                                    }}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                    <div className={`dates-table form-inline indicator-selector`.trim()}>
                                        <p></p>
                                        {sortedUpdates.length > 0 && tab === 'updates' ? (
                                            <>
                                                <p className="pad10">
                                                    <FormattedMessage
                                                        id="chooseHostedIndicatorsToUpdateDialog.tab.updates.description"
                                                        defaultMessage="<strong>These indicators have more recent data in <em>{source}</em> than in your catalog</strong>. Select those indicators you wish to update."
                                                        values={{
                                                            ...getReactIntlHtmlFuncs(),
                                                            count: new Intl.NumberFormat().format(updates.length),
                                                            source:
                                                                source.catalog.info !== undefined
                                                                    ? source.catalog.info.name
                                                                    : source.catalog.url
                                                        }}
                                                    />
                                                </p>
                                                <table id="iaModalDialogHosted_Updates" className="table table-striped">
                                                    {tableHeaders}
                                                    <tbody>{updateRows}</tbody>
                                                </table>
                                            </>
                                        ) : null}
                                        {sortedAdditions.length > 0 && tab === 'extras' ? (
                                            <>
                                                <p className="pad10">
                                                    <FormattedMessage
                                                        id="chooseHostedIndicatorsToUpdateDialog.tab.additions.description"
                                                        defaultMessage="<strong>These indicators are available in <em>{source}</em> but not in your catalog</strong>. Select the indicators you wish to add to your catalog."
                                                        values={{
                                                            ...getReactIntlHtmlFuncs(),
                                                            count: new Intl.NumberFormat().format(additions.length),
                                                            source:
                                                                source.catalog.info !== undefined
                                                                    ? source.catalog.info.name
                                                                    : source.catalog.url
                                                        }}
                                                    />
                                                </p>
                                                <table id="iaModalDialogHosted_Extras" className="table table-striped">
                                                    {tableHeaders}
                                                    <tbody>{extraRows}</tbody>
                                                </table>
                                            </>
                                        ) : null}
                                        {sortedOrphans.length > 0 && tab === 'orphans' ? (
                                            <>
                                                <p className="pad10">
                                                    <FormattedMessage
                                                        id="chooseHostedIndicatorsToUpdateDialog.tab.orphans.description"
                                                        defaultMessage="<strong>These indicators are listed in your catalog but are not in <em>{source}</em></strong>. Only indicators hosted by the same organisation as <em>{source}</em> are shown in this list and these have probably been replaced by newer indicators. Select the indicators you wish to remove from your catalog but <strong>{warning} be careful</strong> not to remove indicators you have added or that have been added to the hosted data service specifically for your organisation."
                                                        values={{
                                                            ...getReactIntlHtmlFuncs(),
                                                            count: new Intl.NumberFormat().format(orphans.length),
                                                            source:
                                                                source.catalog.info !== undefined
                                                                    ? source.catalog.info.name
                                                                    : source.catalog.url,
                                                            warning: (
                                                                <i className="fas fa-fw fa-exclamation-triangle"></i>
                                                            ),
                                                            org: source.org
                                                        }}
                                                    />
                                                </p>
                                                <table id="iaModalDialogHosted_Orphans" className="table table-striped">
                                                    {tableHeaders}
                                                    <tbody>{orphanIndicatorRows}</tbody>
                                                </table>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{ marginLeft: 68 + 'px' }}>
                                <div className="i-after">
                                    <p>
                                        <FormattedMessage
                                            id="manager.chooseHostedIndicatorsDialog.noUpdates.messageFormat"
                                            defaultMessage="All of the {total} indicators in {target} are up-to-date."
                                            values={{
                                                total: <strong>{found.length}</strong>,
                                                target:
                                                    table !== undefined ? (
                                                        <a
                                                            href={table.url}
                                                            target="iaoArcWindow"
                                                            style={{ wordBreak: 'break-all' }}
                                                        >
                                                            {table.title} <i className="fas fa-external-link-alt"></i>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            href={target.catalog.url}
                                                            target="iaoArcWindow"
                                                            style={{ wordBreak: 'break-all' }}
                                                        >
                                                            {target.catalog.info !== undefined
                                                                ? target.catalog.info.name
                                                                : target.catalog.url}{' '}
                                                            <i className="fas fa-external-link-alt"></i>
                                                        </a>
                                                    ),
                                                source: (
                                                    <a
                                                        href={source.catalog.url}
                                                        target="iaoArcWindow"
                                                        style={{ wordBreak: 'break-all' }}
                                                    >
                                                        {source.catalog.info !== undefined
                                                            ? source.catalog.info.name
                                                            : source.catalog.url}{' '}
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                ),
                                                icon: <i className="fas fa-file-import"></i>
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </ModalDialog>
            {activeModal === 'metadataDialog' && (
                <MetadataDialog
                    show={true}
                    editable={false}
                    item={{ ...activeModalProps }}
                    catalog={catalog}
                    onClose={() => setActiveModal('')}
                    overrideMetadataUrl={true}
                    title={
                        <FormattedMessage
                            id="manager.chooseHostedIndicatorsDialog.metadataDialog.indicator.title"
                            defaultMessage="{indicator} | Data Service Metadata"
                            values={{
                                indicator: activeModalProps.indicator.name
                            }}
                        />
                    }
                />
            )}
        </>
    );
};

const getSortedBy = (srcItems, on, dir) => {
    const items = [...srcItems],
        m = dir === 'desc' ? -1 : 1;
    if (on === 'name') {
        items.sort((a, b) => {
            return m * a.name.localeCompare(b.name);
        });
    } else if (on === 'theme') {
        items.sort((a, b) => {
            return m * (a.theme !== undefined && b.theme !== undefined ? a.theme.localeCompare(b.theme) : 0);
        });
    } else if (on === 'date') {
        items.sort((a, b) => {
            const ad = a.updated || new Date(),
                bd = b.updated || new Date();
            return m * (ad.getTime() - bd.getTime());
        });
    } else if (on === 'url') {
        items.sort((a, b) => {
            const ad = a.url || '?',
                bd = b.url || '?';
            return m * ad.localeCompare(bd);
        });
    }
    return items;
};

const HostedIndicatorSelectionRow = ({
    indicator,
    onIndicatorClick,
    checked = false,
    moved = false,
    showUrl = false,
    showMetadata
}) => {
    return (
        <tr id={`row${indicator.id}`} data-indicator-id={indicator.id}>
            <td className="indicator-name">
                <input
                    onChange={onIndicatorClick}
                    type="checkbox"
                    value={indicator.id}
                    checked={checked}
                    id={`indImpBox_${indicator.id}`}
                    className="form-control indicator-box"
                />
                <label htmlFor={`indImpBox_${indicator.id}`} className="control-label i-name">
                    {indicator.name}
                </label>
                {showMetadata !== undefined && typeof showMetadata === 'function' && (
                    <button onClick={showMetadata} className="btn btn-link" value={indicator.id}>
                        <i className="fas fa-fw fa-info-circle"></i>
                    </button>
                )}
            </td>
            <td>
                {indicator.current !== undefined &&
                indicator.updated !== undefined &&
                indicator.current !== null &&
                indicator.updated !== null ? (
                    <span
                        className="pure-tip pure-tip-bottom"
                        data-tooltip={
                            indicator.current !== undefined
                                ? `Current: ${indicator.current
                                      .toISOString()
                                      .substring(0, 10)}, Latest: ${indicator.updated.toISOString().substring(0, 10)}`
                                : `Latest: ${indicator.updated.toISOString().substring(0, 10)}`
                        }
                    >
                        {indicator.updated
                            .toISOString()
                            .substring(0, 10)
                            .replace('T', ' ')
                            .replace('00:00:00', '')
                            .trim()}
                    </span>
                ) : indicator.current !== undefined && indicator.current !== null ? (
                    <em>
                        {indicator.current
                            .toISOString()
                            .substring(0, 10)
                            .replace('T', ' ')
                            .replace('00:00:00', '')
                            .trim()}
                    </em>
                ) : indicator.updated !== undefined && indicator.updated !== null ? (
                    <>
                        {indicator.updated
                            .toISOString()
                            .substring(0, 10)
                            .replace('T', ' ')
                            .replace('00:00:00', '')
                            .trim()}
                    </>
                ) : (
                    <span>-</span>
                )}
            </td>
            <td>
                <span
                    className="pure-tip pure-tip-bottom"
                    data-tooltip={
                        indicator.themeTrail !== undefined && indicator.themeTrail !== null
                            ? indicator.themeTrail.replace(/~/g, '/')
                            : ''
                    }
                >
                    {indicator.theme || '-'}
                </span>
                {moved && (
                    <span
                        className="pure-tip pure-tip-bottom"
                        data-tooltip="Hosted theme - different from this catalog"
                    >
                        &nbsp;<i className="fas fa-asterisk fa-xs"></i>
                    </span>
                )}
            </td>
            {showUrl && (
                <td>
                    <span className="indicator-url">{indicator.url || '-'}</span>
                </td>
            )}
        </tr>
    );
};

export default ChooseHostedIndicatorsToUpdateDialog;
