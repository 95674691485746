import React from 'react';

export const getReactIntlHtmlFuncs = () => {
    //const ks = `k${(new Date()).getTime().toFixed(0)}${Math.round(Math.random() * 10000.0).toString(16)}`;
    return {
        h1: (children) => <h1 key={`h${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</h1>,
        h2: (children) => <h2 key={`h${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</h2>,
        h3: (children) => <h3 key={`h${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</h3>,
        h4: (children) => <h4 key={`h${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</h4>,
        h5: (children) => <h5 key={`h${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</h5>,
        p: (children) => <p key={`p${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</p>,
        div: (children) => <div key={`p${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</div>,
        a: (children) => {
            const tokens = children.toString().split(';'),
                txt = tokens.pop(),
                href = tokens.shift(),
                tgt = tokens.length > 0 ? tokens.shift() : '_self';
            return (
                <a href={href} target={tgt} key={`a${Math.round(Math.random() * 10000).toFixed(0)}`}>
                    {txt}
                </a>
            );
        },
        b: (children) => <strong key={`b${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</strong>,
        i: (children) => <em key={`i${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</em>,
        strong: (children) => <strong key={`st${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</strong>,
        em: (children) => <em key={`em${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</em>,
        span: (children) => <span key={`s${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</span>,
        ul: (children) => <ul key={`ul${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</ul>,
        li: (children) => <li key={`li${Math.round(Math.random() * 10000).toFixed(0)}`}>{children}</li>,
        err: (children) => (
            <span className="error-message" key={`err${Math.round(Math.random() * 10000).toFixed(0)}`}>
                {children}
            </span>
        ),
        warn: (children) => (
            <span className="warning-message" key={`warn${Math.round(Math.random() * 10000).toFixed(0)}`}>
                {children}
            </span>
        ),
        info: (children) => (
            <span className="info-message" key={`info${Math.round(Math.random() * 10000).toFixed(0)}`}>
                {children}
            </span>
        ),
        icon: (children) => <i className={`${children}`}></i>
    };
};

const makeHash = (s) => {
    let hash = 0,
        i,
        char;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
        char = s.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
