import React, { Component } from 'react';
import { setPageState } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { isNullOrUndefined } from 'utils/object';

class StaticContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            __html: '',
            empty: false
        };
    }

    componentWillMount() {
        const { match, location, intl } = this.props,
            rootPath = (process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/').replace(/(.*)\/$/, '$1'),
            resourcePath = location.pathname.replace(match.path, '');

        // fetch the HTML fragment with a local API request
        fetch(`${rootPath}/static/help/${intl.locale}/${resourcePath}.html`)
            .then((resp) => {
                // fetch returns a readable stream, so translate it into stringified HTML
                return resp.status === 200 ? resp.text() : null;
            })
            .then((content) => {
                // dangerouslySetInnerHTML requires using an object with an `__html` key
                const valid =
                    content !== undefined && content !== null && content !== '' && content.indexOf('<body') < 0;
                this.setState({
                    __html: valid
                        ? content
                        : `<div class="spaced">Page not available for key <code>${match.params.id}</code>.</div>`,
                    empty: !valid
                });
            })
            .catch((err) => {
                this.setState({
                    __html: `<div class="spaced">Page not available for key <code>${match.params.id}</code>.</div>`,
                    empty: true
                });
            });
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Help', this.getTitleIcon(), null, null);
    }

    getTitleIcon() {
        return (
            <span className="small ia-page-icon">
                <i className="fas fa-question-circle"></i>
            </span>
        );
    }

    componentDidUpdate() {
        const { redirectIfEmpty } = this.props,
            { empty } = this.state;
        if (!isNullOrUndefined(redirectIfEmpty) && empty) window.location = redirectIfEmpty;
    }

    render() {
        const { user, children } = this.props,
            { empty } = this.state,
            auth = user !== null && user.username !== undefined && user.username !== null;
        return auth ? (
            <div className="simple-central help-content">
                {empty ? (
                    <h3>
                        <i className="fas fa-exclamation-triangle fa-fw"></i> Page Not Available
                    </h3>
                ) : null}
                <div dangerouslySetInnerHTML={this.state} />
                {children}
            </div>
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user
    };
};

const actionCreators = {
    setPageState
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(StaticContentPage)));
