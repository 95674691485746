import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import 'components/manager/Metadata.scss';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import { isNullOrUndefined } from 'utils/object';

class MetadataDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            indicator: {
                id: null,
                name: null
            },
            instance: {
                id: null,
                name: null
            },
            geo: {
                id: null,
                name: null,
                url: null
            },
            active: null,
            changes: [],
            mode: 'view'
        };
        this.onOpen = this.onOpen.bind(this);
    }

    onModeChange(evt, mode) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            mode: mode,
            show: true
        });
    }

    onValueChange(evt, rowId) {
        evt.preventDefault();
        evt.stopPropagation();
        const { changes, indicator, geo, instance } = this.state,
            active = evt.currentTarget;
        let i = -1;
        if ((i = changes.findIndex((c) => c.key === rowId && c.action === 'change')) < 0) {
            changes.push({
                action: 'change',
                key: rowId,
                value: active.value,
                id: indicator.id,
                geo: geo !== undefined ? geo.id : null,
                instance: instance !== undefined ? instance.id : null
            });
        } else if (i >= 0) changes[i].value = active.value;
        this.setState({
            changes: changes,
            show: true,
            mode: 'edit'
        });
    }

    onSaveClick() {
        const { onSave, item } = this.props;
        if (onSave) onSave(this.state.changes, item);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    this.onOpen();
                }
            );
        }
    }

    componentDidMount() {
        if (this.props.show) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    this.onOpen();
                }
            );
        }
    }

    onOpen() {
        const { catalog, item, overrideMetadataUrl } = this.props;
        catalog.getMetadata([item.indicator.id], true, true, overrideMetadataUrl).then((metadataSet) => {
            const hasMetadata =
                    metadataSet !== undefined && metadataSet.features !== undefined && metadataSet.fields !== undefined,
                isInstance = !isNullOrUndefined(item.instance) && !isNullOrUndefined(item.geo),
                metaPromise = isInstance
                    ? catalog.getInstanceMetadata(
                          item.indicator.id,
                          item.instance.id,
                          item.geo.id,
                          true,
                          overrideMetadataUrl
                      )
                    : Promise.resolve(null),
                bgChanges = [],
                defs = {
                    Title: isInstance ? item.instance.name : item.indicator.name,
                    IndicatorID: item.indicator.id
                };
            // Update the title if we can...
            if (
                isInstance &&
                hasMetadata &&
                !isNullOrUndefined(item.instance.name) &&
                metadataSet.features[0].attributes['Title'] !== item.instance.name
            ) {
                metadataSet.features[0].attributes['Title'] = item.instance.name;
                bgChanges.push({
                    action: 'change',
                    key: 'Title',
                    value: item.instance.name,
                    id: item.indicator.id,
                    geo: item.geo.id,
                    instance: item.instance.id
                });
            }
            metaPromise.then((instanceMetadataSet) => {
                const metadataItems = [],
                    exclusions = ['objectid', 'themenametrail', 'themeidtrail', 'geoid', 'instanceid'];
                if (hasMetadata) {
                    const aliasLookup = {},
                        fieldSizes = {};
                    for (let f of metadataSet.fields) {
                        aliasLookup[f.name.toLowerCase()] = f;
                        // Dates are special...
                        if (!isNullOrUndefined(f.type) && f.type === 'esriFieldTypeDate')
                            fieldSizes[f.name.toLowerCase()] = '0000-00-00'.length;
                        else if (!isNullOrUndefined(f.length)) fieldSizes[f.name.toLowerCase()] = f.length;
                        else fieldSizes[f.name.toLowerCase()] = 255;
                    }
                    for (let i in metadataSet.features[0].attributes) {
                        if (exclusions.indexOf(i.toLowerCase()) < 0) {
                            let f = aliasLookup[i.toLowerCase()],
                                sz = fieldSizes[i.toLowerCase()];
                            metadataItems.push({
                                id: i,
                                label: f.alias !== undefined ? f.alias : i.replace(/[A-Z]/g, ' $1').trim(),
                                value:
                                    f.type === 'esriFieldTypeDate'
                                        ? new Date(metadataSet.features[0].attributes[i])
                                              .toISOString()
                                              .substring(0, 10)
                                              .replace('T', ' ')
                                              .replace('1970-01-01', '')
                                        : metadataSet.features[0].attributes[i],
                                max: sz,
                                type: f.type === 'esriFieldTypeDate' ? 'date' : 'text'
                            });
                        }
                    }
                    if (
                        !isNullOrUndefined(instanceMetadataSet) &&
                        !isNullOrUndefined(instanceMetadataSet.features) &&
                        instanceMetadataSet.features.length > 0
                    ) {
                        // Hard-wire the name...
                        instanceMetadataSet.features[0].attributes['Title'] = item.instance.name;
                        const removeReplaceOrAdd = (item, itemList) => {
                            if (!isNullOrUndefined(item.value)) {
                                const i = itemList.findIndex((itm) => itm.id === item.id);
                                if (i >= 0) {
                                    if (itemList[i].value !== item.value) itemList.splice(i, 1, item); // Only replace if an explicit overwrite...
                                } else itemList.push(item);
                            }
                        };
                        for (let i in instanceMetadataSet.features[0].attributes) {
                            if (exclusions.indexOf(i.toLowerCase()) < 0) {
                                let f = aliasLookup[i.toLowerCase()],
                                    sz = fieldSizes[i.toLowerCase()];
                                removeReplaceOrAdd(
                                    {
                                        id: i,
                                        label: f.alias !== undefined ? f.alias : i.replace(/[A-Z]/g, ' $1').trim(),
                                        value:
                                            f.type === 'esriFieldTypeDate'
                                                ? new Date(instanceMetadataSet.features[0].attributes[i])
                                                      .toISOString()
                                                      .substring(0, 16)
                                                      .replace('T', ' ')
                                                      .replace('1970-01-01', '')
                                                : instanceMetadataSet.features[0].attributes[i],
                                        instance: true,
                                        max: sz,
                                        type: f.type === 'esriFieldTypeDate' ? 'date' : 'text'
                                    },
                                    metadataItems
                                );
                            }
                        }
                    }
                } else {
                    for (var f of catalog.meta.info.fields) {
                        if (exclusions.indexOf(f.name.toLowerCase()) < 0) {
                            metadataItems.push({
                                id: f.name,
                                label: f.alias,
                                value: defs[f.name] || ''
                            });
                        }
                    }
                    bgChanges.push({
                        action: 'change',
                        key: 'Title',
                        value: defs['Title'],
                        id: item.indicator.id
                    });
                }
                this.setState({
                    loading: false,
                    mode: 'view',
                    indicator: item.indicator,
                    instance: item.instance,
                    geo: item.geo,
                    values: metadataItems,
                    changes: bgChanges
                });
            });
        });
    }

    render() {
        const { loading, values, active, changes, mode, indicator, instance, geo } = this.state,
            { show, onClose, inline, editable, title } = this.props,
            rows = values.map((mi, index) => {
                const ac = mi.id === active ? 'active' : '',
                    mc =
                        changes.find((c) => c.id === mi.id && c.action === 'delete') !== undefined
                            ? 'marked-for-delete'
                            : '',
                    ic = !isNullOrUndefined(mi.instance) && mi.instance === true ? 'instance-level' : '';
                return (
                    <tr
                        key={index}
                        className={`${ac} ${mc} ${ic} metadata-${mode}-${mi.id.toLowerCase()}`.trim()}
                        data-item-id={mi.id}
                    >
                        <td data-metadata-key={mi.id}>
                            <span>{mi.label}</span>
                        </td>
                        {mode === 'view' ? (
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: !isNullOrUndefined(mi.value)
                                        ? mi.value.replace(/([\/\-\+\&]+)/g, '$1&#173;')
                                        : ''
                                }}
                            ></td>
                        ) : (
                            <td>
                                {['Title', 'IndicatorID'].indexOf(mi.id) >= 0 ? (
                                    <input
                                        type="text"
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        readOnly={true}
                                        disabled="disabled"
                                    />
                                ) : mi.max > 500 ? (
                                    <textarea
                                        rows="5"
                                        cols="100"
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        onKeyUp={(e) => this.onValueChange(e, mi.id)}
                                        maxLength={mi.max}
                                    ></textarea>
                                ) : (
                                    <input
                                        type={mi.type !== undefined ? mi.type : 'text'}
                                        id={`editBox_${mi.id}`}
                                        defaultValue={mi.value}
                                        className="form-control"
                                        onKeyUp={(e) => this.onValueChange(e, mi.id)}
                                        maxLength={mi.max}
                                    />
                                )}
                            </td>
                        )}
                    </tr>
                );
            }),
            atInstanceLevel = !isNullOrUndefined(instance) && !isNullOrUndefined(instance.id);

        return !show ? null : inline ? (
            <table
                className={`table table-striped iao-metadata-table ${
                    atInstanceLevel ? 'instance-level-metadata-table' : ''
                }`.trim()}
            >
                <thead>
                    <tr>
                        <th scope="col" style={{ width: '33%' }}>
                            <a
                                href="https://help.instantatlas.com/instantatlas-data-catalog/data-catalog-metadata/"
                                target="iaHelpWindow"
                            >
                                Key <i className="fas fa-external-link-alt"></i>
                            </a>
                        </th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        ) : (
            <ModalDialog
                title={
                    title !== undefined && title !== null ? (
                        title
                    ) : atInstanceLevel ? (
                        <FormattedMessage
                            id="manager.metadataDialog.instance.title"
                            defaultMessage="{indicator} | {date} | {geo} | Metadata"
                            values={{
                                indicator: indicator.name,
                                geo: geo.name,
                                date: instance.name
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="manager.metadataDialog.indicator.title"
                            defaultMessage="{indicator} | Metadata"
                            values={{
                                indicator: indicator.name
                            }}
                        />
                    )
                }
                buttons={
                    editable
                        ? [
                              <div className="pull-left">
                                  <button
                                      type="button"
                                      className="btn btn-default btn-secondary"
                                      data-dismiss="modal"
                                      onClick={(e) => this.onModeChange(e, 'edit')}
                                  >
                                      <FormattedMessage
                                          id="manager.metadataDialog.button.edit"
                                          defaultMessage="{icon} Edit"
                                          values={{
                                              icon: <i className="fas fa-pencil-alt"></i>
                                          }}
                                      />
                                  </button>
                              </div>,
                              <button
                                  type="button"
                                  className="btn btn-default btn-primary"
                                  onClick={(e) => this.onSaveClick(e)}
                                  disabled={mode !== 'edit'}
                              >
                                  <FormattedMessage
                                      id="manager.metadataDialog.button.save"
                                      defaultMessage="{icon} Save Changes"
                                      values={{
                                          icon: <i className="fas fa-save"></i>
                                      }}
                                  />
                              </button>,
                              <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                                  <FormattedMessage
                                      id="manager.metadataDialog.button.cancel"
                                      defaultMessage="{icon} Cancel"
                                      values={{
                                          icon: <i className="fas fa-times"></i>
                                      }}
                                  />
                              </button>
                          ]
                        : [
                              <button type="button" className="btn btn-default btn-primary" data-dismiss="modal">
                                  <FormattedMessage
                                      id="metadataDialog.button.close"
                                      defaultMessage="{icon} Close"
                                      values={{
                                          icon: <i className="fas fa-times"></i>
                                      }}
                                  />
                              </button>
                          ]
                }
                show={show}
                large={true}
                scroll={true}
                onClose={onClose}
            >
                <div>
                    {loading ? (
                        <ProgressMessage />
                    ) : (
                        <div>
                            <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}>
                                <i className="fas fa-info-circle" style={{ fontSize: '48px' }}></i>
                            </div>
                            <div style={{ marginLeft: 68 + 'px' }}>
                                <div className="i-after">
                                    <table
                                        className={`table table-striped iao-metadata-table ${
                                            atInstanceLevel ? 'instance-level-metadata-table' : ''
                                        }`.trim()}
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: '33%' }}>
                                                    <a
                                                        href="https://help.instantatlas.com/instantatlas-data-catalog/data-catalog-metadata/"
                                                        target="iaHelpWindow"
                                                    >
                                                        Key <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </th>
                                                <th scope="col">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>{rows}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ModalDialog>
        );
    }
}

export default injectIntl(MetadataDialog, { withRef: true });
