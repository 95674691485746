import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import 'pages/Manager.scss';
import ModalDialog from 'components/ModalDialog';

class ChooseDeleteIndicatorActionDialog extends PureComponent 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            action: 'cancel'
        };
    }

    onConfirmClick(action) 
    {
        const { item, onConfirm } = this.props;
        if (onConfirm !== undefined) onConfirm(item, action);
    }

    render()
    {
        const { item, show, onClose } = this.props,
            { counts } = this.state,
            geo = item.geo,
            theme = item.theme,
            buttonSet = [
                <button type="button" className="btn btn-secondary" onClick={(e) => this.onConfirmClick('remove')}>
                    <FormattedMessage id="manager.chooseDeleteIndicatorActionDialog.button.remove" defaultMessage="{icon} Remove" values={{
                        icon: <i className="far fa-trash-alt"></i>
                    }} />
                </button>,
                <button type="button" className="btn btn-secondary" onClick={(e) => this.onConfirmClick('delete')}>
                    <FormattedMessage id="manager.chooseDeleteIndicatorActionDialog.button.delete" defaultMessage="{icon} Delete" values={{
                        icon: <i className="fas fa-trash-alt"></i>
                    }} />
                </button>,
                <button type="button" className="btn btn-primary">
                    <FormattedMessage id="manager.chooseDeleteIndicatorActionDialog.button.cancel" defaultMessage="{icon} Cancel" values={{
                        icon: <i className="fas fa-times"></i>
                    }} />
                </button>
            ];
        return (!show ? null : <ModalDialog
            title={
                <FormattedMessage id="manager.chooseDeleteIndicatorActionDialog.title" defaultMessage="Delete or Remove {indicator}?"
                    values={{
                        indicator: item.name
                    }} />
            }
            buttons={buttonSet}
            show={show}
            scroll={true}
            onClose={onClose}>
            <div>
                <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}><i className="fas fa-exclamation-triangle" style={{ fontSize: '48px' }}></i></div>
                <div style={{ marginLeft: 68 + 'px' }}>
                    <div className="i-after">
                        <p>
                            <FormattedMessage id="manager.chooseDeleteIndicatorActionDialog.oneLayer.messageFormat" defaultMessage="Indicator {indicator} is available in more than one theme for layer {geo}. Do you want to remove it from just this theme {theme} or do you want to delete it completely?"
                                values={{
                                    indicator: <strong className="i-name">{item.name}</strong>,
                                    geo: <strong className="g-name">{geo.name}</strong>,
                                    theme: <strong>{theme.name}</strong>
                                }} />
                        </p>
                    </div>
                </div>
            </div>
        </ModalDialog>);
    }
}

export default injectIntl(ChooseDeleteIndicatorActionDialog, { withRef: true });