export const PageActivityStatus = {
    INACTIVE: 0,
    LOADING_CATALOG: 1,
    LOADING_TREE: 2,
    LOADING_MAP: 4,
    LOADING_REMOTE_SERVICE: 8,
    UNUSED_2: 16,
    UNUSED_3: 32,
    UNUSED_4: 64,
    PROCESSING_TASK: 128, // Something that is only acting on one indicator or similar
    PROCESSING_CORE: 256, // Something that has a global impact
    UNUSED_5: 512,
    UNUSED_6: 1024,
    FATAL_ERROR: 2048
};
