// This component is (heavily?) dependent on https://github.com/Esri/esri-loader
// TODO - move to v4 of the API - speak to JC
import { loadModules } from 'esri-loader';

export default class ArcGISUtils {
    _apiVersion = '4.25';

    constructor(arcModulesList = []) {
        this._arcModuleKeys = arcModulesList.length > 0 ? arcModulesList : ArcGISUtils.ARCGIS_DEFAULT_MODULES;
        this._mapRefs = {};
        this._legendRefs = {};
    }

    static get ARCGIS_DEFAULT_MODULES_V3() {
        return [
            'esri/map',
            'esri/IdentityManager',
            'esri/geometry/Extent',
            'esri/arcgis/utils',
            'esri/layers/FeatureLayer',
            'esri/geometry/support/webMercatorUtils',
            'esri/Color',
            'esri/dijit/Legend',
            'esri/dijit/LayerList',
            'esri/dijit/BasemapGallery'
        ];
    }

    static get ARCGIS_DEFAULT_MODULES() {
        return [
            'esri/config',
            'esri/Map',
            'esri/WebMap',
            'esri/views/MapView',
            'esri/views/SceneView',
            'esri/identity/IdentityManager',
            'esri/geometry/Extent',
            'esri/portal/Portal',
            'esri/portal/PortalItem',
            'esri/layers/FeatureLayer',
            'esri/geometry/support/webMercatorUtils',
            'esri/geometry/projection',
            'esri/Color',
            'esri/widgets/Legend',
            'esri/widgets/LayerList',
            'esri/core/watchUtils'
        ];
    }

    init(options = { version: '4.25', css: true }) {
        this._apiVersion = options.version;
        return loadModules(this._arcModuleKeys, options).then((arcModules = []) => {
            // Update internal state...
            this._arcModules = arcModules;
            // And return...myself
            return this;
        });
    }

    get apiVersion() {
        return this._apiVersion;
    }

    get modules() {
        return this._arcModules.slice();
    }

    static getVanillaWebMap(options) {
        let webmap = {};
        webmap.item = {
            title: options && options.title ? options.title : 'Vanilla Map',
            extent:
                options && options.extent
                    ? options.extent
                    : [
                          [-180, -90],
                          [180, 90]
                      ]
        };
        webmap.itemData = {
            version: '2.0',
            authoringApp: 'InstantAtlas',
            authoringAppVersion: '2.0.0',
            baseMap: {
                baseMapLayers: [
                    {
                        id: 'World_Dark_Gray_Base_IAO',
                        layerType: 'ArcGISTiledMapServiceLayer',
                        url: 'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer',
                        visibility: true,
                        opacity: 1,
                        title: 'World Dark Gray Canvas Base'
                    },
                    {
                        id: 'World_Dark_Gray_Reference_IAO',
                        layerType: 'ArcGISTiledMapServiceLayer',
                        url: 'https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Reference/MapServer',
                        visibility: true,
                        opacity: 1,
                        title: 'World Dark Gray Reference',
                        isReference: true
                    }
                ],
                title: 'Dark Gray Canvas'
            }
        };
        if (options && options.basemaps && options.basemaps.length > 0) {
            webmap.itemData.baseMap.baseMapLayers = [];
            let i = 0;
            for (let m of options.basemaps) {
                webmap.itemData.baseMap.baseMapLayers.push({
                    id: 'reflyr' + i.toFixed(0),
                    layerType: 'ArcGISTiledMapServiceLayer',
                    url: m.split(';')[0],
                    visibility: true,
                    opacity: 1,
                    title: m.split(';').length > 1 ? m.split(';')[1] : m,
                    isReference: i > 0
                });
                i++;
            }
        }
        webmap.itemData.operationalLayers = [];
        return webmap;
    }

    static bindNavigate = (
        mapView,
        clickableLayer,
        idField = 'CODE',
        nameField = 'NAME',
        pattern = './#ID',
        routerHistory = null
    ) => {
        clickableLayer.outFields = [
            ...(clickableLayer.outFields !== null ? clickableLayer.outFields : []),
            idField,
            nameField
        ];
        mapView.on('click', (e) => {
            mapView.hitTest(e).then((hitResults) => {
                const target = hitResults.results.find((r) => r.graphic.layer === clickableLayer);
                if (target !== undefined) {
                    const fid = target.graphic.attributes[idField],
                        fname = target.graphic.attributes[nameField];
                    if (fid !== undefined && fid !== null) {
                        if (e.type === 'click') {
                            if (routerHistory !== null)
                                routerHistory.push(
                                    pattern.replace(/(#ID|#FID)/g, fid).replace(/(#NAME|#FNAME)/g, fname)
                                );
                            else
                                window.location.href = pattern
                                    .replace(/(#ID|#FID)/g, fid)
                                    .replace(/(#NAME|#FNAME)/g, fname);
                        }
                    }
                }
            });
        });
    };

    static bindTooltips = (mapView, tipLayer, labelFieldOrKeyOrFormat = 'NAME', labelFunction = null) => {
        const labels = labelFieldOrKeyOrFormat.split('$feature.').filter((lbl) => lbl !== undefined && lbl !== ''),
            arcadeLike = labelFieldOrKeyOrFormat.indexOf('$feature.') >= 0 || labels.length > 1;
        let usefulLabel = labelFunction !== null;
        if (arcadeLike && !usefulLabel) {
            // Deal with Arcade expressions - TODO? Can I farm this out???
            tipLayer.outFields = [
                ...(tipLayer.outFields !== null ? tipLayer.outFields : []),
                ...labels.map((lbl) => lbl.split(/[^0-9a-zA-Z_]/)[0])
            ];
            //tipLayer.labelingInfo = [{
            //    labelExpressionInfo: {
            //        expression: labelField
            //    },
            //    labelPlacement: 'always-horizontal'
            //}];
            usefulLabel = true;
        } else if (labelFieldOrKeyOrFormat !== '' && !usefulLabel) {
            tipLayer.outFields = [...(tipLayer.outFields !== null ? tipLayer.outFields : []), labelFieldOrKeyOrFormat];
            usefulLabel = true;
        }
        if (usefulLabel) {
            mapView.on('pointer-move', (e) => {
                mapView.hitTest(e).then((hitResults) => {
                    const target = hitResults.results.find((r) => r.graphic.layer === tipLayer),
                        container = mapView.container.parentNode,
                        tt = container.querySelector('.ia-map-tooltip');
                    if (tt !== undefined && tt !== null) {
                        const label = tt.querySelector('.tooltip-text');
                        if (target !== undefined) {
                            let fname = null;
                            if (labelFunction !== null) fname = labelFunction(target.graphic.attributes);
                            else if (arcadeLike) {
                                fname = '';
                                const keys = labels.map((lbl) => lbl.split(/[^0-9a-zA-Z_]/)[0]);
                                for (let i = 0; i < labels.length; i++) {
                                    fname += labels[i].replace(keys[i], target.graphic.attributes[keys[i]]);
                                }
                            } else fname = target.graphic.attributes[labelFieldOrKeyOrFormat];
                            if (fname !== undefined && fname !== null) {
                                if (e.type === 'pointer-move') {
                                    label.innerHTML = fname;
                                    // rely on margin in CSS to offset
                                    tt.style.left = `${e.x}px`;
                                    tt.style.top = `${e.y}px`;
                                    tt.style.display = 'block';
                                }
                            }
                        } else tt.style.display = 'none';
                    }
                });
            });
        }
    };

    /** Version 4 of the API ONLY.
     * */
    addLayerWithRenderer(
        view,
        layer = {
            url: null,
            name: null,
            classification: {
                field: {
                    name: null,
                    label: null
                },
                method: 'equal-interval',
                classes: 5,
                theme: 'high-to-low',
                flip: false,
                scheme: null,
                type: 'color'
            }
        },
        legend = false,
        clearVisibleLayers = true
    ) {
        return loadModules([
            'esri/layers/FeatureLayer',
            'esri/renderers/smartMapping/creators/color',
            'esri/renderers/smartMapping/creators/size',
            'esri/renderers/smartMapping/statistics/histogram',
            'esri/widgets/smartMapping/ClassedColorSlider',
            'esri/renderers/smartMapping/symbology/color',
            'esri/Color',
            'esri/widgets/Legend'
        ])
            .then(
                ([
                    FeatureLayer,
                    colorRendererCreator,
                    sizeRendererCreator,
                    histogram,
                    ClassedColorSlider,
                    colorSchemes,
                    Color,
                    Legend
                ]) => {
                    const map = view.map,
                        flayer = new FeatureLayer({
                            title: layer.title,
                            url: layer.url,
                            visible: true,
                            outFields: layer.outFields || ['*']
                        }),
                        classificationParams = {
                            layer: flayer,
                            view: view,
                            basemap: map.basemap,
                            classificationMethod: layer.classification.method,
                            numClasses: layer.classification.classes,
                            legendOptions: {
                                title: layer.classification.field.label
                            }
                        };
                    // Data? Thrown at us or just a field?
                    if (layer.classification.data !== undefined) {
                        // Arcade - sigh - Decode(code, 1, 'Residential', 2, 'Commercial', 3, 'Mixed', 'Other');
                        const decodable = [];
                        let iv;
                        for (let i = 0; i < layer.classification.data.rowIds.length; i++) {
                            iv = parseFloat(layer.classification.data.rows[i][1]);
                            decodable.push(`'${layer.classification.data.rowIds[i]}', ${!isNaN(iv) ? iv : `'${iv}'`}`);
                        }
                        classificationParams.valueExpression = `Decode($feature.${
                            layer.idField || 'CODE'
                        }, ${decodable.join(', ')}, '')`;
                    } else classificationParams.field = layer.classification.field.name;
                    if (clearVisibleLayers) {
                        for (let lyr of map.layers.items) {
                            if (lyr.type === 'feature') lyr.visible = false;
                        }
                    }
                    map.add(flayer);
                    if (layer.classification.theme !== undefined) {
                        const gt = flayer.geometryType || 'polygon';
                        let s =
                            layer.classification.scheme !== undefined && layer.classification.scheme !== ''
                                ? colorSchemes.getSchemeByName({
                                      geometryType: gt,
                                      theme: layer.classification.theme,
                                      name: layer.classification.scheme,
                                      basemap: map.basemap
                                  })
                                : colorSchemes.getSchemes({
                                      geometryType: gt,
                                      theme: layer.classification.theme,
                                      basemap: map.basemap
                                  });
                        if (s === undefined || s === null) {
                            s = colorSchemes.getSchemes({
                                geometryType: gt,
                                theme: layer.classification.theme,
                                basemap: map.basemap
                            });
                            if (
                                layer.classification.scheme !== undefined &&
                                layer.classification.scheme !== null &&
                                s !== undefined &&
                                s !== null &&
                                s.secondarySchemes !== undefined
                            ) {
                                const sregex = new RegExp(
                                    `^${layer.classification.scheme.split(' ').join('(.*)')}$`,
                                    'i'
                                );
                                if (s.secondarySchemes.find((ss) => sregex.test(ss.name)) !== undefined)
                                    s = s.secondarySchemes.find((ss) => sregex.test(ss.name));
                            }
                        }
                        if (s !== undefined && s !== null) s = s.primaryScheme !== undefined ? s.primaryScheme : s;
                        if (s !== undefined && s !== null && s.name !== undefined) {
                            if (layer.classification.flip) s = colorSchemes.flipColors(s);
                            classificationParams.colorScheme = s;
                        }
                    }
                    if (legend !== false) {
                        const { anchor = 'bottom-left', maxHeight = '200px' } = legend,
                            legendWidget = new Legend({
                                view: view
                            });
                        view.ui.add(legendWidget, anchor);
                        legendWidget.container.style.maxHeight = maxHeight;
                    }
                    return (
                        layer.classification.type !== undefined && layer.classification.type === 'size'
                            ? sizeRendererCreator
                            : colorRendererCreator
                    )
                        .createClassBreaksRenderer(classificationParams)
                        .then((rendererResponse) => {
                            flayer.renderer = rendererResponse.renderer;
                            return flayer; //map.layers.items.find(lyr => `${lyr.url}/${lyr.layerId}`.toLowerCase() === flayer.url.toLowerCase());
                        });
                }
            )
            .catch((err) => {
                // handle any errors
                console.error(err);
            });
    }

    static extentsOverlap(extent1, extent2) {
        return loadModules(['esri/geometry/Extent', 'esri/geometry/support/webMercatorUtils'], {
            version: '4.25'
        }).then(([Extent, webMercatorUtils]) => {
            let ex1 = new Extent(extent1),
                ex2 = new Extent(extent2);
            if (ex1.spatialReference.wkid === 102100) ex1 = webMercatorUtils.webMercatorToGeographic(ex1);
            if (ex2.spatialReference.wkid === 102100) ex2 = webMercatorUtils.webMercatorToGeographic(ex2);
            return ex1.intersects(ex2) !== false || ex2.contains(ex1);
        });
    }

    static convertExtentToGeographic(extentJson) {
        return loadModules(['esri/geometry/Extent', 'esri/geometry/support/webMercatorUtils'], {
            version: '4.25'
        }).then(([Extent, webMercatorUtils]) => {
            let ex1 = new Extent(extentJson);
            if (ex1.spatialReference.wkid === 102100) ex1 = webMercatorUtils.webMercatorToGeographic(ex1);
            return ex1;
        });
    }

    applyRenderer(options) {
        //const [
        //    Map,
        //    esriId,
        //    Extent,
        //    arcgisUtils,
        //    FeatureLayer,
        //    webMercatorUtils,
        //    Color,
        //    Legend,
        //    LayerList,
        //    BasemapGallery
        //] = this._arcModules;
        const renderModules = [
            'dojo/promise/all',
            'esri/renderers/ClassBreaksRenderer',
            'esri/tasks/ClassBreaksDefinition',
            'esri/tasks/AlgorithmicColorRamp',
            'esri/tasks/MultipartColorRamp',
            'esri/tasks/GenerateRendererParameters',
            'esri/tasks/GenerateRendererTask',
            'esri/symbols/SimpleLineSymbol',
            'esri/symbols/SimpleFillSymbol'
        ];
        const webmap = options.webmap,
            lyr = options.layer,
            field = options.field !== undefined ? options.field : options.dataField,
            idf = options.idField !== undefined ? options.idField : options.featureIdField,
            mtd = options.classificationMethod,
            classBreaks = options.classes && typeof options.classes.splice !== 'undefined' ? options.classes : null,
            nclasses = classBreaks !== null ? classBreaks.length - 1 : options.classes || 5,
            colors = options.colors,
            useSmart = typeof options.smart === 'undefined' || options.smart,
            map = options.map,
            addLegend = options.legend && options.legend.show,
            legendAnchor = options.legend && options.legend.anchor ? options.legend.anchor : 'top-left',
            legendlayerLabel = options.legend && options.legend.label ? options.legend.label : null,
            afterRenderFnc = options && options.done ? options.done : null,
            rawValues = options && options.values ? options.values : null,
            rawFeatures = options && options.ids ? options.ids : null;
        if (useSmart) renderModules.push('esri/renderers/smartMapping');
        loadModules(renderModules).then(
            ([
                dojoAll,
                ClassBreaksRenderer,
                ClassBreaksDefinition,
                AlgorithmicColorRamp,
                MultipartColorRamp,
                GenerateRendererParameters,
                GenerateRendererTask,
                SimpleLineSymbol,
                SimpleFillSymbol,
                smartMapping
            ]) => {
                const cutDownModules = [
                    SimpleFillSymbol,
                    SimpleLineSymbol,
                    ClassBreaksDefinition,
                    AlgorithmicColorRamp,
                    GenerateRendererParameters,
                    GenerateRendererTask
                ];
                let fallbackRender = () => {
                    let rendererCommit = (renderer) => {
                        if (rendererOverride !== null) rendererOverride(renderer);
                        lyr.setRenderer(renderer);
                        lyr.redraw();
                        if (webmap && webmap.itemData) {
                            // Find the matching layer and push it back in...
                            for (let olyr of webmap.itemData.operationalLayers) {
                                if (olyr.id === lyr.id) {
                                    if (olyr.layerDefinition === undefined) olyr.layerDefinition = {};
                                    olyr.layerDefinition.drawingInfo = {
                                        renderer: renderer.toJson()
                                    };
                                    break;
                                }
                            }
                            if (this._legend !== undefined) this._legend.refresh();
                        }
                        if (addLegend) this.createLegend(map, lyr, field, legendAnchor, legendlayerLabel);
                        if (afterRenderFnc !== null) {
                            afterRenderFnc({
                                target: lyr,
                                map: map
                            });
                        }
                    };
                    if (typeof field === 'function') {
                        let localRenderer = new ClassBreaksRenderer(null, field);
                        if (classBreaks != null) {
                            for (let c = 0; c < classBreaks.length - 1; c++) {
                                localRenderer.addBreak(parseFloat(classBreaks[c]), parseFloat(classBreaks[c + 1]));
                            }
                        }
                        rendererCommit(localRenderer);
                    } else {
                        let rendererPromise = this.createRendererPromise(
                            lyr.url,
                            field,
                            mtd,
                            nclasses,
                            colors,
                            cutDownModules
                        );
                        rendererPromise.then(rendererCommit, function (err) {
                            console.log(err);
                        });
                    }
                };
                let rendererOverride = null;
                if (classBreaks !== null) {
                    rendererOverride = (r) => {
                        let locals = r.infos.slice(),
                            classBreaksLabelFormat =
                                options.classLabel || (r.isMaxInclusive ? '> MINVAL to MAXVAL' : 'MINVAL to MAXVAL');
                        r.clearBreaks();
                        for (let c = 0; c < classBreaks.length - 1; c++) {
                            if (locals[c]) {
                                locals[c].minValue = parseFloat(classBreaks[c]);
                                locals[c].maxValue = parseFloat(classBreaks[c + 1]);
                                locals[c].label = classBreaksLabelFormat
                                    .replace(/MINVAL/g, locals[c].minValue.toString())
                                    .replace(/MAXVAL/g, locals[c].maxValue.toString());
                                r.addBreak(locals[c]);
                            }
                        }
                    };
                }
                if (useSmart || (typeof field === 'function' && rawValues && rawFeatures)) {
                    const smartArgs = {
                        layer: lyr,
                        field: field,
                        basemap: map.getBasemap() || 'gray', // default to gray if it's not obvious...
                        classificationMethod: mtd,
                        theme: 'high-to-low', // 'above-and-below', 'centered-on', 'extremes',
                        numClasses: nclasses
                    };
                    // If we have passed in the values AND it is not a "connectable" source for that data...
                    if (typeof field === 'function' && rawValues && rawFeatures) {
                        // Arcade - sigh - Decode(code, 1, 'Residential', 2, 'Commercial', 3, 'Mixed', 'Other');
                        let decodable = [],
                            iv;
                        for (let i in rawFeatures) {
                            iv = parseFloat(rawValues[i]);
                            decodable.push("'" + rawFeatures[i] + "', " + (!isNaN(iv) ? iv : "'" + iv + "'"));
                        }
                        smartArgs.valueExpression = 'Decode($feature.' + idf + ', ' + decodable.join(', ') + ", '')";
                        smartArgs.field = null;
                    }
                    smartMapping.createClassedColorRenderer(smartArgs).then(
                        (response) => {
                            if (rendererOverride !== null) rendererOverride(response.renderer);
                            lyr.setRenderer(response.renderer);
                            lyr.redraw();
                            if (addLegend) this.createLegend(map, lyr, field, legendAnchor, legendlayerLabel);
                            if (afterRenderFnc !== undefined) {
                                afterRenderFnc({
                                    target: lyr,
                                    map: map
                                });
                            }
                        },
                        (err) => {
                            console.log(err); // DEBUG
                            fallbackRender();
                        }
                    );
                } else fallbackRender();
            }
        );
    }

    createRendererPromise(lyrUrl, field, mtd, nclasses, colors, renderSpecificModules, preCheckValues = true) {
        //const [
        //    ,  //Map,
        //    ,  //esriId,
        //    ,  //Extent,
        //    ,  //arcgisUtils,
        //    ,  //FeatureLayer,
        //    ,  //webMercatorUtils,
        //    Color,
        //    ,  //Legend,
        //    ,  //LayerList,
        //    ,  //BasemapGallery
        //] = this._arcModules,
        const [, esriId, , , , , Color, , , ,] = this._arcModules,
            [
                SimpleFillSymbol,
                SimpleLineSymbol,
                ClassBreaksDefinition,
                AlgorithmicColorRamp,
                GenerateRendererParameters,
                GenerateRendererTask
            ] = renderSpecificModules;
        let sfs = new SimpleFillSymbol(
            SimpleFillSymbol.STYLE_SOLID,
            new SimpleLineSymbol(SimpleLineSymbol.STYLE_SOLID, Color([0, 0, 0]), 0.5),
            null
        );
        let classDef = new ClassBreaksDefinition();
        classDef.classificationField = field;
        classDef.classificationMethod = mtd ? mtd : 'quantile';
        classDef.breakCount = nclasses || 5;
        classDef.baseSymbol = sfs;
        classDef.standardDeviationInterval = 1;
        let colorRamp; //, subRamp;
        //if (colors.length === 2)
        //{
        colorRamp = new AlgorithmicColorRamp();
        if (colors && colors.length === 1) {
            for (let c of ArcGISUtils.arcColorRamps) {
                if (c.name.toLowerCase() === colors[0].toLowerCase()) {
                    colors = c.colors.slice();
                    break;
                }
            }
        }
        colorRamp.fromColor = colors[0]
            ? colors[0].substring(0, 3) === 'rgb'
                ? Color.fromRgb(colors[0])
                : Color.fromHex(colors[0])
            : Color.fromRgb('rgb(202,155,155)');
        colorRamp.toColor = colors[colors.length - 1]
            ? colors[colors.length - 1].substring(0, 3) === 'rgb'
                ? Color.fromRgb(colors[colors.length - 1])
                : Color.fromHex(colors[colors.length - 1])
            : Color.fromRgb('rgb(202,0,19)');
        colorRamp.algorithm = 'cie-lab'; // options are:  "cie-lab", "hsv", "lab-lch"
        //}
        //else
        //{
        //    colorRamp = new esri.tasks.MultipartColorRamp();
        //    for (var i = 0; i < colors.length - 1; i++)
        //    {
        //        subRamp = new esri.tasks.AlgorithmicColorRamp();
        //        subRamp.fromColor = (colors[i] ? (colors[i].substring(0, 3) === 'rgb' ? esri.Color.fromRgb(colors[i]) : esri.Color.fromHex(colors[i])) : esri.Color.fromRgb('rgb(202,155,155)'));
        //        subRamp.toColor = (colors[i + 1] ? (colors[1].substring(0, 3) === 'rgb' ? esri.Color.fromRgb(colors[i + 1]) : esri.Color.fromHex(colors[i + 1])) : esri.Color.fromRgb('rgb(202,0,19)'));
        //        subRamp.algorithm = 'cie-lab'; // options are:  "cie-lab", "hsv", "lab-lch"
        //        colorRamp.colorRamps.push(subRamp);
        //    }
        //}
        classDef.colorRamp = colorRamp;
        const params = new GenerateRendererParameters();
        params.classificationDefinition = classDef;
        if (preCheckValues) {
            const cred = esriId.findCredential(lyrUrl),
                t =
                    cred !== undefined && cred !== null && cred.token !== undefined && cred.token !== null
                        ? `&token=${cred.token}`
                        : '';
            // https://services1.arcgis.com/HumUw0sDQHwJuboT/ArcGIS/rest/services/Leeds_Dep_IMD2015/FeatureServer/2/query?
            // where=ID3091D20190101000000+IS+NOT+NULL&outFields=ID3091D20190101000000&returnGeometry=false&returnCentroid=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=true&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=true&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=html&token=
            return fetch(
                `${lyrUrl}/query?where=${field}+IS+NOT+NULL&outFields=${field}&returnGeometry=false&returnCentroid=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=true&f=json${t}`
            ).then((rsp) => {
                return rsp.json().then((featureSummary) => {
                    if (featureSummary.count > 0) {
                        const generateRenderer = new GenerateRendererTask(lyrUrl);
                        return generateRenderer.execute(params);
                    } else {
                        return {
                            httpCode: 400,
                            url: lyrUrl,
                            valid: false,
                            message: 'No non-null values'
                        };
                    }
                });
            });
        } else {
            const generateRenderer = new GenerateRendererTask(lyrUrl);
            return generateRenderer.execute(params);
        }
    }

    createLegend(
        mapView,
        lyr,
        field,
        anchor,
        lyrLabel,
        legendContainerId,
        startupDelayMs = 500,
        destroy = false,
        createChildNode = true
    ) {
        const [, , , , , , , , , , , , , Legend] = this._arcModules;
        // Only operational layers...
        const legendArgs = {
            view: mapView
        };
        if (lyr !== undefined && lyr !== null) {
            legendArgs.layerInfos = [];
            legendArgs.layerInfos.push({
                layer: lyr,
                title: lyrLabel !== undefined ? lyrLabel : lyr.title || lyr.name
            });
        }
        //var legendContainerId = $(map.container).prop('id').replace(/\-/g, '') + '_iaolegend';
        if (this._legendRefs[legendContainerId] !== undefined) {
            if (destroy) {
                this._legendRefs[legendContainerId].destroy();
                //console.log(`legend @${legendContainerId} destroyed`); // DEBUG
                this._legendRefs[legendContainerId] = undefined;
            } else {
                this._legendRefs[legendContainerId].layerInfos = legendArgs.layerInfos;
                this._legendRefs[legendContainerId].renderNow();
            }
            //document.getElementById(legendContainerId).getElementsByClassName('esriLegendMsg').remove();
        }
        //$(map.container).append('<div class="iao-arc-legend ' + (anchor ? anchor.toLowerCase() : 'top-left') + '"><div id="' + legendContainerId + '"><\/div><\/div>');
        if (legendContainerId !== undefined) {
            // Pass ourself into the promise, because async nature of React and ArcGIS JS means we can get in a mess... *sigh*
            const legendStore = this;
            return new Promise((resolve) => {
                setTimeout(() => {
                    let legend = null;
                    if (legendStore._legendRefs[legendContainerId] !== undefined) {
                        legend = legendStore._legendRefs[legendContainerId];
                        legend.layerInfos = [...legendArgs.layerInfos];
                        legend.renderNow();
                        //document.getElementById(legendContainerId).getElementsByClassName('esriLegendMsg').remove();
                    } else {
                        if (createChildNode) {
                            const parentNode = document.getElementById(legendContainerId),
                                childId = `${legendContainerId}${new Date().getTime().toFixed(0)}`,
                                destructable = document.createElement('div');
                            destructable.setAttribute('id', childId);
                            parentNode.appendChild(destructable);
                            legendArgs.container = childId; // ArcGIS API is just odd when it comes to destroy() and strips nodes it should not!
                        } else legendArgs.container = legendContainerId;
                        legend = new Legend(legendArgs);
                        //console.log(`legend @${legendContainerId} created`); // DEBUG
                        legend.renderNow();
                    }
                    resolve(legend);
                }, startupDelayMs); // This is likely to be called in an async process, so wait a while...
            }).then((activeLegend) => {
                this._legendRefs[legendContainerId] = activeLegend;
                return activeLegend;
            });
        }
        return Promise.resolve(null);
    }

    destroyLegend(legendContainerId) {
        if (this._legendRefs[legendContainerId] !== undefined) {
            this._legendRefs[legendContainerId].destroy();
            console.log(`legend @${legendContainerId} destroyed`); // DEBUG
            this._legendRefs[legendContainerId] = undefined;
            return true;
        }
        return false;
    }

    getMatchingValue(feature, featureIdField, availableFeatureIds, availableDataSet) {
        let fid, fv;
        if (
            feature !== undefined &&
            feature.attributes !== undefined &&
            feature.attributes[featureIdField] !== undefined
        ) {
            fid = feature.attributes[featureIdField];
            for (let i = 0; i < availableFeatureIds.length; i++) {
                if (fid === availableFeatureIds[i]) {
                    fv = availableDataSet[i];
                    if (fv && !isNaN(parseFloat(fv))) return parseFloat(fv);
                    break;
                }
            }
        }
        return fv;
    }

    checkForValidData = async (geoIndicatorSets = [], token = null) => {
        const promiseData = [];
        try {
            for (let gi of geoIndicatorSets) {
                const lyrUrl = gi.url,
                    field = gi.instances[0].field,
                    t = gi.token !== null ? `&token=${gi.token}` : token !== null ? `&token=${token}` : '',
                    svcInfo = await fetch(`${lyrUrl}?f=json${t}`).then((rsp) => {
                        return rsp.json();
                    }),
                    dataFldName =
                        svcInfo.fields !== undefined &&
                        svcInfo.fields.find((df) => df.name.toLowerCase() === field.toLowerCase()) !== undefined
                            ? svcInfo.fields.find((df) => df.name.toLowerCase() === field.toLowerCase()).name
                            : field,
                    featureSummary = await fetch(
                        `${lyrUrl}/query?where=${dataFldName}+IS+NOT+NULL&outFields=${dataFldName}&returnGeometry=false&returnCentroid=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=true&f=json${t}`
                    ).then((rsp) => {
                        return rsp.json();
                    });
                if (featureSummary.error)
                    throw new Error(`${featureSummary.error.code} ${featureSummary.error.message}`);
                promiseData.push({ url: lyrUrl, valid: featureSummary.count > 0, info: svcInfo });
            }
        } catch (err) {
            //console.log(err); // DEBUG
            if (
                token !== null &&
                err.message !== undefined &&
                err.message !== null &&
                err.message.substring(0, 3) === '498'
            )
                return this.checkForValidData(geoIndicatorSets, null);
        }
        return promiseData;
    };

    createIndicatorMap(mapView, geoIndicatorSets = [], popupTitleIndicatorLabel, extent, opts = {}) {
        const renderModules = [
                'esri/layers/FeatureLayer',
                'esri/smartMapping/renderers/color',
                'esri/smartMapping/renderers/size',
                'esri/smartMapping/symbology/size',
                'esri/smartMapping/renderers/type',
                'esri/PopupTemplate',
                'esri/geometry/Extent',
                'esri/geometry/support/webMercatorUtils'
            ],
            aliasFormat = opts.fieldLabel !== undefined ? opts.fieldLabel : '{0} ({1})',
            popTitleFormat = opts.popupTitle !== undefined ? opts.popupTitle : '{0} | {2}',
            applyScaleThresholds = opts.scales !== undefined ? opts.scales : true,
            excludeGeos = opts.excludes !== undefined ? opts.excludes : ['G8', 'G9'],
            includeChart = opts.chart !== undefined ? opts.chart : false,
            countsAsCircles = opts.proportionalCircles !== undefined && opts.proportionalCircles === true,
            classificationMethod = opts.classificationMethod !== undefined ? opts.classificationMethod : 'quantile',
            nClasses = opts.classes !== undefined ? Math.max(opts.classes, 2) : 5,
            preCheck = opts.check !== undefined ? opts.check : true,
            runPreCheck = preCheck ? this.checkForValidData(geoIndicatorSets, opts.token) : Promise.resolve(null);
        return runPreCheck.then((validDataArray) => {
            return loadModules(renderModules, {}).then(
                ([
                    FeatureLayer,
                    colorCreator,
                    sizeCreator,
                    sizeHelper,
                    typeCreator,
                    PopupTemplate,
                    Extent,
                    webMercatorUtils
                ]) => {
                    const lonLatExtent =
                            extent !== null && extent !== undefined && extent.spatialReference.wkid === 102100
                                ? webMercatorUtils.webMercatorToGeographic(extent)
                                : extent,
                        webmap =
                            opts && opts.webmap
                                ? opts.webmap
                                : ArcGISUtils.getVanillaWebMap({
                                      title: popupTitleIndicatorLabel + ' Map',
                                      extent: lonLatExtent
                                  }),
                        scaleLookup = {
                            G1: [198647, 0], // LSOA
                            G2: [3181491, 834519], // LTLA
                            G3: geoIndicatorSets['G2']
                                ? [4693934, 3181491]
                                : geoIndicatorSets['G7'] || geoIndicatorSets['G19']
                                ? [4693934, 834519]
                                : geoIndicatorSets['G1']
                                ? [4693934, 189394]
                                : [4693934, 0], // UTLA - sensitive to what lies beneath
                            G7: [834519, 189394], // Ward
                            G8: [0, 4693934], // Region
                            G9: [0, 19483858], // Country
                            G19: [1155582, 834519] // CED
                        },
                        codeLookup = {
                            G1: 'LSOACode',
                            G2: 'LTLACode',
                            G3: 'UTLACode',
                            G7: 'WardCode',
                            G8: 'RgnCode'
                        },
                        lyrPromises = [],
                        olyrLookup = {},
                        metaviewUrl = opts.metadataViewerUrl !== undefined ? opts.metadataViewerUrl : '/metadata/',
                        dataCatalogId = opts.catalog !== undefined ? opts.catalog : '';
                    let olyr,
                        isRate,
                        isCount,
                        isCategoric,
                        localChart,
                        existingIdx,
                        isValidLookup = {};
                    if (preCheck) {
                        for (let vc of validDataArray) isValidLookup[vc.url] = vc;
                    }
                    // Store some references here so we don't get index mixup...
                    let lyrOffset = 0;
                    for (let geoIndSet of geoIndicatorSets.filter((gi) => !preCheck || isValidLookup[gi.url].valid)) {
                        if (excludeGeos.indexOf(geoIndSet.geo.id) < 0) {
                            const idf = opts.idField || geoIndSet.idField || codeLookup[geoIndSet.geo.id],
                                matchedIdFld =
                                    idf !== undefined &&
                                    idf !== null &&
                                    isValidLookup[geoIndSet.url].valid &&
                                    isValidLookup[geoIndSet.url].info !== undefined &&
                                    isValidLookup[geoIndSet.url].info.fields !== undefined
                                        ? isValidLookup[geoIndSet.url].info.fields.find(
                                              (ff) => ff.name.toLowerCase() === idf.toLowerCase()
                                          )
                                        : undefined,
                                nmf = opts.nameField || geoIndSet.nameField || 'NAME',
                                matchedNmFld =
                                    isValidLookup[geoIndSet.url].valid &&
                                    isValidLookup[geoIndSet.url].info !== undefined &&
                                    isValidLookup[geoIndSet.url].info.fields !== undefined
                                        ? isValidLookup[geoIndSet.url].info.fields.find(
                                              (ff) => ff.name.toLowerCase() === nmf.toLowerCase()
                                          )
                                        : undefined;
                            olyr = {
                                id:
                                    opts && opts.replace && opts.id
                                        ? opts.id
                                        : `${geoIndSet.geo.id}_${geoIndSet.indicator.id}_Layer${lyrOffset}`,
                                title:
                                    opts && opts.replace && opts.title
                                        ? opts.title
                                        : geoIndSet.title !== undefined
                                        ? geoIndSet.title
                                        : `${geoIndSet.indicator.name} (${geoIndSet.instances[0].alias}) | ${geoIndSet.geo.name}`,
                                visibility: true,
                                opacity: 0.85,
                                url: geoIndSet.url,
                                popupInfo: {
                                    title:
                                        opts && opts.replace && opts.popupTitle
                                            ? opts.popupTitle
                                            : popTitleFormat
                                                  .replace(/\{0\}/g, geoIndSet.geo.name)
                                                  .replace(/\{1\}/g, geoIndSet.indicator.name)
                                                  .replace(
                                                      /\{2\}/g,
                                                      `{${matchedNmFld !== undefined ? matchedNmFld.name : nmf}}`
                                                  )
                                                  .replace(
                                                      /\{3\}/g,
                                                      `{${matchedIdFld !== undefined ? matchedIdFld.name : idf}}`
                                                  ),
                                    fieldInfos: [],
                                    expressionInfos: []
                                },
                                layerType: 'ArcGISFeatureLayer'
                            };
                            if (opts.idField || geoIndSet.idField || codeLookup[geoIndSet.geo.id]) {
                                olyr.popupInfo.fieldInfos.push({
                                    fieldName: matchedIdFld !== undefined ? matchedIdFld.name : idf,
                                    visible: false
                                });
                            }
                            if (opts.nameField || geoIndSet.nameField) {
                                olyr.popupInfo.fieldInfos.push({
                                    fieldName: matchedNmFld !== undefined ? matchedNmFld.name : nmf,
                                    visible: false
                                });
                            }
                            if (applyScaleThresholds && scaleLookup[geoIndSet.geo.id]) {
                                olyr.layerDefinition = {
                                    minScale: scaleLookup[geoIndSet.geo.id][0],
                                    maxScale: scaleLookup[geoIndSet.geo.id][1]
                                };
                            }
                            // Pull rates from indicator metadata or a name (fallback)
                            const idt =
                                geoIndSet.indicator !== undefined && geoIndSet.indicator.dataType !== undefined
                                    ? geoIndSet.indicator.dataType
                                    : undefined;
                            isRate =
                                (idt !== undefined && idt.toLowerCase() === 'rate') ||
                                (idt === undefined &&
                                    (popupTitleIndicatorLabel.toLowerCase().indexOf(' per ') > 0 ||
                                        popupTitleIndicatorLabel.toLowerCase().indexOf('%') >= 0 ||
                                        popupTitleIndicatorLabel.toLowerCase().indexOf('percentage') >= 0));
                            isCount = idt !== undefined && idt.toLowerCase() === 'count';
                            isCategoric = idt !== undefined && idt.toLowerCase() === 'categoric';
                            if (includeChart && geoIndSet.instances.length > 1) {
                                localChart = {
                                    title: '',
                                    type:
                                        ('line,bar,column,pie'.indexOf(includeChart.toString()) >= 0
                                            ? includeChart.toString()
                                            : 'line') + 'chart',
                                    caption: popupTitleIndicatorLabel,
                                    value: {
                                        fields: []
                                    },
                                    tooltipField: matchedNmFld !== undefined ? matchedNmFld.name : nmf
                                };
                            } else localChart = false;
                            for (let j in geoIndSet.instances) {
                                const matchedFld =
                                    isValidLookup[geoIndSet.url].valid &&
                                    isValidLookup[geoIndSet.url].info !== undefined &&
                                    isValidLookup[geoIndSet.url].info.fields !== undefined
                                        ? isValidLookup[geoIndSet.url].info.fields.find(
                                              (ff) =>
                                                  ff.name.toLowerCase() === geoIndSet.instances[j].field.toLowerCase()
                                          )
                                        : undefined;
                                olyr.popupInfo.fieldInfos.push({
                                    fieldName:
                                        matchedFld !== undefined ? matchedFld.name : geoIndSet.instances[j].field,
                                    label: aliasFormat
                                        .replace(/\{0\}/g, popupTitleIndicatorLabel)
                                        .replace(/\{1\}/g, geoIndSet.instances[j].alias),
                                    isEditable: false,
                                    visible: true,
                                    format: {
                                        places: isRate ? 2 : 0,
                                        digitSeparator: true
                                    }
                                });
                                if (localChart)
                                    localChart.value.fields.unshift(
                                        matchedFld !== undefined ? matchedFld.name : geoIndSet.instances[j].field
                                    );
                            }
                            if (localChart) {
                                olyr.popupInfo.mediaInfos = [];
                                olyr.popupInfo.mediaInfos.push(localChart);
                            }
                            if (
                                !opts ||
                                typeof opts.metadata === 'undefined' ||
                                'no,0,false'.indexOf(opts.metadata.toString().toLowerCase()) < 0
                            ) {
                                olyr.popupInfo.expressionInfos.push({
                                    name: 'urlExpr' + geoIndSet.indicator.id,
                                    title: 'Metadata',
                                    expression: `Concatenate(['${metaviewUrl}','${
                                        geoIndSet.indicator.id
                                    }?name=${encodeURIComponent(
                                        geoIndSet.indicator.name
                                    )}','&catalog=${encodeURIComponent(dataCatalogId)}'],'')`
                                });
                                olyr.popupInfo.fieldInfos.push({
                                    fieldName: `expression/urlExpr${geoIndSet.indicator.id}`,
                                    label: ' ',
                                    isEditable: false,
                                    visible: true
                                });
                            }
                            // If we have this one, replace it!
                            existingIdx = -1;
                            if (opts.replace !== false) {
                                for (let j in webmap.itemData.operationalLayers) {
                                    if (webmap.itemData.operationalLayers[j].id === olyr.id) existingIdx = j;
                                }
                            }
                            if (existingIdx >= 0) webmap.itemData.operationalLayers.splice(existingIdx, 1, olyr);
                            else webmap.itemData.operationalLayers.push(olyr);
                            olyrLookup[olyr.id] = olyr;
                            // Use the first instance - most recent date, by default
                            const existingLyr = mapView.map.findLayerById(olyr.id) !== undefined,
                                flyr = existingLyr ? mapView.map.findLayerById(olyr.id) : new FeatureLayer(olyr.url),
                                flyrId = olyr.id;
                            //if (!existingLyr) mapView.map.layers.add(flyr); // Pre-add, potentially speeds up one step...
                            if (isCategoric) {
                                lyrPromises.push(
                                    typeCreator.createRenderer({
                                        layer: flyr,
                                        field: geoIndSet.instances[0].field,
                                        view: mapView
                                    })
                                );
                                lyrOffset++;
                            } else if (isCount && countsAsCircles) {
                                // TODO - params for size
                                lyrPromises.push(
                                    sizeCreator
                                        .createClassBreaksRenderer({
                                            classificationMethod,
                                            layer: flyr,
                                            field: geoIndSet.instances[0].field,
                                            numClasses: nClasses,
                                            view: mapView,
                                            defaultSymbolEnabled: false // Don't want the normal polygons, hide them
                                        })
                                        .then((rr) => {
                                            return {
                                                ...rr,
                                                layer: flyrId
                                            };
                                        })
                                );
                                lyrOffset++;
                            } else {
                                lyrPromises.push(
                                    colorCreator
                                        .createClassBreaksRenderer({
                                            classificationMethod,
                                            layer: flyr,
                                            field: geoIndSet.instances[0].field,
                                            numClasses: nClasses,
                                            view: mapView
                                        })
                                        .then((rr) => {
                                            return {
                                                ...rr,
                                                layer: flyrId
                                            };
                                        })
                                );
                                lyrOffset++;
                            }
                        }
                    }
                    return Promise.all(lyrPromises).then((rendererResults) => {
                        const rendererLookup = {};
                        for (let i = rendererResults.length - 1; i >= 0; i--) {
                            const activeLayerId = rendererResults[i].layer,
                                activeRenderer = rendererResults[i].renderer;
                            if (activeRenderer.attributeField || activeRenderer.field) {
                                if (!olyrLookup[activeLayerId].layerDefinition)
                                    olyrLookup[activeLayerId].layerDefinition = {};
                                olyrLookup[activeLayerId].layerDefinition.drawingInfo = {
                                    renderer: activeRenderer.toJSON()
                                };
                                rendererLookup[activeLayerId] = activeRenderer;
                            } else if (activeRenderer.httpCode) {
                                console.log(
                                    'Error generating renderer for ' + olyrLookup[i].title + ': ' + activeRenderer
                                );
                                var discard = olyrLookup.pop();
                                for (var j = webmap.itemData.operationalLayers.length - 1; j >= 0; j--) {
                                    if (webmap.itemData.operationalLayers[j].id === discard.id) {
                                        webmap.itemData.operationalLayers.splice(j, 1);
                                        break;
                                    }
                                }
                            }
                        }
                        if (webmap.itemData.operationalLayers.length < 1) {
                            console.log('No layers! Why?'); // DEBUG
                            return webmap;
                        }
                        webmap.item.extent[0] = webMercatorUtils.xyToLngLat(extent.xmin, extent.ymin);
                        webmap.item.extent[1] = webMercatorUtils.xyToLngLat(extent.xmax, extent.ymax);
                        // Choices - if we have a map and legend inject the relevant stuff, otherwise (re)create a whole map
                        if (mapView.map !== undefined) {
                            let featureLayer,
                                pop,
                                layerInfos = [],
                                lyr,
                                flds;
                            for (let j = webmap.itemData.operationalLayers.length - 1; j >= 0; j--) {
                                olyr = webmap.itemData.operationalLayers[j];
                                // Sigh. Doesn't seem to be a utility method to do this. It can do a whole map but not a layer at a time...
                                if (
                                    !mapView.map.findLayerById(olyr.id) ||
                                    (opts && opts.replace && existingIdx === j)
                                ) {
                                    pop =
                                        olyr.popupInfo !== undefined
                                            ? JSON.parse(JSON.stringify(olyr.popupInfo))
                                            : { fieldInfos: [] };
                                    flds = ['NAME'];
                                    for (var f in pop.fieldInfos.filter((f) => f.fieldName.indexOf('expression/') < 0))
                                        flds.push(pop.fieldInfos[f].fieldName);
                                    //for (var f in pop.expressionInfos.filter(f => f.name.indexOf('fldExpr') === 0)) flds.push(pop.expressionInfos[f].name.substring(7));
                                    pop = new PopupTemplate({
                                        ...pop,
                                        content: [{ type: 'fields', fieldInfos: [...pop.fieldInfos] }]
                                    });
                                    if (!mapView.map.findLayerById(olyr.id)) {
                                        // Feature collection - nasty - but should be there already...
                                        if (olyr.featureCollection !== undefined) {
                                        } else {
                                            featureLayer = new FeatureLayer(olyr.url, {
                                                id: olyr.id,
                                                opacity: olyr.opacity,
                                                popupTemplate: pop,
                                                outFields: flds // Really? How clunky is this - why not use the ALREADY specified feature infoTemplate???
                                            });
                                            if (olyr.layerDefinition && olyr.layerDefinition.minScale)
                                                featureLayer.minScale = olyr.layerDefinition.minScale;
                                            if (olyr.layerDefinition && olyr.layerDefinition.maxScale)
                                                featureLayer.maxScale = olyr.layerDefinition.maxScale;
                                            featureLayer.renderer = rendererLookup[olyr.id];
                                            mapView.map.layers.add(featureLayer);
                                        }
                                    } else if (opts && opts.replace && existingIdx === j) {
                                        featureLayer = mapView.map.findLayerById(olyr.id);
                                        featureLayer.popupTemplate = pop;
                                        if (olyr.layerDefinition && olyr.layerDefinition.minScale)
                                            featureLayer.minScale = olyr.layerDefinition.minScale;
                                        if (olyr.layerDefinition && olyr.layerDefinition.maxScale)
                                            featureLayer.maxScale = olyr.layerDefinition.maxScale;
                                        featureLayer.renderer = rendererLookup[olyr.id];
                                    }
                                }
                            }
                            if (opts.legend) {
                                for (let i in webmap.itemData.operationalLayers) {
                                    lyr = mapView.map.findLayerById(webmap.itemData.operationalLayers[i].id);
                                    if (lyr) {
                                        layerInfos.push({
                                            layer: lyr,
                                            title: webmap.itemData.operationalLayers[i].title
                                        });
                                    }
                                }
                                opts.legend.refresh(layerInfos);
                            }
                        }
                        return webmap;
                    });
                }
            );
        });
    }

    static get arcColorRamps() {
        return [
            {
                name: 'Red',
                colors: ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15']
            },
            {
                name: 'Purple',
                colors: ['#feebe2', '#fbb4b9', '#f768a1', '#c51b8a', '#7a0177']
            },
            {
                name: 'Blue',
                colors: ['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c']
            },
            {
                name: 'Cyan',
                colors: ['#e4f4f8', '#c0e7f0', '#6bc1d7', '#2198b5', '#07576b']
            },
            {
                name: 'Green',
                colors: ['#edf8e9', '#bae4b3', '#74c476', '#31a354', '#006d2c']
            },
            {
                name: 'Grey',
                colors: ['#f0f0f0', '#bdbdbd', '#111111']
            },
            {
                name: 'Red Yellow',
                colors: ['#ffffcc', '#feb24c', '#b10026']
            },
            {
                name: 'Blue Yellow',
                colors: ['#ffffd9', '#7fcddb', '#0c2c83']
            },
            {
                name: 'Green Yellow',
                colors: ['#ffffe5', '#addd8e', '#005a32']
            }
        ];
    }
}

export const redirectToSignIn = (props = { portalUrl: 'https://www.arcgis.com/sharing/rest' }) => {
    const { portalUrl, appAuthId, authExpiry } = props;
    window.location.href =
        portalUrl +
        '/oauth2/authorize?' +
        'client_id=' +
        appAuthId +
        '&response_type=token&redirect_uri=' +
        encodeURIComponent(window.location.href.split('#')[0]) +
        '&expiration=' +
        (authExpiry ? authExpiry : 2 * 24 * 60).toFixed();
};

export { loadModules, loadCss } from 'esri-loader';
