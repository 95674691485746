import React, { Component } from 'react';
import { setPageState } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { lowerKeyParams } from 'utils/object';
import { findCatalogTable } from 'api/catalogUtils';
import ItemsLinkList from 'components/ItemsLinkList';
import ListToggleButton from 'components/ListToggleButton';
import ModalDialog from 'components/ModalDialog';
import { isNullOrUndefined } from 'utils/object';
import 'pages/DataExplorer.scss';
import ProgressMessage from 'components/ProgressMessage';

class DataExplorerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataExplorerLoaded: false,
            dataExplorerLaunched: false,
            table: null,
            catalogs: []
        };
        this.toggleButton = React.createRef();
        this.explorerNode = React.createRef();
    }

    componentWillMount() {
        const { appLinks } = this.props,
            rootPath = (process.env.PUBLIC_URL !== undefined ? process.env.PUBLIC_URL : '/').replace(/(.*)\/$/, '$1');
        if (process.env.NODE_ENV === 'development') this.dataExplorerUrl = 'https://localhost:3000/';
        else if (!isNullOrUndefined(appLinks) && !isNullOrUndefined(appLinks.dataCatalogExplorer)) {
            this.dataExplorerUrl =
                appLinks.dataCatalogExplorer.url.substring(0, 1) !== '/'
                    ? `${rootPath}${appLinks.dataCatalogExplorer.url}`
                    : appLinks.dataCatalogExplorer.url;
        } else this.dataExplorerUrl = `/data-catalog-explorer/`;
        window.addEventListener('message', this.onFrameMessage, false);
    }

    onFrameMessage = (msg) => {
        if (
            !isNullOrUndefined(msg.origin) &&
            !isNullOrUndefined(msg.data) &&
            (msg.origin.indexOf(`${window.location.protocol}//${window.location.hostname}`) === 0 ||
                this.dataExplorerUrl.indexOf(msg.origin) === 0)
        ) {
            this.setState({
                dataExplorerLoaded: msg.data.type === 'dataExplorerLoad'
            });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('message', this.onFrameMessage);
    }

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-database"></i>
                <i className="fas fa-search"></i>
            </span>
        );
    }

    componentDidMount() {
        this.props.setPageState('Data Catalog | Explorer', this.getTitleIcon(), null, null);
        this.setState({
            isLoading: true,
            isError: false,
            dataExplorerLoaded: false
        });
        const qs = lowerKeyParams(new URLSearchParams(window.location.search));
        if (this.props.user !== null && this.props.user.username !== undefined && this.props.user.username !== null)
            this.loadMasterTableBasics(
                qs['item'],
                null,
                null,
                null,
                !isNullOrUndefined(qs['public']) && '1,true,yes'.indexOf(qs['public']) >= 0
            );
    }

    onCatalogChange = (evt, url, itemId) => {
        const { user } = this.props,
            { catalogs } = this.state;
        this.setState(
            {
                isLoading: true,
                isError: false,
                dataExplorerLoaded: false
            },
            () => {
                const c = catalogs.find((dc) => dc.id === itemId),
                    isLikelyPublic = c.url.indexOf(user.orgId) < 0;
                this.loadMasterTableBasics(itemId, null, null, null, isLikelyPublic);
                this.toggleButton.current.setState({
                    on: false
                });
            }
        );
    };

    loadMasterTableBasics = (itemId, itemInfo, tableItems, listHtml, includePublicCatalogs = false) => {
        const { user, token, portalUrl, portalHome } = this.props;
        if (itemId === undefined || itemId === null || itemInfo === undefined || itemInfo === null) {
            findCatalogTable({
                home: portalUrl,
                user: user,
                token: token,
                table: itemId,
                allowViewOnly: true,
                public: includePublicCatalogs,
                max: includePublicCatalogs ? 50 : -1
            }).then((catalogItems) => {
                const tableItem = catalogItems.table,
                    //mapItem = catalogItems.map,
                    possibles = catalogItems.available,
                    listHtml = catalogItems.html;
                if (tableItem !== undefined && tableItem !== null)
                    this.loadMasterTableBasics(tableItem.id, tableItem, possibles, listHtml);
                else {
                    const errMsg = !isNullOrUndefined(itemId) ? (
                        <FormattedMessage
                            id="explorer.invalidItemDialog.messageFormat"
                            defaultMessage="You cannot use {item} with the Data Explorer - either you do not have access or it cannot be found. Please contact {email} for more information."
                            values={{
                                item: <strong>{itemId}</strong>,
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="explorer.noValidItemsDialog.messageFormat"
                            defaultMessage="No suitable catalog items were found on {portal}. Data Explorer cannot start. Sometimes this is caused by temporary network issues - you could try to {reload}. If this error is persistent please contact {email} for more information."
                            values={{
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>,
                                portal: (
                                    <a href={portalHome} target="iaoArcWindow">
                                        {portalHome} <i className="fas fa-external-link-alt"></i>
                                    </a>
                                ),
                                reload: (
                                    <a
                                        href="?action=reload"
                                        onClick={() => {
                                            window.location.reload();
                                            return false;
                                        }}
                                    >
                                        <FormattedMessage
                                            id="manager.noValidItemsDialog.button.reload"
                                            defaultMessage="Reload {icon}"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                )
                            }}
                        />
                    );

                    this.setState({
                        isLoading: false,
                        isError: true,
                        error: {
                            message: errMsg,
                            key: 'NoMasterTable',
                            buttons: [
                                <Link to="/" className="btn btn-default btn-secondary">
                                    <FormattedMessage
                                        id="explorer.errorDialog.button.close"
                                        defaultMessage="{icon} Cancel"
                                        values={{
                                            icon: <i className="fas fa-times"></i>
                                        }}
                                    />
                                </Link>
                            ]
                        },
                        catalogs: possibles
                    });
                }
            });
        } else {
            this.props.setPageState('Data Catalog | Explorer', this.getTitleIcon(), null, {
                id: itemId,
                text: (
                    <span>
                        {itemInfo.title}{' '}
                        <a href={`https://www.arcgis.com/home/item.html?id=${itemId}`} target="iaoArcWindow">
                            <i className="fas fa-external-link-alt" style={{ fontSize: '0.9em' }}></i>
                        </a>
                    </span>
                )
            });
            // Ping the master table to make sure it is there...
            fetch(`${itemInfo.url}/0?f=json&token=${token}`).then((svcRsp) => {
                svcRsp.json().then((svcInfo) => {
                    if (!isNullOrUndefined(svcInfo.error)) this.relayError(svcInfo.error, itemInfo);
                    else {
                        this.setState(
                            {
                                table: itemInfo,
                                isLoading: false,
                                isError: false,
                                dataExplorerLoaded: false, // Needs to wait for a render
                                messages: [],
                                progress: 0,
                                catalogs: tableItems
                            },
                            () => {
                                window.history.pushState(
                                    '',
                                    '',
                                    `?item=${itemInfo.id}${includePublicCatalogs ? '&public=yes' : ''}`
                                );
                            }
                        );
                    }
                });
            });
        }
    };

    relayError = (err, itemInfo) => {
        const { portalType } = this.props;
        this.setState({
            isLoading: false,
            isError: true,
            error: {
                title: (
                    <FormattedMessage
                        id="explorer.errorDialog.title"
                        defaultMessage="Unexpected Error"
                        values={{
                            name: <strong>{err.code}</strong>
                        }}
                    />
                ),
                message: (
                    <FormattedMessage
                        id="explorer.errorDialog.messageFormat"
                        tagName="div"
                        defaultMessage="InstantAtlas encountered an unexpected error while connecting to ArcGIS {portal}{itemLink}. This can happen when a network error or slowdown occurs, or if your authentication has timed out (you can sign in on the {home}). Please {reload} and try again. The error message was: {message}"
                        values={{
                            portal: portalType,
                            message: (
                                <div>
                                    <br />
                                    <div className="bg-danger pad10">
                                        {err.code} {err.message}{' '}
                                        {!isNullOrUndefined(err.details) ? (
                                            <span>
                                                <br />
                                                {err.details}
                                            </span>
                                        ) : null}
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ),
                            home: (
                                <Link to="/">
                                    <FormattedMessage
                                        id="explorer.errorDialog.homeLink"
                                        defaultMessage="Hub home page"
                                    />
                                </Link>
                            ),
                            reload: (
                                <strong>
                                    <a href={`${window.location.pathname}?#reset`}>
                                        <FormattedMessage
                                            id="explorer.errorDialog.reloadLink"
                                            defaultMessage="{icon} reload and reset this page"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                </strong>
                            ),
                            itemLink: isNullOrUndefined(itemInfo) ? null : (
                                <FormattedMessage
                                    id="explorer.errorDialog.itemLink"
                                    defaultMessage=" (for {arcLink})"
                                    values={{
                                        arcLink: (
                                            <a
                                                href={`https://www.arcgis.com/home/item.html?id=${itemInfo.id}`}
                                                target="iaoArcWindow"
                                            >
                                                {itemInfo.title}{' '}
                                                <i
                                                    className="fas fa-external-link-alt"
                                                    style={{ fontSize: '0.9em' }}
                                                ></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                ),
                key: !isNullOrUndefined(err) && !isNullOrUndefined(err.code) ? err.code : 'FatalError',
                buttons: [
                    <a
                        href={`${window.location.pathname}?#reset`}
                        className="btn btn-primary"
                        onClick={this.clearError}
                    >
                        <FormattedMessage
                            id="explorer.errorDialog.button.reload"
                            defaultMessage="{icon} Reload"
                            values={{
                                icon: <i className="fas fa-sync"></i>
                            }}
                        />
                    </a>,
                    <Link to="/" className="btn btn-secondary">
                        <FormattedMessage
                            id="explorer.errorDialog.button.close"
                            defaultMessage="{icon} Cancel"
                            values={{
                                icon: <i className="fas fa-times"></i>
                            }}
                        />
                    </Link>
                ]
            }
        });
    };

    render() {
        const { user, token, portalType, portalUrl } = this.props,
            { catalogs, table, isLoading, dataExplorerLoaded, error } = this.state,
            auth = user !== undefined && user !== null && user.username !== undefined && user.username !== null,
            frameLoading = isLoading || !dataExplorerLoaded,
            ff = 'Avenir-light', //(!isNullOrUndefined(portalType) && (portalType.toLowerCase() === 'online') ? 'Work%20Sans' : 'work_sans_regular'),
            dataExplorerUrl = !isNullOrUndefined(table)
                ? `${this.dataExplorerUrl}?item=${
                      table.id
                  }&access_token=${token}&fontFamily=${ff}&portalType=${portalType}&portalUrl=${encodeURIComponent(
                      portalUrl
                  )}&themeColor=%238CBF26`
                : '';
        return auth ? (
            <div>
                <div className="catalog-switcher-top">
                    <ListToggleButton
                        ref={this.toggleButton}
                        text={<i className="fas fa-bars fa-fw"></i>}
                        tooltip="Choose Catalog"
                        className="btn btn-link pure-tip pure-tip-bottom"
                    >
                        <ItemsLinkList
                            items={catalogs}
                            action={this.onCatalogChange}
                            linkIcon={
                                <span>
                                    <i className="fas fa-fw fa-database"></i>
                                    <i className="fas fa-search"></i>{' '}
                                </span>
                            }
                        />
                    </ListToggleButton>
                </div>
                <div className="catalog-explorer">
                    <div className="data-explorer-frame">
                        <iframe ref={this.explorerNode} src={dataExplorerUrl} title="Data Explorer"></iframe>
                        {frameLoading ? (
                            <div ref={this.explorerMask} className="masker">
                                <ProgressMessage />
                            </div>
                        ) : null}
                    </div>
                </div>
                {!isNullOrUndefined(error) && !isNullOrUndefined(error.message) ? (
                    <ModalDialog
                        title={error.title !== undefined ? error.title : 'Error'}
                        show={true}
                        onClose={this.clearError}
                        buttons={
                            !isNullOrUndefined(error.buttons)
                                ? error.buttons
                                : [
                                      <button type="button" className="btn btn-secondary" onClick={this.clearError}>
                                          <FormattedMessage
                                              id="manager.errorDialog.button.close"
                                              defaultMessage="{icon} Close"
                                              values={{
                                                  icon: <i className="fas fa-times"></i>
                                              }}
                                          />
                                      </button>
                                  ]
                        }
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: 10 + 'px',
                                        marginBottom: 10 + 'px',
                                        fontSize: '48px'
                                    }}
                                >
                                    {error.type !== undefined && error.type === 'warning' ? (
                                        <i className="fas fa-exclamation-triangle"></i>
                                    ) : (
                                        <i className="fas fa-times-circle"></i>
                                    )}
                                </div>
                                <div style={{ marginLeft: 68 + 'px' }}>{error.message}</div>
                            </div>
                        </div>
                    </ModalDialog>
                ) : null}
            </div>
        ) : (
            <Redirect to="/" />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        appLinks: state.hubAppSettings.appLinks,
        user: state.hubAppSettings.user,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(DataExplorerPage)));
